import { Box, Grid, Card, CardHeader, CardContent } from "@mui/material";
import { RiskScorePanel } from "../risk_score/RiskScorePanel";

function MembersOverview() {
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader>{/* Leave for consistent spacing with other pages*/}</CardHeader>
						<CardContent>
							<RiskScorePanel />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export { MembersOverview };
