import React from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";
import * as CSS from "csstype";

interface ParametersItemProps {
  statLabel: string;
  statValue: string;
  statValueColor: CSS.Property.Color | undefined;
}

function ParametersItem(props: ParametersItemProps) {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" py={2}>
        <Typography sx={{ textTransform: "uppercase", fontWeight: 700 }}>
          {props.statLabel}
        </Typography>
        <Typography
          sx={{ color: props.statValueColor || "gray.main", fontWeight: 700 }}
        >
          {props.statValue}
        </Typography>
      </Stack>
      <Divider></Divider>
    </Box>
  );
}

export default ParametersItem;
