import { FilterQueryRequest, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { RequestModelsCacheMonthlyQueryRequest, getMedicationAggregations } from "api";
import { useQuery } from "react-query";

function getMedicationAggregationsX(request: Partial<RequestModelsCacheMonthlyQueryRequest>) {
	return getMedicationAggregations(request);
}

const useX_getMedicationAggregations = (
	codes: Array<string>,
	fqr?: Partial<FilterQueryRequest>,
	includeLookup: boolean = false,
	sortOrderRequest: SortOrderRequest | null = null
) => {
	let fqrX = { ...useFilterQuery(), ...fqr };

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: fqrX.startDate,
			endDate: fqrX.endDate,
		},
		jobIds: fqrX.memberTypes,
		codes: codes,
		includeLookup: includeLookup,
		itemType: "MEDICATION",
	};
	if (sortOrderRequest) {
		request.paginationRequest = {
			field: sortOrderRequest.sortBy,
			descending: sortOrderRequest.orderBy === "DESC",
			skip: 0,
			take: 0,
		};
	}

	let cacheKeys = ["getMedicationAggregations", "getMedicationAggregations_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getMedicationAggregationsX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getMedicationAggregations };
