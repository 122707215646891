/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CacheTreatmentDataPopulateNDCCodeDataResult,
  CacheTreatmentDataAddNdcDrugstructuresAddNdcDrugstructuresResponse,
  CacheTreatmentDataAddNdcDrugstructuresRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const populateNDCCodeData = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheTreatmentDataPopulateNDCCodeDataResult>(
      {url: `/api/admin/CacheTreatmentData/PopulateNDCCodeData`, method: 'get'
    },
      options);
    }
  

export const getPopulateNDCCodeDataQueryKey = () => [`/api/admin/CacheTreatmentData/PopulateNDCCodeData`];

    
export const usePopulateNDCCodeData = <TData = AsyncReturnType<typeof populateNDCCodeData>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof populateNDCCodeData>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getPopulateNDCCodeDataQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof populateNDCCodeData>> = () => populateNDCCodeData(requestOptions);

  const query = useQuery<AsyncReturnType<typeof populateNDCCodeData>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addNdcDrugStructures = (
    cacheTreatmentDataAddNdcDrugstructuresRequest: CacheTreatmentDataAddNdcDrugstructuresRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheTreatmentDataAddNdcDrugstructuresAddNdcDrugstructuresResponse>(
      {url: `/api/admin/CacheTreatmentData/AddNdcDrugStructures`, method: 'post',
      data: cacheTreatmentDataAddNdcDrugstructuresRequest
    },
      options);
    }
  


    export const useAddNdcDrugStructures = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addNdcDrugStructures>, TError,{data: CacheTreatmentDataAddNdcDrugstructuresRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addNdcDrugStructures>, {data: CacheTreatmentDataAddNdcDrugstructuresRequest}> = (props) => {
          const {data} = props || {};

          return  addNdcDrugStructures(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addNdcDrugStructures>, TError, {data: CacheTreatmentDataAddNdcDrugstructuresRequest}, TContext>(mutationFn, mutationOptions)
    }
    