import { Button } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";

type ViewEntityDetailButtonProps = {
	itemType: UserFollowedItemTypeNames | "ALLOW_CACHEITEMTYPE"; // If set to ALLOW_CACHEITEMTYPE then use the provided cacheItemType and return null if it is not set
	cacheItemType?: CacheMonthlyTypes;
	code: string;
	forceCacheItemDetails?: boolean;
};

const ViewEntityDetailButton = ({ itemType, code, forceCacheItemDetails, cacheItemType }: ViewEntityDetailButtonProps) => {
	const assuredItemType = useMemo(() => {
		if (itemType === "ALLOW_CACHEITEMTYPE") {
			return cacheItemType;
		}
		return itemType;
	}, [itemType, cacheItemType]);
	if (!assuredItemType) {
		return null;
	}
	return (
		<Link to={`/itemDetails?code=${code}&itemType=${assuredItemType}${forceCacheItemDetails === true ? "&forceCacheItemDetails=true" : ""}`}>
			<Button size="small" color="secondary">
				Details
			</Button>
		</Link>
	);
};

export { ViewEntityDetailButton };
