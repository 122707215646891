import { Grid, Card, CardHeader, CardContent, Box } from "@mui/material";
import ValueTile from "../../../../shared/components/ValueTile";
import { palette } from "../../../../theme";
import { displayConverter } from "../../../../shared/utilities/displayConverter";
import { AdminGetConditionDetailsResult, ModelsDiagnosisRangeCacheMonthlyAggregate, ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse } from "api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Loader } from "shared/components/Loaders/Loader";
import { useMemo } from "react";
import { DiagnosisRangeApiRequest } from "../../../item_details/diagnosis/DiagnosisDetailsMain";
import { ChartColorHelper } from "shared/utilities/ChartColorHelper";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";
import { useX_getDiagnosis_GenderAndAge } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosis_GenderAndAge";

SwiperCore.use([Pagination]);

type DiagnosisDetailsOverviewProps = {
	diagnosisID: string;
	api_data?: AdminGetConditionDetailsResult;
	rangeAggregateItem?: ModelsDiagnosisRangeCacheMonthlyAggregate;
	totalMembers: number;
	diagnosisRangeApiRequest: DiagnosisRangeApiRequest;
	code?: string | null;
	range?: string | null;
	genderAndAge?: ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse;
};

function DiagnosisDetailsOverview(props: DiagnosisDetailsOverviewProps) {
	const { api_data, rangeAggregateItem, totalMembers, genderAndAge: genderAndAgeExternal, code, range } = props;

	// Fix issue with genderAndAge with expecting it to be provided externally. Not sure what happend to cause this but replace if missing with new call
	let genderAndAge = genderAndAgeExternal;
	let genderAndAge_ = useX_getDiagnosis_GenderAndAge(code ? [code] : null, range ? [range] : null).data?.response;
	if (!genderAndAge) {
		genderAndAge = genderAndAge_;
	}

	const pieChart = useMemo(() => {
		if (genderAndAge) {
			let pieData: Array<{ name: string; value: number }> = genderAndAge.genderModels.map((x) => {
				return { name: x.name ?? "N/A", value: x.count ?? 0 };
			});
			return (
				<StandardPieChart
					data={pieData}
					colors={ChartColorHelper.colorArrayForNameValuesData(pieData)}
					showLegend
					responsiveHeight={300}
					innerRadius={32}
					displayPercentage
					tooltipFormatter={(value: number) => displayConverter.insertThousandsCommas(value)}
				/>
			);
		}
		return null;
	}, [genderAndAge]);

	const barChart = useMemo(() => {
		if (genderAndAge) {
			let ageGendersModels = genderAndAge.ageGenderModels;
			let barData = ageGendersModels.map((agm) => {
				let a: { [key: string]: number | string; name: string } = { name: agm.ageKey };
				agm.genderCounts.forEach((gc) => {
					a[gc.name.toLowerCase()] = gc.value;
				});
				return a;
			});

			barData.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});

			return (
				<StandardBarChart
					data={barData}
					dataKey="name"
					showYAxis
					responsiveHeight={300}
					textAnchor="middle"
					legendStyle={{ paddingBottom: "1rem" }}
					bar_definitions={[
						{
							dataKey: "male",
							name: "Male",
							fill: palette.green.main,
							legendType: "circle",
						},
						{
							dataKey: "female",
							name: "Female",
							fill: palette.purple.main,
							legendType: "circle",
						},
					]}
				/>
			);
		}
		return null;
	}, [genderAndAge]);

	console.log({ api_data, rangeAggregateItem });
	if (!api_data || !rangeAggregateItem) {
		return <Loader />;
	}
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 3,
							},
							"1200": {
								slidesPerView: 4,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile
								value={
									rangeAggregateItem.uniquePatientCount / totalMembers > 0.01
										? displayConverter.toPercent(rangeAggregateItem.uniquePatientCount / totalMembers)
										: "<1%"
								}
								title="PCT of Total Members"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.uniquePatientCount)} title="Members" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.eobCount)} title="Diagnosis Count" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(Number(rangeAggregateItem.totalAmount))} title="Total Spend" minHeight="7rem" />
						</SwiperSlide>
					</Swiper>
				</Box>
			</Grid>
			<Grid item xs={12} md={12}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Gender"></CardHeader>
					<CardContent>{pieChart ?? <Loader />}</CardContent>
				</Card>

				<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
					<CardHeader title="Age"></CardHeader>
					<CardContent>{barChart ?? <Loader />}</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export { DiagnosisDetailsOverview };
