import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Box, ButtonBase, Menu, MenuItem, FormControl, FormControlLabel, Radio, RadioGroup, FormGroup, Checkbox, Typography } from "@mui/material";
import Icon from "../Icon";
import { icons } from "../../theme";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Pill } from "./Pill";

type SelectOptions = {
	value: string;
	label: string;
};
type DropDownSelectProps = {
	title: string;
	icon?: React.ReactNode;
	handleChange?: (ev: CIM | null, value: Array<string>, selected?: boolean) => any;
	type: "radio" | "checkbox";
	options?: Array<SelectOptions>;
	ariaLabel?: string;
	defaultValue?: string;
	selectedValues?: Array<string>;
	labelSx?: SxProps<Theme>;
	setValues?: React.Dispatch<React.SetStateAction<string[]>> | ((x: Array<string>) => any);
	displaySelected?: boolean;
	set_selectedDisplayList?: React.Dispatch<React.SetStateAction<ReactNode>>;
};

function DropDownSelect(props: DropDownSelectProps) {
	const [anchor, setAnchor] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchor(event.currentTarget);
	};
	const handleClose = () => {
		setAnchor(null);
	};

	const handleChangeRadio = (ev: React.ChangeEvent, value: string) => {
		if (props.setValues) {
			props.setValues([value]);
		}
		if (props.handleChange) {
			props.handleChange(ev, [value]);
		}
	};

	const handleChangeCheckbox = (value: string, x: SelectOptions, selected: boolean) => {
		if (props.selectedValues !== undefined && props.setValues) {
			let selection = new Set(props.selectedValues);
			if (selected) {
				selection.add(value);
			} else {
				selection.delete(value);
			}
			props.setValues(Array.from(selection));
		}
		if (props.handleChange) {
			props.handleChange(x, [value], selected);
		}
	};

	const open = Boolean(anchor);
	const id = props.title.toLowerCase().split(" ").join("-");

	var labelSx = props.labelSx ?? { ml: 0.5, mr: 0.5 };

	const selectedItemsDisplay = useMemo(() => {
		if (!props.displaySelected && !props.set_selectedDisplayList) {
			return null;
		}
		return (
			<>
				{props.options
					?.filter((x) => {
						return props.selectedValues ? props.selectedValues.indexOf(x.value) !== -1 : false;
					})
					.map((x) => {
						return (
							<Pill
								key={x.label}
								onX={() => {
									handleChangeCheckbox(x.value, x, false);
								}}
							>
								<Typography>{x.label}</Typography>
							</Pill>
						);
					})}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.displaySelected, props.selectedValues, props.options]);

	useEffect(() => {
		props.set_selectedDisplayList?.(selectedItemsDisplay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItemsDisplay]);

	return (
		<Box style={{ flexGrow: 1 }}>
			<ButtonBase id={`${id}-button`} aria-controls={`${id}-menu`} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
				{props.icon ? props.icon : null}
				<Box sx={labelSx}>{props.title}</Box>
				<Icon fixedWidth icon={icons.angleDown} />
			</ButtonBase>
			<Menu
				id={`${id}-menu`}
				anchorEl={anchor}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": `${id}-button`,
				}}
			>
				<MenuItem>
					{props.type === "radio" ? (
						<FormControl component="fieldset">
							<RadioGroup
								aria-label={props.ariaLabel}
								name={props.title}
								defaultValue={!props.selectedValues ? props.defaultValue : undefined}
								onChange={handleChangeRadio}
							>
								{props.options?.map((item) => {
									return (
										<FormControlLabel
											value={item.value}
											control={<Radio checked={props.selectedValues ? props.selectedValues.indexOf(item.value) !== -1 : undefined} />}
											label={item.label}
											key={item.value}
											data-check={`${item.label} ${item.value}`}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					) : props.type === "checkbox" ? (
						<FormGroup>
							{props.options?.map((item) => {
								let isSelected = props.selectedValues ? props.selectedValues.indexOf(item.value) !== -1 : false;
								return (
									<FormControlLabel
										value={item.value}
										control={
											<Checkbox
												value={item.value}
												//defaultChecked={!isSelected}
												checked={isSelected}
											/>
										}
										label={item.label}
										key={item.value}
										onChange={(event: any) => {
											handleChangeCheckbox(item.value, item, !isSelected);
										}}
									/>
								);
							})}
						</FormGroup>
					) : null}
				</MenuItem>
			</Menu>
			{props.displaySelected ? selectedItemsDisplay : null}
		</Box>
	);
}

export { DropDownSelect };

export type { SelectOptions };
