/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminRetrieveCacheDoctorDataResult,
  AdminRetrieveCacheDoctorDataRequest,
  AdminGetDoctorsByDiagnosisResult,
  AdminGetDoctorsByDiagnosisQuery,
  AdminGetDoctorsByMedicationResult,
  AdminGetDoctorsByMedicationQuery,
  AdminGetDoctorsByProductServiceResult,
  AdminGetDoctorsByProductServiceQuery,
  AdminGetDoctorDataByNpiResult,
  AdminGetDoctorDataByNpiQuery,
  AdminEnrichMissingProviderSpecialityResult,
  AdminEnrichMissingProviderSpecialityCommand
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const retrieveCacheDoctorData = (
    adminRetrieveCacheDoctorDataRequest: AdminRetrieveCacheDoctorDataRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminRetrieveCacheDoctorDataResult>(
      {url: `/api/admin/CacheDoctorData/RetrieveCacheDoctorData`, method: 'post',
      data: adminRetrieveCacheDoctorDataRequest
    },
      options);
    }
  


    export const useRetrieveCacheDoctorData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof retrieveCacheDoctorData>, TError,{data: AdminRetrieveCacheDoctorDataRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof retrieveCacheDoctorData>, {data: AdminRetrieveCacheDoctorDataRequest}> = (props) => {
          const {data} = props || {};

          return  retrieveCacheDoctorData(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof retrieveCacheDoctorData>, TError, {data: AdminRetrieveCacheDoctorDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByDiagnosis = (
    adminGetDoctorsByDiagnosisQuery: AdminGetDoctorsByDiagnosisQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDoctorsByDiagnosisResult>(
      {url: `/api/admin/CacheDoctorData/GetDoctorsByDiagnosis`, method: 'post',
      data: adminGetDoctorsByDiagnosisQuery
    },
      options);
    }
  


    export const useGetDoctorsByDiagnosis = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorsByDiagnosis>, TError,{data: AdminGetDoctorsByDiagnosisQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorsByDiagnosis>, {data: AdminGetDoctorsByDiagnosisQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorsByDiagnosis(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorsByDiagnosis>, TError, {data: AdminGetDoctorsByDiagnosisQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByMedication = (
    adminGetDoctorsByMedicationQuery: AdminGetDoctorsByMedicationQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDoctorsByMedicationResult>(
      {url: `/api/admin/CacheDoctorData/GetDoctorsByMedication`, method: 'post',
      data: adminGetDoctorsByMedicationQuery
    },
      options);
    }
  


    export const useGetDoctorsByMedication = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorsByMedication>, TError,{data: AdminGetDoctorsByMedicationQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorsByMedication>, {data: AdminGetDoctorsByMedicationQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorsByMedication(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorsByMedication>, TError, {data: AdminGetDoctorsByMedicationQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByProductService = (
    adminGetDoctorsByProductServiceQuery: AdminGetDoctorsByProductServiceQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDoctorsByProductServiceResult>(
      {url: `/api/admin/CacheDoctorData/GetDoctorsByProductService`, method: 'post',
      data: adminGetDoctorsByProductServiceQuery
    },
      options);
    }
  


    export const useGetDoctorsByProductService = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorsByProductService>, TError,{data: AdminGetDoctorsByProductServiceQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorsByProductService>, {data: AdminGetDoctorsByProductServiceQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorsByProductService(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorsByProductService>, TError, {data: AdminGetDoctorsByProductServiceQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorDataByNpi = (
    adminGetDoctorDataByNpiQuery: AdminGetDoctorDataByNpiQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDoctorDataByNpiResult>(
      {url: `/api/admin/CacheDoctorData/GetDoctorDataByNpi`, method: 'post',
      data: adminGetDoctorDataByNpiQuery
    },
      options);
    }
  


    export const useGetDoctorDataByNpi = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorDataByNpi>, TError,{data: AdminGetDoctorDataByNpiQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorDataByNpi>, {data: AdminGetDoctorDataByNpiQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorDataByNpi(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorDataByNpi>, TError, {data: AdminGetDoctorDataByNpiQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const enrichMissingProviderSpeciality = (
    adminEnrichMissingProviderSpecialityCommand: AdminEnrichMissingProviderSpecialityCommand,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminEnrichMissingProviderSpecialityResult>(
      {url: `/api/admin/CacheDoctorData/EnrichMissingProviderSpeciality`, method: 'post',
      data: adminEnrichMissingProviderSpecialityCommand
    },
      options);
    }
  


    export const useEnrichMissingProviderSpeciality = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof enrichMissingProviderSpeciality>, TError,{data: AdminEnrichMissingProviderSpecialityCommand}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof enrichMissingProviderSpeciality>, {data: AdminEnrichMissingProviderSpecialityCommand}> = (props) => {
          const {data} = props || {};

          return  enrichMissingProviderSpeciality(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof enrichMissingProviderSpeciality>, TError, {data: AdminEnrichMissingProviderSpecialityCommand}, TContext>(mutationFn, mutationOptions)
    }
    