import { Dispatch, SetStateAction } from "react";

type MonthDefinition = { name: string; abbr: string; number: number };
const monthDefinitions: Array<MonthDefinition> = [
	{ name: "January", abbr: "Jan", number: 1 },
	{ name: "February", abbr: "Feb", number: 2 },
	{ name: "March", abbr: "Mar", number: 3 },
	{ name: "April", abbr: "Apr", number: 4 },
	{ name: "May", abbr: "May", number: 5 },
	{ name: "June", abbr: "Jun", number: 6 },
	{ name: "July", abbr: "Jul", number: 7 },
	{ name: "August", abbr: "Aug", number: 8 },
	{ name: "September", abbr: "Sep", number: 9 },
	{ name: "October", abbr: "Oct", number: 10 },
	{ name: "November", abbr: "Nov", number: 11 },
	{ name: "December", abbr: "Dec", number: 12 },
];

export type { MonthDefinition };

const MonthRangeUtility = {
	monthDefinitions: monthDefinitions,

	constructMonthsByYearFromRange(
		startLimitYYYYMM: string,
		endLimitYYYYMM: string
	): {
		[key: number]: number[];
	} {
		const fullMonthsOptionsList: Array<Array<number>> = [];
		var [sY, sM] = startLimitYYYYMM.split("-").map((v) => Number(v));
		var [eY, eM] = endLimitYYYYMM.split("-").map((v) => Number(v));
		var [y, m] = [sY, sM];
		var complete = false;
		if (sY > eY || (sY >= eY && sM > eM)) {
			console.error("Invalid Date Limits");
			return [];
		}
		while (fullMonthsOptionsList.length < 1000 && !complete) {
			fullMonthsOptionsList.push([y, m]);
			if (m === 12) {
				m = 1;
				y++;
			} else {
				m++;
			}
			if (y > eY || (y >= eY && m > eM)) {
				complete = true;
			}
		}
		// console.debug(fullMonthsOptionsList);

		// var years = [];
		var monthsByYear: { [key: number]: Array<number> } = {};
		fullMonthsOptionsList.forEach((ym) => {
			let [y, m] = ym;
			if (!monthsByYear[y]) {
				monthsByYear[y] = [m];
			} else {
				monthsByYear[y].push(m);
			}
		});
		// var years = Object.getOwnPropertyNames(monthsByYear);
		return monthsByYear;
	},

	setWithinLowestAndHighest(months: Array<number>, active: number, set_active: Dispatch<SetStateAction<number>>) {
		let [lowestMonth, highestMonth] = MonthRangeUtility.getLowestAndHighestMonthsInNumberArray(months);
		if (lowestMonth > active) {
			set_active(lowestMonth);
		} else if (highestMonth < active) {
			set_active(highestMonth);
		}
	},

	getLowestAndHighestMonthsInNumberArray(months: Array<number>) {
		let mNum = months.sort((x, y) => (x > y ? 1 : x < y ? -1 : 0));
		return [mNum[0], mNum[mNum.length - 1]];
	},

	getDateEdgeForToken(token: string, edgeType: "leading" | "trailing"): Date {
		let d = new Date("0000-01-01T00:00:00");
		// console.debug(d);
		let v = token.split("-");
		let year = parseInt(v[0]);
		let month = v.length > 1 ? parseInt(v[1]) : edgeType === "leading" ? 1 : 11;
		d.setFullYear(year);
		d.setMonth(month - (edgeType === "leading" ? 1 : 0));
		if (edgeType === "trailing") {
			d = new Date(d.valueOf() - 1);
		}
		return d;
	},

	getDateEdgeForMonth(year: number, month: number, edgeType: "leading" | "trailing"): Date {
		let d = new Date("0000-01-01T00:00:00");
		// console.debug(d);
		d.setFullYear(year);
		d.setMonth(month - (edgeType === "leading" ? 1 : 0));
		if (edgeType === "trailing") {
			d = new Date(d.valueOf() - 1);
		}
		return d;
	},

	getTokenForDate(date: Date) {
		return `${String(date.getFullYear()).padStart(4, "0")}-${String(date.getMonth() + 1).padStart(2, "0")}`;
	},
};

export { MonthRangeUtility };
