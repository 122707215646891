import { APIConfig } from "./config/APIConfig";

export const apiRequest = (
	config: {
		api_type?: "RadiusAPI";
		url: string;
		method: string;
		fetch_options?: { [key: string]: any };
		response_type?: "text" | "json";
		[key: string]: any;
	},
	options?: { [key: string]: any }
): Promise<any> => {
	const promise = new Promise((resolve, reject) => {
		let url = "";
		if (config.api_type === "RadiusAPI") {
			url = APIConfig.BaseUrl + "/api/";
		}
		url += config.url;
		// console.debug("apiRequest:", url);
		fetch(url, {
			method: config.method ?? "GET",
			...(config.fetch_options ?? {}),
		})
			.then(async (response) => {
				console.debug("apiRequest", url, response);
				if (!config?.response_type || config.response_type === "json") {
					let j: any = null;
					try {
						j = await response.json();
					} catch (e) {
						console.debug(e);
					}
					return j;
				} else if (config.response_type === "text") {
					return response.text();
				} else {
					return response;
				}
			})
			.then((data) => {
				resolve(data);
			})
			.catch((e) => {
				console.debug(e);
				reject({
					success: false,
					description: "Failed to connect or received unhandled error",
				});
			});
	});

	return promise;
};

// Would like to catch when auth has expired and display a modal prompting user to log in back in
// 400 code
// AuthFlow	"REFRESH_TOKEN_AUTH"
// Full JSON response for logged out -  {"ClientId":"7pnlc7a2788dbra7vpbcp1encp","AuthFlow":"REFRESH_TOKEN_AUTH","AuthParameters":{"REFRESH_TOKEN":"eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.YoRdL45zShjb1r40xAF_14lg1YwUXEhkL4YYuy2tPwJsOKh7NNWLhjBoIf6jtTR2EsvGyedj-V_EFseTQJ5UZpjVn4v4O148LW-51jwHs8wBojiO87U2Jj0LRWFComdmUXx_PWXBTHwwHofBUvgCYIg5n5BDsvyBVliwRryje5zA1p_J7FMTl8DoLCL8OIDgNxE6TrJ4nx-90HqMP9nT8LULmq6LV3E7Y5Rtco4ZwtGk42rRSmDCdEoYnTf6i2a7wPdrWvhzDov1BUh47oyIqBDZ-JTZVEaz5YJocbu7XRQG8ZgG8bjCUaxdhTNJEPBtuWrbq2LE41J-CPQyCJIDEw.DWNJ7_U158loA-ks.Y9J6jx4ytBZXVp55vN72pbyUp2zpFFkDuuldVO1-8J3w51wxe_u7izFqP494065NMqXk1ErSdlo_C4xeTkq17ozoyXYiOExH5rDkFpirB6iVcMP_gpOcvRitiLdMANGPztYLD20PCSW10n-C0gBx3eRHOQdaBV00ZpOBsL_dVvB4BNJL8qG4Q0V5VeqOXwIExmOsIta1io0q9c4Lq2ZmBrNRY9SfMSZmx8K4iHvhZbHVWgP8Akm3OyiQz6-NEai3x4aq9JvgvjqqYUnnKhxOQ4UtBagi6VBwvuzy775JUfzXfLQ006C-M9EEvtW5wTbFiPMgitkylSu5busKJ-noXTDNb4-3aJZI39vIbPY7GlOP-JaUVnOQScYQO9PAyjhoVa7GO92EnI_wgf-4qNUDk_15K6H9dkMV6zKL_ZD5EWOVoOjyEmz5_XQNM6Ug3z5lv5wPdGC-uVA8dyWbhQfn7VQbK1FZuuR6ySqKy1b-VayllkPCpyV94Md1Aj-tKTDK8671RQ1mnWG1XCO8k7wY3qzLhXh5epxuvnjW2j4l9PRe1PqzIBJGzZjHed8h7-1Sh1-OyNPDyGnmxJjirCgiY6aVetLcXocCa_8CgoDQeDguJiLQjDNLrssyZx61fR43WcrBSRFRytRW2VzpcRGaNCQ408QQ6U11GUU3WbekKDXIcfvmq2_zjv5-fgecAyPvTEaCeVfxJZwKU6UQk50sduUh2G56DQm0QTdLx-UFz0fONHGzRebcb7DYxW1ZEpBsYlRDDqN4OHR4KtDqmtYgSTdw27ksYY0T9BvI-gL6uBESgJ76KHP1EmmWtDw2ZUW9AsoJKz-ptL0PiJpZpJVMfDHo0Afvs3hdP1m0FCSZMTb3ngdatxPDsZ0RZc2jNIi8iOZlWAvSOu_rNuO7GMoSLR1BiKpEEfb-I7i8oNwwgPICY5AX-74HLyut3GPcqTIgFuJfmZujZ1ulnVMwK-m8tenZb7kiFFtLPjEvv7v1TrhGEdFUu7WT160GKZaE2npTase7296B6uyKA5XPWPOgPxvCQYz5NZIVJkx7sqXWggrWcHFX8pPqmutL7cIMpZUWbCX9rvYaVZrfua-KsCNDorY7i7HPCQ5bhQ_LQ7boCVm-z2uK2nB2TMkNmBcfvH6arkjW875lfjhGemn6ihQjFp1r5HWRQmD0ip-dwS1_jQl4WORi_x-cAlabuzENNuXFIEMYqKlaixwzIkNNE8NO58_8gr9rqDincEG9E75IbcGoR6sSKaBw56K6cG5ARRfEYeb8W3xGrBLjLG0mLzbp3DAi.d715wA1wYjSwgs7DS4dlPg","DEVICE_KEY":null}}
