import {
	Box,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Button,
	Alert,
	TextField,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
} from "@mui/material";
import { addUser } from "api/users/users";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { EmailMaskedInput, PhoneMaskedInput } from "shared/forms/InputMasks";
import { Contact, Name, useGetAllDistinctJobIds } from "api";
import { useMemo } from "react";

interface AddUserForm {
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	userRole: string;
	allowedInsuranceTypes: string[];
}

function AddUser() {
	const { control, handleSubmit } = useForm<AddUserForm>({
		defaultValues: {
			username: "",
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			userRole: "Limited",
			allowedInsuranceTypes: [],
		},
	});
	const { enqueueSnackbar } = useSnackbar();
	let eobDataTypes = useGetAllDistinctJobIds();
	let allRoles = ["Administrator", "Limited"];

	const memberTypes = useMemo(() => {
		return eobDataTypes?.data?.dataTypes;
	}, [eobDataTypes?.data?.dataTypes]);

	const onSubmit = handleSubmit((form) => {
		const name: Name = {
			first: form.firstName,
			last: form.lastName,
		};
		const contact: Contact = {
			email: form.email,
			phone: form.phoneNumber,
		};
		addUser({
			username: form.username,
			name: name,
			contact: contact,
			userRole: form.userRole,
			insuranceTypes: form.allowedInsuranceTypes,
		}).then(successCallback, failureCallback);
	});

	function successCallback(result: any) {
		enqueueSnackbar("User Added", {
			variant: "success",
		});
		window.location.replace(`/settings/users/${result.user.id}`);
	}

	function failureCallback() {
		enqueueSnackbar("Error while creating user", {
			variant: "error",
		});
	}
	return (
		<Box sx={{ width: "100%" }} onSubmit={onSubmit} component="form">
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardHeader title="Add User" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "grid", height: "100%" }}>
							<Controller
								control={control}
								name="username"
								render={({ field: { onChange, value } }) => (
									<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="username" label="Username" />
								)}
							/>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid Email Address",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem" }}
											error={!!error}
											helperText={error && "Invalid email"}
											onChange={onChange}
											value={value}
											required
											id="email"
											label="Email"
											placeholder="jdoe@example.com"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: EmailMaskedInput as any,
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="phoneNumber"
									rules={{
										required: true,
										pattern: {
											value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
											message: "Invalid Phone Number",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem", pl: 2 }}
											onChange={onChange}
											value={value}
											error={!!error}
											helperText={error && "Invalid phone number"}
											required
											id="phoneNumber"
											label="Phone"
											placeholder="+1 (555) 555-5555"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: PhoneMaskedInput as any,
											}}
										/>
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="firstName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="firstName" label="First Name" />
									)}
								/>
								<Controller
									control={control}
									name="lastName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem", pl: 2 }} onChange={onChange} value={value} id="lastName" label="Last Name" />
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column", pb: 2, width: "6rem" }}>
								<Controller
									name="userRole"
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormLabel id="user-role-label">User Role</FormLabel>

											<RadioGroup onChange={onChange} value={value} aria-labelledby="user-role-label" name="radio-buttons-group">
												{allRoles.map((item) => {
													return <FormControlLabel key={item} value={item} control={<Radio />} label={item} />;
												})}
											</RadioGroup>

											{error && <Alert severity="error">{error.message}</Alert>}
										</>
									)}
								/>
							</Box>
							{memberTypes && (
								<>
									<FormLabel id="insurance-types-label">Insurance Types</FormLabel>
									<FormGroup aria-labelledby="insurance-types-label" sx={{ width: "4rem" }}>
										<Controller
											name="allowedInsuranceTypes"
											control={control}
											render={({ field }) => (
												<>
													{memberTypes.map((item) => {
														return (
															<FormControlLabel
																key={item}
																control={
																	<Checkbox
																		checked={field.value.some((x) => x === item)}
																		value={item}
																		onChange={(event, checked) => {
																			if (checked) {
																				field.onChange([...field.value, event.target.value]);
																			} else {
																				field.onChange(field.value.filter((value) => value !== event.target.value));
																			}
																		}}
																	/>
																}
																label={item}
															/>
														);
													})}
												</>
											)}
										/>
									</FormGroup>
								</>
							)}
						</Box>
						<br />
						<Box sx={{ display: "flex" }}>
							<Button type="submit">Save Changes</Button>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

export default AddUser;
