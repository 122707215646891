import React from "react";
import { Box, Grid, Card, CardHeader, CardContent, Tabs } from "@mui/material";
import PanelRevenueProcedures from "./components/PanelRevenueProcedures";
import PanelRevenueAppointments from "./components/PanelRevenueAppointments";
import PanelRevenuePrescriptions from "./components/PanelRevenuePrescriptions";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";

const { TabControl, TabPanel } = TabsUtilities.create_TabRenderFunctions("revenue-services");

function RevenueServices() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="Revenue Service Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
											maxWidth: "100%",
										},
									}}
								>
									<TabControl label="Visits" value={0} />
									<TabControl label="Procedures" value={1} />
									<TabControl label="Prescriptions" value={2} />
								</Tabs>
							}
						></CardHeader>
						<CardContent>
							<TabPanel value={value} index={0}>
								<PanelRevenueAppointments />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<PanelRevenueProcedures />
							</TabPanel>

							<TabPanel value={value} index={2}>
								<PanelRevenuePrescriptions />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export { RevenueServices };
