import { TextField, Autocomplete, SxProps, Theme } from "@mui/material";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { ReactNode } from "react";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";

type ConditionSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
};
function ConditionSelect(props: ConditionSelectProps) {
	const [{ Conditions }, diagnosisFilters] = [useContextCodeDefinitions(), useDiagnosisFilters()];
	if (Conditions?.data) {
		return (
			<Autocomplete
				sx={{
					minWidth: "25rem",
					width: "100%",
					...props.styles,
				}}
				multiple
				autoComplete
				value={diagnosisFilters.values.Conditions}
				options={Conditions.data}
				getOptionLabel={(a) => {
					return a.name;
				}}
				onChange={(_event, currentValue) => {
					diagnosisFilters.setValues({ Conditions: currentValue });
				}}
				renderInput={(params) => <TextField {...params} label="" placeholder={props.placeholder ? props.placeholder : undefined} />}
			/>
		);
	} else {
		return null;
	}
}
export { ConditionSelect };
