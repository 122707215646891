import { TextField, Autocomplete, SxProps, Theme } from "@mui/material";
import { Condition, SubRange } from "../../../shared/schemas/dataStructures";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { ReactNode } from "react";
import { DiagnosisSummary } from "../hooks/useDiagnosisSummaryList";
import { ModelsICD10Short } from "api";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";

type ICD10SubSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	condition?: Condition;
	diagnosisSummary?: DiagnosisSummary;
	styles?: SxProps<Theme>;
	icd10_codes?: ModelsICD10Short[];
	setIcd10Value?: () => void;
};
function ICD10SubSelect(props: ICD10SubSelectProps) {
	const [{ Conditions }, diagnosisFilters] = [useContextCodeDefinitions(), useDiagnosisFilters()];
	let condition: Condition | undefined;
	if (!props.condition) {
		if (props.diagnosisSummary && Conditions?.data) {
			condition = Conditions.data.find((a) => a.id === props.diagnosisSummary?.id);
		}
	} else {
		condition = props.condition;
	}
	if (!condition) {
		return null;
	} else {
		const value = diagnosisFilters.values.ICD10CodesByCondition[condition.id] ?? "NO_SELECTION";
		return (
			<>
				{props.icd10_codes && props.icd10_codes.length > 0 ? (
					<Autocomplete
						sx={{
							minWidth: "30rem",
							width: "100%",
							...props.styles,
						}}
						autoComplete
						disableClearable
						value={value}
						options={props.icd10_codes}
						isOptionEqualToValue={(option: any, value: any) => {
							if (option.title === "All Codes" && value === "NO_SELECTION") {
								return true;
							}
							return option.code === value.code;
						}}
						getOptionLabel={(code: any) => {
							if (code && code !== "NO_SELECTION" && code.description !== "All Codes") {
								const displayCode = code?.code?.length > 3 ? code?.code?.slice(0, 3) + "." + code?.code?.slice(3) : code?.code;
								return displayCode + ": " + code?.description;
							}
							return "All Codes";
						}}
						onChange={(_event, currentValue: SubRange) => {
							let update: { [key: string]: SubRange } = {};
							currentValue.range = currentValue.code ?? "";
							update[condition?.id as string] = currentValue;
							diagnosisFilters.setValues({ ICD10CodesByCondition: update });
						}}
						renderInput={(params) => (
							<TextField {...params} label="" placeholder={props.placeholder ? props.placeholder : condition?.name ? "Filter by ICD-10" : ""} />
						)}
					/>
				) : null}
			</>
		);
	}
}
export { ICD10SubSelect };
