import { Box } from "@mui/material";
import { DoctorOverviewHeaderTiles } from "./DoctorOverviewHeaderTiles";
import { CacheMonthlyListDisplay } from "sections/followed_items/CacheMonthlyListDisplay";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";
import { DoctorSelect } from "sections/item_details/doctor/DoctorSelect";
import { useState } from "react";
import { useX_getCacheMonthlyTotals } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyTotals";
import { RequestModelsCacheMonthlyQueryRequest } from "api";

const columns_cmd = DataGridColumns.CacheMonthly("PROVIDER_LIST");

function DoctorOverviewList() {
	const request: RequestModelsCacheMonthlyQueryRequest = { itemType: "PROVIDER" };

	const data = useX_getCacheMonthlyTotals(request, ["PROVIDER", "PATIENT", "MEDICATION", "PRODUCTSERVICE", "DIAGNOSIS"]).data?.response;

	let [searchBarValue, set_searchBarValue] = useState<string | null>(null);

	return (
		<Box>
			<DoctorOverviewHeaderTiles overview_data={data ?? null} />
			<CacheMonthlyListDisplay
				itemType={"PROVIDER"}
				titleText="Doctor List"
				columns={columns_cmd}
				useSortBarSelect={true}
				searchBar={<DoctorSelect styles={{ width: 30 }} setSelectValue={set_searchBarValue} />}
				searchBarValue={searchBarValue}
			/>
		</Box>
	);
}

export { DoctorOverviewList };
