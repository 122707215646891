import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { useX_getDoctorsBySearchTerm } from "api_extension/useQueryX/doctor/useX_getDoctorsBySearchTerm";
import { ModelsDoctorStub, ModelsNPIEnumerationType } from "api";
import { useSearchInput, useSearchText } from "shared/utilities/search";

type DoctorSelectProps = {
	styles?: SxProps<Theme>;
	setSelectValue?: (values: string | null) => void;
	enumType?: ModelsNPIEnumerationType;
};
function DoctorSelect(props: Readonly<DoctorSelectProps>) {
	const { setValue } = useSearchInput();
	const searchText = useSearchText();

	let apiSearchResult = useX_getDoctorsBySearchTerm({ searchQuery: searchText, size: 20, enumerationType: props.enumType });

	let dropdownOptions = apiSearchResult.data?.doctors;

	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			loading={apiSearchResult?.isLoading || apiSearchResult?.isFetching}
			options={dropdownOptions ?? []}
			getOptionLabel={(doc: ModelsDoctorStub) => {
				const name = doc.firstName ? doc.firstName + " " + doc.lastName : doc.organizationName;
				return name + ": " + doc.npi;
			}}
			isOptionEqualToValue={(option: ModelsDoctorStub, currentVal: ModelsDoctorStub) => {
				return option.npi === currentVal.npi;
			}}
			onInputChange={(_event, currentVal) => {
				setValue(currentVal);
			}}
			onChange={(_event, currentVal: ModelsDoctorStub | null) => {
				props.setSelectValue?.(currentVal?.npi ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"Name / NPI Number"} />}
		/>
	);
}
export { DoctorSelect };
