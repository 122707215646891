import { useMemo } from "react";
import { Grid, Card, CardHeader, CardContent, Box, Stack, Typography, Divider, Rating } from "@mui/material";
import { palette } from "../../../theme";

import { StandardLineChart } from "shared/components/charts/line/StandardLineChart";
import { displayConverter } from "../../../shared/utilities/displayConverter";
import {
	ModelsDoctorStub,
	ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts,
	ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse,
} from "api";

import * as CSS from "csstype";

interface StatRowProps {
	label: string;
	value: string;
	color: CSS.Property.Color | undefined;
	renderValue?: React.ReactNode;
}

function StatRow(props: StatRowProps) {
	return (
		<Box>
			<Stack direction="row" justifyContent="space-between" py={2}>
				<Box display={"flex"} flexDirection={"column"}>
					<Typography sx={{ textTransform: "uppercase", fontWeight: 700 }}>{props.label}</Typography>
				</Box>
				{props.renderValue ? (
					(props.renderValue as React.ReactNode)
				) : (
					<Typography sx={{ color: props.color ?? "gray.main", fontWeight: 700 }}>{props.value}</Typography>
				)}
			</Stack>
			<Divider></Divider>
		</Box>
	);
}

type getBucketAndLookupItemResponse = [
	ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts[] | null,
	ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts | null,
	ModelsDoctorStub | null | undefined
];
function getBucketAndLookupItem(code: string, detailsData: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse): getBucketAndLookupItemResponse {
	if (detailsData?.codeMonthAggregations?.[code]) {
		let bucketGroup = detailsData.codeMonthAggregations[code];
		let codeAggregation = detailsData.codeAggregations?.[code] ?? null;

		return [bucketGroup, codeAggregation, bucketGroup[0]?.lookUpItem?.provider];
	}
	return [null, null, null];
}
export { getBucketAndLookupItem };

type BucketNESTEDCodeCountsAggTotals = {
	eobCount: number;
	totalAmount: number;
	diagnosisCount: number;
	medicationCount: number;
	providerCount: number;
	organizationCount: number;
	patientCount: number;
	productserviceCount: number;
};

function getBucketAggTotals(codeMonthAgg: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts[] | null): BucketNESTEDCodeCountsAggTotals {
	const aggTotals: BucketNESTEDCodeCountsAggTotals = {
		eobCount: 0,
		totalAmount: 0,
		diagnosisCount: 0,
		medicationCount: 0,
		providerCount: 0,
		organizationCount: 0,
		patientCount: 0,
		productserviceCount: 0,
	};
	if (codeMonthAgg) {
		console.log({ codeMonthAgg });
		codeMonthAgg.forEach((x, i, arr) => {
			aggTotals.eobCount += x.eobCount ?? 0;
			aggTotals.totalAmount += x.totalAmount ?? 0;
			aggTotals.diagnosisCount += x.diagnosis?.totalCount ?? 0;
			aggTotals.medicationCount += x.medication?.totalCount ?? 0;
			aggTotals.productserviceCount += x.productservice?.totalCount ?? 0;

			let uniquePatients = new Set<string>();
			x.patient?.codeCounts?.forEach((cc) => {
				if (cc.code) {
					uniquePatients.add(cc.code);
				}
			});
			aggTotals.patientCount = uniquePatients.size;

			let uniqueOrganizations = new Set<string>();
			x.organization?.codeCounts?.forEach((cc) => {
				if (cc.code) {
					uniqueOrganizations.add(cc.code);
				}
			});
			aggTotals.organizationCount = uniqueOrganizations.size;
		});
	}
	return aggTotals;
}
export { getBucketAggTotals };
/*









/*





*/ /*





*/ /*





*/ /*





*/ /*





*/

function DoctorDetailsOverview({
	npi,
	detailsData,
	itemType,
}: {
	npi: string;
	detailsData: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse;
	itemType: "PROVIDER" | "ORGANIZATION" | "FACILITY";
}) {
	/*





	*/
	const [codeMonthAgg, codeAgg, doctor] = useMemo(() => {
		return getBucketAndLookupItem(npi, detailsData);
	}, [detailsData, npi]);
	/*





*/
	const codeMonthAggTotals = useMemo(() => {
		return getBucketAggTotals(codeMonthAgg);
	}, [codeMonthAgg]);
	/*





*/
	const costChartData = useMemo(() => {
		if (codeMonthAgg) {
			return codeMonthAgg.map((x) => {
				return { name: x.periodDate ? x.periodDate.split("T")[0] : "--", value: x.totalAmount ?? 0 };
			});
		}
		return null;
	}, [codeMonthAgg]);

	// const eobChartData = useMemo(() => {
	// 	if (codeMonthAgg) {
	// 		return codeMonthAgg.map((x) => {
	// 			return { name: x.periodDate ? x.periodDate.split("T")[0] : "--", value: x.eobCount ?? 0 };
	// 		});
	// 	}
	// 	return null;
	// }, [codeMonthAgg]);

	const multiCountChartData = useMemo(() => {
		if (codeMonthAgg) {
			return codeMonthAgg.map((x) => {
				return {
					name: x.periodDate ? x.periodDate.split("T")[0] : "--",
					eob: x.eobCount ?? 0,
					diagnosis: x.diagnosis?.totalCount,
					productservice: x.productservice?.totalCount,
					medication: x.medication?.totalCount,
				};
			});
		}
		return null;
	}, [codeMonthAgg]);
	/*





*/
	const statRows = useMemo(() => {
		const statData: Array<StatRowProps | null> = [
			{
				label: "Billing",
				value: displayConverter.toUSD(codeMonthAggTotals.totalAmount),
				color: palette.purple.main,
			},
			{
				label: "Visits",
				value: displayConverter.insertThousandsCommas(codeMonthAggTotals.eobCount),
				color: palette.purple.main,
			},
			{
				label: "Prescriptions",
				value: displayConverter.insertThousandsCommas(codeAgg?.medication?.totalCount ?? "N/A"),
				color: palette.purple.main,
			},
			{
				label: "Procedures",
				value: displayConverter.insertThousandsCommas(codeMonthAggTotals.productserviceCount),
				color: palette.purple.main,
			},
			{
				label: "Diagnoses",
				value: displayConverter.insertThousandsCommas(codeMonthAggTotals.diagnosisCount),
				color: palette.purple.main,
			},
			{
				label: "Members",
				value: displayConverter.insertThousandsCommas(codeAgg?.patient?.uniqueCount ?? "N/A"),
				color: palette.purple.main,
			},
			itemType !== "PROVIDER"
				? {
						label: "Doctors",
						value: displayConverter.insertThousandsCommas(codeAgg?.provider?.uniqueCount ?? "N/A"),
						color: palette.purple.main,
				  }
				: null,
			itemType !== "ORGANIZATION" && itemType !== "FACILITY"
				? {
						label: "Organizations",
						value: displayConverter.insertThousandsCommas(codeAgg?.organization?.uniqueCount ?? "N/A"),
						color: palette.purple.main,
				  }
				: null,
			itemType !== "ORGANIZATION" && itemType !== "FACILITY"
				? {
						label: "Facilities",
						value: displayConverter.insertThousandsCommas(codeAgg?.facility?.uniqueCount ?? "N/A"),
						color: palette.purple.main,
				  }
				: null,
			{
				label: "Specialty",
				value: doctor?.specialtyText ?? "N/A",
				color: palette.purple.main,
			},
			{
				label: "Rating",
				value: doctor?.radiusRating ? doctor?.radiusRating + "/5" : "N/A",
				color: palette.purple.main,
				renderValue: <Rating size="small" name="read-only" value={doctor?.radiusRating} readOnly />,
			},
		];
		return (
			<>
				{statData.map((d) => {
					if (d) {
						return <StatRow key={d.label} {...d} />;
					}
					return null;
				})}
			</>
		);
	}, [
		codeMonthAggTotals,
		doctor,
		itemType,
		codeAgg?.facility?.uniqueCount,
		codeAgg?.medication?.totalCount,
		codeAgg?.organization?.uniqueCount,
		codeAgg?.patient?.uniqueCount,
		codeAgg?.provider?.uniqueCount,
	]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} lg={4}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", height: "100%" }}>
					<CardContent>
						<Box pt={4}>{statRows}</Box>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} lg={8}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Billing"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{costChartData ? (
							<StandardLineChart
								data={costChartData}
								yKeys={[
									{
										dataKey: "value",
										color: palette.green.main,
										name:
											doctor?.firstName || doctor?.lastName
												? `${doctor.firstName} ${doctor.lastName}`
												: doctor?.organizationName ?? `Doctor:${npi}`,
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => displayConverter.toUSD(parseInt(value))}
								yAxisTickFormat={(value: string, index: number) => displayConverter.toUSD(parseInt(value))}
							/>
						) : null}
					</CardContent>
				</Card>

				{/* <Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Visits"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{eobChartData ? (
							<StandardLineChart
								data={eobChartData}
								yKeys={[
									{
										dataKey: "value",
										color: palette.purple.main,
										name: doctor?.firstName || doctor?.lastName ? `${doctor.firstName} ${doctor.lastName}` : `Doctor:${npi}`,
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
							/>
						) : null}
					</CardContent>
				</Card> */}
				<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
					<CardHeader title="Monthly Detail"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{multiCountChartData ? (
							<StandardLineChart
								data={multiCountChartData}
								yKeys={[
									{
										dataKey: "eob",
										color: palette.purple.main,
										name: "Visits",
									},
									{
										dataKey: "diagnosis",
										color: palette.lightBlue.main,
										name: "Diagnosis",
									},
									{
										dataKey: "medication",
										color: palette.green.main,
										name: "Medication",
									},
									{
										dataKey: "productservice",
										color: palette.orange.main,
										name: "Procedure",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
							/>
						) : null}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export { DoctorDetailsOverview };
