import { Button, Card, CardContent, Grid } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { useGetAllConsumerUsers, Contact, Location } from "api";
import useDataGrid from "shared/components/grid/useDataGrid";

const columns: GridColumns = [
	{
		field: "id",
		headerName: "Consumer ID",
		flex: 0.5,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "email",
		headerName: "Email",
		flex: 1.5,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "name",
		headerName: "Name",
		flex: 1.5,
		headerAlign: "left",
		align: "left",
	},
	{
		field: `gender`,
		headerName: "Gender",
		flex: 1.5,
		headerAlign: "left",
		align: "left",
		valueFormatter: (params) => ((params?.value ?? "") + "").toLowerCase(),
	},
	{
		field: "dateOfBirth",
		headerName: "Date of Birth",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueFormatter: (params) => {
			var date = params?.value?.toString().substring(0, 10);
			return date === "0001-01-01" ? "" : date;
		},
	},
	{
		field: "contact",
		headerName: "Phone Number",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueFormatter: (params) => (params?.value as Contact)?.phone ?? "",
	},
	{
		field: "location",
		headerName: "Zip Code",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueFormatter: (params) => (params?.value as Location)?.zip ?? "",
	},
	{
		field: "details",
		headerName: "",
		flex: 1,
		headerAlign: "left",
		align: "center",

		renderCell: ({ row }) => (
			<Button component={Link} to={`/settings/consumerUsers/${row.id}`} color="secondary">
				Details
			</Button>
		),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onSearch">) {
	return <DataGridToolbar header={"Consumer User List"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function ConsumerUserList() {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("consumerUsers", columns);

	const { data, isFetching } = useGetAllConsumerUsers(
		{
			...query,
		},
		{ query: { keepPreviousData: true } }
	);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "flex", height: "100%" }}>
							<Box
								sx={{
									flexGrow: 1,
								}}
							>
								{data && (
									<DataGridPro
										{...grid}
										autoHeight
										rows={data?.items ?? []}
										rowCount={data?.total}
										loading={isFetching}
										components={{ Toolbar: Toolbar }}
										componentsProps={{
											toolbar: {
												onSearch: (searchString: string) => {
													onSearchEntered(searchString);
												},
												initialSearch: query.searchQuery ?? "",
											},
										}}
										sx={{ pt: 1 }}
									/>
								)}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

export default ConsumerUserList;
