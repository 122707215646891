import React from "react";
import { Stack, Box, Typography, Divider, Rating } from "@mui/material";
import * as CSS from "csstype";

interface DoctorStatProps {
	statLabel: string;
	statValue: string;
	statValueColor: CSS.Property.Color | undefined;
	rating?: number;
}

function DoctorStat(props: DoctorStatProps) {
	return (
		<Box>
			<Stack direction="row" justifyContent="space-between" py={2}>
				<Box display={"flex"} flexDirection={"column"}>
					<Typography sx={{ textTransform: "uppercase", fontWeight: 700 }}>{props.statLabel}</Typography>
					{props.rating && <Rating size="small" name="read-only" value={props.rating} readOnly />}
				</Box>

				<Typography sx={{ color: props.statValueColor || "gray.main", fontWeight: 700 }}>{props.statValue}</Typography>
			</Stack>
			<Divider></Divider>
		</Box>
	);
}

export default DoctorStat;
