import { useEffect, ReactNode } from "react";
import { TextField, Autocomplete, SxProps, Theme } from "@mui/material";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { Condition, SubRange } from "../../../shared/schemas/dataStructures";
// import { DiagnosisFiltersUpdate } from "DataProvider/DataProvider";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";
import { DiagnosisFiltersUpdate } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";

type ConditionSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	condition?: Condition;
	setValue?: (values: Condition) => void;
};
function ConditionSelectSingle(props: ConditionSelectProps) {
	const [{ Conditions }, diagnosisFilters] = [useContextCodeDefinitions(), useDiagnosisFilters()];
	let setValue = props.setValue;

	useEffect(() => {
		let c = diagnosisFilters.values.Conditions.length ? diagnosisFilters.values.Conditions[0] : null;
		if (setValue && c) {
			setValue(c);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (diagnosisFilters.values.Conditions.length > 1) {
			// if this component is being rendered we are going to change the selected condition Filters to include only one option
			diagnosisFilters.setValues({
				Conditions: [diagnosisFilters.values.Conditions[0]],
			});
		}
	}, [diagnosisFilters]);

	// let defaultCondition: Condition = {
	// 	name: "NO_SELECTION",
	// 	id: "NO_SELECTION",
	// 	icd10_codes: [],
	// };

	if (Conditions?.data) {
		const value = diagnosisFilters.values.Conditions.length ? diagnosisFilters.values.Conditions[0] : null;

		return !value ? null : (
			<Autocomplete
				sx={{
					minWidth: "30rem",
					width: "100%",
					...props.styles,
				}}
				autoComplete
				value={value}
				options={Conditions.data}
				disableClearable
				getOptionLabel={(a) => {
					if (a) {
						return a.name;
					}
					return "";
				}}
				onChange={(_event, currentValue) => {
					const filtersUpdate: DiagnosisFiltersUpdate = {};
					if (props.condition) {
						const subRange: SubRange = { range: "", description: "" };
						filtersUpdate.ICD10CodesByCondition = { [props.condition.id]: subRange };
						filtersUpdate.SubRangeByCondition = { [props.condition.id]: subRange };
					}

					if (currentValue === null) {
						diagnosisFilters.setValues({ ...filtersUpdate, Conditions: [] });
					} else {
						let c: Condition = {
							id: currentValue.id,
							name: currentValue.name,
							icd10_codes: (currentValue as any).subRanges,
						};
						diagnosisFilters.setValues({
							...filtersUpdate,
							Conditions: [c],
						});
						props.setValue?.(c);
					}
				}}
				isOptionEqualToValue={(x, y) => {
					if (x && y) {
						return x.id === y.id;
					}
					return false;
				}}
				renderInput={(params) => <TextField {...params} label="" placeholder={props.placeholder ? props.placeholder : undefined} />}
			/>
		);
	} else {
		return null;
	}
}
export { ConditionSelectSingle };
