import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";

interface NewPasswordFormProps {
  onNewPassword: (password: string) => void;
}

function NewPasswordForm({ onNewPassword }: NewPasswordFormProps) {
  const { register, handleSubmit } = useForm<{
    password: string;
    confirmPassword: string;
  }>();

  const onSubmit = handleSubmit(({ password }) => onNewPassword(password));

  return (
    <Card
      component="form"
      onSubmit={onSubmit}
      sx={{ maxWidth: 400, width: "100%" }}
      elevation={4}
    >
      <CardHeader title="Set Password" />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label="Password"
            type="password"
            {...register("password", { required: true })}
          />
          <TextField
            label="Confirm Password"
            type="password"
            {...register("confirmPassword", { required: true })}
          />
        </Stack>
      </CardContent>
      <FormErrorAlert />
      <CardActions sx={{ justifyContent: "center" }}>
        <SubmitButton>Change Password</SubmitButton>
      </CardActions>
    </Card>
  );
}

export default withFormStatus(NewPasswordForm);
