import { Box, Typography } from "@mui/material";
import { GridColumns, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { RowDisplayForEntityType } from "shared/components/DisplayElements/DisplayForType/RowDisplayForEntityType";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";
import { DateConverter } from "shared/utilities/DateConverter";

import { displayConverter } from "shared/utilities/displayConverter";
import { DataGridColumnsDelete, DataGridColumnsOverRide, DataGridColumnsAdd, DataGridColumnsShuffle } from "../DataGridColumns";

const ColumnsConstruct_CacheMonthly = (type?: CacheMonthlyTypes | "PROVIDER_LIST") => {
	let columns: GridEnrichedColDef[] = GRIDCOLUMNS_CACHEMONTHLY;
	if (type !== "PROVIDER_LIST" && type !== "ORGANIZATION") {
		columns = DataGridColumnsDelete(columns, ["productservice_counts.count", "medication_counts.count", "diagnosis_counts.count"]);
	}

	if (type === "PATIENT") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "ITEMLOOKUP", headerName: "Member", minWidth: 200, flex: 1 },
			{ field: "eobCount", headerName: "Visits" },
			{ field: "job_id", headerName: "Member Type" },
		]);
		columns = DataGridColumnsDelete(columns, ["code", "patient_counts.count"]);

		columns = DataGridColumnsAdd(
			columns,
			{
				field: "riskCategory",
				headerName: "Risk Score",
				flex: 0.25,
				minWidth: 70,
				headerAlign: "left",
				align: "left",
				valueGetter: (params) => params.row.lookUpItem?.patientRiskCategory?.riskCategory,
			},
			1
		);
		columns = DataGridColumnsAdd(
			columns,
			{
				field: "gender",
				headerName: "Gender",
				flex: 0.25,
				minWidth: 60,
				headerAlign: "left",
				align: "left",
				valueGetter: (params) => params.row.lookUpItem?.patientRiskCategory?.gender,
			},
			1
		);
		columns = DataGridColumnsAdd(
			columns,
			{
				field: "birthDate",
				headerName: "Age",
				flex: 0.25,
				minWidth: 30,
				headerAlign: "left",
				align: "left",
				valueGetter: (params) => DateConverter.ageFromApiStringDate(params.row.lookUpItem?.patientRiskCategory?.birthDate),
			},
			1
		);
	} else if (type === "DIAGNOSIS") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "ITEMLOOKUP", headerName: "Diagnosis", minWidth: 200, flex: 1 },
			{ field: "code", headerName: "ICD Code" },
		]);
		columns = DataGridColumnsDelete(columns, ["job_id"]);
	} else if (type === "DIAGNOSIS_RANGE") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "code", headerName: "ICD Range" },
			{ field: "ITEMLOOKUP", headerName: "Diagnosis Range", minWidth: 200, flex: 1 },
		]);
		columns = DataGridColumnsDelete(columns, ["job_id"]);
	} else if (type === "MEDICATION") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "code", headerName: "NDC" },
			{ field: "ITEMLOOKUP", headerName: "Medication", minWidth: 200, flex: 1 },
		]);
		columns = DataGridColumnsDelete(columns, ["job_id"]);
	} else if (type === "PROVIDER") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "code", headerName: "NPI" },
			{ field: "ITEMLOOKUP", headerName: "Provider", minWidth: 200, flex: 1 },
		]);
		columns = DataGridColumnsDelete(columns, ["job_id"]);
	} else if (type === "PROVIDER_LIST") {
		columns = DataGridColumnsDelete(columns, ["job_id", "code", "patient_counts.count"]);
		columns = DataGridColumnsOverRide(columns, [
			{
				field: "ITEMLOOKUP",
				headerName: "Doctor Name",
				minWidth: 200,
				flex: 1,
			},
			{ field: "eob_count", headerName: "Visits" },
			{ field: "productservice_counts.count", headerName: "Procedures" },
			{ field: "total_amount", headerName: "Total Billing" },
		]);

		columns = DataGridColumnsAdd(
			columns,
			{
				field: "speciality",
				headerName: "Speciality",
				flex: 1,
				minWidth: 60,
				headerAlign: "left",
				align: "left",
				sortable: false,
				disableColumnMenu: true,
				valueGetter: (params) => {
					return params.row.lookUpItem?.provider?.specialtyText ?? params.row.lookUpItem?.provider?.codes?.[0];
				},
			},
			1
		);

		columns = DataGridColumnsShuffle(columns, [
			[3, 2],
			[5, 3],
			[6, 4],
			[6, 5],
		]);
	} else if (type === "ORGANIZATION") {
		columns = DataGridColumnsDelete(columns, ["job_id", "code", "patient_counts.count"]);
		columns = DataGridColumnsOverRide(columns, [
			{
				field: "ITEMLOOKUP",
				headerName: "Facility Name",
				minWidth: 200,
				flex: 1,
			},
			{ field: "eob_count", headerName: "Visits" },
			{ field: "productservice_counts.count", headerName: "Procedures" },
			{ field: "total_amount", headerName: "Total Billing" },
		]);

		columns = DataGridColumnsAdd(
			columns,
			{
				field: "speciality",
				headerName: "Speciality",
				flex: 1,
				minWidth: 60,
				headerAlign: "left",
				align: "left",
				valueGetter: (params) => {
					return params.row.lookUpItem?.provider?.specialtyText ?? params.row.lookUpItem?.provider?.codes?.[0];
				},
			},
			1
		);

		columns = DataGridColumnsShuffle(columns, [
			[3, 2],
			[5, 3],
			[6, 4],
			[6, 5],
		]);
	} else if (type === "PRODUCTSERVICE") {
		columns = DataGridColumnsOverRide(columns, [
			{ field: "code", headerName: "HCPCS" },
			{ field: "ITEMLOOKUP", headerName: "Procedure", minWidth: 200, flex: 1 },
		]);
		columns = DataGridColumnsDelete(columns, ["job_id"]); ///"VIEW_DETAIL"
	}
	return columns;
};

const GRIDCOLUMNS_CACHEMONTHLY: GridColumns = [
	{
		field: "ITEMLOOKUP",
		headerName: "[ITEM]",
		flex: 1,
		headerAlign: "left",
		minWidth: 50,
		align: "left",
		sortable: false,
		disableColumnMenu: true,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "100%",
					}}
				>
					{/* <Loader /> */}
					<RowDisplayForEntityType item={params.row} />
				</Box>
			);
		},
	},
	{
		field: "code",
		headerName: "[CODE]",
		flex: 1,
		headerAlign: "left",
		minWidth: 50,
		align: "left",
		sortable: false,
		disableColumnMenu: true,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "100%",
					}}
				>
					{params.row.code}
				</Box>
			);
		},
	},
	{
		field: "job_id",
		headerName: "[JOBID]",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: false,
		disableColumnMenu: true,
		valueGetter: (params) => (params.row.jobIds && params.row.jobIds.length ? params.row.jobIds[0] : null),
		renderCell: (params) => {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "100%",
					}}
				>
					{params.row.jobIds?.map((j: string) => {
						return <Typography key={j}>{j}</Typography>;
					}) ?? <Typography>[Not Found]</Typography>}
				</Box>
			);
		},
	},
	{
		field: "total_amount",
		headerName: "Total Cost",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.toUSD(params.row.totalAmount),
	},
	{
		field: "eob_count",
		headerName: "EOB count",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.eobCount),
	},
	{
		field: "diagnosis_counts.count",
		headerName: "Diagnoses",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.diagnosis?.totalCount),
	},
	{
		field: "medication_counts.count",
		headerName: "Prescriptions",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.medication?.totalCount),
	},
	{
		field: "productservice_counts.count",
		headerName: "Procedures",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.productservice?.totalCount),
	},
	{
		field: "patient_counts.count",
		headerName: "Members",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.patient?.uniqueCount),
	},
	{
		field: "VIEW_DETAIL",
		headerName: "",
		flex: 0.25,
		minWidth: 100,
		align: "center",
		renderCell: (params) => {
			return <ViewEntityDetailButton itemType={params.row.lookUpItem?.itemType} code={params.row.code} />;
		},
	},
];

export { GRIDCOLUMNS_CACHEMONTHLY, ColumnsConstruct_CacheMonthly };
