import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {}
function SettingsPage(props: Props) {
	return (
		<Box sx={{ width: "100%" }}>
			<Grid
				container
				spacing={4}
				sx={{
					"& .item": {
						width: "100%",

						"& .MuiTypography-root ": {
							fontSize: "1.5rem",
							fontWeight: 700,
							color: "",
						},
					},
				}}
			>
				<Grid item xs={12} md={3} xl={4}>
					<Button sx={{ backgroundColor: "#02024c", borderRadius: "1rem" }} component={Link} to="/settings/userList" className="item">
						<Typography>Insight Users</Typography>
					</Button>
				</Grid>
				<Grid item xs={12} md={3} xl={4}>
					<Button sx={{ backgroundColor: "#02024c", borderRadius: "1rem" }} component={Link} to="/settings/consumerUserList" className="item">
						<Typography>Consumer Users</Typography>
					</Button>
				</Grid>
				<Grid item xs={12} md={3} xl={4}>
					<Button sx={{ backgroundColor: "#02024c", borderRadius: "1rem" }} component={Link} to="/settings/errorLog" className="item">
						<Typography>Error Log</Typography>
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default SettingsPage;
