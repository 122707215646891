import { apiRequest } from "../../api_extension/apiRequest";
import { useQuery, UseQueryResult } from "react-query";

/*
██████  ██████  ███████ ██████   █████  ██████  ███████     ███████ ██   ██ ██ ██████       ██████  ██████  ██████  ███████ ███████ ███████ ████████ ███████ 
██   ██ ██   ██ ██      ██   ██ ██   ██ ██   ██ ██          ██      ██   ██ ██ ██   ██     ██      ██    ██ ██   ██ ██      ██      ██         ██    ██      
██████  ██████  █████   ██████  ███████ ██████  █████       █████   ███████ ██ ██████      ██      ██    ██ ██   ██ █████   ███████ █████      ██    ███████ 
██      ██   ██ ██      ██      ██   ██ ██   ██ ██          ██      ██   ██ ██ ██   ██     ██      ██    ██ ██   ██ ██           ██ ██         ██         ██ 
██      ██   ██ ███████ ██      ██   ██ ██   ██ ███████     ██      ██   ██ ██ ██   ██      ██████  ██████  ██████  ███████ ███████ ███████    ██    ███████
*/
var FHIRCodeSets: null | any = null;

const FHIRCodeSet_Utility = {
	retrieveFHIRCodeSetValues: async (): Promise<any> => {
		const FHIRCodeSetValues: { [key: string]: any } = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/bluebutton.cms.gov/FHIRCodeSetValues.json`,
			method: "GET",
		}).then((x) => {
			const code_set_map = new Map();
			Object.keys(x).forEach((k) => {
				let code_set = x[k];
				code_set.value_map = new Map(code_set.values.map((v: any) => [v.value, v.description]));
				code_set.name_lower = code_set.name.toLowerCase();
				code_set_map.set(code_set.name_lower, code_set);
			});
			// console.debug("FHIRCodeSetValues", code_set_map);
			// console.debug(
			//   "FHIRCodeSetValues",
			//   Array.from(code_set_map).map((x: any) => {
			//     return x[1].display + ":" + x[1].description;
			//   })
			// );
			return code_set_map;
		});
		return FHIRCodeSetValues;
	},
};

function useFHIRCodeSetValues(): UseQueryResult<any> {
	return useQuery("FHIRCodeSetValues", FHIRCodeSet_Utility.retrieveFHIRCodeSetValues, {
		staleTime: Infinity,
	});
}

export { useFHIRCodeSetValues, FHIRCodeSets };
