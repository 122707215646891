/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminRetrieveLatestRiskModelScoresOverviewResult,
  ProvidersRiskModelFilterCriteria,
  AdminGetPaginatedMembersListResult,
  AdminGetPaginatedMembersListRequest,
  AdminCalculateAndCachePatientRiskModelScoresResult,
  CalculateAndCachePatientRiskModelScoresParams,
  AdminCreateRiskModelScoresOverviewResult,
  CreateRiskModelScoresOverviewParams,
  AdminDeletePatientRiskModelScoresResult,
  DeletePatientRiskModelScoresParams,
  AdminGetRiskModelScoresCacheInfoResult,
  GetRiskModelScoresCacheInfoParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const retrieveLatestRiskModelScoresOverview = (
    providersRiskModelFilterCriteria: ProvidersRiskModelFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminRetrieveLatestRiskModelScoresOverviewResult>(
      {url: `/api/admin/RiskModel/RetrieveLatestRiskModelScoresOverview`, method: 'post',
      data: providersRiskModelFilterCriteria
    },
      options);
    }
  


    export const useRetrieveLatestRiskModelScoresOverview = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof retrieveLatestRiskModelScoresOverview>, TError,{data: ProvidersRiskModelFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof retrieveLatestRiskModelScoresOverview>, {data: ProvidersRiskModelFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  retrieveLatestRiskModelScoresOverview(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof retrieveLatestRiskModelScoresOverview>, TError, {data: ProvidersRiskModelFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const getPaginatedMembersList = (
    adminGetPaginatedMembersListRequest: AdminGetPaginatedMembersListRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetPaginatedMembersListResult>(
      {url: `/api/admin/RiskModel/GetPaginatedMembersList`, method: 'post',
      data: adminGetPaginatedMembersListRequest
    },
      options);
    }
  


    export const useGetPaginatedMembersList = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getPaginatedMembersList>, TError,{data: AdminGetPaginatedMembersListRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getPaginatedMembersList>, {data: AdminGetPaginatedMembersListRequest}> = (props) => {
          const {data} = props || {};

          return  getPaginatedMembersList(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getPaginatedMembersList>, TError, {data: AdminGetPaginatedMembersListRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const calculateAndCachePatientRiskModelScores = (
    params?: CalculateAndCachePatientRiskModelScoresParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminCalculateAndCachePatientRiskModelScoresResult>(
      {url: `/api/admin/RiskModel/CalculateAndCachePatientRiskModelScores`, method: 'post',
        params,
    },
      options);
    }
  


    export const useCalculateAndCachePatientRiskModelScores = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof calculateAndCachePatientRiskModelScores>, TError,{params?: CalculateAndCachePatientRiskModelScoresParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof calculateAndCachePatientRiskModelScores>, {params?: CalculateAndCachePatientRiskModelScoresParams}> = (props) => {
          const {params} = props || {};

          return  calculateAndCachePatientRiskModelScores(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof calculateAndCachePatientRiskModelScores>, TError, {params?: CalculateAndCachePatientRiskModelScoresParams}, TContext>(mutationFn, mutationOptions)
    }
    export const createRiskModelScoresOverview = (
    params?: CreateRiskModelScoresOverviewParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminCreateRiskModelScoresOverviewResult>(
      {url: `/api/admin/RiskModel/CreateRiskModelScoresOverview`, method: 'post',
        params,
    },
      options);
    }
  


    export const useCreateRiskModelScoresOverview = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof createRiskModelScoresOverview>, TError,{params?: CreateRiskModelScoresOverviewParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof createRiskModelScoresOverview>, {params?: CreateRiskModelScoresOverviewParams}> = (props) => {
          const {params} = props || {};

          return  createRiskModelScoresOverview(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof createRiskModelScoresOverview>, TError, {params?: CreateRiskModelScoresOverviewParams}, TContext>(mutationFn, mutationOptions)
    }
    export const deletePatientRiskModelScores = (
    params?: DeletePatientRiskModelScoresParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminDeletePatientRiskModelScoresResult>(
      {url: `/api/admin/RiskModel/DeletePatientRiskModelScores`, method: 'post',
        params,
    },
      options);
    }
  


    export const useDeletePatientRiskModelScores = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deletePatientRiskModelScores>, TError,{params?: DeletePatientRiskModelScoresParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deletePatientRiskModelScores>, {params?: DeletePatientRiskModelScoresParams}> = (props) => {
          const {params} = props || {};

          return  deletePatientRiskModelScores(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deletePatientRiskModelScores>, TError, {params?: DeletePatientRiskModelScoresParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getRiskModelScoresCacheInfo = (
    params?: GetRiskModelScoresCacheInfoParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetRiskModelScoresCacheInfoResult>(
      {url: `/api/admin/RiskModel/GetRiskModelScoresCacheInfo`, method: 'post',
        params,
    },
      options);
    }
  


    export const useGetRiskModelScoresCacheInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getRiskModelScoresCacheInfo>, TError,{params?: GetRiskModelScoresCacheInfoParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getRiskModelScoresCacheInfo>, {params?: GetRiskModelScoresCacheInfoParams}> = (props) => {
          const {params} = props || {};

          return  getRiskModelScoresCacheInfo(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getRiskModelScoresCacheInfo>, TError, {params?: GetRiskModelScoresCacheInfoParams}, TContext>(mutationFn, mutationOptions)
    }
    