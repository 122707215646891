/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminEnrichCachePatientMonthlyResult,
  AdminEnrichCachePatientMonthlyRequest,
  AdminCachePatientMonthlyQueryResult,
  RequestModelsCacheMonthlyQueryRequestRiskModel,
  AdminRetrieveCachePatientDataResult,
  AdminRetrieveCachePatientDataRequest,
  AdminRetrieveCachePatientRiskModelScoreOverviewResult,
  ProvidersRiskModelFilterCriteria,
  CachePatientDataQueryPatientBySearchTermResult,
  QueryPatientBySearchTermParams,
  CachePatientDataGetTotalMembersResult,
  CachePatientDataGetTotalMembersQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const enrichCachePatientMonthly = (
    adminEnrichCachePatientMonthlyRequest: AdminEnrichCachePatientMonthlyRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminEnrichCachePatientMonthlyResult>(
      {url: `/api/admin/CachePatientData/EnrichCachePatientMonthly`, method: 'put',
      data: adminEnrichCachePatientMonthlyRequest
    },
      options);
    }
  


    export const useEnrichCachePatientMonthly = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof enrichCachePatientMonthly>, TError,{data: AdminEnrichCachePatientMonthlyRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof enrichCachePatientMonthly>, {data: AdminEnrichCachePatientMonthlyRequest}> = (props) => {
          const {data} = props || {};

          return  enrichCachePatientMonthly(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof enrichCachePatientMonthly>, TError, {data: AdminEnrichCachePatientMonthlyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const queryCachePatientMonthly = (
    requestModelsCacheMonthlyQueryRequestRiskModel: RequestModelsCacheMonthlyQueryRequestRiskModel,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminCachePatientMonthlyQueryResult>(
      {url: `/api/admin/CachePatientData/QueryCachePatientMonthly`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequestRiskModel
    },
      options);
    }
  


    export const useQueryCachePatientMonthly = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof queryCachePatientMonthly>, TError,{data: RequestModelsCacheMonthlyQueryRequestRiskModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof queryCachePatientMonthly>, {data: RequestModelsCacheMonthlyQueryRequestRiskModel}> = (props) => {
          const {data} = props || {};

          return  queryCachePatientMonthly(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof queryCachePatientMonthly>, TError, {data: RequestModelsCacheMonthlyQueryRequestRiskModel}, TContext>(mutationFn, mutationOptions)
    }
    export const retrieveCachePatientData = (
    adminRetrieveCachePatientDataRequest: AdminRetrieveCachePatientDataRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminRetrieveCachePatientDataResult>(
      {url: `/api/admin/CachePatientData/RetrieveCachePatientData`, method: 'post',
      data: adminRetrieveCachePatientDataRequest
    },
      options);
    }
  


    export const useRetrieveCachePatientData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof retrieveCachePatientData>, TError,{data: AdminRetrieveCachePatientDataRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof retrieveCachePatientData>, {data: AdminRetrieveCachePatientDataRequest}> = (props) => {
          const {data} = props || {};

          return  retrieveCachePatientData(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof retrieveCachePatientData>, TError, {data: AdminRetrieveCachePatientDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const retrieveCachePatientRiskModelScoreOverview = (
    providersRiskModelFilterCriteria: ProvidersRiskModelFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminRetrieveCachePatientRiskModelScoreOverviewResult>(
      {url: `/api/admin/CachePatientData/RetrieveCachePatientRiskModelScoreOverview`, method: 'post',
      data: providersRiskModelFilterCriteria
    },
      options);
    }
  


    export const useRetrieveCachePatientRiskModelScoreOverview = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof retrieveCachePatientRiskModelScoreOverview>, TError,{data: ProvidersRiskModelFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof retrieveCachePatientRiskModelScoreOverview>, {data: ProvidersRiskModelFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  retrieveCachePatientRiskModelScoreOverview(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof retrieveCachePatientRiskModelScoreOverview>, TError, {data: ProvidersRiskModelFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const queryPatientBySearchTerm = (
    params?: QueryPatientBySearchTermParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CachePatientDataQueryPatientBySearchTermResult>(
      {url: `/api/admin/CachePatientData/QueryPatientBySearchTerm`, method: 'get',
        params,
    },
      options);
    }
  

export const getQueryPatientBySearchTermQueryKey = (params?: QueryPatientBySearchTermParams,) => [`/api/admin/CachePatientData/QueryPatientBySearchTerm`, ...(params ? [params]: [])];

    
export const useQueryPatientBySearchTerm = <TData = AsyncReturnType<typeof queryPatientBySearchTerm>, TError = unknown>(
 params?: QueryPatientBySearchTermParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof queryPatientBySearchTerm>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getQueryPatientBySearchTermQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof queryPatientBySearchTerm>> = () => queryPatientBySearchTerm(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof queryPatientBySearchTerm>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getTotalMembers = (
    cachePatientDataGetTotalMembersQuery: CachePatientDataGetTotalMembersQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CachePatientDataGetTotalMembersResult>(
      {url: `/api/admin/CachePatientData/GetTotalMembers`, method: 'post',
      data: cachePatientDataGetTotalMembersQuery
    },
      options);
    }
  


    export const useGetTotalMembers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getTotalMembers>, TError,{data: CachePatientDataGetTotalMembersQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getTotalMembers>, {data: CachePatientDataGetTotalMembersQuery}> = (props) => {
          const {data} = props || {};

          return  getTotalMembers(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getTotalMembers>, TError, {data: CachePatientDataGetTotalMembersQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const doNothingButStopOrvalIssueWithUnusedImportUseQuery = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<string>(
      {url: `/api/admin/CachePatientData/DoNothingButStopOrvalIssueWithUnusedImportUseQuery`, method: 'get'
    },
      options);
    }
  

export const getDoNothingButStopOrvalIssueWithUnusedImportUseQueryQueryKey = () => [`/api/admin/CachePatientData/DoNothingButStopOrvalIssueWithUnusedImportUseQuery`];

    
export const useDoNothingButStopOrvalIssueWithUnusedImportUseQuery = <TData = AsyncReturnType<typeof doNothingButStopOrvalIssueWithUnusedImportUseQuery>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof doNothingButStopOrvalIssueWithUnusedImportUseQuery>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getDoNothingButStopOrvalIssueWithUnusedImportUseQueryQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof doNothingButStopOrvalIssueWithUnusedImportUseQuery>> = () => doNothingButStopOrvalIssueWithUnusedImportUseQuery(requestOptions);

  const query = useQuery<AsyncReturnType<typeof doNothingButStopOrvalIssueWithUnusedImportUseQuery>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

