import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useX_retrieveTopCodes } from "api_extension/useQueryX/cacheMonthly/useX_retrieveTopCodes";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { useMemo } from "react";
import { ColumnsConstruct_TopCodes } from "shared/utilities/DataGridColumns/dataGridLayouts/gridColumns_TopCodes";
import { Loader } from "shared/components/Loaders/Loader";

function TopCodesPanel({ itemType }: { itemType: CacheMonthlyTypes }) {
	const columns = useMemo(() => {
		return ColumnsConstruct_TopCodes(itemType);
	}, [itemType]);

	const topCodesResponse = useX_retrieveTopCodes({ itemType: itemType });

	const topCodes = useMemo(() => {
		if (topCodesResponse?.data?.response?.topCodes) {
			return topCodesResponse?.data?.response?.topCodes;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topCodesResponse, topCodesResponse?.data?.response?.topCodes]);

	return (
		<Box>
			<ResendQueryButton queryCacheName="retrieveTopCodes" />
			<Box sx={{ display: "flex", height: "100%" }}>
				{topCodesResponse.isFetching || topCodesResponse.isLoading ? (
					<Loader />
				) : (
					<Box
						sx={{
							flexGrow: 1,
							"& .MuiDataGrid-footerContainer": { display: "none" },
							"& .MuiDataGrid-toolbarContainer": { display: "none" },
						}}
					>
						{topCodes ? (
							<DataGridPro
								//
								getRowId={(row) => row.rank}
								rowsPerPageOptions={[10]}
								rowCount={10}
								columns={columns}
								autoHeight
								rows={topCodes}
							/>
						) : null}
					</Box>
				)}
			</Box>
		</Box>
	);
}
export { TopCodesPanel };
