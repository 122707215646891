import { Box, Tooltip, Typography } from "@mui/material";
import { UserFollowedItemButton } from "./UserFollowedItemButton";

interface Props {
	code: string;
	name: string;
	followedItemType: UserFollowedItemTypeNames;
	tooltip?: React.ReactNode;
}
function UserFollowedItemTitle({ code, name, followedItemType, tooltip }: Readonly<Props>) {
	return (
		<Box display={"flex"}>
			<Tooltip arrow placement="right" title={tooltip}>
				<Typography sx={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "1.5rem" }}>
					<UserFollowedItemButton itemType={followedItemType} code={code} />
					{name}
				</Typography>
			</Tooltip>
		</Box>
	);
}

export { UserFollowedItemTitle };
