import { UseQueryResult } from "react-query";
import { Loader, LoaderSizes } from "./Loader";
import { apiResponseReader, ProcessedResponseErrorType } from "shared/utilities/apiResponseReader";
import { ReactNode } from "react";
import { ErrorDisplay } from "../DisplayElements/ErrorDisplay";
import { ModelsOperationStatus } from "api";
import { Box } from "@mui/material";

interface QueryStatusDisplayProps {
	queryResult?: UseQueryResult;
	operationStatus?: ModelsOperationStatus | null;
	debug?: boolean;
	showAsLoading?: boolean;
	// errorContent?: ReactNode;
	forceRenderChildren?: boolean;
	loaderSize?: LoaderSizes;
}

function d(debug: boolean | undefined, args: any[]) {
	if (debug === true) {
		console.debug(...args);
	}
}

const OperationStatusErrorDisplay = ({ opStat }: { opStat: ModelsOperationStatus | any }) => {
	if (apiResponseReader.typeCheck_ModelsOperationStatus(opStat) && !opStat.success) {
		return (
			<Box data-name="OperationStatusErrorDisplay" style={{ padding: "16px 0px" }}>
				{renderOperationStatusError(opStat)}
			</Box>
		);
	}
	return null;
};

const renderOperationStatusError = (op_status: ModelsOperationStatus) => {
	console.debug({ op_status });
	return renderError(op_status.errorMessage, op_status.errorType as ProcessedResponseErrorType);
};

const renderError = (errorMessage: null | ReactNode = null, errorType: ProcessedResponseErrorType = null) => {
	return (
		<ErrorDisplay
			errorType={errorType}
			errorMessage={errorMessage}
			//errorContent={errorContent}
		/>
	);
};

const QueryStatusDisplay = ({
	children,
	queryResult: qR,
	operationStatus: opStat,
	debug,
	showAsLoading,
	// errorContent,
	forceRenderChildren,
	loaderSize,
}: React.PropsWithChildren<QueryStatusDisplayProps>) => {
	if (typeof qR?.data == "object" && qR?.data?.hasOwnProperty("data") && !opStat) {
		if (apiResponseReader.typeCheck_ModelsOperationStatus((qR.data as any).data)) {
			opStat = (qR.data as any).data;
		}
	}
	if (forceRenderChildren === true) {
		return <>{children}</>;
	}

	let dbg = d.bind(null, debug);
	dbg(["QueryStatusDisplay queryResult", qR]);

	if (!qR && !opStat) {
		return null;
	}

	let errorMessage: null | ReactNode | string = null;
	let errorType: ProcessedResponseErrorType = null;

	// console.debug(qR);
	// console.debug((qR?.data as any)?.response?.data);
	// If axios is packaging a ModelOperationStatus error strip it off as the opStat value so it can be displayed as intended
	let cim: any = (qR?.data as any)?.response?.data;
	if (!opStat && cim) {
		if (apiResponseReader.typeCheck_ModelsOperationStatus(cim)) {
			opStat = cim as ModelsOperationStatus;
		}
	}
	//
	if (qR && !opStat) {
		if (qR.isLoading || qR.isRefetching || showAsLoading) {
			return <Loader size={loaderSize} />;
		}
		// AXIOS
		if (qR.isError) {
			errorMessage = <p>The request could not be completed</p>;
		}
		if (qR.isSuccess === true && qR.status === "success") {
			if (qR.data && typeof qR.data === "object") {
				if (apiResponseReader.typeCheck_ProcessedResponse(qR.data)) {
					if (qR.data.success === true) {
						return <>{children}</>;
					}
				}
			}
		}
	}
	if (qR) {
		if (qR.data && typeof qR.data === "object") {
			// console.debug("typeCheck_ModelsOperationStatus " + JSON.stringify(qR.data));

			if (apiResponseReader.typeCheck_ModelsOperationStatus(qR.data)) {
				if (!qR.data.success) {
					return renderOperationStatusError(qR.data);
				}
			}

			if (qR.data.hasOwnProperty("status") && (qR.data as any).status === 500) {
				errorMessage = <p>The request could not be completed</p>;
			}
			if (qR.data.hasOwnProperty("isAxiosError") && (qR.data as any).isAxiosError) {
				errorMessage = <p>The request could not be completed</p>;
			}
			if (qR.hasOwnProperty("isAxiosError") && (qR as any).isAxiosError) {
				errorMessage = <p>The request could not be completed</p>;
			}

			if (apiResponseReader.typeCheck_ProcessedResponse(qR.data)) {
				if (qR.data.success === true) {
					return <>{children}</>;
				}
				errorType = qR.data.errorType;
				errorMessage = <p>{qR.data.errorMessage}</p>;
			}

			if (apiResponseReader.typeCheck_ProcessedResponse(qR)) {
				if (qR.data.success === true) {
					return <>{children}</>;
				}
				errorMessage = <p>{qR.errorMessage}</p>;
			}
		}
	}

	if (opStat) {
		if (opStat.success === false) {
			return renderOperationStatusError(opStat);
		}
	}

	if (errorMessage) {
		dbg(["ERROR"]);
		return renderError(errorMessage, errorType);
	}

	return <>{children}</>;
};

export { QueryStatusDisplay, OperationStatusErrorDisplay };
