import React, { useMemo } from "react";
import { Box, Grid, Card, CardContent } from "@mui/material";

import { useSearchParams } from "react-router-dom";
import { DiagnosisDetailsMainControllable } from "sections/item_details/diagnosis/DiagnosisDetailsMain[Controllable]";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyDetail } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetail";
import { MedicationDetailsMain } from "sections/item_details/medication/MedicationDetailsMain";
import { MainTitleDisplayForEntityType } from "shared/components/DisplayElements/DisplayForType/MainTitleDisplayForEntityType";
import { CacheItemDetails } from "sections/item_details/cache/CacheItemDetails";
import { DoctorDetailsMain } from "./doctor/DoctorDetailsMain";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { ProductServiceDetailsMain } from "./productservice/ProductServiceDetailsMain";

function ItemDetailsMain(props: { forceCacheItemDetails?: boolean }) {
	const [searchParams] = useSearchParams();

	const [code, itemType, forceCacheItemDetails] = useMemo(() => {
		return [
			searchParams.get("code"),
			searchParams.get("itemType"),
			props.forceCacheItemDetails ?? !!(searchParams.get("forceCacheItemDetails") && searchParams.get("forceCacheItemDetails") !== "false"),
		];
	}, [searchParams, props.forceCacheItemDetails]);

	const { values: pfValues } = usePrimaryFilters();

	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		if (code && itemType) {
			return {
				jobIds: [...pfValues.Members],
				codes: [code],
				itemType: itemType,
				includeLookup: true,
			};
		}
		return null;
	}, [pfValues.Members, itemType, code]);

	const apiResult = useX_getCacheMonthlyDetail(queryRequest);

	const titleDisplay = useMemo(() => {
		if (code && apiResult.data?.response?.codeMonthAggregations?.[code]) {
			return MainTitleDisplayForEntityType({ item: apiResult.data?.response?.codeMonthAggregations?.[code][0] });
		}
		return null;
	}, [apiResult.data?.response?.codeMonthAggregations, code]);

	const [itemDetailsDisplay, hideTitle] = useMemo(() => {
		let display: React.ReactNode;
		let hideTitle = false;
		switch (itemType) {
			case "PROVIDER":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="PROVIDER" showDateSelect={false} /> : null;
				} else {
					display = code ? <DoctorDetailsMain npi={code} itemType="PROVIDER" /> : <p>error</p>;
				}
				break;
			case "ORGANIZATION":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="ORGANIZATION" showDateSelect={false} /> : null;
				} else {
					display = code ? <DoctorDetailsMain npi={code} itemType="ORGANIZATION" /> : <p>error</p>;
				}
				break;
			case "FACILITY":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="FACILITY" showDateSelect={false} /> : null;
				} else {
					display = code ? <DoctorDetailsMain npi={code} itemType="FACILITY" /> : <p>error</p>;
				}
				break;
			case "DIAGNOSIS":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="DIAGNOSIS" showDateSelect={false} /> : null;
				} else {
					display = <DiagnosisDetailsMainControllable code={code} />;
				}
				break;
			case "DIAGNOSIS_RANGE":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="DIAGNOSIS_RANGE" showDateSelect={false} /> : null;
				} else {
					display = <DiagnosisDetailsMainControllable range={code} />;
				}
				break;
			case "MEDICATION":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="MEDICATION" showDateSelect={false} /> : null;
				} else {
					display = <MedicationDetailsMain code={code} />;
					hideTitle = true;
				}
				break;
			case "PRODUCTSERVICE":
				if (forceCacheItemDetails) {
					display = code ? <CacheItemDetails code={code} itemType="PRODUCTSERVICE" showDateSelect={false} /> : null;
				} else {
					display = <ProductServiceDetailsMain code={code} />;
					hideTitle = true;
				}
				break;
			case "PATIENT":
				display = (
					<Card elevation={4}>
						<CardContent
							sx={{
								pt: 0,
								padding: 2,
							}}
						>
							{code ? <CacheItemDetails code={code} itemType="PATIENT_EOB" showDateSelect={false} /> : null}
						</CardContent>
					</Card>
				);
				break;
		}
		return [display, hideTitle];
	}, [itemType, code, forceCacheItemDetails]);

	return (
		<Box sx={{ width: "100%" }}>
			<ResendQueryButton queryCacheName="getCacheMonthlyDetail" />

			<Grid container spacing={2}>
				{!hideTitle ? (
					<Grid item xs={12} lg={12}>
						<Card>
							<CardContent
								sx={{
									pt: 0,
									padding: 2,
								}}
							>
								{titleDisplay}
							</CardContent>
						</Card>
					</Grid>
				) : null}
				<Grid item xs={12} lg={12}>
					{itemDetailsDisplay}
				</Grid>
			</Grid>
		</Box>
	);
}
export { ItemDetailsMain };
