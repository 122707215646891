import { useCallback, useEffect, useMemo, useState } from "react";
import { enrichCachePatientMonthly } from "api";
import { Loader } from "shared/components/Loaders/Loader";

type CachePatientMonthlyUpdater_Success = {
	count: number;
	iteration: number;
	jobId: string | null;
};

const outer: { add_SuccessCount: (sc: CachePatientMonthlyUpdater_Success) => any } = {
	add_SuccessCount: (sc: CachePatientMonthlyUpdater_Success) => null,
};

const CachePatientMonthlyUpdater = ({ activeJobId }: { activeJobId: string | null }) => {
	console.group("CachePatientMonthlyUpdater");

	const [successCounts, set_successCounts] = useState<Array<CachePatientMonthlyUpdater_Success>>([]);

	useEffect(() => {
		set_successCounts([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeJobId]);

	const [requesting, set_requesting] = useState(false);
	const add_SuccessCount = useCallback(
		(sc: CachePatientMonthlyUpdater_Success) => {
			set_successCounts([...successCounts, sc]);
		},
		[set_successCounts, successCounts]
	);
	outer.add_SuccessCount = add_SuccessCount;
	const trigger_enrichment = useMemo(() => {
		return async (iteration: number = 0) => {
			set_requesting(true);
			let x = await enrichCachePatientMonthly({ memberTypes: activeJobId ? [activeJobId] : undefined });
			console.debug("enrichCachePatientMonthly");
			console.debug({ x });
			set_requesting(false);
			if (typeof x.result?.data?.recordCount === "number") {
				outer.add_SuccessCount({
					count: x.result.data.recordCount,
					iteration: iteration,
					jobId: activeJobId,
				});
			}
		};
	}, [activeJobId, set_requesting]);

	useEffect(() => {
		let last = successCounts[successCounts.length - 1];
		if (last && (last.count > 0 || last.iteration <= 3)) {
			trigger_enrichment(successCounts.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successCounts]);

	console.groupEnd();
	return (
		<div>
			<p>{activeJobId}</p>

			<button
				onClick={() => {
					trigger_enrichment();
				}}
			>
				Run CachePatientMonthly Enrich
			</button>
			<div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", width: 600, border: "solid blue 1px" }}>
				{successCounts.map((sc) => {
					return (
						<div key={JSON.stringify(sc)} style={{ display: "inline-block", backgroundColor: "green", border: "solid black 1px", padding: 20 }}>
							{sc.count} ({sc.jobId}#{sc.iteration})
						</div>
					);
				})}
				{requesting ? (
					<div>
						<Loader size="small" />
					</div>
				) : null}
			</div>
		</div>
	);
};

export { CachePatientMonthlyUpdater };
