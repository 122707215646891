import { DiagnosisDetailsMain } from "../item_details/diagnosis/DiagnosisDetailsMain";
import { DiagnosisSummaryTileList } from "./ListSummaries/DiagnosisSummaryTileList";
import { Route, Routes } from "react-router-dom";

function DiagnosisRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<DiagnosisSummaryTileList />} />
			<Route path={`details/:diagnosisId`} element={<DiagnosisDetailsMain />} />
		</Routes>
	);
}

export { DiagnosisRoutes };
