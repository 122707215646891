/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
export type GetAllUsersParams = { page?: number; pageSize?: number; orderBy?: string; reverse?: boolean; searchQuery?: string };

export type DiagnosisAlertParams = { type?: string; patientReference?: string; billStartDate?: string; diagnosisCodes?: string[] };

export type GetRiskModelScoresCacheInfoParams = { riskModel?: string; jobId?: string };

export type DeletePatientRiskModelScoresParams = { riskModel?: string; jobId?: string };

export type CreateRiskModelScoresOverviewParams = { jobId?: string; riskModel?: string };

export type CalculateAndCachePatientRiskModelScoresParams = { riskModel?: string; jobId?: string; iteration?: number };

export type GetStateCountyListParams = { stateCode?: string };

export type GetHistoricalLookupsParams = { query?: AdminGetHistoricalLookupsQuery };

export type GetIcdSummaryParams = { icdCode?: string; icdSubRange?: string; icdTopRange?: string; raceCode?: string; genderCode?: string; stateCode?: string; countyCode?: string; ageCode?: string };

export type GetAllDistinctJobIdsParams = { query?: AdminGetAllDistinctJobIdsQuery };

export type GetProviderSavingsParams = { 'clientLocation.latitude'?: number; 'clientLocation.longitude'?: number; 'clientLocation.zipcode'?: string; 'clientLocation.distanceRadiusMiles'?: number };

export type RetrieveGoogleMapsLocationParams = { address?: string };

export type GetErrorByIdParams = { id?: number };

export type GetErrorReportsParams = { page?: number; pageSize?: number; errorCount?: number; searchQuery?: string; errorCode?: string; errorType?: string; startDate?: string; endDate?: string };

export type AddRecordBody = {
  pATIENT__reference?: string;
  fACILITY__clm_fac_type_cd?: string;
  fACILITY__npi?: string;
  oRGANIZATION__npi?: string;
  pROVIDER__prvdr_num?: string;
  iCD10CM?: string[];
  iCD10CM__category?: string[];
  iCD10CM__alphaCategory?: string[];
  iCD10CM__rangeCategory?: string[];
  iCD10PCS?: string[];
  iCD10PCS__rangeCategory?: string[];
  cARETEAM_primary?: string[];
  cARETEAM_assist?: string[];
  cODECOLLECTION?: string;
  recordID?: string;
  pK?: string;
  sK?: string;
};

export type RetrieveRecordBody = {
  recordID?: string;
  pK?: string;
  sK?: string;
};

export type GetTopProvidersAssociatedParams = { npi?: string; entityType?: ModelsNPIEnumerationType };

export type GetDoctorTopIcdSpendParams = { npi?: string };

export type GetDoctorTopCondtionsParams = { npi?: string };

export type RetrieveConditionsIcd10sByRangeParams = { range?: string };

export type GetAllConsumerUsersParams = { page?: number; pageSize?: number; orderBy?: string; reverse?: boolean; searchQuery?: string };

export type GetConfigDebugReportParams = { s?: string };

export type SendUserFeedbackParams = { subject?: string; message?: string };

export type EditConsumerUserLocationParams = { 'location.latitude'?: number; 'location.longitude'?: number; 'location.zip'?: string };

export type DismissUserAlertParams = { userAlertId?: number };

export type EditAlertPreferencesParams = { 'alertPreferences.newDiagnosis'?: boolean; 'alertPreferences.reminders'?: boolean; 'alertPreferences.careCollaboratorInvite'?: boolean; 'alertPreferences.updateUserInfo'?: boolean };

export type GetDrugStructureDataParams = { query?: string };

export type SearchTreatmentsByQueryStringParams = { query?: string; latitude?: number; longitude?: number; distance?: number; medicationType?: string };

export type GetCouponParams = { partnerUsername?: string; partnerPassword?: string };

export type GetDrugPricesAndLocationsParams = { ndc?: string; quantity?: number };

export type GetAllUSPSTFParams = { year?: number };

export type GetRecommendedUSPSTFParams = { year?: number; patientID?: string };

export type OverviewParams = { yearsBack?: number; startDate?: string; endDate?: string; patientId?: string };

export type GetMedicationDetailsParams = { query?: string };

export type GetDrugPricesAndLocationsMobileParams = { ndc?: string; quantity?: number };

export type GetConditionDetailMobileParams = { icd10?: string; includeDoctors?: boolean; inNetwork?: boolean };

export type GetDoctorsByZipCodesMobileParams = { zipCodes?: string; providerType?: string; facilityType?: string; physicianType?: string; gender?: string; languages?: string; classification?: string; taxonomyCodes?: string; rating?: number; inNetwork?: boolean };

export type GetDoctorsByLocationMobileParams = { providerType?: string; facilityType?: string; physicianType?: string; gender?: string; languages?: string; classification?: string; taxonomyCodes?: string; rating?: number; inNetwork?: boolean };

export type GetRecommendedUspstfMobileParams = { year?: number; patientID?: string };

export type GetConditionDetailParams = { icd10?: string; includeDoctors?: boolean; inNetwork?: boolean };

export type SendContactFormParams = { firstName?: string; lastName?: string; email?: string; comments?: string };

export type GetSimpleHospitalParams = { id?: string };

export type GetProviderDetailParams = { npi?: string };

export type GetDoctorsByNPIsBody = {
  npis?: string;
};

export type GetDoctorsByZipCodesParams = { zipCodes?: string; providerType?: string; facilityType?: string; physicianType?: string; gender?: string; languages?: string; classification?: string; taxonomyCodes?: string; rating?: number; inNetwork?: boolean };

export type GetDoctorsByLocationParams = { providerType?: string; facilityType?: string; physicianType?: string; gender?: string; languages?: string; classification?: string; taxonomyCodes?: string; rating?: number; inNetwork?: boolean };

export type SetActiveCareCollaborationParams = { authId?: number; patientAccountEmail?: string };

export type AcceptCareCollaborationParams = { authId?: number; patientAccountEmail?: string };

export type DismissInactiveReceivedCareCollaborationParams = { id?: number; senderEmail?: string };

export type DismissInactiveSentCareCollaborationParams = { id?: number; collaboratorEmail?: string };

export type RemoveReceivedCareCollaboratorParams = { inviterEmail?: string };

export type RevokeInvitedCareCollaboratorParams = { collaboratorEmail?: string };

export type InviteCareCollaboratorParams = { inviteEmail?: string };

export type GetSubLevelRangesParams = { icd10Range?: string };

export type ConnectHorizonBlueAccountParams = { email?: string; state?: string; code?: string; origin?: string };

export type HorizonBlueConstructAuthorizationURIParams = { email?: string; state?: string; origin?: string };

export type ConnectAnthemAccountParams = { email?: string; state?: string; code?: string; origin?: string };

export type AnthemConstructAuthorizationURIParams = { email?: string; state?: string; origin?: string };

export type RetrieveMedicareDataParams = { email?: string; origin?: string };

export type ConnectUnitedHealthAccountParams = { email?: string; state?: string; code?: string; origin?: string };

export type UnitedHealthConstructAuthorizationURIParams = { email?: string; state?: string; origin?: string };

export type ConnectAetnaAccountParams = { email?: string; state?: string; code?: string; origin?: string };

export type AetnaConstructAuthorizationURIParams = { email?: string; state?: string; origin?: string };

export type ConnectMedicareAccountParams = { email?: string; state?: string; code?: string; origin?: string };

export type MedicareConstructAuthorizationURIParams = { email?: string; state?: string; origin?: string };

export type QueryPatientBySearchTermParams = { searchQuery?: string; jobIds?: string };

export type RefreshTransformParams = { transformName?: string };

export type GetMedicationDropdownListParams = { searchQuery?: string; jobIds?: string };

export type GetProductServiceDropdownListParams = { searchQuery?: string; jobIds?: string };

export type UserFollowedItemType = 1 | 2 | 3 | 4 | 5 | 6 | 7;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFollowedItemType = {
  NUMBER_1: 1 as UserFollowedItemType,
  NUMBER_2: 2 as UserFollowedItemType,
  NUMBER_3: 3 as UserFollowedItemType,
  NUMBER_4: 4 as UserFollowedItemType,
  NUMBER_5: 5 as UserFollowedItemType,
  NUMBER_6: 6 as UserFollowedItemType,
  NUMBER_7: 7 as UserFollowedItemType,
};

export interface UserFollowedItemResponse {
  itemType?: UserFollowedItemType;
  code: string;
  created: string;
  deactivated?: string | null;
  itemTypeName?: string;
}

export interface TestNewEobNotifierRequest {
  icd?: string;
  patientReference?: string | null;
}

export interface SQSOptionsPlain {
  readonly userRequestQueueURL?: string;
  type?: string | null;
}

export interface SQSOptions {
  userRequestQueueURL?: string | null;
}

export interface RxSenseOptionsPlain {
  clientID?: string;
  clientSecret?: string;
  apiUrl?: string;
  type?: string | null;
}

export interface RequestModelsCacheMonthlyQueryRequestNestedProperties {
  medicationCodes?: string[] | null;
  diagnosisCodes?: string[] | null;
  providerCodes?: string[] | null;
  productserviceCodes?: string[] | null;
  patientCodes?: string[] | null;
}

export interface RequestModelsCacheMonthlyQueryRequestRiskModel {
  jobIds?: string[] | null;
  dateRangeRequest?: ModelsDateRangeRequest;
  paginationRequest?: ModelsPaginationAndSortRequest;
  codes?: string[] | null;
  nestedCodes?: RequestModelsCacheMonthlyQueryRequestNestedProperties;
  itemType?: string;
  includeLookup?: boolean;
  includeMissingItemsLookup?: boolean;
  riskModelRequest?: ModelsRiskModelRequest;
}

export interface RequestModelsCacheMonthlyQueryRequest {
  jobIds?: string[] | null;
  dateRangeRequest?: ModelsDateRangeRequest;
  paginationRequest?: ModelsPaginationAndSortRequest;
  codes?: string[] | null;
  nestedCodes?: RequestModelsCacheMonthlyQueryRequestNestedProperties;
  itemType?: string;
  includeLookup?: boolean;
  includeMissingItemsLookup?: boolean;
}

export interface RequestModelsCacheMonthlyDetailsQueryRequest {
  jobIds?: string[] | null;
  dateRangeRequest?: ModelsDateRangeRequest;
  paginationRequest?: ModelsPaginationAndSortRequest;
  codes?: string[] | null;
  nestedCodes?: RequestModelsCacheMonthlyQueryRequestNestedProperties;
  itemType?: string;
  includeLookup?: boolean;
  includeMissingItemsLookup?: boolean;
  codeMonthAggregations?: boolean;
  codeAggregations?: boolean;
}

export interface ProvidersSummaryFilterCriteria {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
}

export interface ProvidersRiskModelRepositoryRiskModelScoresCacheInfo {
  riskModel?: string | null;
  jobId?: string | null;
  cachedCount?: number | null;
  patientCount?: number | null;
}

export interface ProvidersRiskModelFilterCriteria {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  riskModel?: string | null;
}

export interface ProvidersPatientCacheRepositoryRetrievePatientCacheRecordsBYAllCodesRESPONSE {
  items: ModelsPatientCacheRecordCompilation[];
  patient_count: number;
  total_amount: number;
  pagination: ModelsPaginationRequest;
}

export interface ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse {
  ageGenderModels: ModelsAgeGenderModel[];
  genderModels: ModelsGenderModel[];
}

export interface ProvidersMedicationCacheRepositoryRetrieveMedicationImpactResponse {
  billingGraph?: ModelsGraphModel;
  fillsGraph?: ModelsDecimalGraphModel;
  totalRevenue?: number | null;
  categoryPct?: number | null;
  retentionRate?: number | null;
}

export interface ProvidersMedicationCacheRepositoryRetrieveMedicationCodeAggregationsByProductServiceResponse {
  aggregateItems?: ModelsCacheMedicationMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  medicationCount?: number | null;
  averageCost?: number | null;
}

export interface ProvidersMedicationCacheRepositoryRetrieveMedicationCodeAggregationsByDiagnosisResponse {
  aggregateItems?: ModelsCacheMedicationMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  medicationCount?: number | null;
  averageCost?: number | null;
}

export interface ProvidersMedicationCacheRepositoryRetrieveMedicationAggregationsResponse {
  items?: ModelsCacheMonthly[] | null;
  aggregateItems?: ModelsMedicationRangeCacheMonthlyAggregate[] | null;
  rangeCount?: number | null;
}

export interface ProvidersHistoricalLookupRecord {
  id?: string;
  type?: string;
  code?: string;
  description?: string;
}

export interface ReportingGetStateCountyListResult {
  counties?: ProvidersHistoricalLookupRecord[];
}

export interface ReportingGetDropdownDataResult {
  genders?: ProvidersHistoricalLookupRecord[];
  races?: ProvidersHistoricalLookupRecord[];
  states?: ProvidersHistoricalLookupRecord[];
  ageRanges?: ProvidersHistoricalLookupRecord[];
}

export interface ProvidersDoctorCacheRepositoryRetrieveDoctorsByProductServiceResponse {
  items?: ModelsCacheProviderMonthly[] | null;
  aggs?: ModelsCacheMonthlyAggregate[];
  doctorCount?: number | null;
}

export interface ProvidersDoctorCacheRepositoryRetrieveDoctorsByMedicationResponse {
  items?: ModelsCacheProviderMonthly[] | null;
  aggs?: ModelsCacheMonthlyAggregate[];
  doctorCount?: number | null;
}

export interface ProvidersDoctorCacheRepositoryRetrieveDoctorsByDiagnosisResponse {
  items?: ModelsCacheProviderMonthly[] | null;
  aggs?: ModelsCacheProviderMonthlyAggregate[];
  doctorCount?: number | null;
}

export interface ProvidersDoctorCacheRepositoryRetrieveDoctorDataByNpiResponse {
  npis?: string[];
  aggs?: ModelsCacheProviderMonthlyDoctorDataAggregate[];
}

export interface ProvidersDoctorCacheRepositoryBucketDoctorCacheMonthly {
  npi?: string;
  totalAmount?: number;
  diagnosisCount?: number;
  medicationCount?: number;
  procedureCount?: number;
  codeCounts?: ModelsCodeCount[];
  name?: string | null;
  recordCount?: number | null;
  radiusRating?: number | null;
  specialties?: string | null;
}

export interface ProvidersDoctorCacheRepositoryRetrieveDoctorCacheRecordsResponse {
  cacheProviderMonthlies?: ModelsCacheProviderMonthly[] | null;
  doctors?: ProvidersDoctorCacheRepositoryBucketDoctorCacheMonthly[] | null;
  doctorCount?: number | null;
}

export interface ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisRangeAggregationsResponse {
  items?: ModelsDiagnosisRangeCacheMonthly[] | null;
  aggregateItems?: ModelsDiagnosisRangeCacheMonthlyAggregate[] | null;
  rangeCount?: number | null;
}

export interface ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisImpactResponse {
  billingGraph?: ModelsGraphModel;
  fillsGraph?: ModelsDecimalGraphModel;
  totalRevenue?: number | null;
  categoryPct?: number | null;
  retentionRate?: number | null;
}

export interface ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsResponse {
  items?: ModelsCacheDiagnosisMonthly[] | null;
  aggregateItems?: ModelsCacheDiagnosisMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
}

export interface ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsByProductServiceResponse {
  aggregateItems?: ModelsCacheDiagnosisMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  conditionCount?: number | null;
  averageCost?: number | null;
}

export interface ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsByMedicationResponse {
  aggregateItems?: ModelsCacheDiagnosisMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  conditionCount?: number | null;
  averageCost?: number | null;
}

export interface ProvidersConfigDebugReport {
  medicareBlueButtonOptionsPlain?: MedicareBlueButtonOptionsPlain;
  iAetnaOptions?: IAetnaOptions;
  healthdatasourceconfiG__MEDICARE__BLUEBUTTON_EnvVar?: string | null;
  healthdatasourceconfiG__AETNA__FHIRSERVER_EnvVar?: string | null;
  rxsensE_EnvVar?: string;
  rxSenseOptionsPlain?: RxSenseOptionsPlain;
  rxSenseOptionsPlain_EnvVar?: RxSenseOptionsPlain;
  awS_EnvVar?: string | null;
  messagingPlain?: MessagingMessagingConfigurationSecretsPlain;
  messagingPlain_AppSettings?: MessagingMessagingConfigurationSecretsPlain;
  sqsOptions?: SQSOptions;
  sqsOptionsPlain?: SQSOptionsPlain;
}

export interface ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyResponse {
  items?: ModelsCacheMonthly[] | null;
  buckets?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts[] | null;
  totalItems?: number | null;
  missingItemList?: ModelsLookUpItem[] | null;
}

export interface ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse {
  totalAmount?: number;
  eobCount?: number;
  distinctCodeCount?: number;
  itemType?: string;
}

export interface ProvidersCacheMonthlyRepositoryCodeTotalsResult {
  code?: string;
  lookUpItem?: ModelsLookUpItem;
  eobCount?: number | null;
  totalAmount?: number | null;
  rank?: number | null;
  jobIds?: string[] | null;
}

export interface ProvidersCacheMonthlyRepositoryRetrieveTopCodesResponse {
  topCodes?: ProvidersCacheMonthlyRepositoryCodeTotalsResult[];
}

export interface ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount {
  codeCounts?: ModelsCodeCountLookup[] | null;
  totalCount?: number;
  uniqueCount?: number;
  totalCost?: number;
}

export interface ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts {
  code?: string;
  period?: number | null;
  periodDate?: string | null;
  periodEndDate?: string | null;
  lookUpItem?: ModelsLookUpItem;
  jobIds?: string[] | null;
  eobCount?: number | null;
  totalAmount?: number;
  diagnosis?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  medication?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  provider?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  organization?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  patient?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  productservice?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
  facility?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCount;
}

export type ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponseCodeAggregations = {[key: string]: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts};

export type ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponseCodeMonthAggregations = {[key: string]: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts[]};

export interface ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse {
  items?: ModelsCacheMonthly[] | null;
  codeMonthAggregations?: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponseCodeMonthAggregations;
  codeAggregations?: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponseCodeAggregations;
  totalItems?: number | null;
  lookupDictionaries?: ModelsLookUpDictionaries;
}

export interface ProductServiceCacheProductServiceMonthlyAggregate {
  productServiceCode: string;
  uniquePatientCount: number;
  eobCount: number;
  totalAmount: number;
  pmPm: number;
  name: string;
  description: string;
  uniqueMedicationCount: number;
  uniqueDiagnosisCount: number;
  hcpcsBase?: ModelsHCPCSBase;
}

export interface PatientGetPatientRecordsResult {
  eobRecords?: ModelsEobRecord[];
  totalRecords?: number;
}

export interface PatientGetPatientRecordsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  searchQuery?: string;
  skip?: number;
  take?: number;
  recordCount?: number;
}

export interface PatientGetAllPatientsResult {
  page?: number;
  pageSize?: number;
  readonly pageCount?: number;
  readonly count?: number;
  total?: number;
  readonly paginationToken?: string | null;
  items?: ModelsPatientModel[];
}

export interface PatientGetAllPatientsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  patientCount?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface PatientDataAuthorizationItem {
  id?: number | null;
  patientDataAuthorizationId: number;
  patientType: string;
  patient: string;
  patientRecordID: string;
  isSelectedByUser: boolean;
  isActivePatientItem?: boolean;
  demographicInfo?: ModelsOpenSearchPatient;
}

export interface OverviewGetTopFacilitiesGraphResult {
  facilitiesGraphModel?: ModelsGraphModelPoint[];
}

export interface OverviewGetRevenueSummaryChartsResult {
  summaryGraphModels?: ModelsRevenueGraphModels;
}

export interface OverviewGetOverallServicesChartResult {
  overallServicesModel?: ModelsGraphModelStringPoint[];
}

export interface Name {
  first?: string | null;
  middle?: string | null;
  last?: string | null;
}

export interface MyCostGetProviderSavingsResult {
  clinics?: ModelsClinicRecord[];
}

export interface MyCostGetNearbyAmbulatoryResult {
  ambulatoryCenters?: MyCostAmbulatoryEntity[];
}

export interface MyCostGetNearbyAmbulatoryRequest {
  clientLocation: ModelsClientLocation;
}

export interface MyCostAmbulatoryEntity {
  zip?: string | null;
  organizationName?: string | null;
  npi?: string;
  radiusRating?: number | null;
  distanceMiles?: number | null;
  imageUrl?: string;
}

export interface ModelsYearlySavingsModel {
  medicationCost?: number;
  providerCost?: number;
  hospitalCost?: number;
  medicationSavings?: number;
  providerSavings?: number;
  hospitalSavings?: number;
  combinedCategoryCost?: number;
}

export interface ModelsVisitToken {
  id?: string;
  date?: string | null;
  paymentAmount?: number | null;
}

export interface ModelsVisitAssessmentV2 {
  id?: string;
  date?: string | null;
  paymentAmount?: number | null;
  title?: string;
  readonly careAlertTypes?: string[] | null;
  provider?: ModelsDoctorNubSpecialty;
  diagnoses?: ModelsDiagnosisToken[];
  treatments?: ModelsHCPCSBase[];
  medications?: ModelsTerminalDrugStructureDataNub[];
  organization?: ModelsDoctorNubSpecialty;
}

export interface ModelsViewport {
  northeast?: ModelsGeoLocation;
  southwest?: ModelsGeoLocation;
}

export interface ModelsValueCount {
  name: string;
  count: number;
}

export interface ModelsUspstfStubIgnorable {
  id?: number;
  type?: string;
  shortTitle?: string;
  text?: string;
  isComplete?: boolean;
  hasReminder?: boolean;
  title?: string;
  markupTitle?: string;
  isIgnorable?: boolean;
  readonly code?: string;
}

export interface ModelsUspstfStub {
  id?: number;
  type?: string;
  shortTitle?: string;
  text?: string;
  isComplete?: boolean;
  hasReminder?: boolean;
  title?: string;
  markupTitle?: string;
}

export interface ModelsUniversalItemDisplay {
  name?: string | null;
  description?: string | null;
}

export interface ModelsUSPSTF {
  id?: number;
  type?: string;
  shortTitle?: string;
  text?: string;
  hasReminder?: boolean;
  title?: string;
  markupTitle?: string;
  ageRange?: number[];
  gender?: string;
  general?: string;
  grade?: string;
  gradeVer?: number;
  risk?: string[];
  riskName?: string;
  riskText?: string;
  sex?: string;
  categories?: string;
  tool?: string[];
  inferredType?: string;
  userHasCompleted?: boolean | null;
  manuallyCompleted?: boolean | null;
  manuallyIgnored?: boolean | null;
  completedByCPTCode?: string | null;
  cptCodes?: string[] | null;
  userAddedReminder?: boolean | null;
  readonly isComplete?: boolean;
}

export interface ModelsTerminalDrugStructureDataNub {
  ndc: string;
  name?: string;
}

export interface ModelsTerminalDrugStructureDataStub {
  ndc: string;
  name?: string;
  aggregatedRecord?: boolean;
  description?: string | null;
  fullName?: string | null;
  gpi?: string;
  inactive?: boolean | null;
  isGeneric?: boolean | null;
}

export interface ModelsTerminalDrugStructureData {
  ndc: string;
  name?: string;
  aggregatedRecord?: boolean;
  description?: string | null;
  fullName?: string | null;
  gpi?: string;
  inactive?: boolean | null;
  isGeneric?: boolean | null;
  createdAt?: string;
  canonicalURL?: string | null;
  deaClassCode?: string | null;
  displayQuantity?: number | null;
  dosage?: string | null;
  form?: string | null;
  marketingForm?: string | null;
  marketingName?: string | null;
  marketingSEOName?: string | null;
  metaDescription?: string | null;
  metaTitle?: string | null;
  numScripts?: number | null;
  packageQuantity?: number | null;
  packageSize?: number | null;
  packageSizeUOM?: string | null;
  priceUsualAndCustomary?: number | null;
  quantity?: number | null;
  repack?: boolean | null;
  rxNorm?: string | null;
  seoName?: string | null;
  seoNoIndex?: boolean | null;
  strength?: number | null;
  strengthString?: string | null;
  strengthUnitOfMeasure?: string | null;
  topPrescriptionSortOrder?: number | null;
  treatment?: string | null;
  unitDoseUnitUsePkgCode?: string | null;
  generatedFromInexactMatchNdc?: string | null;
}

export interface ModelsSummaryGraphModels {
  totalPatientsGraphModel?: ModelsGraphModel;
  pmpmBillablesGraph?: ModelsGraphModel;
  billingTrendGraphModel?: ModelsGraphModel;
  inNetworkGraphModel?: ModelsGraphModel;
  totalConversionsGraphModel?: ModelsGraphModel;
}

export interface ModelsSubRange {
  range?: string;
  description?: string;
  code?: string;
}

export interface ModelsStringApiResponse {
  operationStatus?: ModelsOperationStatus;
  content?: string | null;
}

export interface ModelsSiteList {
  name?: string;
  organization?: string;
  url?: string;
  languageMappedUrl?: string;
}

export interface ModelsSimpleHospital {
  zip?: string;
  overview?: string;
  recordId?: string;
  address?: string;
  name?: string;
  hospitalRating?: number | null;
  state?: string;
  distanceMiles?: number | null;
}

export interface ModelsServicesModel {
  recordId?: string | null;
  patientId?: string | null;
  age?: number;
  doctorName?: string | null;
  affiliation?: string | null;
  serviceType?: string | null;
  date?: string | null;
  totalBilling?: number;
}

export interface ModelsRxSenseTreatmentResult {
  hitType?: string;
  score?: number;
  id?: string;
  displayName?: string;
  seoName?: string;
  ndc?: string;
  isGeneric?: boolean;
  location?: ModelsLatitudeLongitude;
}

export interface ModelsRxSenseTieredPricingValue {
  isLegalEligible?: boolean;
  mailOrderPricings?: unknown[];
  ndc?: string;
  pharmacyPricings?: ModelsRxSenseTieredPricingPharmacyPricing[];
  quantity?: number;
}

export interface ModelsRxSensePrice {
  exclusivePriceDetails?: unknown;
  formattedPrice?: string;
  isLoyaltyEnabled?: boolean;
  pbaClientId?: number;
  pbaClientName?: string;
  pbm?: unknown;
  partnerPassword?: string;
  partnerUser?: string;
  percentageSavings?: string;
  price?: number;
  requiresMembership?: boolean;
  usualAndCustomary?: number;
}

export interface ModelsRxSenseAddress {
  address1?: string;
  address2?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  state?: string;
}

export interface ModelsRxSensePharmacy {
  address?: ModelsRxSenseAddress;
  cacheDistribution?: unknown;
  distance?: number;
  groups?: unknown;
  inNetworkPharmacy?: boolean;
  isOnlineOnly?: boolean;
  logoFileName?: string;
  logoUrl?: string;
  nabp?: string;
  npi?: string;
  name?: string;
  ncpdpChaincode?: string;
  pharmacyExclusions?: unknown;
}

export interface ModelsRxSenseTieredPricingPharmacyPricing {
  displayPrice?: number;
  pharmacy?: ModelsRxSensePharmacy;
  prices?: ModelsRxSensePrice[];
  sortByDisplayPrice?: boolean;
}

export interface ModelsRiskModelScoreOverview {
  id?: string;
  job_id?: string;
  risk_model?: string;
  calculated_date?: string;
  most_recent_bill_end_date?: string;
  is_valid?: boolean;
  total_member?: number;
  total_no_records?: number;
  total_average_score_basis?: number;
  average_score?: number;
  score_range_max?: number;
  score_range_min?: number;
  members_healthy?: number;
  members_moderate?: number;
  members_serious?: number;
  records_processed_count?: number;
}

export interface ModelsRiskModelScoreMembersResponse {
  id: string;
  patientId: string;
  jobId: string;
  procedureCount?: number | null;
  medicationCount?: number | null;
  diagnosisCount?: number | null;
  paymentAmount?: number | null;
  totalAmount?: number | null;
  adjustmentAmount?: number | null;
  riskCategoryRadiusCare?: string | null;
  birthDate?: string | null;
  gender?: string | null;
  riskModel: string;
  calculatedDate: string;
  mostRecentBillEndDate: string;
  qualifierCodes: string[];
  hits?: number;
  score?: number;
  isValid?: boolean;
  categories?: string[];
  conditions?: string[];
  uniqueDiagnosisCodes?: string[] | null;
  recordsProcessedCount?: number;
  riskCategory?: string | null;
  readonly age?: number | null;
}

export interface ModelsRiskModelRequest {
  riskModelName?: string;
  riskCategoryName?: string | null;
}

export interface ModelsRiskModel {
  icd: string;
  category: string;
  condition: string;
  codeRange: string;
  start: string;
  end?: string | null;
}

export interface ModelsRiskCategoryData {
  riskCategory: string;
  memberPercent: number;
  memberCount: number;
}

export interface ModelsRevenueServicesProcedureModel {
  totalProcedures?: number;
  totalBillAmount?: number;
  averageCost?: number;
  totalProcedureTypes?: number;
  procedures?: ModelsProcedureModel[];
}

export interface ModelsRevenueServicesPrescriptionsModel {
  totalPrescriptions?: number;
  totalBilling?: number;
  averageCost?: number;
  totalPrescriptionTypes?: number;
  prescriptions?: ModelsPrescription[];
}

export interface ModelsRevenueServicesAppointmentsModel {
  totalAppointments?: number;
  totalBilling?: string;
  averageCost?: number;
  providerCount?: number;
  appointments?: ModelsAppointment[];
}

export interface ModelsRevenueOverviewModel {
  newPrescriptions?: number;
  patientCoPay?: string;
  totalRevenueGraph?: ModelsGraphModelStringPoint[];
  facilityRevenueGraph?: ModelsGraphModelStringPointWithLookUpItem[];
  conditionSpendGraph?: ModelsGraphModelStringPointWithLookUpItem[];
  medicationSpendGraph?: ModelsGraphModelStringPointWithLookUpItem[];
  productServiceSpendGraph?: ModelsGraphModelStringPointWithLookUpItem[];
}

export interface ModelsRevenueGraphModels {
  diagnosisSummaryGraph?: ModelsGraphModel;
  productserviceSummaryGraph?: ModelsGraphModel;
  medicationSummaryGraph?: ModelsGraphModel;
  revenueOverview?: ModelsRevenueOverviewModel;
}

export interface ModelsProviderTaxonomy {
  code?: string;
  specialization?: string;
  section?: string;
  classification?: string;
  grouping?: string;
  displayName?: string;
  isHiddenInSearch?: boolean;
}

export interface ModelsProviderAssessmentV2 {
  npi?: string;
  name?: string;
  specialties?: string[];
  specialtyClassifications?: string[];
  radiusRating?: number | null;
  genderCode?: string | null;
  imageUrl?: string | null;
  careAlertTypes?: string[] | null;
  visits?: ModelsVisitToken[];
  diagnoses?: ModelsDiagnosisToken[];
  treatments?: ModelsHCPCSBase[];
  medications?: ModelsTerminalDrugStructureDataNub[];
  ignoreCareAlerts?: boolean;
}

export interface ModelsProcedureModel {
  procedureName?: string;
  cptCode?: string;
  numberOfProcedures?: number;
  totalBilling?: number;
}

export interface ModelsPrescription {
  name?: string | null;
  medicationCode?: string | null;
  totalPrescriptions?: number;
  averageCost?: number;
  totalBilling?: number;
}

export interface ModelsPrescribingEventToken {
  id?: string;
  date?: string | null;
  paymentAmount?: number | null;
  readonly prescribedBy?: string | null;
  readonly pharmacy?: string | null;
  daysSupply?: number | null;
}

export interface ModelsPrescribingEvent {
  pharmacy?: string | null;
  daysSupply?: number | null;
  npi?: string | null;
  date?: string;
}

export interface ModelsPieChartModel {
  name?: string;
  value?: number;
}

export interface ModelsPharmacyPricingCondensed {
  nabp?: string;
  ncpdpChaincode?: string;
  name?: string;
  distanceInMiles?: number;
  imageUrl?: string;
  partnerPassword?: string;
  partnerUser?: string;
  pricing?: number;
  address?: ModelsRxSenseAddress;
}

export interface ModelsPharmacyPricingsCondensed {
  ndc?: string;
  pharmacyPricings?: ModelsPharmacyPricingCondensed[];
}

export interface ModelsPatientRiskModelScoresResponse {
  jobId?: string[];
  riskModel?: string;
  calculatedDate?: string;
  readonly memberHealthData?: ModelsRiskCategoryData[];
  metrics?: ModelsMetricsPatientRiskModelScoresResponse;
  fromCachedRiskModelScoreOverview?: boolean;
  fromCombinedPatientRiskModelScoresResponse?: boolean;
  combinedPatientRiskModelScoresResponseCount?: number | null;
}

export interface ModelsPatientRiskCategory {
  patientId: string;
  riskCategory?: string | null;
  gender?: string | null;
  birthDate?: string | null;
  jobId?: string | null;
}

export interface ModelsPatientModel {
  id?: string | null;
  age?: number;
  gender?: string | null;
  providers?: number;
  procedures?: number;
  visits?: number;
  conditions?: number;
  prescriptions?: number;
}

export interface ModelsPatientCacheRecordCompilation {
  jobId?: string;
  patId?: string;
  bDay?: string | null;
  gender?: string | null;
  totAmntSum?: number | null;
  recCnt?: number | null;
}

export interface ModelsPatientCacheBasicResponseItem {
  patientId?: string;
  recordCount?: number | null;
  totalAmount?: number | null;
  diagnosisCount?: number | null;
  medicationCount?: number | null;
  productserviceCount?: number | null;
  gender?: string | null;
  birthDate?: string | null;
  riskCategory?: string | null;
  jobId?: string | null;
}

export interface ModelsPaginationRequest {
  skip: number;
  take: number;
}

export interface ModelsPaginationAndSortRequest {
  skip: number;
  take: number;
  field?: string | null;
  subfield?: string | null;
  descending?: boolean;
  maxNestedSize?: number | null;
}

export interface ModelsOperationStatusString {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: string | null;
  readonly responseType: string;
}

export type ModelsOperationStatusData = unknown | null;

export interface ModelsOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ModelsOperationStatusData;
  readonly responseType: string;
}

export interface ModelsOpenSearchPatient {
  record_id?: string;
  address?: string;
  deceased?: string;
  gender?: string;
  birth_date?: string;
  birth_date_string?: string;
  name?: string;
  create_date?: string;
  client_id?: string;
  insured_relationship_description?: string | null;
  insured_reference?: string | null;
  insured_relationship_code?: string | null;
}

export interface ModelsNestedCodeCountAggregation {
  count?: number | null;
  cost?: number | null;
  avgcost?: number | null;
}

export type ModelsNPIEnumerationType = 1 | 2;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModelsNPIEnumerationType = {
  NUMBER_1: 1 as ModelsNPIEnumerationType,
  NUMBER_2: 2 as ModelsNPIEnumerationType,
};

export interface ModelsNDC {
  ndc?: string;
  description?: string | null;
  full_name?: string | null;
  marketing_name?: string | null;
  name?: string | null;
  price_usual_and_customary?: number | null;
}

export type ModelsModifyOperationType = 1 | 2 | 3 | 4 | 5;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModelsModifyOperationType = {
  NUMBER_1: 1 as ModelsModifyOperationType,
  NUMBER_2: 2 as ModelsModifyOperationType,
  NUMBER_3: 3 as ModelsModifyOperationType,
  NUMBER_4: 4 as ModelsModifyOperationType,
  NUMBER_5: 5 as ModelsModifyOperationType,
};

export interface ModelsMetricsPatientRiskModelScoresResponse {
  alreadyCached?: number;
  newlyCached?: number;
  patientsProcessed?: number;
  iterationPatientSize?: number;
  paginationRequest?: ModelsPaginationRequest;
  recordsProcessedCount?: number;
  checkedMemberCount?: number;
  totalMemberCount?: number;
  totalCachedCount?: number;
  totalCalculatedCount?: number;
  totalNoRecordsCount?: number;
  averageScore?: number;
  averageScoreBasisCount?: number;
  scoreRangeMax?: number;
  scoreRangeMin?: number;
}

export interface ModelsMedicationRangeCacheMonthlyAggregate {
  code: string;
  name: string;
  occurrenceCount: number;
  totalAmount: number;
  uniquePatientCount: number;
  uniqueConditionCount: number;
  uniqueProcedureCount: number;
  pmpm: number;
}

export interface ModelsMedicationDetailsMobileResponseDrugInformation {
  name?: string;
  sideEffectsHtml?: string | null;
  summaryText?: string | null;
  subTypes?: ModelsDrugInformationSubType[];
}

export interface ModelsMedicationAssessmentV2 {
  ndc: string;
  name?: string;
  prescriptions?: ModelsPrescribingEventToken[];
}

export interface ModelsLookUpItem {
  itemType?: string;
  code?: string;
  provider?: ModelsDoctorStub;
  organization?: ModelsDoctorStub;
  medication?: ModelsTerminalDrugStructureData;
  medicationNdc?: ModelsNDC;
  diagnosis?: ModelsICD10CMLight;
  diagnosisRange?: ModelsICD10Range;
  hcpcs?: ModelsHCPCSBase;
  icdpcs?: ModelsICDPCS;
  patientRiskCategory?: ModelsPatientRiskCategory;
  universalItemDisplay?: ModelsUniversalItemDisplay;
}

export type ModelsLookUpDictionaryTerminalDrugStructureDataItemDictionary = {[key: string]: ModelsTerminalDrugStructureData} | null;

export interface ModelsLookUpDictionaryTerminalDrugStructureData {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryTerminalDrugStructureDataItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryPatientRiskCategoryItemDictionary = {[key: string]: ModelsPatientRiskCategory} | null;

export interface ModelsLookUpDictionaryPatientRiskCategory {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryPatientRiskCategoryItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryICDPCSItemDictionary = {[key: string]: ModelsICDPCS} | null;

export interface ModelsLookUpDictionaryICDPCS {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryICDPCSItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryICD10RangeItemDictionary = {[key: string]: ModelsICD10Range} | null;

export interface ModelsLookUpDictionaryICD10Range {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryICD10RangeItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryICD10CMLightItemDictionary = {[key: string]: ModelsICD10CMLight} | null;

export interface ModelsLookUpDictionaryICD10CMLight {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryICD10CMLightItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryHCPCSBaseItemDictionary = {[key: string]: ModelsHCPCSBase} | null;

export interface ModelsLookUpDictionaryHCPCSBase {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryHCPCSBaseItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export type ModelsLookUpDictionaryDoctorStubItemDictionary = {[key: string]: ModelsDoctorStub} | null;

export interface ModelsLookUpDictionaryDoctorStub {
  codes?: string[];
  readonly itemDictionary?: ModelsLookUpDictionaryDoctorStubItemDictionary;
  readonly itemType?: string;
  itemKey?: string;
}

export interface ModelsLookUpDictionaries {
  provider?: ModelsLookUpDictionaryDoctorStub;
  organization?: ModelsLookUpDictionaryDoctorStub;
  facility?: ModelsLookUpDictionaryDoctorStub;
  medication?: ModelsLookUpDictionaryTerminalDrugStructureData;
  diagnosis?: ModelsLookUpDictionaryICD10CMLight;
  diagnosisRange?: ModelsLookUpDictionaryICD10Range;
  hcpcs?: ModelsLookUpDictionaryHCPCSBase;
  icdpcs?: ModelsLookUpDictionaryICDPCS;
  patientRiskCategory?: ModelsLookUpDictionaryPatientRiskCategory;
}

export interface ModelsLatitudeLongitude {
  latitude: number;
  longitude: number;
}

export interface ModelsInfoCategory {
  name?: string;
  siteLists?: ModelsSiteList[];
}

export interface ModelsICDPCS {
  code: string;
  description: string;
}

export interface ModelsICD10SubRange {
  range: string;
  description: string;
}

export interface ModelsICD10Stub {
  code: string;
  description: string;
}

export interface ModelsICD10Short {
  code: string;
  description: string;
  range: string;
  title?: string | null;
}

export interface ModelsICD10AlphaCategory {
  alpha: string;
  category: string;
}

export interface ModelsICD10Range {
  range: string;
  description: string;
  simple_range: string;
  title?: string | null;
  parent_range?: string | null;
  sub_ranges: ModelsICD10SubRange[];
  icd10s?: ModelsICD10Short[] | null;
  alphaCategory?: ModelsICD10AlphaCategory;
  taxonomy_codes?: string[] | null;
}

export interface ModelsICD10CMLight {
  icd10Code: string;
  description: string;
  title: string;
  rangeCategoryID?: string;
  parentRange?: string | null;
}

export interface ModelsICD10CM {
  icd10Code: string;
  description: string;
  title: string;
  rangeCategoryID?: string;
  parentRange?: string | null;
  rangeCategory?: string;
  alphaCategory?: string;
  medlineSummary: string;
  nihJson?: string;
  medlineSummaryRequestFailed?: boolean;
  ailment?: string[] | null;
  details: ModelsInfoCategory[];
  taxonomyCodes?: string[] | null;
}

export interface ModelsHCPCSBase {
  code: string;
  description: string;
  title: string;
}

export interface ModelsHCPCS {
  code: string;
  description: string;
  title: string;
  terminated?: boolean;
  terminated_date?: string;
  cpt_category?: string;
}

export interface ModelsGraphModelStringPointWithLookUpItem {
  name?: string;
  value?: string;
  lookUpItem?: ModelsLookUpItem;
}

export interface ModelsGraphModelStringPoint {
  name?: string;
  value?: string;
}

export interface ModelsGraphModelPoint {
  name?: string;
  value?: number;
  value2?: number;
  value3?: number;
}

export interface ModelsGraphModelDoublePoint {
  name?: string;
  value?: number;
  value2?: number;
}

export interface ModelsGraphModel {
  graph?: ModelsGraphModelPoint[];
  currentMonth?: number;
  total?: number | null;
  totalPatientsAllTime?: number | null;
}

export interface ModelsGeoLocation {
  lat?: number;
  lng?: number;
}

export interface ModelsGeometry {
  bounds?: ModelsBounds;
  location?: ModelsGeoLocation;
  location_type?: string;
  viewport?: ModelsViewport;
}

export interface ModelsGoogleMapResult {
  address_components?: ModelsAddressComponent[];
  formatted_address?: string;
  geometry?: ModelsGeometry;
  place_id?: string;
  types?: string[];
}

export interface ModelsGenderModel {
  name?: string | null;
  count?: number;
  percent?: number;
}

export interface ModelsExplanationOfBenefitTreatmentAssessment {
  count: number;
  paymentAmountTotal: number;
  dates: string[];
  code: string;
  prescribingEvents: ModelsPrescribingEvent[];
}

export type ModelsExplanationOfBenefitProviderAssessmentReferredTo = {[key: string]: ModelsDateCountTracker};

export type ModelsExplanationOfBenefitProviderAssessmentReferredFrom = {[key: string]: ModelsDateCountTracker};

export type ModelsExplanationOfBenefitProviderAssessmentMedicationCodes = {[key: string]: ModelsDateCountTracker};

export type ModelsExplanationOfBenefitProviderAssessmentProductOrServiceCodes = {[key: string]: ModelsDateCountTracker};

export type ModelsExplanationOfBenefitProviderAssessmentDiagnosisCodes = {[key: string]: ModelsDateCountTracker};

export interface ModelsExplanationOfBenefitProviderAssessment {
  count: number;
  paymentAmountTotal: number;
  dates: string[];
  diagnosisCodes: ModelsExplanationOfBenefitProviderAssessmentDiagnosisCodes;
  productOrServiceCodes: ModelsExplanationOfBenefitProviderAssessmentProductOrServiceCodes;
  medicationCodes: ModelsExplanationOfBenefitProviderAssessmentMedicationCodes;
  providerNpi: string;
  providerCareAlertsIgnored: boolean;
  referredFrom: ModelsExplanationOfBenefitProviderAssessmentReferredFrom;
  referredTo: ModelsExplanationOfBenefitProviderAssessmentReferredTo;
}

export interface ModelsExplanationOfBenefitDiagnosisAssessment {
  count: number;
  paymentAmountTotal: number;
  dates: string[];
  npIsByDates: ModelsDateAndNPI[];
  diagnosisCode: string;
}

export interface ModelsExplanationOfBenefit {
  jobId?: string | null;
  createdOn?: string;
  facilityNpi?: string | null;
  providerNpi?: string | null;
  organizationNpi?: string | null;
  referringNpi?: string | null;
  billStartDate?: string | null;
  recordId?: string;
  patientReference?: string | null;
  diagnosisCodes?: string[] | null;
  careteamPrimary?: string[] | null;
  careteamAssistIds?: string[] | null;
  medicationCodes?: string[] | null;
  productOrServiceCodes?: string[] | null;
  billEndDate?: string | null;
  createDate?: string | null;
  date?: string | null;
  loadDate?: string | null;
  facilityDescription?: string | null;
  diagnosisNames?: string[] | null;
  daysSupply?: number | null;
  paymentAmount?: number | null;
  paymentDate?: string | null;
  providerCareAlertsIgnored?: boolean;
  totalAmount?: number | null;
}

export interface ModelsEobRecord {
  jobId?: string | null;
  createdOn?: string;
  id?: string | null;
  codes?: string[] | null;
  patientId?: string | null;
  provider?: string | null;
  medicationCodes?: string[] | null;
  productOrServiceCodes?: string[] | null;
  providerNpi?: string | null;
  referringProviderNpi?: string | null;
  facilityNpi?: string | null;
  facilityDescription?: string | null;
  organizationNpi?: string | null;
  careTeamAssist?: string[] | null;
  careTeamPrimary?: string[] | null;
  paymentAmount?: number;
  billStartDate?: string;
  billEndDate?: string;
  providerNpiOptions?: string[] | null;
}

export interface ModelsEobDisplayModel {
  jobId?: string | null;
  createdOn?: string;
  id?: string | null;
  codes?: string[] | null;
  patientId?: string | null;
  provider?: string | null;
  medicationCodes?: string[] | null;
  productOrServiceCodes?: string[] | null;
  providerNpi?: string | null;
  referringProviderNpi?: string | null;
  facilityNpi?: string | null;
  facilityDescription?: string | null;
  organizationNpi?: string | null;
  careTeamAssist?: string[] | null;
  careTeamPrimary?: string[] | null;
  paymentAmount?: number;
  billStartDate?: string;
  billEndDate?: string;
  providerNpiOptions?: string[] | null;
  age?: number;
  gender?: string;
  formattedDate?: string;
  serviceType?: string;
}

export type ModelsDynamicDictionaryApiResponseContent = {[key: string]: unknown} | null;

export interface ModelsDynamicDictionaryApiResponse {
  operationStatus?: ModelsOperationStatus;
  content?: ModelsDynamicDictionaryApiResponseContent;
}

export interface ModelsDrugStructureDataResultValueLayer4 {
  key?: string;
  value?: ModelsTerminalDrugStructureData;
}

export interface ModelsDrugStructureDataResultValueLayer3Dosage {
  key?: string;
  value?: ModelsDrugStructureDataResultValueLayer4[] | null;
}

export interface ModelsDrugStructureDataResultValueLayer2Format {
  key?: string;
  value?: ModelsDrugStructureDataResultValueLayer3Dosage[];
}

export interface ModelsDrugStructureDataResultValueLayer1Type {
  key?: string;
  value?: ModelsDrugStructureDataResultValueLayer2Format[];
}

export interface ModelsDrugStructureDataResult {
  hasWarnings?: boolean;
  success?: boolean;
  value?: ModelsDrugStructureDataResultValueLayer1Type[] | null;
}

export interface ModelsDrugRxSenseGetTieredPricingModel {
  errorDetails?: string[];
  errors?: string[];
  hasWarnings?: boolean;
  success?: boolean;
  warnings?: unknown[];
  value?: ModelsRxSenseTieredPricingValue;
}

export interface ModelsDrugInformationSubTypeDrugInformationFormatDrugInformationFormatDosageDrugInformationFormatDosageQuantity {
  ndc?: string | null;
  quantity?: number;
  typicalPrice?: number;
  dosage?: string | null;
  quantities?: ModelsDrugInformationSubTypeDrugInformationFormatDrugInformationFormatDosageDrugInformationFormatDosageQuantity[] | null;
}

export interface ModelsDrugInformationSubTypeDrugInformationFormatDrugInformationFormatDosage {
  dosage?: string | null;
  quantities?: ModelsDrugInformationSubTypeDrugInformationFormatDrugInformationFormatDosageDrugInformationFormatDosageQuantity[] | null;
}

export interface ModelsDrugInformationSubTypeDrugInformationFormat {
  format?: string;
  dosages?: ModelsDrugInformationSubTypeDrugInformationFormatDrugInformationFormatDosage[];
}

export interface ModelsDrugInformationSubType {
  name?: string;
  formats?: ModelsDrugInformationSubTypeDrugInformationFormat[];
}

export interface ModelsDrugInformation {
  sideEffects?: string;
  summary?: string;
}

export interface ModelsDoctorStubX {
  name?: string;
  npi?: string;
  radiusRating?: number | null;
  imageUrl?: string;
  genderCode?: string | null;
  specialties?: string | null;
  distanceMiles?: number | null;
  providerOptions?: string[] | null;
  inNetwork?: boolean | null;
}

export interface ModelsDoctorStub {
  jobId?: string | null;
  createdOn?: string;
  npi?: string;
  radiusRating?: number | null;
  imageUrl?: string;
  genderCode?: string | null;
  providerOptions?: string[] | null;
  inNetwork?: boolean | null;
  distanceMiles?: number | null;
  entityTypeCode?: string | null;
  zip?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  namePrefix?: string | null;
  organizationName?: string | null;
  enumerationDate?: string;
  yearsExperience?: number;
  specialtyText?: string | null;
  readonly codes?: string[];
}

export interface ModelsDoctorServicesModel {
  services?: ModelsEobDisplayModel[];
  totalServices?: number;
  appointments?: number;
  procedures?: number;
  referrals?: number;
  prescriptions?: number;
}

export interface ModelsDoctorRecord {
  jobId?: string | null;
  createdOn?: string;
  npi?: string;
  radiusRating?: number | null;
  imageUrl?: string;
  genderCode?: string | null;
  providerOptions?: string[] | null;
  inNetwork?: boolean | null;
  distanceMiles?: number | null;
  entityTypeCode?: string | null;
  zip?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  namePrefix?: string | null;
  organizationName?: string | null;
  enumerationDate?: string;
  yearsExperience?: number;
  specialtyText?: string | null;
  readonly codes?: string[];
  address?: string | null;
  secondLineAddress?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  state?: string | null;
  city?: string | null;
  latLong?: ModelsLatitudeLongitude;
  phoneNumber?: string | null;
  credential?: string | null;
  overview?: string;
  experience?: string;
  specialties?: string;
  subSpecialties?: string;
  education?: string;
  certifications?: string;
  awards?: string;
  publications?: string;
  nationalR?: string | null;
  localR?: string | null;
  hgSpecialty?: string;
  hgSpecialties?: string;
  hgOverview?: string;
  hgEducation?: string;
  hgOfficeHours?: string;
}

export interface ModelsDoctorOverviewModel {
  doctors?: ModelsDoctorModel[];
  totalDoctors?: number;
  totalAppointments?: number;
  totalPrescriptions?: number;
  totalReferrals?: number;
  totalProcedures?: number;
  totalBilling?: string;
}

export interface ModelsDoctorNubSpecialty {
  jobId?: string | null;
  createdOn?: string;
  npi?: string;
  radiusRating?: number | null;
  imageUrl?: string;
  genderCode?: string | null;
  name?: string | null;
  providerOptions?: string[] | null;
  inNetwork?: boolean | null;
  distanceMiles?: number | null;
  specialtyClassification?: string;
}

export interface ModelsDoctorModel {
  npi?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  prefix?: string | null;
  readonly name?: string | null;
  rating?: number | null;
  specialty?: string[];
  affiliation?: string | null;
  appointments?: number;
  prescriptions?: number;
  procedures?: number;
  referrals?: number;
  totalBilling?: number;
  genderCode?: string | null;
  address?: string | null;
  secondLineAddress?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  state?: string | null;
  city?: string | null;
  nationalR?: string | null;
  localR?: string | null;
  phoneNumber?: string | null;
}

export interface ModelsDoctorDetailsModel {
  npi?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  prefix?: string | null;
  readonly name?: string | null;
  rating?: number | null;
  specialty?: string[];
  affiliation?: string | null;
  appointments?: number;
  prescriptions?: number;
  procedures?: number;
  referrals?: number;
  totalBilling?: number;
  genderCode?: string | null;
  address?: string | null;
  secondLineAddress?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  state?: string | null;
  city?: string | null;
  nationalR?: string | null;
  localR?: string | null;
  phoneNumber?: string | null;
  profileViews?: number;
  userReviews?: number;
  billingTrendGraph?: ModelsGraphModel;
  visitsGraph?: ModelsGraphModel;
  services?: ModelsEobDisplayModel[];
}

export interface ModelsDiagnosisToken {
  icd10Code: string;
  title?: string;
}

export interface ModelsDiagnosisRangeCacheMonthlyAggregate {
  range: string;
  eobCount: number;
  totalAmount: number;
  uniquePatientCount: number;
  uniqueMedicationCount: number;
  uniqueProcedureCount: number;
  pmpm: number;
  icd10Range?: ModelsICD10Range;
}

export interface ModelsDiagnosisRangeCacheMonthly {
  record_id?: string;
  code?: string | null;
  job_id?: string;
  top_parent_range?: string | null;
  month?: number | null;
  total_amount?: number | null;
  eob_count?: number | null;
  product_service_counts?: ModelsCodeCount[] | null;
  medication_counts?: ModelsCodeCount[] | null;
  patient_counts?: ModelsCodeCount[] | null;
  diagnosis_counts?: ModelsCodeCount[] | null;
}

export interface ModelsDiagnosisModel {
  rank?: number;
  users?: number;
  totalUsers?: number;
  code?: string | null;
  title?: string | null;
  codeName?: string | null;
  averageCost?: number;
  totalSpend?: number;
  topLevelRange?: ModelsICD10Range;
  subLevelRange?: ModelsICD10Range;
  noDecimalCode?: string | null;
}

export interface ModelsDecimalGraphModel {
  graph?: ModelsGraphModelDoublePoint[];
  currentMonth?: number;
}

export interface ModelsDateRangeRequest {
  yearsBack?: number | null;
  fullYear?: number | null;
  startDate?: string | null;
  endDate?: string | null;
}

export interface ModelsDateRange {
  start?: string | null;
  end?: string | null;
  operationStatusError?: ModelsOperationStatus;
}

export interface ModelsDateCountTracker {
  id: string;
  dates: string[];
  count: number;
}

export interface ModelsDateAndNPI {
  npi: string;
  date: string;
}

export interface ModelsDataPointNumeric {
  name: string;
  value: number;
}

export interface ModelsConsumerUserReminderData {
  uspstf?: ModelsUspstfStub;
}

export interface ModelsConsumerUserReminder {
  code: string;
  type: string;
  markupTitle: string;
  actions?: string[];
  date?: string | null;
  ignorable?: boolean;
  isAlert?: boolean;
  readonly actionableId: string;
  data?: ModelsConsumerUserReminderData;
}

export interface ModelsConditionRecord {
  id?: string | null;
  name?: string | null;
  range?: string | null;
  parentRange?: string | null;
  codeType?: string | null;
  subRanges?: ModelsSubRange[] | null;
  summary?: string;
  title?: string;
  noDecimalCode?: string;
  simpleRange?: string;
}

export interface ModelsConditionModel {
  id?: string | null;
  name?: string | null;
  categoryPct?: number;
  patients?: number;
  newDiagnoses?: number;
  oneMonthChange?: number;
  totalSpend?: string;
  averageCost?: number;
  pmPm?: number;
  icD10Codes?: ModelsICD10Short[];
  subRanges?: ModelsConditionRecord[];
}

export interface ModelsConditionDetailsServicesModel {
  services?: ModelsServicesModel[];
  appointments?: number;
  prescriptions?: number;
  procedures?: number;
  referrals?: number;
  totalServices?: number;
}

export interface ModelsConditionDetailsModel {
  conditionModel?: ModelsConditionModel;
}

export interface ModelsConditionDetailsImpactModel {
  totalRevenue?: number;
  categoryPct?: number;
  retentionRate?: number;
}

export interface ModelsConditionDetailsDoctorsModel {
  doctors?: ModelsDoctorModel[];
  doctorCount?: number;
  totalServices?: number;
  totalBilling?: number;
}

export interface ModelsConditionAssessmentV2 {
  icd10Code: string;
  title?: string;
  firstDiagnosed?: string | null;
  firstDiagnosedBy?: ModelsDoctorNubSpecialty;
  visits?: ModelsVisitToken[];
}

export interface ModelsCodeCountReport {
  total_count?: number | null;
  total_cost?: number | null;
  total_avgcost?: number | null;
  unique_count?: number | null;
}

export interface ModelsCodeCountLookup {
  count?: number | null;
  code?: string | null;
  cost?: number | null;
  avgcost?: number | null;
  name?: string | null;
  description?: string | null;
}

export interface ModelsCodeCountCost {
  count?: number | null;
  code?: string | null;
  cost?: number | null;
  avgcost?: number | null;
}

export interface ModelsCodeCount {
  count?: number | null;
  code?: string | null;
}

export interface ModelsClinicRecord {
  id?: string;
  directionUrl?: string;
  storeNumber?: string;
  latitude?: number;
  longitude?: number;
  openHours?: string;
  zipCode?: string;
  url?: string;
  phoneNumber?: string;
  providerName?: string;
  address_Street?: string;
  city?: string;
  name?: string;
  state?: string;
  status?: string;
  distanceMiles?: number;
}

export interface ModelsClientLocation {
  latitude?: number | null;
  longitude?: number | null;
  zipcode?: string | null;
  distanceRadiusMiles?: number | null;
}

export interface ModelsCacheProviderMonthlyDoctorDataAggregate {
  npi?: string;
  diagnosis?: ModelsCodeCountReport;
  procedure?: ModelsCodeCountReport;
  patient?: ModelsCodeCountReport;
  medication?: ModelsCodeCountReport;
  total_eob_count?: number | null;
  total_amount?: number | null;
  doctor?: ModelsDoctorStub;
}

export interface ModelsCacheProviderMonthlyAggregate {
  npi?: string;
  filtered_diagnosis_count?: number | null;
  filtered_diagnosis_cost?: number | null;
  filtered_diagnosis_avgcost?: number | null;
  doctor?: ModelsDoctorStub;
}

export interface ModelsCacheProviderMonthly {
  npi?: string;
  month?: number;
  job_id?: string;
  eob_count?: number;
  total_amount?: number;
  patient_counts?: ModelsCodeCount[] | null;
  product_service_counts?: ModelsCodeCount[] | null;
  diagnosis_counts?: ModelsCodeCount[] | null;
  medication_counts?: ModelsCodeCount[] | null;
}

export interface ModelsCachePatientRiskModelScoreOverviewCategory {
  riskCategory?: string;
  patientCount?: number;
  patientPercent?: number;
  eobCount?: number;
  totalAmount?: number;
}

export interface ModelsCachePatientRiskModelScoreOverview {
  riskModel?: string | null;
  jobIds?: string[];
  dateRange?: ModelsDateRange;
  categories?: ModelsCachePatientRiskModelScoreOverviewCategory[];
}

export interface ModelsCacheMonthlyAggregate {
  code?: string;
  diagnosisCodeCountAggregation_Combined?: ModelsNestedCodeCountAggregation;
  medicationCodeCountAggregation_Combined?: ModelsNestedCodeCountAggregation;
  productServiceCodeCountAggregation_Combined?: ModelsNestedCodeCountAggregation;
  doctor?: ModelsDoctorStub;
}

export interface ModelsCacheMonthly {
  record_id?: string;
  job_id?: string;
  month?: number | null;
  code?: string | null;
  eob_count?: number;
  total_amount?: number;
  productservice_counts?: ModelsCodeCountCost[] | null;
  medication_counts?: ModelsCodeCountCost[] | null;
  patient_counts?: ModelsCodeCount[] | null;
  diagnosis_counts?: ModelsCodeCountCost[] | null;
  provider_counts?: ModelsCodeCountCost[] | null;
  organization_counts?: ModelsCodeCountCost[] | null;
  facility_counts?: ModelsCodeCountCost[] | null;
}

export interface ModelsCacheMedicationMonthlyAggregate {
  medicationCode?: string;
  uniquePatientCount?: number;
  eobCount?: number;
  totalAmount?: number;
  paymentAmount?: number;
  uniqueMedicationCount?: number;
  uniqueProcedureCount?: number;
  medication?: ModelsTerminalDrugStructureData;
}

export interface ModelsCacheDiagnosisMonthlyAggregate {
  diagnosisCode?: string;
  uniquePatientCount?: number;
  eobCount?: number;
  totalAmount?: number;
  uniqueMedicationCount?: number;
  uniqueProcedureCount?: number;
  topLevelRange?: ModelsICD10Range;
  subRange?: ModelsICD10Range;
  icd10cm?: ModelsICD10CMLight;
}

export interface ModelsCacheDiagnosisMonthly {
  job_id?: string;
  record_id?: string;
  parent_ranges?: string[] | null;
  top_parent_range?: string | null;
  diagnosis_code?: string;
  month?: number | null;
  total_amount?: number | null;
  eob_count?: number | null;
  product_service_counts?: ModelsCodeCount[] | null;
  medication_counts?: ModelsCodeCount[] | null;
  patient_counts?: ModelsCodeCount[] | null;
  cooccurring_diagnosis_counts?: ModelsCodeCount[] | null;
}

export interface ModelsCPTCategory {
  cpt_category: string;
  name: string;
  description: string;
}

export interface ModelsBounds {
  northeast?: ModelsGeoLocation;
  southwest?: ModelsGeoLocation;
}

export interface ModelsAppointment {
  doctorName?: string | null;
  doctorNpi?: string | null;
  specialty?: string | null;
  totalAppointments?: number;
  totalBilling?: number;
  rating?: number | null;
}

export interface ModelsAgeGenderModel {
  ageKey: string;
  genderCounts: ModelsDataPointNumeric[];
}

export interface ModelsAddressComponent {
  long_name?: string;
  short_name?: string;
  types?: string[];
}

export interface MessagingMessagingConfigurationSecretsPlain {
  accessKeyId?: string;
  accessKeySecret?: string;
}

export interface MedicationOperationStatusGetMedicationDetailsPatients {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersPatientCacheRepositoryRetrievePatientCacheRecordsBYAllCodesRESPONSE;
  readonly responseType: string;
}

export interface MedicationOperationStatusGetProceduresByMedications {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceCodeAggregationsByMedicationResponse;
  readonly responseType: string;
}

export interface MedicationOperationStatusGetMedicationImpact {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersMedicationCacheRepositoryRetrieveMedicationImpactResponse;
  readonly responseType: string;
}

export interface MedicationOperationStatusGetConditionsByMedication {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsByMedicationResponse;
  readonly responseType: string;
}

export interface MedicationGetProceduresByMedicationsResult {
  operationStatus?: MedicationOperationStatusGetProceduresByMedications;
}

export interface MedicationGetProceduresByMedicationsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface MedicationGetMedicationImpactResult {
  operationStatus?: MedicationOperationStatusGetMedicationImpact;
}

export interface MedicationGetMedicationImpactQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface MedicationGetMedicationDetailsPatientsResult {
  operationStatus?: MedicationOperationStatusGetMedicationDetailsPatients;
}

export interface MedicationGetMedicationDetailsPatientsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface MedicationGetConditionsByMedicationResult {
  operationStatus?: MedicationOperationStatusGetConditionsByMedication;
}

export interface MedicationGetConditionsByMedicationQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface MedicareBlueButtonOptionsPlain {
  baseURI?: string | null;
  authorizationExt?: string | null;
  tokenExt?: string | null;
  explanationOfBenefitExt?: string | null;
  patientExt?: string | null;
  coverageExt?: string | null;
  redirectURI?: string | null;
  clientID?: string | null;
  clientSecret?: string | null;
  jobID?: string | null;
  type?: string | null;
}

export interface MarketingSendContactFormResult {
  operationStatus?: ModelsOperationStatus;
}

export interface Location {
  latitude?: number;
  longitude?: number;
  zip?: string;
}

export interface JobExecution {
  executionID: string;
  createDate: string;
  jobState: string;
  jobID: string;
}

export interface IAetnaOptions {
  baseURI?: string | null;
  authorizationExt?: string | null;
  tokenExt?: string | null;
  audExt?: string | null;
  scope?: string | null;
  redirectURI?: string | null;
  clientID?: string | null;
  clientSecret?: string | null;
  jobID?: string | null;
}

export interface ErrorLog {
  id?: number;
  trace?: string | null;
  message?: string | null;
  code?: string | null;
  type?: string | null;
  path?: string | null;
  createdAt?: string;
  userId?: string | null;
}

export interface ErrorsGetErrorReportsErrorLogsModel {
  errorLogs?: ErrorLog[];
  allErrorTypes?: string[];
  allErrorCodes?: string[];
  errorCount?: number;
}

export interface ErrorsGetErrorReportsResult {
  model?: ErrorsGetErrorReportsErrorLogsModel;
}

export interface DoctorQueryDoctorBySearchTermResult {
  doctors?: ModelsDoctorStub[];
}

export interface DoctorQueryDoctorBySearchTermQuery {
  searchQuery?: string;
  size?: number;
  enumerationType?: ModelsNPIEnumerationType;
  jobIds?: string;
}

export interface DoctorGetDoctorsByLocationResult {
  model?: ModelsDoctorOverviewModel;
}

export interface DoctorGetDoctorsByLocationQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  doctorCount?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface DoctorGetDoctorTopNpisByTypeRatedDoctorRecord {
  npi?: string | null;
  doctor?: ModelsDoctorStub;
  percentOfTotalCount?: number;
}

export interface DoctorGetDoctorTopNpisByTypeResult {
  models?: DoctorGetDoctorTopNpisByTypeRatedDoctorRecord[];
}

export interface DoctorGetDoctorTopIcdSpendResultModel {
  code?: string;
  totalCharges?: number;
}

export interface DoctorGetDoctorTopIcdSpendResult {
  topIcdSpendTotals?: DoctorGetDoctorTopIcdSpendResultModel[];
}

export interface DoctorGetDoctorTopConditionsResult {
  models?: ModelsPieChartModel[];
}

export interface DoctorGetDoctorDetailsServicesResult {
  doctor?: ModelsDoctorServicesModel;
}

export interface DoctorGetDoctorDetailsServicesQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  npi?: string;
  page?: number;
  pageSize?: number;
  searchQuery?: string;
}

export interface DoctorGetDoctorDetailsResult {
  doctor?: ModelsDoctorDetailsModel;
}

export interface DoctorGetDoctorDetailsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  npi?: string;
}

export interface DataSourceOptionSecondary {
  displayName: string;
  enabled: boolean;
  planCode: string;
}

export interface DataSourceOptionPrimary {
  displayName: string;
  enabled: boolean;
  planSelectionOptions?: DataSourceOptionSecondary[] | null;
  hideWhenDisabled: boolean;
  dataSourceId: string;
  providerOptions?: string | null;
}

export interface Contact {
  email?: string | null;
  phone?: string | null;
  address?: string | null;
}

export interface ConsumerUserReminders {
  uspstfCodes?: string | null;
}

export interface ConsumerUserPreferences {
  useStoredLocation?: boolean | null;
  defaultSearchRadiusMiles?: number | null;
}

export interface ConsumerUserIgnoreItems {
  careAlertNPIs?: string | null;
  uspstfCodes?: string | null;
  uspstfCodesCompletedManually?: string | null;
  reminders?: string | null;
}

export interface ConsumerUpdateConsumerUserPreferencesResponseModel {
  createdAt?: string;
  command?: ConsumerUpdateConsumerUserPreferencesCommand;
}

export interface ConsumerUpdateConsumerUserPreferencesRequestModel {
  preferences: ConsumerUserPreferences;
}

export interface ConsumerUpdateConsumerUserPreferencesCommand {
  request?: ConsumerUpdateConsumerUserPreferencesRequestModel;
}

export interface ConsumerTreatmentSearchTreatmentsByQueryStringCommand {
  query?: string;
  latitude?: number;
  longitude?: number;
  distance?: number;
  medicationType?: string;
}

export interface ConsumerTreatmentSearchTreatmentsByQueryStringResponseModel {
  createdAt?: string;
  searchResults: ModelsRxSenseTreatmentResult[];
  command: ConsumerTreatmentSearchTreatmentsByQueryStringCommand;
}

export interface ConsumerTreatmentSearchTreatmentsByQueryStringResult {
  response?: ConsumerTreatmentSearchTreatmentsByQueryStringResponseModel;
}

export interface ConsumerTreatmentRxSenseCouponContact {
  bin?: string;
  contactId?: number;
  contactSourceId?: number;
  contactTypeId?: number;
  groupNumber?: string;
  memberNumber?: string;
  memberNumberInt?: number;
  pcn?: string;
  pdfUrl?: string;
  prospectId?: number;
  segmentCode?: string;
}

export interface ConsumerTreatmentRxSenseCouponAPIResponse {
  errorDetails?: string[];
  errors?: string[];
  hasWarnings?: boolean;
  success?: boolean;
  warnings?: string[];
  contacts?: ConsumerTreatmentRxSenseCouponContact[];
  ipLocation?: string;
  pdfCreated?: boolean;
  prospectId?: number;
  sentEmail?: boolean;
  sentSMS?: boolean;
}

export interface ConsumerTreatmentOperationStatusGetDrugPricesAndLocationsResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerTreatmentGetDrugPricesAndLocationsResponse;
  readonly responseType: string;
}

export interface ConsumerTreatmentGetDrugStructureDataRequestStructure {
  name?: string;
}

export interface ConsumerTreatmentGetDrugStructureDataCommand {
  request?: ConsumerTreatmentGetDrugStructureDataRequestStructure;
}

export interface ConsumerTreatmentGetDrugStructureDataResponseModel {
  createdAt?: string;
  queryResult: string;
  drugStructureData?: ModelsDrugStructureDataResult;
  drugInformation?: ModelsDrugInformation;
  command: ConsumerTreatmentGetDrugStructureDataCommand;
}

export interface ConsumerTreatmentGetDrugStructureDataResult {
  response?: ConsumerTreatmentGetDrugStructureDataResponseModel;
}

export interface ConsumerTreatmentGetDrugPricesAndLocationsResult {
  response?: ConsumerTreatmentOperationStatusGetDrugPricesAndLocationsResponse;
}

export interface ConsumerTreatmentGetDrugPricesAndLocationsRequestStructure {
  clientLocation?: ModelsClientLocation;
  ndc?: string;
  quantity?: number | null;
}

export interface ConsumerTreatmentGetDrugPricesAndLocationsCommand {
  request?: ConsumerTreatmentGetDrugPricesAndLocationsRequestStructure;
}

export interface ConsumerTreatmentGetDrugPricesAndLocationsResponse {
  createdAt?: string;
  pricingModel: ModelsDrugRxSenseGetTieredPricingModel;
  command: ConsumerTreatmentGetDrugPricesAndLocationsCommand;
}

export interface ConsumerTreatmentGetDrugCouponResponseModel {
  createdAt: string;
  command: ConsumerTreatmentGetDrugCouponCommand;
  rxSenseCouponAPIResponse: ConsumerTreatmentRxSenseCouponAPIResponse;
}

export interface ConsumerTreatmentGetDrugCouponResult {
  response?: ConsumerTreatmentGetDrugCouponResponseModel;
}

export interface ConsumerTreatmentGetDrugCouponRequestContactValues {
  contactTypeId?: number;
  creative?: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  utmType?: string;
}

export interface ConsumerTreatmentGetDrugCouponRequest {
  partnerUsername: string;
  partnerPassword: string;
  contactValues: ConsumerTreatmentGetDrugCouponRequestContactValues;
}

export interface ConsumerTreatmentGetDrugCouponCommand {
  request?: ConsumerTreatmentGetDrugCouponRequest;
}

export interface ConsumerTreatmentGetBatchDrugDataResult {
  response?: ConsumerTreatmentGetBatchDrugDataResponseModel;
}

export interface ConsumerTreatmentGetBatchDrugDataRequest {
  ndCs: string[];
}

export interface ConsumerTreatmentGetBatchDrugDataCommand {
  request?: ConsumerTreatmentGetBatchDrugDataRequest;
}

export interface ConsumerTreatmentGetBatchDrugDataResponseModel {
  createdAt: string;
  command: ConsumerTreatmentGetBatchDrugDataCommand;
  ndCs: ModelsTerminalDrugStructureData[];
}

export interface ConsumerSendUserFeedbackResult {
  operationStatus?: ModelsOperationStatus;
}

export interface ConsumerRevokeCareCollaboratorResponseOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: CareCollaboratorAuthorization[];
  readonly responseType: string;
}

export type ConsumerRetrievePatientHealthDataResponseModelMedicationAssessments = {[key: string]: ModelsExplanationOfBenefitTreatmentAssessment};

export type ConsumerRetrievePatientHealthDataResponseModelTreatmentAssessments = {[key: string]: ModelsExplanationOfBenefitTreatmentAssessment};

export type ConsumerRetrievePatientHealthDataResponseModelDiagnosisAssessments = {[key: string]: ModelsExplanationOfBenefitDiagnosisAssessment};

export type ConsumerRetrievePatientHealthDataResponseModelProviderAssessments = {[key: string]: ModelsExplanationOfBenefitProviderAssessment};

export interface ConsumerRetrievePatientHealthDataResponseModel {
  createdAt: string;
  explanationOfBenefits: ModelsExplanationOfBenefit[];
  providerAssessments: ConsumerRetrievePatientHealthDataResponseModelProviderAssessments;
  diagnosisAssessments: ConsumerRetrievePatientHealthDataResponseModelDiagnosisAssessments;
  treatmentAssessments: ConsumerRetrievePatientHealthDataResponseModelTreatmentAssessments;
  medicationAssessments: ConsumerRetrievePatientHealthDataResponseModelMedicationAssessments;
  overview: ConsumerPatientHealthDataOverview;
  visitRecordIDs: string[];
  uniqueNPIs: string[];
  lookUps: ConsumerPatientHealthDataLookUps;
  keyFindings?: ConsumerKeyFindingsResponseModel;
  jobExecutions?: JobExecution[] | null;
}

export interface ConsumerRetrievePatientHealthDataRequest {
  dateRangeRequest?: ModelsDateRangeRequest;
  includeLookUps?: boolean | null;
  includeKeyFindings?: boolean | null;
  patientID?: string | null;
  clientLocation?: ModelsClientLocation;
}

export interface ConsumerRetrieveGoogleMapsLocationResult {
  response?: ConsumerOpStatGoogleMapsLocationResponse;
}

export interface ConsumerRetrieveCareCollaborationsResponse {
  sent?: CareCollaboratorAuthorization[];
  received?: CareCollaboratorAuthorization[];
  receivedAccepted?: CareCollaboratorAuthorization[];
  receivedPending?: CareCollaboratorAuthorization[];
  receivedRevoked?: CareCollaboratorAuthorization[];
  receivedRemoved?: CareCollaboratorAuthorization[];
  active?: CareCollaboratorAuthorization;
}

export type ConsumerResendVerificationCodeResponseModelData = unknown | null;

export interface ConsumerResendVerificationCodeResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: ConsumerResendVerificationCodeResponseModelData;
}

export interface ConsumerResendVerificationCodeResult {
  response?: ConsumerResendVerificationCodeResponseModel;
}

export interface ConsumerResendVerificationCodeCommand {
  email?: string;
  checkStatus?: boolean;
}

export interface ConsumerRecommendedVaccinationsKeyFindingModel {
  count?: number;
  subItems?: ModelsUSPSTF[];
  lookUpItems?: string[];
  ids?: string[];
}

export type ConsumerRecommendedUspstfResponseCompletions = {[key: string]: ConsumerCompletionTracker};

export interface ConsumerRecommendedUspstfResponse {
  uspstf: ModelsUSPSTF[];
  completions: ConsumerRecommendedUspstfResponseCompletions;
}

export interface ConsumerRecommendedScreeningsKeyFindingModel {
  count?: number;
  subItems?: ModelsUSPSTF[];
  lookUpItems?: string[];
  ids?: string[];
}

export interface ConsumerPatientHealthDataUpdateRemindersUSPSTFResult {
  response?: ConsumerPatientHealthDataOperationStatusUpdateRemindersUSPSTFResponseModel;
}

export interface ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel {
  uspstfCodes?: string[] | null;
  uspstfCodesIgnore?: string[] | null;
  uspstfCodesCompleted?: string[] | null;
  removeItems?: boolean | null;
}

export type ConsumerPatientHealthDataOverviewCareTeamPrimaryTypeCounts = {[key: string]: ModelsDateCountTracker};

export interface ConsumerPatientHealthDataOverview {
  mostRecentLoadTime: string;
  explanationOfBenefitRecordCount: number;
  providerCount: number;
  visitCount: number;
  conditionCount: number;
  treatmentCount: number;
  medicationCount: number;
  totalPaymentAmount: number;
  careTeamPrimaryTypeCounts: ConsumerPatientHealthDataOverviewCareTeamPrimaryTypeCounts;
}

export interface ConsumerPatientHealthDataOperationStatusUpdateRemindersUSPSTFResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerUserReminders;
  readonly responseType: string;
}

export interface ConsumerPatientHealthDataOperationStatusAddUserIgnoreItemsResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerUserIgnoreItems;
  readonly responseType: string;
}

export interface ConsumerPatientHealthDataOperationStatusAddUserIgnoreItemsDataAgnosticResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerUserIgnoreItems;
  readonly responseType: string;
}

export type ConsumerPatientHealthDataLookUpsMedication = {[key: string]: ModelsTerminalDrugStructureDataStub};

export type ConsumerPatientHealthDataLookUpsDiagnosis = {[key: string]: ModelsICD10CM};

export type ConsumerPatientHealthDataLookUpsProvider = {[key: string]: ModelsDoctorStub};

export interface ConsumerPatientHealthDataLookUps {
  provider: ConsumerPatientHealthDataLookUpsProvider;
  diagnosis: ConsumerPatientHealthDataLookUpsDiagnosis;
  medication: ConsumerPatientHealthDataLookUpsMedication;
}

export interface ConsumerPatientHealthDataAddUserIgnoreItemsResult {
  response?: ConsumerPatientHealthDataOperationStatusAddUserIgnoreItemsResponseModel;
}

export interface ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel {
  careAlertNPIs?: string[] | null;
  removeItems?: boolean | null;
}

export interface ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticResult {
  response?: ConsumerPatientHealthDataOperationStatusAddUserIgnoreItemsDataAgnosticResponseModel;
}

export interface ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel {
  careAlertNPIs?: string[] | null;
  uspstfCodes?: string[] | null;
  uspstfCodesCompleted?: string[] | null;
  reminders?: string[] | null;
  removeItems?: boolean | null;
}

export interface ConsumerOperationStatusUpdateConsumerUserPreferences {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerUpdateConsumerUserPreferencesResponseModel;
  readonly responseType: string;
}

export interface ConsumerOperationStatusRetrieveCareCollaborationsResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerRetrieveCareCollaborationsResponse;
  readonly responseType: string;
}

export interface ConsumerOperationStatusRetrievePatientHealthDataResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerRetrievePatientHealthDataResponseModel;
  readonly responseType: string;
}

export interface ConsumerOperationStatusInviteCareCollaboratorResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: CareCollaboratorAuthorization;
  readonly responseType: string;
}

export interface ConsumerOperationStatusIcd10Range {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerIcd10RangeResponse;
  readonly responseType: string;
}

export interface ConsumerOperationStatusGetRecommendedUspstfResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerRecommendedUspstfResponse;
  readonly responseType: string;
}

export interface ConsumerOperationStatusGetKeyFindingsResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerKeyFindingsResponseModel;
  readonly responseType: string;
}

export interface ConsumerOperationStatusGetAllUspstfResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ModelsUSPSTF[];
  readonly responseType: string;
}

export interface ConsumerOperationStatusCostOfCareResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerCostOfCareResponseModel;
  readonly responseType: string;
}

export interface ConsumerOperationStatusCareScoreResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerCareScoreResponseModel;
  readonly responseType: string;
}

export interface ConsumerOperationStatusApplyRiskModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerApplyRiskModelResponse;
  readonly responseType: string;
}

export interface ConsumerOperationStatusApplicationDefinitions {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerApplicationDefinitions;
  readonly responseType: string;
}

export interface ConsumerOperationStatusAcceptCareCollaborationResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: CareCollaboratorAuthorization;
  readonly responseType: string;
}

export interface ConsumerOpStatGoogleMapsLocationResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerGoogleMapsResponse;
  readonly responseType: string;
}

export type ConsumerNewAreaDoctorsKeyFindingModelProvidersByClassification = {[key: string]: ModelsDoctorStub[]};

export interface ConsumerNewAreaDoctorsKeyFindingModel {
  count?: number;
  subItems?: ModelsDoctorStub[];
  lookUpItems?: string[];
  ids?: string[];
  providersByClassification?: ConsumerNewAreaDoctorsKeyFindingModelProvidersByClassification;
}

export interface ConsumerNameValue {
  name: string;
  value: string;
}

export interface ConsumerPasswordRequirementsDefinition {
  readonly passwordRequirements?: string;
  regexRequirements?: ConsumerNameValue[];
}

export interface ConsumerMostRecentMedicationKeyFindingModel {
  count?: number;
  subItems?: ModelsExplanationOfBenefitTreatmentAssessment[];
  lookUpItems?: ModelsTerminalDrugStructureDataStub[];
  ids?: string[];
}

export interface ConsumerMostRecentDiagnosisKeyFindingModel {
  count?: number;
  subItems?: ModelsExplanationOfBenefitDiagnosisAssessment[];
  lookUpItems?: ModelsICD10CM[];
  ids?: string[];
}

export interface ConsumerMobileUserProfileMobileResponse {
  email?: string;
  name?: string | null;
  dateOfBirth?: string | null;
  gender?: string | null;
  phone?: string | null;
  address?: string | null;
  preferredZip?: string | null;
  alertPreferences?: AlertPreferences;
}

export interface ConsumerMobileSendPushNotificationRequest {
  title?: string | null;
  body?: string | null;
  deviceIds?: string[] | null;
  eventId?: string | null;
  refId?: string | null;
}

export interface ConsumerMobileSendMassVersionUpdateNotificationRequest {
  android?: boolean;
  ios?: boolean;
  latestVersion?: string;
}

export interface ConsumerMobileRegisterDeviceIdentifierRequest {
  deviceType: string;
  deviceId: string;
  remove?: boolean;
}

export type ConsumerMobileRecommendedUspstfResponseMobileCompletions = {[key: string]: ConsumerCompletionTracker};

export interface ConsumerMobileRecommendedUspstfResponseMobile {
  achievements: ModelsUspstfStubIgnorable[];
  completions: ConsumerMobileRecommendedUspstfResponseMobileCompletions;
}

export type ConsumerMobileOptimizeCareDoctorsResponseModelDoctorsByType = {[key: string]: ModelsDoctorStub[]};

export interface ConsumerMobileOptimizeCareDoctorsResponseModel {
  doctorsByType?: ConsumerMobileOptimizeCareDoctorsResponseModelDoctorsByType;
  distanceRadiusMiles?: number;
}

export interface ConsumerMobileOptimizeCareDoctorsRequestModel {
  clientLocation: ModelsClientLocation;
  yearsBack?: number | null;
  inNetwork?: boolean | null;
}

export interface ConsumerMobileOperationStatusOptimizeCareDoctorsResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerMobileOptimizeCareDoctorsResponseModel;
  readonly responseType: string;
}

export interface ConsumerMobileOperationStatusHealthSnapshotResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerHealthSnapshotResponseModel;
  readonly responseType: string;
}

export interface ConsumerMobileOperationStatusGetRecommendedUspstfMobileResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerMobileRecommendedUspstfResponseMobile;
  readonly responseType: string;
}

export interface ConsumerMobileOperationStatusGetDrugPricesAndLocationsResponseMobile {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ModelsPharmacyPricingsCondensed;
  readonly responseType: string;
}

export interface ConsumerMobileOperationStatusCostOfCareResponseModelMobile {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerMobileCostOfCareResponseModelMobile;
  readonly responseType: string;
}

export interface ConsumerMobileOperationStatusCareScoreOverviewResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerMobileCareScoreOverviewResponseModel;
  readonly responseType: string;
}

export interface ConsumerMobileHealthSnapshotRequest {
  yearsBack?: number | null;
  fullYear?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  patientID?: string | null;
}

export interface ConsumerMobileGetMedicationDetailsResult {
  response?: ModelsMedicationDetailsMobileResponseDrugInformation;
}

export interface ConsumerMobileGetDrugPricesAndLocationsMobileResult {
  response?: ConsumerMobileOperationStatusGetDrugPricesAndLocationsResponseMobile;
}

export interface ConsumerMobileGetConditionDetailMobileResponseModel {
  createdAt?: string;
  icd10Cm?: ModelsICD10CM;
  doctorsInAreaTaxonomyApproachMethod?: string | null;
  treatingTaxonomies?: ModelsProviderTaxonomy[] | null;
  doctorsInArea?: ModelsDoctorStubX[] | null;
}

export interface ConsumerMobileGetConditionDetailMobileResult {
  response?: ConsumerMobileGetConditionDetailMobileResponseModel;
}

export interface ConsumerMobileCostOfCareResponseModelMobile {
  totals: ConsumerCostOfCareAssessment;
  months: ConsumerCostOfCareAssessment[];
  savingsModel: ModelsYearlySavingsModel;
}

export interface ConsumerMobileCareScoreOverviewResponseModel {
  careScore?: ConsumerCareScoreResponse;
  keyFindings?: ConsumerKeyFindingsSimple;
  reminders: ModelsConsumerUserReminder[];
  alerts?: ConsumerAlertsUserAlertDiagnosisResponse[] | null;
}

export interface ConsumerMobileCarePlanResponse {
  patientRecordID?: string;
  dataSourceId?: string;
  patientName?: string;
  jobState?: string;
  patients?: PatientDataAuthorizationItem[];
}

export interface ConsumerMobileConsumerUserDetailsResponse {
  userProfile?: ConsumerMobileUserProfileMobileResponse;
  carePlans?: ConsumerMobileCarePlanResponse[];
  userAssessments?: ConsumerGetConsumerUserDetailsUserAssessments;
}

export interface ConsumerMobileGetConsumerUserDetailsMobileResult {
  response?: ConsumerMobileConsumerUserDetailsResponse;
}

export interface ConsumerMedicalCodeGetIcd10CmCodesPackageResponseModel {
  createdAt: string;
  icd: ModelsICD10Short[];
  rangeCategory: ModelsICD10Range[];
  alphaCategory: ModelsICD10AlphaCategory[];
}

export interface ConsumerMedicalCodeGetICD10CMCodesPackageResult {
  response?: ConsumerMedicalCodeGetIcd10CmCodesPackageResponseModel;
}

export interface ConsumerMedicalCodeGetAllICD10CMCodesResponseModel {
  createdAt: string;
  icd10Cm: ModelsICD10CM[];
}

export interface ConsumerMedicalCodeGetAllICD10CMCodesResult {
  response?: ConsumerMedicalCodeGetAllICD10CMCodesResponseModel;
}

export interface ConsumerMedicalCodeGetAllHCPCSCodesResponseModel {
  createdAt: string;
  hcpcs: ModelsHCPCS[];
  cptCategory?: ModelsCPTCategory[];
}

export interface ConsumerMedicalCodeGetAllHCPCSCodesResult {
  response?: ConsumerMedicalCodeGetAllHCPCSCodesResponseModel;
}

export interface ConsumerKeyFindingsSimple {
  mostRecentDiagnosis?: ModelsICD10Stub[];
  mostRecentMedication?: ModelsTerminalDrugStructureDataNub[];
  recommendedScreenings?: ModelsUspstfStub[];
  recommendedVaccinations?: ModelsUspstfStub[];
  newAreaDoctors?: ModelsDoctorNubSpecialty[] | null;
}

export interface ConsumerKeyFindingsResponseModel {
  mostRecentDiagnosis?: ConsumerMostRecentDiagnosisKeyFindingModel;
  mostRecentMedication?: ConsumerMostRecentMedicationKeyFindingModel;
  recommendedScreenings?: ConsumerRecommendedScreeningsKeyFindingModel;
  recommendedVaccinations?: ConsumerRecommendedVaccinationsKeyFindingModel;
  newAreaDoctors?: ConsumerNewAreaDoctorsKeyFindingModel;
}

export interface ConsumerIcd10RangeResponse {
  icd10Ranges?: ModelsICD10Range[];
  allChildIcd10s?: ModelsICD10Short[];
}

export interface ConsumerHealthSnapshotResponseModel {
  providers: ModelsProviderAssessmentV2[];
  visits: ModelsVisitAssessmentV2[];
  conditions: ModelsConditionAssessmentV2[];
  medications: ModelsMedicationAssessmentV2[];
}

export interface ConsumerGoogleMapsResponse {
  results?: ModelsGoogleMapResult[];
}

export interface ConsumerGetKeyFindingsRequest {
  dateRangeRequest?: ModelsDateRangeRequest;
  clientLocation?: ModelsClientLocation;
}

export interface ConsumerGetCurrentCareCollaborationUserDetailsResultMobile {
  activeCareCollaboratorAuthorization?: CareCollaboratorAuthorization;
  consumerUserDetails?: ConsumerMobileConsumerUserDetailsResponse;
}

export interface ConsumerGetCurrentCareCollaborationUserDetailsResult {
  activeCareCollaboratorAuthorization?: CareCollaboratorAuthorization;
  consumerUserDetails?: ConsumerGetConsumerUserDetailsResult;
}

export interface ConsumerGetConsumerUserDetailsUserAssessments {
  inactiveCollaborationWithNoDirectDatasource?: boolean;
  providerOptions?: string[] | null;
}

export interface ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel {
  id: number;
  createdAt?: string;
  email: string;
  patient: string;
  patientRecordID: string;
  dataSourceID: string;
  executionID: string;
  isValid: boolean;
  state: string;
  reauthorizationRequired: boolean;
  patientItems?: PatientDataAuthorizationItem[];
  patients?: PatientDataAuthorizationItem[];
  activePatientItem?: PatientDataAuthorizationItem;
  jobExecution?: JobExecution;
  providerOptionsActive?: boolean;
  providerOptions?: string[] | null;
}

export interface ConsumerGetConsumerUserDetailsConsumerUserResponseModel {
  createdAt?: string;
  email: string;
  verificationCodeStatus: number;
  name?: string | null;
  contact?: Contact;
  location?: Location;
  alertPreferences?: AlertPreferences;
  dateOfBirth?: string | null;
  readonly dateOfBirthString?: string | null;
  consumerUserReminders?: ConsumerUserReminders;
  gender?: string | null;
}

export interface ConsumerGetConsumerUserDetailsResult {
  consumerUser?: ConsumerGetConsumerUserDetailsConsumerUserResponseModel;
  patientDataAuthorizations?: ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel[];
  patientDemographics?: ModelsOpenSearchPatient[];
  reminders?: ModelsConsumerUserReminder[];
  userAssessments?: ConsumerGetConsumerUserDetailsUserAssessments;
}

export interface ConsumerGetConditionDetailResponseModel {
  createdAt?: string;
  icd10Cm: ModelsICD10CM;
  doctorsInArea?: ModelsDoctorStub[];
  doctorsInAreaTaxonomyApproachMethod?: string | null;
  treatingTaxonomies?: ModelsProviderTaxonomy[] | null;
}

export interface ConsumerGetConditionDetailResult {
  response?: ConsumerGetConditionDetailResponseModel;
}

export interface ConsumerGetAllUSPSTFResult {
  response?: ConsumerOperationStatusGetAllUspstfResponseModel;
}

export interface ConsumerGetAllConsumerUsersConsumerUserModel {
  id: number;
  name?: string | null;
  email?: string | null;
  gender?: string | null;
  dateOfBirth?: string | null;
  contact?: Contact;
  location?: Location;
}

export interface ConsumerGetAllConsumerUsersResult {
  page?: number;
  pageSize?: number;
  readonly pageCount?: number;
  readonly count?: number;
  total?: number;
  readonly paginationToken?: string | null;
  items?: ConsumerGetAllConsumerUsersConsumerUserModel[];
}

export interface ConsumerEditConsumerUserLocationResponseModel {
  createdAt?: string;
  email: string;
  verificationCodeStatus?: number;
  name?: string | null;
  contact?: Contact;
  location?: Location;
}

export interface ConsumerEditConsumerUserLocationResult {
  user?: ConsumerEditConsumerUserLocationResponseModel;
}

export interface ConsumerEditConsumerUserInfoResult {
  user?: ConsumerGetAllConsumerUsersConsumerUserModel;
}

export interface ConsumerEditConsumerUserInfoForm {
  id?: number;
  contact?: Contact;
  zip?: string | null;
  name?: string | null;
  dateOfBirth?: string | null;
  gender?: string | null;
}

export interface ConsumerEditAlertPreferencesResponseModel {
  createdAt?: string;
  email: string;
  verificationCodeStatus?: number;
  name?: string | null;
  contact?: Contact;
  location?: Location;
}

export interface ConsumerEditAlertPreferencesResult {
  user?: ConsumerEditAlertPreferencesResponseModel;
}

export type ConsumerDoctorSuggestSimilarDoctorsResponseModelDoctorRecordsByType = {[key: string]: ModelsDoctorStub[]};

export interface ConsumerDoctorSuggestSimilarDoctorsResponseModel {
  createdAt?: string;
  doctorRecordsByType: ConsumerDoctorSuggestSimilarDoctorsResponseModelDoctorRecordsByType;
  doctorRecord: ModelsDoctorRecord;
  distanceRadiusMiles: number;
  command?: ConsumerDoctorSuggestSimilarDoctorsCommand;
}

export interface ConsumerDoctorSuggestSimilarDoctorsRequestModel {
  npi: string;
  clientLocation: ModelsClientLocation;
  inNetwork?: boolean | null;
}

export type ConsumerDoctorSuggestSimilarDoctorsMobileResponseModelDoctorsByClassification = {[key: string]: ModelsDoctorStubX[]};

export interface ConsumerDoctorSuggestSimilarDoctorsMobileResponseModel {
  doctorsByClassification: ConsumerDoctorSuggestSimilarDoctorsMobileResponseModelDoctorsByClassification;
  distanceRadiusMiles: number;
}

export interface ConsumerDoctorSuggestSimilarDoctorsCommand {
  request?: ConsumerDoctorSuggestSimilarDoctorsRequestModel;
  providerOptions?: string[] | null;
}

export interface ConsumerDoctorProviderTypeScore {
  type: string;
  scores: number[];
}

export interface ConsumerDoctorProviderTypeScoresRequestModel {
  doctor?: ConsumerDoctorProviderTypeScore[];
  facility?: ConsumerDoctorProviderTypeScore[];
}

export interface ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerDoctorSuggestSimilarDoctorsResponseModel;
  readonly responseType: string;
}

export interface ConsumerDoctorOperationStatusSuggestSimilarDoctorsMobileResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerDoctorSuggestSimilarDoctorsMobileResponseModel;
  readonly responseType: string;
}

export interface ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerDoctorFindHigherRatedCareResponseModel;
  readonly responseType: string;
}

export interface ConsumerDoctorGetSimpleHospitalResult {
  response?: ConsumerDoctorOperationStatusGetSimpleHospitalResponseModel;
}

export interface ConsumerDoctorGetSimpleHospitalResponseModel {
  createdAt?: string;
  simpleHospital: ModelsSimpleHospital;
}

export interface ConsumerDoctorOperationStatusGetSimpleHospitalResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerDoctorGetSimpleHospitalResponseModel;
  readonly responseType: string;
}

export interface ConsumerDoctorGetProviderTaxonomiesResponseModel {
  createdAt?: string;
  providerTaxonomies: ModelsProviderTaxonomy[];
}

export interface ConsumerDoctorGetProviderTaxonomiesResult {
  response?: ConsumerDoctorGetProviderTaxonomiesResponseModel;
}

export interface ConsumerDoctorGetProviderDetailResponseModel {
  createdAt?: string;
  doctorRecord: ModelsDoctorRecord;
}

export interface ConsumerDoctorGetProviderDetailResult {
  response?: ConsumerDoctorGetProviderDetailResponseModel;
}

export interface ConsumerDoctorGetDoctorsByZipCodesCommand {
  zipCodes?: string;
  clientLocation?: ModelsClientLocation;
  providerType?: string;
  facilityType?: string | null;
  physicianType?: string | null;
  gender?: string | null;
  classification?: string | null;
  taxonomyCodes?: string | null;
  languages?: string | null;
  rating?: number | null;
  inNetwork?: boolean | null;
  providerOptions?: string[] | null;
}

export interface ConsumerDoctorGetDoctorsByZipCodesResponseModel {
  createdAt?: string;
  doctorRecords: ModelsDoctorStub[];
  command: ConsumerDoctorGetDoctorsByZipCodesCommand;
}

export interface ConsumerDoctorGetDoctorsByZipCodesResult {
  response?: ConsumerDoctorGetDoctorsByZipCodesResponseModel;
}

export interface ConsumerDoctorGetDoctorsByZipCodesMobileResponse {
  createdAt?: string;
  doctorRecords: ModelsDoctorStubX[];
  command: ConsumerDoctorGetDoctorsByZipCodesCommand;
}

export interface ConsumerDoctorGetDoctorsByNPIsCommand {
  npis?: string;
}

export interface ConsumerDoctorGetDoctorsByNPIsResponseModel {
  createdAt?: string;
  doctorRecords: ModelsDoctorRecord[];
  command: ConsumerDoctorGetDoctorsByNPIsCommand;
}

export interface ConsumerDoctorGetDoctorsByNPIsResult {
  response?: ConsumerDoctorGetDoctorsByNPIsResponseModel;
}

export interface ConsumerDoctorGetDoctorsByLocationCommand {
  clientLocation?: ModelsClientLocation;
  providerType?: string;
  facilityType?: string | null;
  physicianType?: string | null;
  gender?: string | null;
  classification?: string | null;
  taxonomyCodes?: string | null;
  languages?: string | null;
  rating?: number | null;
  inNetwork?: boolean | null;
  providerOptions?: string[] | null;
}

export interface ConsumerDoctorGetDoctorsByLocationResponseModel {
  createdAt?: string;
  zipBatches: string[];
  nextBatchIndex: number;
  doctorRecords: ModelsDoctorStub[];
  command: ConsumerDoctorGetDoctorsByLocationCommand;
}

export interface ConsumerDoctorGetDoctorsByLocationResult {
  response?: ConsumerDoctorGetDoctorsByLocationResponseModel;
}

export interface ConsumerDoctorGetDoctorsByLocationMobileResponse {
  createdAt?: string;
  zipBatches: string[];
  nextBatchIndex: number;
  doctorRecords: ModelsDoctorStubX[];
  command: ConsumerDoctorGetDoctorsByLocationCommand;
}

export interface ConsumerDoctorFindHospitalsByLocationRequestModel {
  clientLocation: ModelsClientLocation;
  desiredResponseCount?: number | null;
  maximumDistanceRadiusMiles?: number | null;
}

export interface ConsumerDoctorFindHospitalsByLocationCommand {
  request?: ConsumerDoctorFindHospitalsByLocationRequestModel;
}

export interface ConsumerDoctorFindHospitalsByLocationResponseModel {
  createdAt?: string;
  hospitals: ModelsSimpleHospital[];
  distanceRadiusMiles?: number;
  command?: ConsumerDoctorFindHospitalsByLocationCommand;
}

export interface ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ConsumerDoctorFindHospitalsByLocationResponseModel;
  readonly responseType: string;
}

export type ConsumerDoctorFindHigherRatedCareResponseModelNewDoctorRecordsByType = {[key: string]: ModelsDoctorStub[]};

export type ConsumerDoctorFindHigherRatedCareResponseModelDoctorRecordsByType = {[key: string]: ModelsDoctorStub[]};

export type ConsumerDoctorFindHigherRatedCareResponseModelFacilityRecordsByType = {[key: string]: ModelsDoctorStub[]};

export interface ConsumerDoctorFindHigherRatedCareRequestModel {
  providerTypeScores: ConsumerDoctorProviderTypeScoresRequestModel;
  clientLocation: ModelsClientLocation;
  inNetwork?: boolean | null;
  maxResultsPerCategory?: number | null;
}

export interface ConsumerDoctorFindHigherRatedCareCommand {
  fhrcRequest?: ConsumerDoctorFindHigherRatedCareRequestModel;
  providerOptions?: string[] | null;
}

export interface ConsumerDoctorFindHigherRatedCareResponseModel {
  createdAt?: string;
  facilityRecordsByType: ConsumerDoctorFindHigherRatedCareResponseModelFacilityRecordsByType;
  doctorRecordsByType?: ConsumerDoctorFindHigherRatedCareResponseModelDoctorRecordsByType;
  newDoctorRecordsByType?: ConsumerDoctorFindHigherRatedCareResponseModelNewDoctorRecordsByType;
  distanceRadiusMiles: number;
  command?: ConsumerDoctorFindHigherRatedCareCommand;
}

export interface ConsumerDismissUserAlertResult {
  result?: ModelsOperationStatusString;
}

export interface ConsumerCostOfCareRequest {
  dateRangeRequest: ModelsDateRangeRequest;
  clientLocation: ModelsClientLocation;
  patientID?: string | null;
}

export type ConsumerCostOfCareCountsUncategorizedCodes = {[key: string]: ModelsValueCount};

export type ConsumerCostOfCareCountsNdc = {[key: string]: ModelsValueCount};

export type ConsumerCostOfCareCountsHcpcs = {[key: string]: ModelsValueCount};

export type ConsumerCostOfCareCountsNpi = {[key: string]: ModelsValueCount};

export interface ConsumerCostOfCareCounts {
  npi: ConsumerCostOfCareCountsNpi;
  hcpcs: ConsumerCostOfCareCountsHcpcs;
  ndc: ConsumerCostOfCareCountsNdc;
  uncategorizedCodes: ConsumerCostOfCareCountsUncategorizedCodes;
}

export interface ConsumerCostOfCareAssessment {
  name: string;
  year: number;
  medicationCount: number;
  treatmentCount: number;
  providerCount: number;
  labTestCount: number;
  procedureCount: number;
  totalCost: number;
  totalCount: number;
}

export interface ConsumerCostOfCareResponseModel {
  totals: ConsumerCostOfCareAssessment;
  months: ConsumerCostOfCareAssessment[];
  valueCounts: ConsumerCostOfCareCounts;
  savingsModel: ModelsYearlySavingsModel;
}

export type ConsumerConfirmVerificationCodeNotSignedInResponseModelData = unknown | null;

export interface ConsumerConfirmVerificationCodeNotSignedInResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: ConsumerConfirmVerificationCodeNotSignedInResponseModelData;
}

export interface ConsumerConfirmVerificationCodeNotSignedInResult {
  response?: ConsumerConfirmVerificationCodeNotSignedInResponseModel;
}

export interface ConsumerConfirmVerificationCodeNotSignedInForm {
  email?: string;
  verificationCode?: string;
}

export interface ConsumerCompletionTracker {
  name: string;
  total: number;
  totalCompleted: number;
}

export interface ConsumerCareScoreValue {
  type: string;
  score: number;
  basisCount: number;
  maxScoreValue: number;
  percentage: number;
}

export type ConsumerCareScoreResponseModelProviders = {[key: string]: ModelsDoctorStub[]};

export type ConsumerCareScoreResponseModelCareScores = {[key: string]: ConsumerCareScoreValue};

export interface ConsumerCareScoreResponseModel {
  careScores: ConsumerCareScoreResponseModelCareScores;
  providers: ConsumerCareScoreResponseModelProviders;
  newDiagnosis: string[];
}

export interface ConsumerCareScoreResponse {
  score?: number;
  maxScoreValue?: number;
  contributingScores?: ConsumerCareScoreValue[];
}

export interface ConsumerCareScoreRequest {
  dateRangeRequest?: ModelsDateRangeRequest;
  patientID?: string | null;
}

export interface ConsumerCacheRenewValues {
  providerTaxonomies: string;
  icd10: string;
  hcpcs: string;
}

export interface ConsumerApplyRiskModelResponse {
  uniqueDiagnosisCodes?: string[];
  hits?: ModelsRiskModel[];
  riskScore?: number;
  categories?: string[];
}

export interface ConsumerApplyRiskModelRequest {
  modelName?: string | null;
  dateRangeRequest?: ModelsDateRangeRequest;
  clientLocation?: ModelsClientLocation;
}

export interface ConsumerApplicationDefinitionsOptions {
  includeConditionRangesIcd10?: boolean;
  includeTaxonomyClassifications?: boolean;
}

export interface ConsumerApplicationDefinitions {
  cacheRenewValues: ConsumerCacheRenewValues;
  conditionRangesIcd10?: ModelsICD10SubRange[] | null;
  providerTaxonomyClassifications?: string[] | null;
  readonly providerTypes: ConsumerNameValue[];
  readonly physicianTypes: ConsumerNameValue[];
  readonly facilityTypes: ConsumerNameValue[];
  readonly medicationBrandTypes: ConsumerNameValue[];
  readonly genders: ConsumerNameValue[];
  readonly conditions: ConsumerNameValue[];
  readonly languages: ConsumerNameValue[];
  readonly states: ConsumerNameValue[];
  insuranceOptions: DataSourceOptionPrimary[];
  currentAppVersions: AppVersion[];
  readonly passwordRequirements?: string;
}

export interface ConsumerAlertsUserAlertDiagnosisResponse {
  id?: number;
  email?: string;
  sub?: string;
  alertType?: string;
  recordId?: string;
  recordBillStartDate?: string | null;
  recordDiagnosisCodes?: string | null;
  diagnosisId?: string;
  dismissedOn?: string | null;
  originDate?: string | null;
  icd10s?: ModelsICD10Stub[] | null;
}

export interface ConsumerAddConsumerUserForm {
  email?: string;
  password?: string;
}

export interface ConsumerAddConsumerUserConsumerUserModel {
  id: number;
  email: string;
  password?: string;
}

export interface ConsumerAddConsumerUserOperationStatusAddConsumerUser {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ConsumerAddConsumerUserConsumerUserModel;
  readonly responseType: string;
}

export interface ConsumerAddConsumerUserResult {
  response?: ConsumerAddConsumerUserOperationStatusAddConsumerUser;
}

export type CarePlanConfigurationUnitedHealthConstructAuthorizationURIResponseModelData = {[key: string]: string} | null;

export interface CarePlanConfigurationUnitedHealthConstructAuthorizationURIResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: CarePlanConfigurationUnitedHealthConstructAuthorizationURIResponseModelData;
}

export interface CarePlanConfigurationUnitedHealthConstructAuthorizationURIResult {
  response?: CarePlanConfigurationUnitedHealthConstructAuthorizationURIResponseModel;
}

export interface CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest {
  patientDataAuthorizationID: number;
  patientDataAuthorizationItemID?: number | null;
  toggleOff?: boolean;
}

export interface CarePlanConfigurationOperationStatusDeactivatePatientDataAuthorization {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: CarePlanConfigurationDeactivatePatientDataAuthorizationResponseModel;
  readonly responseType: string;
}

export type CarePlanConfigurationMedicareConstructAuthorizationURIResponseModelData = {[key: string]: string} | null;

export interface CarePlanConfigurationMedicareConstructAuthorizationURIResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: CarePlanConfigurationMedicareConstructAuthorizationURIResponseModelData;
}

export interface CarePlanConfigurationMedicareConstructAuthorizationURIResult {
  response?: CarePlanConfigurationMedicareConstructAuthorizationURIResponseModel;
}

export type CarePlanConfigurationHorizonBlueConstructAuthorizationURIResponseModelData = {[key: string]: string} | null;

export interface CarePlanConfigurationHorizonBlueConstructAuthorizationURIResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: CarePlanConfigurationHorizonBlueConstructAuthorizationURIResponseModelData;
}

export interface CarePlanConfigurationHorizonBlueConstructAuthorizationURIResult {
  response?: CarePlanConfigurationHorizonBlueConstructAuthorizationURIResponseModel;
}

export interface CarePlanConfigurationDeactivatePatientDataAuthorizationRequestModel {
  patientRecordID?: string;
  dataSourceID?: string;
  email: string;
  sub?: string | null;
}

export interface CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel {
  patientRecordID?: string;
  dataSourceID?: string;
}

export interface CarePlanConfigurationDeactivatePatientDataAuthorizationCommand {
  request?: CarePlanConfigurationDeactivatePatientDataAuthorizationRequestModel;
}

export interface CarePlanConfigurationDeactivatePatientDataAuthorizationResponseModel {
  createdAt?: string;
  wasDeactivated?: boolean;
  command?: CarePlanConfigurationDeactivatePatientDataAuthorizationCommand;
}

export interface CarePlanConfigurationCignaCariskConnectionInformation {
  cariskPatientId: string;
  dateOfBirth: string;
  zipCode: string;
  firstName: string;
  lastName: string;
}

export type CarePlanConfigurationAnthemConstructAuthorizationURIResponseModelData = {[key: string]: string} | null;

export interface CarePlanConfigurationAnthemConstructAuthorizationURIResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: CarePlanConfigurationAnthemConstructAuthorizationURIResponseModelData;
}

export interface CarePlanConfigurationAnthemConstructAuthorizationURIResult {
  response?: CarePlanConfigurationAnthemConstructAuthorizationURIResponseModel;
}

export type CarePlanConfigurationAetnaConstructAuthorizationURIResponseModelData = {[key: string]: string} | null;

export interface CarePlanConfigurationAetnaConstructAuthorizationURIResponseModel {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  data?: CarePlanConfigurationAetnaConstructAuthorizationURIResponseModelData;
}

export interface CarePlanConfigurationAetnaConstructAuthorizationURIResult {
  response?: CarePlanConfigurationAetnaConstructAuthorizationURIResponseModel;
}

export interface CareCollaboratorAuthorization {
  id?: number;
  email?: string;
  cognitoSub?: string;
  collaboratorEmail?: string;
  collaboratorCognitoSub?: string | null;
  createdAt?: string;
  revokedAt?: string | null;
  isAuthorized?: boolean;
  isCurrentlySelectedByCollaborator?: boolean;
  status?: string;
  dismissedBySenderOn?: string | null;
  dismissedByCollaboratorOn?: string | null;
  ownerDisplayName?: string | null;
  collaboratorDisplayName?: string | null;
}

export interface CacheTreatmentDataPopulateNDCCodeDataResult {
  drug_list?: ModelsTerminalDrugStructureData[];
}

export interface CacheTreatmentDataAddNdcDrugstructuresResult {
  requestedCodes?: string[];
  existingCodes?: string[];
  missingCodes?: string[];
  codesImported?: string[];
  codesNotFound?: string[];
  totalDistinctNdcCountInCacheData?: number;
}

export interface CacheTreatmentDataAddNdcDrugstructuresRequest {
  codes?: string[] | null;
  page?: ModelsPaginationRequest;
}

export interface CacheTreatmentDataAddNdcDrugstructuresAddNdcDrugstructuresResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: CacheTreatmentDataAddNdcDrugstructuresResult;
  readonly responseType: string;
}

export interface CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceImpactResponse {
  billingGraph?: ModelsGraphModel;
  proceduresGraph?: ModelsDecimalGraphModel;
  totalRevenue?: number | null;
  categoryPct?: number | null;
  retentionRate?: number | null;
}

export interface CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceCodeAggregationsByMedicationResponse {
  aggregateItems?: ProductServiceCacheProductServiceMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  productServiceCount?: number | null;
  averageCost?: number | null;
}

export interface CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceCodeAggregationsByDiagnosisResponse {
  aggregateItems?: ProductServiceCacheProductServiceMonthlyAggregate[] | null;
  diagnosisCount?: number | null;
  totalAmount?: number | null;
  productServiceCount?: number | null;
  averageCost?: number | null;
}

export interface CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceAggregationsResponse {
  items?: ModelsCacheMonthly[] | null;
  aggregateItems?: ProductServiceCacheProductServiceMonthlyAggregate[] | null;
  rangeCount?: number | null;
}

export interface CachePatientDataQueryPatientBySearchTermResult {
  patientIds?: string[];
}

export type CachePatientDataGetTotalMembersResultInsuranceTypeMembersCount = {[key: string]: number};

export interface CachePatientDataGetTotalMembersResult {
  totalMembers?: number;
  insuranceTypeMembersCount?: CachePatientDataGetTotalMembersResultInsuranceTypeMembersCount;
}

export interface CachePatientDataGetTotalMembersQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  jobIds?: string[];
}

export interface CacheMonthlyDataRefreshTransformResult {
  success?: boolean;
}

export interface CacheMonthlyDataGetSpendSummaryChartsResult {
  response?: ModelsRevenueGraphModels;
}

export interface CacheMonthlyDataGetCacheMonthlyTotalsResult {
  response?: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse[];
}

export interface CacheMonthlyDataGetCacheMonthlyTotalsQuery {
  queryRequest?: RequestModelsCacheMonthlyQueryRequest;
  itemTypes?: string[] | null;
}

export interface CacheMonthlyDataGetCacheMonthlyDetailResult {
  response?: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse;
}

export interface CacheMonthlyDataGetCacheMonthlyAggregationResult {
  response?: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyResponse;
}

export interface CacheDiagnosisDataOperationStatusGetDiagnosisImpact {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisImpactResponse;
  readonly responseType: string;
}

export interface CacheDiagnosisDataGetDiagnosisImpactResult {
  operationStatus?: CacheDiagnosisDataOperationStatusGetDiagnosisImpact;
}

export interface CacheDiagnosisDataGetDiagnosisImpactQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  ranges?: string[] | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface AppVersion {
  id?: number;
  platform: string;
  createdAt: string;
  major?: number;
  minor?: number;
  patch?: number;
  buildNumber?: number;
  readonly version?: string;
}

export interface AlertPreferences {
  newDiagnosis?: boolean;
  reminders?: boolean;
  careCollaboratorInvite?: boolean;
  updateUserInfo?: boolean;
}

export interface AdminUserResetPasswordUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
}

export interface AdminUserResetPasswordResult {
  user?: AdminUserResetPasswordUserModel;
}

export interface AdminUpdateTopParentRangeCacheDiagnosisMonthlyResult {
  itemsUpdatedCount?: number;
}

export interface AdminUpdateParentRangesCacheDiagnosisMonthlyResult {
  itemsUpdatedCount?: number;
}

export interface AdminRetrieveTopCodesResult {
  response?: ProvidersCacheMonthlyRepositoryRetrieveTopCodesResponse;
}

export interface AdminRetrieveLatestRiskModelScoresOverviewResult {
  result?: AdminCachePatientRiskModelScoreOverviewOperationStatus;
}

export type AdminRetrieveFollowedItemsResultItemsByType = {[key: string]: UserFollowedItemResponse[]};

export interface AdminRetrieveFollowedItemsResult {
  items?: UserFollowedItemResponse[];
  itemsByType?: AdminRetrieveFollowedItemsResultItemsByType;
}

export interface AdminRetrieveFollowedItemsRequest {
  includeDeactivated?: boolean;
}

export interface AdminRetrieveCachePatientRiskModelScoreOverviewResult {
  result?: AdminCachePatientRiskModelScoreOverviewOperationStatus;
}

export interface AdminRetrieveCachePatientDataRequest {
  memberTypes?: string[];
  dateRangeRequest?: ModelsDateRangeRequest;
  paginationRequest?: ModelsPaginationAndSortRequest;
  riskModelRequest?: ModelsRiskModelRequest;
  patientIds?: string[];
}

export interface AdminRetrieveCachePatientDataResponse {
  request?: AdminRetrieveCachePatientDataRequest;
  items?: ModelsPatientCacheBasicResponseItem[];
  total?: number;
}

export interface AdminRetrieveCachePatientDataResponseOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminRetrieveCachePatientDataResponse;
  readonly responseType: string;
}

export interface AdminRetrieveCachePatientDataResult {
  result?: AdminRetrieveCachePatientDataResponseOperationStatus;
}

export interface AdminRetrieveCacheDoctorDataResult {
  result?: AdminRetrieveCacheDoctorDataResponseOperationStatus;
}

export interface AdminRetrieveCacheDoctorDataRequest {
  paginationRequest?: ModelsPaginationAndSortRequest;
  dateRangeRequest?: ModelsDateRangeRequest;
}

export interface AdminRetrieveCacheDoctorDataResponse {
  request?: AdminRetrieveCacheDoctorDataRequest;
  response?: ProvidersDoctorCacheRepositoryRetrieveDoctorCacheRecordsResponse;
}

export interface AdminRetrieveCacheDoctorDataResponseOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminRetrieveCacheDoctorDataResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetConditionDetailsPatients {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersPatientCacheRepositoryRetrievePatientCacheRecordsBYAllCodesRESPONSE;
  readonly responseType: string;
}

export interface AdminOperationStatusGetProductServicesByDiagnosis {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceCodeAggregationsByDiagnosisResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetProductServiceImpact {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceImpactResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetProductServiceDetailsPatients {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersPatientCacheRepositoryRetrievePatientCacheRecordsBYAllCodesRESPONSE;
  readonly responseType: string;
}

export interface AdminOperationStatusGetProductServiceDetailsMedications {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersMedicationCacheRepositoryRetrieveMedicationCodeAggregationsByProductServiceResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetPaginatedMembersListResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminGetPaginatedMembersListResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetMedicationsByDiagnosis {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersMedicationCacheRepositoryRetrieveMedicationCodeAggregationsByDiagnosisResponse;
  readonly responseType: string;
}

export interface AdminOperationStatusGetConditionsByProductService {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data: ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsByProductServiceResponse;
  readonly responseType: string;
}

export interface AdminModifyFollowedItemResult {
  result?: ModelsOperationStatusString;
}

export interface AdminModifyFollowedItemRequest {
  operationType?: ModelsModifyOperationType;
  itemType?: string;
  code?: string;
}

export type AdminGetUserUserModelInsuranceTypeMembersCount = {[key: string]: number};

export type AdminGetUserUserModelInsuranceTypeEobDateRanges = {[key: string]: ModelsDateRange};

export interface AdminGetUserUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
  userRole?: string;
  insuranceTypes?: string[];
  insuranceTypeEobDateRanges?: AdminGetUserUserModelInsuranceTypeEobDateRanges;
  insuranceTypeMembersCount?: AdminGetUserUserModelInsuranceTypeMembersCount;
}

export interface AdminGetUserResult {
  user?: AdminGetUserUserModel;
}

export interface AdminGetUserByIdUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
  userRole?: string;
  insuranceTypes?: string[];
}

export interface AdminGetUserByIdResult {
  user?: AdminGetUserByIdUserModel;
}

export interface AdminGetTopLevelRangesResult {
  items?: ModelsConditionRecord[];
}

export interface AdminGetSummaryGraphsResult {
  summaryGraphModels?: ModelsSummaryGraphModels;
}

export interface AdminGetSubLevelRangesResult {
  items?: ModelsConditionRecord[];
}

export interface AdminGetRiskModelScoresCacheInfoResult {
  result?: ProvidersRiskModelRepositoryRiskModelScoresCacheInfo;
}

export interface AdminGetRevenueServicesProceduresResult {
  proceduresModel?: ModelsRevenueServicesProcedureModel;
}

export interface AdminGetRevenueServicesProceduresQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminGetRevenueServicesPrescriptionsResult {
  prescriptionsModel?: ModelsRevenueServicesPrescriptionsModel;
}

export interface AdminGetRevenueServicesPrescriptionsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminGetRevenueServicesAppointmentsResult {
  appointmentsModel?: ModelsRevenueServicesAppointmentsModel;
}

export interface AdminGetRevenueServicesAppointmentsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminGetProductServicesByDiagnosisResult {
  operationStatus?: AdminOperationStatusGetProductServicesByDiagnosis;
}

export interface AdminGetProductServicesByDiagnosisQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  ranges?: string[] | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetProductServiceImpactResult {
  operationStatus?: AdminOperationStatusGetProductServiceImpact;
}

export interface AdminGetProductServiceImpactQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface AdminGetProductServiceGenderAndAgeResult {
  response?: ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse;
}

export interface AdminGetProductServiceDropdownListResult {
  procedures?: ModelsHCPCSBase[];
}

export interface AdminGetProductServiceDetailsPatientsResult {
  operationStatus?: AdminOperationStatusGetProductServiceDetailsPatients;
}

export interface AdminGetProductServiceDetailsPatientsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface AdminGetProductServiceDetailsMedicationsResult {
  operationStatus?: AdminOperationStatusGetProductServiceDetailsMedications;
}

export interface AdminGetProductServiceDetailsMedicationsQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetProductServiceAggregationsResult {
  response?: CacheRepositoriesProductServiceCacheRepositoryRetrieveProductServiceAggregationsResponse;
}

export interface AdminGetProductServiceAggregationsQuery {
  jobIds?: string[];
  orderBy?: string | null;
  sortBy?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  codes?: string[] | null;
  includeItemLookup?: boolean;
}

export interface AdminGetPaginatedMembersListResult {
  result?: AdminOperationStatusGetPaginatedMembersListResponse;
}

export interface AdminGetPaginatedMembersListResponse {
  members?: ModelsRiskModelScoreMembersResponse[];
  totalCount?: number;
}

export interface AdminGetPaginatedMembersListRequest {
  skip: number;
  take: number;
  riskModelFilterCriteria?: ProvidersRiskModelFilterCriteria;
  riskCategoryName?: string | null;
}

export interface AdminGetMedicationsByDiagnosisResult {
  operationStatus?: AdminOperationStatusGetMedicationsByDiagnosis;
}

export interface AdminGetMedicationsByDiagnosisQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  ranges?: string[] | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetMedicationRangeGenderAndAgeResult {
  response?: ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse;
}

export interface AdminGetMedicationRangeAggregationsResult {
  response?: ProvidersMedicationCacheRepositoryRetrieveMedicationAggregationsResponse;
}

export interface AdminGetMedicationRangeAggregationsQuery {
  orderBy?: string | null;
  sortBy?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  jobIds?: string[] | null;
  codes?: string[] | null;
  includeItemLookup?: boolean;
}

export interface AdminGetMedicationDropdownListResult {
  medications?: ModelsTerminalDrugStructureDataStub[];
}

export interface AdminGetMedicationAggregationsResult {
  response?: ProvidersMedicationCacheRepositoryRetrieveMedicationAggregationsResponse;
}

export interface AdminGetIcdSummaryResultModel {
  type?: string;
  value?: string;
  description?: string;
  percent?: number;
}

export interface AdminGetIcdSummaryResult {
  summaryItems?: AdminGetIcdSummaryResultModel[];
}

export interface AdminGetIcdCodesFromRangesResult {
  items?: ModelsICD10Short[];
}

export interface AdminGetHistoricalLookupsResult {
  lookupItems?: ProvidersHistoricalLookupRecord[];
}

export interface AdminGetHistoricalLookupsQuery { [key: string]: any }

export interface AdminGetHistoricalDataTop10Model {
  rank?: number;
  percentage?: number;
  name?: string;
  rating?: number | null;
  value?: string;
}

export interface AdminGetHistoricalDataResultModel {
  type?: string;
  value?: string;
  description?: string;
  percent?: number;
}

export interface AdminGetHistoricalDataResult {
  summaryItems?: AdminGetHistoricalDataResultModel[];
  genderModels?: ModelsPieChartModel[];
  ageGraphModels?: ModelsGraphModelDoublePoint[];
  raceGraphModels?: ModelsGraphModelDoublePoint[];
  top10Doctors?: AdminGetHistoricalDataTop10Model[];
  top10Facilities?: AdminGetHistoricalDataTop10Model[];
  top10Taxonomies?: AdminGetHistoricalDataTop10Model[];
  top10States?: AdminGetHistoricalDataTop10Model[];
  top10Icds?: ModelsPieChartModel[];
  icdMoneySummary?: AdminGetHistoricalDataIcdMoneyResultModel[];
}

export interface AdminGetHistoricalDataQuery {
  icdCode?: string | null;
  subRange?: string | null;
  topRange?: string | null;
  state?: string | null;
  county?: string | null;
  race?: string | null;
  gender?: string | null;
  ageRange?: string | null;
}

export interface AdminGetHistoricalDataIcdMoneyResultModel {
  code?: string;
  totalCharges?: number;
  primaryPayerAmount?: number;
  paymentAmount?: number;
}

export interface AdminGetDoctorsByProductServiceResult {
  response?: ProvidersDoctorCacheRepositoryRetrieveDoctorsByProductServiceResponse;
}

export interface AdminGetDoctorsByProductServiceQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetDoctorsByMedicationResult {
  response?: ProvidersDoctorCacheRepositoryRetrieveDoctorsByMedicationResponse;
}

export interface AdminGetDoctorsByMedicationQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetDoctorsByDiagnosisResult {
  response?: ProvidersDoctorCacheRepositoryRetrieveDoctorsByDiagnosisResponse;
}

export interface AdminGetDoctorsByDiagnosisQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  ranges?: string[] | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
  paginationSortRequest?: ModelsPaginationAndSortRequest;
}

export interface AdminGetDoctorDataByNpiResult {
  response?: ProvidersDoctorCacheRepositoryRetrieveDoctorDataByNpiResponse;
}

export interface AdminGetDoctorDataByNpiQuery {
  dateRangeRequest?: ModelsDateRangeRequest;
  npis?: string[];
  jobIds?: string[] | null;
}

export interface AdminGetDiagnosisRangeGenderAndAgeResult {
  response?: ProvidersPatientCacheRepositoryRetrieveGenderAndAgeResponse;
}

export interface AdminGetDiagnosisRangeGenderAndAgeQuery {
  request?: RequestModelsCacheMonthlyQueryRequest;
  ranges?: string[];
}

export interface AdminGetDiagnosisRangeAggregationsResult {
  response?: ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisRangeAggregationsResponse;
}

export interface AdminGetDiagnosisRangeAggregationsQuery {
  startDate?: string | null;
  endDate?: string | null;
  ranges?: string[] | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
  orderBy?: string | null;
  sortBy?: string | null;
  includeRangeLookup?: boolean;
}

export interface AdminGetDiagnosisCodeAggregationsResult {
  response?: ProvidersDiagnosisCacheRepositoryRetrieveDiagnosisCodeAggregationsResponse;
}

export interface AdminGetDiagnosisCodeAggregationsQuery {
  startDate?: string | null;
  endDate?: string | null;
  codes?: string[] | null;
  jobIds?: string[] | null;
  includeIcdLookup?: boolean;
}

export interface AdminGetConsumerUserByIdResult {
  consumerUser?: ConsumerGetAllConsumerUsersConsumerUserModel;
}

export interface AdminGetConditionsListResult {
  items?: ModelsConditionRecord[];
}

export interface AdminGetConditionsListQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminGetConditionsByProductServiceResult {
  operationStatus?: AdminOperationStatusGetConditionsByProductService;
}

export interface AdminGetConditionsByProductServiceQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  tableQuery?: string;
  codes?: string[] | null;
  jobIds?: string[] | null;
}

export interface AdminGetConditionDetailsServicesResult {
  conditions?: ModelsConditionDetailsServicesModel;
}

export interface AdminGetConditionDetailsServicesQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
  subRangeQuery?: string;
  icd10Query?: string;
  tableQuery?: string;
}

export interface AdminGetConditionDetailsResult {
  conditions?: ModelsConditionDetailsModel;
}

export interface AdminGetConditionDetailsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  searchQuery?: string;
  icd10Query?: string;
  subRangeQuery?: string;
}

export interface AdminGetConditionDetailsPatientsResult {
  operationStatus?: AdminOperationStatusGetConditionDetailsPatients;
}

export interface AdminGetConditionDetailsPatientsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  take?: number;
  skip?: number;
  searchQuery?: string;
  icd10Query?: string;
  subRangeQuery?: string;
  tableQuery?: string;
}

export interface AdminGetConditionDetailsImpactResult {
  items?: ModelsConditionDetailsImpactModel;
}

export interface AdminGetConditionDetailsImpactQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
  icd10Query?: string;
  subRangeQuery?: string;
}

export interface AdminGetConditionDetailsDoctorsResult {
  conditions?: ModelsConditionDetailsDoctorsModel;
}

export interface AdminGetConditionDetailsDoctorsQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
  subRangeQuery?: string;
  icd10Query?: string;
  tableQuery?: string;
}

export interface AdminGetConditionDataResult {
  page?: number;
  pageSize?: number;
  readonly pageCount?: number;
  readonly count?: number;
  total?: number;
  readonly paginationToken?: string | null;
  items?: ModelsConditionModel[];
}

export interface AdminGetConditionDataQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  conditionsCount?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminGetAllUsersUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
}

export interface AdminGetAllUsersResult {
  page?: number;
  pageSize?: number;
  readonly pageCount?: number;
  readonly count?: number;
  total?: number;
  readonly paginationToken?: string | null;
  items?: AdminGetAllUsersUserModel[];
}

export interface AdminGetAllDistinctJobIdsResult {
  dataTypes?: string[];
}

export interface AdminGetAllDistinctJobIdsQuery { [key: string]: any }

export interface AdminGetAllDiagnosesResult {
  page?: number;
  pageSize?: number;
  readonly pageCount?: number;
  readonly count?: number;
  total?: number;
  readonly paginationToken?: string | null;
  items?: ModelsDiagnosisModel[];
}

export interface AdminGetAllDiagnosesQuery {
  memberTypes?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  specialRequest?: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  reverse?: boolean;
  searchQuery?: string;
}

export interface AdminEnrichMissingProviderSpecialityResult {
  result?: AdminEnrichMissingProviderSpecialityResponseOpStat;
}

export interface AdminEnrichMissingProviderSpecialityRequest {
  providerUpdateLimit?: number;
}

export interface AdminEnrichMissingProviderSpecialityResponse {
  request?: AdminEnrichMissingProviderSpecialityRequest;
  updatedCount?: number;
}

export interface AdminEnrichMissingProviderSpecialityResponseOpStat {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminEnrichMissingProviderSpecialityResponse;
  readonly responseType: string;
}

export interface AdminEnrichMissingProviderSpecialityCommand {
  request?: AdminEnrichMissingProviderSpecialityRequest;
}

export interface AdminEnrichCachePatientMonthlyRequest {
  memberTypes?: string[] | null;
}

export interface AdminEnrichCachePatientMonthlyResponse {
  request?: AdminEnrichCachePatientMonthlyRequest;
  recordCount?: number;
  uniquePatientIds?: number;
}

export interface AdminEnrichCachePatientMonthlyResponseOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminEnrichCachePatientMonthlyResponse;
  readonly responseType: string;
}

export interface AdminEnrichCachePatientMonthlyResult {
  result?: AdminEnrichCachePatientMonthlyResponseOperationStatus;
}

export interface AdminEditUserUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
  dateOfBirth?: string;
  userRole?: string;
  memberTypes?: string[];
}

export interface AdminEditUserResult {
  user?: AdminEditUserUserModel;
}

export interface AdminEditUserForm {
  name?: Name;
  contact?: Contact;
}

export interface AdminDeleteOperationResponse {
  deleteCount?: number;
}

export interface AdminDeleteCacheResponse {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminDeleteOperationResponse;
  readonly responseType: string;
}

export interface AdminDeletePatientRiskModelScoresResult {
  result?: AdminDeleteCacheResponse;
}

export interface AdminCreateRiskModelScoresOverviewResult {
  result?: ModelsRiskModelScoreOverview;
}

export interface AdminConsumerUserResetPasswordResult {
  status?: string;
}

export interface AdminCalculateAndCachePatientRiskModelScoresResult {
  result?: ModelsPatientRiskModelScoresResponse;
}

export interface AdminCachePatientRiskModelScoreOverviewOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: ModelsCachePatientRiskModelScoreOverview;
  readonly responseType: string;
}

export interface AdminCachePatientMonthlyRetrieveResponse {
  request?: RequestModelsCacheMonthlyQueryRequestRiskModel;
  items?: ModelsPatientCacheBasicResponseItem[];
  total?: number;
}

export interface AdminCachePatientMonthlyRetrieveResponseOperationStatus {
  success: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
  errorType?: string | null;
  data?: AdminCachePatientMonthlyRetrieveResponse;
  readonly responseType: string;
}

export interface AdminCachePatientMonthlyQueryResult {
  result?: AdminCachePatientMonthlyRetrieveResponseOperationStatus;
}

export interface AdminAdminEditUserUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
  dateOfBirth?: string;
  userRole?: string;
  memberTypes?: string[];
}

export interface AdminAdminEditUserResult {
  user?: AdminAdminEditUserUserModel;
}

export interface AdminAdminEditUserForm {
  name?: Name;
  contact?: Contact;
  userRole?: string;
  insuranceTypes?: string[] | null;
}

export interface AdminAddUserUserModel {
  id: number;
  username: string;
  name?: Name;
  contact?: Contact;
  userRole?: string;
  insuranceTypes?: string[];
}

export interface AdminAddUserResult {
  user?: AdminAddUserUserModel;
}

export interface AdminAddUserForm {
  username?: string;
  name?: Name;
  contact?: Contact;
  userRole?: string;
  insuranceTypes?: string[] | null;
}

