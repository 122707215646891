import { apiRequest } from "../../api_extension/apiRequest";
import { useQuery, UseQueryResult } from "react-query";
import { PackagedICD10Codes, ICD10, CPT, PackagedCPTCodes, Condition, PackagedICD10PCSCodes } from "../../shared/schemas/dataStructures";

import { ICD10CodeCategorizer, ICD10PCSCodeCategorizer } from "../utilities/ICD10CodeCategorizer";
import { AdminGetConditionsListResult, getAllConditions } from "api";
import { useRunFilterQuery } from "DataProvider/hooks/useRunFilterQuery";

/*
 ██████ ██████  ████████ 
██      ██   ██    ██    
██      ██████     ██    
██      ██         ██    
 ██████ ██         ██    
*/
async function retrieveCPTCodes(): Promise<PackagedCPTCodes> {
	const codes: Array<CPT> = await apiRequest({
		url: `${process.env.PUBLIC_URL}/test_data/CPTCode.json`,
		method: "GET",
	}).then((cpts) => {
		let s = new Set();
		// in order to reliably map we have to filter out duplicate codes in the demo data
		return cpts.filter((cpt: CPT) => {
			if (!s.has(cpt.CPTCode)) {
				s.add(cpt.CPTCode);
				return true;
			}
			return false;
		});
	});

	return {
		codes: codes,
	};
}

function useCPTCodes(): UseQueryResult<PackagedCPTCodes> {
	return useQuery("CPTCodes", retrieveCPTCodes, { staleTime: Infinity });
}

/*
██  ██████ ██████   ██  ██████  
██ ██      ██   ██ ███ ██  ████ 
██ ██      ██   ██  ██ ██ ██ ██ 
██ ██      ██   ██  ██ ████  ██ 
██  ██████ ██████   ██  ██████  
*/

async function retrieveICD10Codes(): Promise<PackagedICD10Codes> {
	const codes: Array<ICD10> = await ICD10CodeCategorizer.retrieve_ICD10_Codes_Package();

	const code_map = new Map();
	const ailment_map = new Map();

	codes.forEach((c) => {
		code_map.set(c.ICD10Code, c);
		c.Ailment.forEach((ailment_name: string) => {
			ailment_map.has(ailment_name) ? ailment_map.get(ailment_name).push(c) : ailment_map.set(ailment_name, [c]);
		});
	});

	const packaged: PackagedICD10Codes = {
		codes: codes,
		ailments: Array.from(ailment_map.keys())
			.sort()
			.map((x) => {
				return {
					ailmentID: x.replaceAll(" ", "_").toUpperCase(),
					name: x,
					ailment: x,
					description: x,
					range: x,
					id: x,
					codeType: x,
					icd10_codes: x,
				};
			}),
		lookups: {
			code: code_map,
			ailment: ailment_map,
		},
	};

	return packaged;
}

function useICD10Codes(): UseQueryResult<PackagedICD10Codes> {
	return useQuery("ICD10Codes", retrieveICD10Codes, {
		staleTime: Infinity,
	});
}

/*
 █████  ██ ██      ███    ███ ███████ ███    ██ ████████ ███████ 
██   ██ ██ ██      ████  ████ ██      ████   ██    ██    ██      
███████ ██ ██      ██ ████ ██ █████   ██ ██  ██    ██    ███████ 
██   ██ ██ ██      ██  ██  ██ ██      ██  ██ ██    ██         ██ 
██   ██ ██ ███████ ██      ██ ███████ ██   ████    ██    ███████ 
*/

function useConditions(): UseQueryResult<Array<Condition>> {
	const result = useRunFilterQuery<AdminGetConditionsListResult>(getAllConditions, {});
	const conditions = result?.data?.items;
	return useQuery("Conditions", () => conditions, { staleTime: Infinity, enabled: !!conditions });
}

/*
██  ██████ ██████   ██  ██████      ██████   ██████ ███████ 
██ ██      ██   ██ ███ ██  ████     ██   ██ ██      ██      
██ ██      ██   ██  ██ ██ ██ ██     ██████  ██      ███████ 
██ ██      ██   ██  ██ ████  ██     ██      ██           ██ 
██  ██████ ██████   ██  ██████      ██       ██████ ███████
*/

function useICD10PCSCodes(): UseQueryResult<PackagedICD10PCSCodes> {
	return useQuery("ICD10PCSCodes", ICD10PCSCodeCategorizer.retrieve_ICD10PCS_Codes_Package, { staleTime: Infinity });
}

export { useCPTCodes, retrieveCPTCodes, useConditions, useICD10Codes, useICD10PCSCodes };
