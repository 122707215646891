import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useCallback, useContext, useMemo, useState } from "react";
import { updateParentRangesCacheDiagnosisMonthly, updateTopParentRangeCacheDiagnosisMonthly } from "../../../../api/cache-diagnosis-data/cache-diagnosis-data";
import { AdminUpdateParentRangesCacheDiagnosisMonthlyResult } from "api";
import { Loader } from "shared/components/Loaders/Loader";

const updatedCounts: { value: { [key: string]: number } } = { value: {} };
const DiagnosisCacheTools = () => {
	const pFilters = useContext(PrimaryFiltersContext);

	const [requestInProgress, set_requestInProgress] = useState<string | null>(null);
	const add_updatedCountsX = useMemo(() => {
		console.debug(updatedCounts);
		return (key: string, newCount: number) => {
			updatedCounts.value[key] = newCount;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCounts.value]);

	const updateParentRanges_ProcessResponse = useCallback(
		async (response: AdminUpdateParentRangesCacheDiagnosisMonthlyResult, id: string, iteration: number) => {
			if (response?.itemsUpdatedCount !== undefined) {
				add_updatedCountsX(id, response.itemsUpdatedCount);
				if (response.itemsUpdatedCount > 0 || iteration < 2) {
					updateParentRanges(iteration + 1);
					return;
				} else {
					set_requestInProgress(null);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requestInProgress, set_requestInProgress, pFilters.values, add_updatedCountsX, updatedCounts]
	);

	const updateParentRanges = useCallback(
		async (iteration: number = 1) => {
			if (iteration === 1) {
				updatedCounts.value = {};
			}
			let id = `ParentRange x${iteration}`;
			set_requestInProgress(id);
			updateParentRangesCacheDiagnosisMonthly().then((x) => {
				updateParentRanges_ProcessResponse(x, id, iteration);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requestInProgress, set_requestInProgress, pFilters.values, updatedCounts]
	);
	/*




*/
	const updateTopParentRange_ProcessResponse = useCallback(
		async (response: AdminUpdateParentRangesCacheDiagnosisMonthlyResult, id: string, iteration: number) => {
			if (response?.itemsUpdatedCount !== undefined) {
				add_updatedCountsX(id, response.itemsUpdatedCount);
				if (response.itemsUpdatedCount > 0 || iteration < 2) {
					updateTopParentRange(iteration + 1);
					return;
				} else {
					set_requestInProgress(null);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requestInProgress, set_requestInProgress, pFilters.values, add_updatedCountsX, updatedCounts]
	);
	const updateTopParentRange = useCallback(
		async (iteration: number = 1) => {
			if (iteration === 1) {
				updatedCounts.value = {};
			}
			let id = `TopParentRange x${iteration}`;
			set_requestInProgress(id);
			await updateTopParentRangeCacheDiagnosisMonthly().then((x) => {
				updateTopParentRange_ProcessResponse(x, id, iteration);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requestInProgress, set_requestInProgress, pFilters.values]
	);
	/*
██████  ███████  ██████  ██    ██ ███████ ███████ ████████   
██   ██ ██      ██    ██ ██    ██ ██      ██         ██      
██████  █████   ██    ██ ██    ██ █████   ███████    ██      
██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██      
██   ██ ███████  ██████   ██████  ███████ ███████    ██      
                    ▀▀                                                                
*/

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
                                                  
*/

	return (
		<div>
			<h1>Diagnosis Cache Tools</h1>

			{requestInProgress ? (
				<>
					<p>Currently Processing: {requestInProgress}</p>
					<div style={{ width: "200px" }}>
						<Loader size="small" />
					</div>
				</>
			) : null}
			<div>
				{Object.keys(updatedCounts.value)?.map((x) => {
					return (
						<p key={x}>
							{x}: Updated {updatedCounts.value[x]} Records
						</p>
					);
				})}
			</div>

			<div style={{ display: "flex", flexDirection: "row" }}>
				<button
					disabled={!!requestInProgress}
					onClick={() => {
						updateParentRanges();
					}}
				>
					Update ParentRanges
				</button>
			</div>

			<div style={{ display: "flex", flexDirection: "row" }}>
				<button
					disabled={!!requestInProgress}
					onClick={() => {
						updateTopParentRange();
					}}
				>
					Update TopParentRange
				</button>
			</div>
		</div>
	);
};

export { DiagnosisCacheTools };
