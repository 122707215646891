import { useEffect } from "react";
import { useAuthState } from "./auth/context";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { Navigate } from "react-router-dom";

function SignOutPage() {
	const auth = useAuthState();
	let filters = usePrimaryFilters();

	useEffect(() => {
		filters.values.Members = [];
		filters.values.MembersInitialized = false;
		auth.signOut();
	}, [auth, filters.values]);

	return <Navigate replace to="/" />;
}

export { SignOutPage };
