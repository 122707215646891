import { Box, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid-pro";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";
import { DateConverter } from "shared/utilities/DateConverter";
import { displayConverter } from "shared/utilities/displayConverter";

const gridColumnsLayout_CachePatientMonthly_Basic: GridColumns = [
	{
		field: "patientId",
		headerName: "Member ID",
		flex: 1,
		headerAlign: "left",
		minWidth: 300,
		align: "left",
		sortable: false,
		disableColumnMenu: true,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "100%",
					}}
				>
					<Box>
						{params.row.patientId && params.row.jobId ? (
							<UserFollowedItemButton itemType="PATIENT" code={`${params.row.patientId}||${params.row.jobId}`} />
						) : null}
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Typography>{params.row.patientId}</Typography>
						</Box>
					</Box>
				</Box>
			);
		},
	},
	{
		field: "jobId",
		headerName: "MemberType",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: false,
		disableColumnMenu: true,
	},
	{
		field: "eob_count",
		headerName: "Record Count",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.recordCount),
	},
	{
		field: "diagnosis_count",
		headerName: "Diagnoses",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.diagnosisCount),
	},
	{
		field: "medication_count",
		headerName: "Prescriptions",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.medicationCount),
	},
	{
		field: "productservice_count",
		headerName: "Procedures",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.productserviceCount),
	},
	{
		field: "total_amount",
		headerName: "Spend",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.toUSD(params.row.totalAmount),
	},
	{
		field: "riskCategory",
		headerName: "Risk Score",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: false,
	},
	{
		field: "gender",
		headerName: "Gender",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: false,
	},
	{
		field: "birth_date",
		headerName: "Age",
		flex: 1,
		headerAlign: "left",
		align: "left",
		sortable: true,
		valueGetter: (params) => DateConverter.ageFromApiStringDate(params.row.birthDate),
	},
];

export { gridColumnsLayout_CachePatientMonthly_Basic };
