import React from "react";
import {
	Box,
	Stack,
	Typography,
	//Avatar,
	Card,
	CardContent,
} from "@mui/material";
import {
	wholeNumberFormatter,
	//	, wholePercentFormatter
} from "shared/formatters";
import { palette } from "theme";
import { useNavigate } from "react-router-dom";

interface MemberRiskRowProps {
	variant: string;
	memberPercent: number;
	memberCount: number;
	retentionRate: number;
}

type RiskCategoryVariantDefinition = { avatarColor: string; textColor: string; description: React.ReactNode; code: RiskCategoryNames };
//const riskCategoryVariants: Record<MemberRiskRowProps["variant"], RiskCategoryVariantDefinition> = {
const riskCategoryVariants: Record<string, RiskCategoryVariantDefinition> = {
	Healthy: {
		avatarColor: "green",
		textColor: palette.success.main,
		description: "These members have conditions of low concern.",
		code: "healthy",
	},
	Moderate: {
		avatarColor: "orange",
		textColor: palette.warning.main,
		description: "These members have conditions of moderate concern.",
		code: "moderate",
	},
	Serious: {
		avatarColor: "red",
		textColor: palette.error.main,
		description: "These members have conditions that will require continuous care.",
		code: "serious",
	},
	"No Visits": {
		avatarColor: "light-blue",
		textColor: palette.info.main,
		description: "These members have a new conditions but no follow up visit.",
		code: "no_visits",
	},
	"No Visits X": {
		avatarColor: "light-blue",
		textColor: palette.info.main,
		description: "These members have a new conditions but no follow up visit.",
		code: "no_visits",
	},
};

function MemberRiskRow({ variant, memberPercent, memberCount }: MemberRiskRowProps) {
	const navigate = useNavigate();

	const {
		//avatarColor,
		code,
		textColor,
		description,
	} = riskCategoryVariants[variant];
	return (
		<Stack
			direction="row"
			width="100%"
			sx={{
				borderBottom: "2px solid",
				borderColor: "gray.main",
				pb: 2,
				mb: 2,
				cursor: "pointer",
				"&:hover": {
					opacity: 0.6,
				},
			}}
			onClick={() => {
				navigate("/members/overview?riskCategory=" + code);
			}}
		>
			{/* <Box sx={{ display: "none" }}>
				<Avatar className={avatarColor}>{wholePercentFormatter.format(memberPercent)}</Avatar>
			</Box> */}
			<Box sx={{ pl: 1, flex: "1" }}>
				<Typography variant="h3" sx={{ textTransform: "uppercase", color: textColor }}>
					{variant}
				</Typography>
				<Typography component="p" sx={{ color: "gray.dark" }}>
					{description}
				</Typography>
				<Stack
					paddingTop=".5rem"
					sx={{
						flexDirection: {
							xs: "column",
							sm: "row",
						},
					}}
				>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "50%",
							},
							pr: {
								xs: 0,
								sm: 1,
							},
							pb: {
								xs: 1,
								sm: 0,
							},
						}}
					>
						<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
							<CardContent sx={{ textAlign: "center" }}>
								<Typography variant="h4" sx={{ color: textColor }}>
									{wholeNumberFormatter.format(memberCount)}
								</Typography>
								<Typography
									component="span"
									sx={{
										textTransform: "uppercase",
										fontWeight: 700,
										color: "gray.dark",
									}}
								>
									Total Members
								</Typography>
							</CardContent>
						</Card>
					</Box>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "50%",
							},
							pl: {
								xs: 0,
								sm: 1,
							},
						}}
					>
						<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
							<CardContent sx={{ textAlign: "center" }}>
								<Typography variant="h4" sx={{ color: textColor }}>
									{/* {wholePercentFormatter.format(retentionRate)} */}
									{memberPercent + "%"}
								</Typography>
								<Typography
									component="span"
									sx={{
										textTransform: "uppercase",
										fontWeight: 700,
										color: "gray.dark",
									}}
								>
									Total Member PCT
								</Typography>
							</CardContent>
						</Card>
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
}

export { MemberRiskRow, riskCategoryVariants };
