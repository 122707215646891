import { CognitoUser } from "amazon-cognito-identity-js";
import { createContext, Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { FullscreenLoader } from "shared/components/Loaders/FullscreenLoader";
import { AdminGetUserResult, getUser } from "api";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { ContextUserFollowedItemsProvider } from "./Context_UserFollowedItems";

/*


 ██████ ██       █████  ███████ ███████ 
██      ██      ██   ██ ██      ██      
██      ██      ███████ ███████ ███████ 
██      ██      ██   ██      ██      ██ 
 ██████ ███████ ██   ██ ███████ ███████
*/
class AccountManagerClass {
	userDetails: AdminGetUserResult | null = null;
	user: CognitoUser | null = null;
	keyPrefix: string | null = null;
	username: string = "UNINITIALIZED";
	signInUserSession: any = null;
	intializedAt?: number;
	set_accountManagerContextValues?: (acctMan: AccountManagerClass) => any;
	set_updateTrigger?: Dispatch<SetStateAction<number>>;
	set_displayModal: (x: AccountManagerDisplayModalTypes) => any = (x: AccountManagerDisplayModalTypes) => null; //Dispatch<SetStateAction<AccountManagerDisplayModalTypes>=>void //| null = null

	idToken = () => {
		return localStorage.getItem(`${this.keyPrefix}.${this.username}.idToken`);
	};

	clear = () => {
		this.user = null;
		this.keyPrefix = null;
		this.username = "UNINITIALIZED";
		this.userDetails = null;
	};

	setUser = async (user: CognitoUser | null | "REFRESH") => {
		if (this.set_accountManagerContextValues && this.set_updateTrigger) {
			this.setUserX(user);
		} else {
			console.debug("waiting for AccountManager to complete initialization");
			setTimeout(() => {
				this.setUser(user);
			}, 500);
		}
	};

	setUserX = async (user: CognitoUser | null | "REFRESH") => {
		// console.trace();
		if (user) {
			if (user === "REFRESH") {
				// Dont change the user - just refresh the details
			} else {
				this.user = user;
			}
			this.keyPrefix = (this.user as any).keyPrefix ?? null;
			this.username = (this.user as any).username ?? null;

			this.userDetails = await getUser();
			// console.debug("this.userDetails");
			// console.debug(this.userDetails);
		} else {
			this.clear();
		}
		if (this.set_accountManagerContextValues) {
			this.set_accountManagerContextValues(this);
		} else {
			console.warn("set_accountManagerContextValues was not ready");
		}
		if (this.set_updateTrigger) {
			this.set_updateTrigger(Date.now());
		}
	};

	constructor() {
		if (!(AccountManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(AccountManagerClass as any).instance = this;
		}
		return (AccountManagerClass as any).instance;
	}
}
const AccountManager = new AccountManagerClass();

/*







 ██████  ██████  ███    ██ ████████ ███████ ██   ██ ████████ 
██      ██    ██ ████   ██    ██    ██       ██ ██     ██    
██      ██    ██ ██ ██  ██    ██    █████     ███      ██    
██      ██    ██ ██  ██ ██    ██    ██       ██ ██     ██    
 ██████  ██████  ██   ████    ██    ███████ ██   ██    ██  
*/

type AccountManagerDisplayModalTypes = "InactiveCollaborationWithNoDirectDatasource" | null;
const AccountManagerContext = createContext<{
	user: CognitoUser | null;
	username: string | null;
	accountManager: AccountManagerClass;
	updateTrigger: number;
	set_updateTrigger: Dispatch<SetStateAction<number>>;
}>({
	user: AccountManager.user,
	username: AccountManager.username,
	accountManager: AccountManager,
	updateTrigger: 0,
	set_updateTrigger: () => null,
});

function AccountManagerContextProvider(props: { children: React.ReactNode }) {
	const [user, set_user] = useState(AccountManager.user);
	const [username, set_username] = useState(AccountManager.username);
	const [updateTrigger, set_updateTrigger] = useState(0);

	let primaryFilters = usePrimaryFilters();

	const _set_contextValues = useCallback(
		(am: AccountManagerClass) => {
			set_user(am.user);
			const memberTypes = am.userDetails?.user?.insuranceTypes;

			if (!primaryFilters.values.MembersInitialized && memberTypes) {
				if (am.userDetails?.user?.insuranceTypeEobDateRanges) {
					let jobIdEobRanges = am.userDetails?.user?.insuranceTypeEobDateRanges;
					const dateRanges: { [key: string]: { start: Date; end: Date } } = {};
					Object.getOwnPropertyNames(jobIdEobRanges).forEach((k) => {
						let range = jobIdEobRanges[k];
						if (range) {
							dateRanges[k] = { start: new Date(range.start!), end: new Date(range.end!) };
						}
					});
					primaryFilters.values.InsuranceTypeEobDateRanges = dateRanges;
				}
				if (am.userDetails?.user?.insuranceTypeMembersCount) {
					primaryFilters.values.InsuranceTypeMembersCount = am.userDetails.user.insuranceTypeMembersCount;
				}
				primaryFilters.values.MembersInitialized = true;
				if (!primaryFilters.values.Members) {
					primaryFilters.values.Members = Object.assign([], memberTypes); //clone array so remove op doesn't affect API data
				}
			}
			set_username(am.username);
		},
		[primaryFilters.values]
	);

	AccountManager.set_accountManagerContextValues = _set_contextValues;
	AccountManager.set_updateTrigger = set_updateTrigger;

	// auth.update(null);

	const completeUserValue = useMemo(() => {
		return {
			user: user,
			username: username,
			accountManager: AccountManager,
			set_updateTrigger: set_updateTrigger,
			updateTrigger: updateTrigger,
		};
	}, [updateTrigger, user, username]);

	return (
		<AccountManagerContext.Provider value={completeUserValue}>
			{/* <p>MembersInitialized {String(primaryFilters.values.MembersInitialized)}</p> */}
			{user && username && primaryFilters.values.MembersInitialized ? (
				<ContextUserFollowedItemsProvider>{props.children}</ContextUserFollowedItemsProvider>
			) : (
				<FullscreenLoader />
			)}
		</AccountManagerContext.Provider>
	);
}

export { AccountManager, AccountManagerContextProvider, AccountManagerContext };

export type { AccountManagerDisplayModalTypes };
