/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetAllUsersResult,
  GetAllUsersParams,
  AdminAddUserResult,
  AdminAddUserForm,
  AdminGetUserByIdResult,
  AdminUserResetPasswordResult,
  AdminGetUserResult,
  AdminAdminEditUserResult,
  AdminAdminEditUserForm,
  AdminEditUserResult,
  AdminEditUserForm,
  AdminModifyFollowedItemResult,
  AdminModifyFollowedItemRequest,
  AdminRetrieveFollowedItemsResult,
  AdminRetrieveFollowedItemsRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllUsers = (
    params?: GetAllUsersParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetAllUsersResult>(
      {url: `/api/admin/Users`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams,) => [`/api/admin/Users`, ...(params ? [params]: [])];

    
export const useGetAllUsers = <TData = AsyncReturnType<typeof getAllUsers>, TError = unknown>(
 params?: GetAllUsersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllUsers>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllUsers>> = () => getAllUsers(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllUsers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addUser = (
    adminAddUserForm: AdminAddUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminAddUserResult>(
      {url: `/api/admin/Users`, method: 'post',
      data: adminAddUserForm
    },
      options);
    }
  


    export const useAddUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addUser>, TError,{data: AdminAddUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addUser>, {data: AdminAddUserForm}> = (props) => {
          const {data} = props || {};

          return  addUser(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addUser>, TError, {data: AdminAddUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getUserById = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetUserByIdResult>(
      {url: `/api/admin/Users/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetUserByIdQueryKey = (userId: number,) => [`/api/admin/Users/${userId}`];

    
export const useGetUserById = <TData = AsyncReturnType<typeof getUserById>, TError = unknown>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUserById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserById>> = () => getUserById(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUserById>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const resetUserPassword = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminUserResetPasswordResult>(
      {url: `/api/admin/Users/${userId}`, method: 'post'
    },
      options);
    }
  


    export const useResetUserPassword = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof resetUserPassword>, TError,{userId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof resetUserPassword>, {userId: number}> = (props) => {
          const {userId} = props || {};

          return  resetUserPassword(userId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof resetUserPassword>, TError, {userId: number}, TContext>(mutationFn, mutationOptions)
    }
    export const getUser = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetUserResult>(
      {url: `/api/admin/Users/getUser`, method: 'get'
    },
      options);
    }
  

export const getGetUserQueryKey = () => [`/api/admin/Users/getUser`];

    
export const useGetUser = <TData = AsyncReturnType<typeof getUser>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUser>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUser>> = () => getUser(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUser>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const adminEditUser = (
    userId: number,
    adminAdminEditUserForm: AdminAdminEditUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminAdminEditUserResult>(
      {url: `/api/admin/Users/adminEdit/${userId}`, method: 'post',
      data: adminAdminEditUserForm
    },
      options);
    }
  


    export const useAdminEditUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof adminEditUser>, TError,{userId: number;data: AdminAdminEditUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof adminEditUser>, {userId: number;data: AdminAdminEditUserForm}> = (props) => {
          const {userId,data} = props || {};

          return  adminEditUser(userId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof adminEditUser>, TError, {userId: number;data: AdminAdminEditUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const editUser = (
    adminEditUserForm: AdminEditUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminEditUserResult>(
      {url: `/api/admin/Users/edit`, method: 'post',
      data: adminEditUserForm
    },
      options);
    }
  


    export const useEditUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof editUser>, TError,{data: AdminEditUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof editUser>, {data: AdminEditUserForm}> = (props) => {
          const {data} = props || {};

          return  editUser(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof editUser>, TError, {data: AdminEditUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const modifyFollowedItem = (
    adminModifyFollowedItemRequest: AdminModifyFollowedItemRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminModifyFollowedItemResult>(
      {url: `/api/admin/Users/ModifyFollowedItem`, method: 'put',
      data: adminModifyFollowedItemRequest
    },
      options);
    }
  


    export const useModifyFollowedItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof modifyFollowedItem>, TError,{data: AdminModifyFollowedItemRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof modifyFollowedItem>, {data: AdminModifyFollowedItemRequest}> = (props) => {
          const {data} = props || {};

          return  modifyFollowedItem(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof modifyFollowedItem>, TError, {data: AdminModifyFollowedItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const retrieveFollowedItems = (
    adminRetrieveFollowedItemsRequest: AdminRetrieveFollowedItemsRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminRetrieveFollowedItemsResult>(
      {url: `/api/admin/Users/RetrieveFollowedItems`, method: 'post',
      data: adminRetrieveFollowedItemsRequest
    },
      options);
    }
  


    export const useRetrieveFollowedItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof retrieveFollowedItems>, TError,{data: AdminRetrieveFollowedItemsRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof retrieveFollowedItems>, {data: AdminRetrieveFollowedItemsRequest}> = (props) => {
          const {data} = props || {};

          return  retrieveFollowedItems(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof retrieveFollowedItems>, TError, {data: AdminRetrieveFollowedItemsRequest}, TContext>(mutationFn, mutationOptions)
    }
    