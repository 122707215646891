import { Box, Grid, Card, CardHeader, CardContent, Typography, TextField, Button } from "@mui/material";
import { editUser } from "api/users/users";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { EmailMaskedInput, PhoneMaskedInput } from "shared/forms/InputMasks";
import { Contact, Name } from "api";
import { AccountManagerContext } from "DataProvider/contexts/AccountManager";
import { useNavigate } from "react-router-dom";
import { createUser } from "./auth/flow";

interface MyAccountFormProps {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
}

function MyAccount() {
	const { enqueueSnackbar } = useSnackbar();
	const data = useContext(AccountManagerContext).accountManager.userDetails;

	const { control, handleSubmit } = useForm<MyAccountFormProps>({
		defaultValues: {
			firstName: data?.user?.name?.first ?? "",
			lastName: data?.user?.name?.last ?? "",
			email: data?.user?.contact?.email ?? "",
			phoneNumber: data?.user?.contact?.phone ?? "",
		},
	});

	const navigate = useNavigate();

	const onChangePassword = () => {
		var email = data?.user?.username ?? "N/A";
		const user = createUser(email);

		console.log("Starting password change for " + email);
		user.forgotPassword({
			onSuccess() {
				navigate("/account/changePassword");
			},
			onFailure(err) {
				enqueueSnackbar(err);
			},
		});
	};

	const onSubmit = handleSubmit((form) => {
		debugger;
		const name: Name = {
			first: form.firstName,
			last: form.lastName,
		};
		const contact: Contact = {
			email: form.email,
			phone: form.phoneNumber,
		};
		editUser({
			name: name,
			contact: contact,
		}).then(successCallback, failureCallback);
	});

	function successCallback() {
		enqueueSnackbar("User Updated", {
			variant: "success",
		});
	}

	function failureCallback() {
		enqueueSnackbar("Error while saving changes", {
			variant: "error",
		});
	}
	return (
		<Box sx={{ width: "100%" }} onSubmit={onSubmit} component="form">
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardHeader title="My Account" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "grid", height: "100%" }}>
							<Box sx={{ display: "flex", height: "100%" }}>
								<Box
									sx={{
										display: "flex",
										borderRadius: "1.5rem",
										backgroundColor: "#EBEBEB",
										p: 1,
										mb: 2,
										pl: 2,
										pr: 2,
										boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
									}}
								>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Username:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.user?.username} |</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Member Id:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.user?.id}</Typography>
								</Box>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid Email Address",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem" }}
											error={!!error}
											helperText={error && "Invalid email"}
											onChange={onChange}
											value={value}
											required
											id="email"
											label="Email"
											placeholder="jdoe@example.com"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: EmailMaskedInput as any,
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="phoneNumber"
									rules={{
										required: true,
										pattern: {
											value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
											message: "Invalid Phone Number",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem", pl: 2 }}
											onChange={onChange}
											value={value}
											error={!!error}
											helperText={error && "Invalid phone number"}
											required
											id="phoneNumber"
											label="Phone"
											placeholder="+1 (555) 555-5555"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: PhoneMaskedInput as any,
											}}
										/>
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="firstName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="firstName" label="First Name" />
									)}
								/>
								<Controller
									control={control}
									name="lastName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem", pl: 2 }} onChange={onChange} value={value} id="lastName" label="Last Name" />
									)}
								/>
							</Box>

							<Box display={"flex"} pb={2} flexDirection={"column"}>
								<Typography fontWeight={"bolder"} pr={1}>
									Role:{" "}
								</Typography>
								<Typography>{data?.user?.userRole}</Typography>
							</Box>
							<Box display={"flex"} flexDirection={"column"}>
								<Typography fontWeight={"bolder"} pr={1}>
									Insurance Types:{" "}
								</Typography>
								{data?.user?.insuranceTypes?.map((item) => {
									return (
										<Typography key={item} pr={1}>
											{item}
										</Typography>
									);
								})}
							</Box>
						</Box>
						<br />
						<Box sx={{ display: "flex" }}>
							<Button type="submit">Save Changes</Button>
							&nbsp;
							<Button onClick={onChangePassword} type="button">
								Change Password
							</Button>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

export default MyAccount;
