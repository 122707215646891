import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { ModelsPaginationAndSortRequest, RequestModelsCacheMonthlyQueryRequest, getCacheMonthlyAggregation } from "api";
import { useQuery } from "react-query";

function getCacheMonthlyAggregationX(request: RequestModelsCacheMonthlyQueryRequest, noCall: boolean = false) {
	if (noCall) {
		return null;
	}
	return getCacheMonthlyAggregation(request);
}

const useX_getCacheMonthlyAggregation = (request_: RequestModelsCacheMonthlyQueryRequest, noCall: boolean = false) => {
	let r = Object.assign({}, useFilterQuery(), request_);

	let pagesort: ModelsPaginationAndSortRequest | undefined = r.paginationRequest ? { ...r.paginationRequest } : undefined;
	if (pagesort && pagesort?.field && pagesort?.field?.indexOf(".") !== 1) {
		// split field into field+subfield if contains a .
		let f_ = pagesort.field.split(".");
		pagesort.field = f_[0];
		pagesort.subfield = f_[1];
	}
	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes: r.codes,
		nestedCodes: r.nestedCodes,
		paginationRequest: pagesort,
		itemType: r.itemType,
		includeLookup: r.includeLookup,
		includeMissingItemsLookup: r.includeMissingItemsLookup,
	};

	let cacheKeys = ["getCacheMonthlyAggregation", "getCacheMonthlyAggregation_" + JSON.stringify(request) + `|noCall:${noCall}`];
	return useQuery(cacheKeys, getCacheMonthlyAggregationX.bind(null, request, noCall), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getCacheMonthlyAggregation };
