import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "account/auth/AuthProvider";
import { DataProvider } from "./DataProvider/DataProvider";
import { SnackbarProvider } from "notistack";
import { theme } from "./theme";
import { Shell } from "shared/layout/Shell";
import { clearJwtToken, setJwtToken } from "api_extension/client";
import { AuthState, getAuthSession } from "account/auth/context";
import { PublicRoutes } from "account/PublicRoutes";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { RouterMain } from "RouterMain";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();
window.RadiusCare = {
	data: {},
	developer: {
		queryInvalidateCache: (x: string) => null,
		enableResendButtons: (x: boolean) => null,
		setDeveloperMode: (x: boolean) => null,
		test: {},
	},
};

function onAuthStateChange(state: AuthState) {
	if (state.authenticated) {
		setJwtToken(async () => (await getAuthSession(state.user)).getIdToken().getJwtToken());
	} else {
		clearJwtToken();
	}
}

function App() {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<SnackbarProvider autoHideDuration={2000}>
						<AuthProvider onStateChange={onAuthStateChange} publicRoutes={<PublicRoutes />}>
							<QueryClientProvider client={queryClient}>
								<DataProvider>
									<Shell>
										<RouterMain />
									</Shell>
								</DataProvider>
							</QueryClientProvider>
						</AuthProvider>
					</SnackbarProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
