import { Button, Card, CardContent, Grid } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { useGetAllUsers } from "api";
import useDataGrid from "shared/components/grid/useDataGrid";

const columns: GridColumns = [
	{
		field: "id",
		headerName: "Member ID",
		flex: 0.5,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "username",
		headerName: "Username",
		flex: 1.5,
		headerAlign: "left",
		align: "left",
	},
	{
		field: `email`,
		headerName: "Email",
		flex: 1.5,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => params.row.contact?.email,
	},
	{
		field: "name",
		headerName: "First Name",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => params.row.name?.first,
	},
	{
		field: "lastName",
		headerName: "Last Name",
		flex: 1,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => params.row.name?.last,
	},
	{
		field: "details",
		headerName: "",
		flex: 1,
		headerAlign: "left",
		align: "center",

		renderCell: ({ row }) => (
			<Button component={Link} to={`/settings/users/${row.id}`} color="secondary">
				Details
			</Button>
		),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"User List"} addButtonLabel="Add User" addButtonLink="/settings/AddUser" {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function UserList() {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("users", columns);

	const { data, isFetching } = useGetAllUsers(
		{
			...query,
		},
		{ query: { keepPreviousData: true } }
	);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "flex", height: "100%" }}>
							<Box
								sx={{
									flexGrow: 1,
								}}
							>
								{data && (
									<DataGridPro
										{...grid}
										autoHeight
										rows={data?.items ?? []}
										rowCount={data?.total}
										loading={isFetching}
										components={{ Toolbar: Toolbar }}
										componentsProps={{
											toolbar: {
												onSearch: (searchString: string) => {
													onSearchEntered(searchString);
												},
												initialSearch: query.searchQuery ?? "",
											},
										}}
										sx={{ pt: 1 }}
									/>
								)}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

export default UserList;
