import { apiRequest } from "../../api_extension/apiRequest";

import { ICD10, ICD10PCS, ICD10PCSRangeCategory, PackagedICD10PCSCodes } from "../schemas/dataStructures";

type ICD_PDF_Category = {
	diagnosis: string;
	duration: string;
	ICD9: Array<string>;
	ICD10: Array<string>;
	rules: string;
	"chronic/other": string;
};

type AILMENT_BY_CODE = {
	ailment: Array<string>;
	code: string;
	prefix: string;
	simple_code: string;
	hasMatch?: number;
};

const ICD10CodeCategorizer = {
	/*
  ██  ██████ ██████   ██  ██████         █████  ██ ██      ███    ███ ███████ ███    ██ ████████        ██████  ██████   ██████  ██    ██ ██████  ███████     ██████  ██████  ███████ 
  ██ ██      ██   ██ ███ ██  ████       ██   ██ ██ ██      ████  ████ ██      ████   ██    ██          ██       ██   ██ ██    ██ ██    ██ ██   ██ ██          ██   ██ ██   ██ ██      
  ██ ██      ██   ██  ██ ██ ██ ██ █████ ███████ ██ ██      ██ ████ ██ █████   ██ ██  ██    ██    █████ ██   ███ ██████  ██    ██ ██    ██ ██████  ███████     ██████  ██   ██ █████   
  ██ ██      ██   ██  ██ ████  ██       ██   ██ ██ ██      ██  ██  ██ ██      ██  ██ ██    ██          ██    ██ ██   ██ ██    ██ ██    ██ ██           ██     ██      ██   ██ ██      
  ██  ██████ ██████   ██  ██████        ██   ██ ██ ███████ ██      ██ ███████ ██   ████    ██           ██████  ██   ██  ██████   ██████  ██      ███████     ██      ██████  ██      
                                                                                                                                                                              
  */
	process_category_csvfile: async function () {
		//this is to process ICD categories from constructed .csv from pdf
		const category_code_groups: Array<ICD_PDF_Category> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/source_files/ICD_categorization/ICD_categorization_full.csv`,
			method: "GET",
			response_type: "text",
		}).then((response) => {
			function parseICDCategoryList(list: string) {
				list = list
					.replaceAll("(any DX on the claim)", " ")
					.replaceAll("(any DX or procedure on the claim)", "")
					.split("ICD-10 Procedure Codes:")[0]
					.split("EXCEPTION:")[0]
					.split("NDCs for Buprenorphine:")[0]
					.split("HCPCS codes for MAT:")[0]
					.split("Any positive result from Indicators")[0];
				let codes = list.split(",").map((x) => x.trim());
				codes = codes.map((code) => {
					if (code.indexOf(" ") !== -1) {
						if (code.indexOf(" ") === -1) {
							return code;
						} else if (code.split(" ")[0].search(/\d/) !== -1) {
							// if the first particle has a number in it then treat it as a code
							return code.split(" ")[0].trim();
						} else {
							return "UNPARSED:" + code; // filter this out later or do something with it
						}
					} else {
						return code;
					}
				});
				return codes.filter((c) => {
					return c.indexOf("UNPARSED:") === -1 && c !== "" && c !== "DX";
				});
			}
			return response
				.split("\r\n")
				.map((x: string) => {
					return x.split("|").map((y) => y.trim());
				})
				.map((cat: Array<string>) => {
					return {
						diagnosis: cat[0],
						duration: cat[1],
						ICD9: parseICDCategoryList(cat[2]),
						ICD10: parseICDCategoryList(cat[3]),
						rules: cat[4],
						"chronic/other": cat[5],
					};
				});
		});
		console.debug("category_code_groups", category_code_groups);
		console.debug(JSON.stringify(category_code_groups));
	},

	retrieve_processed_ICD10AilmentGroups: async function (): Promise<Map<string, AILMENT_BY_CODE>> {
		// Categorized ICD10s from PDF files
		return apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/source_files/ICD_categorization/ICD_categorization_full_processed.json`,
			method: "GET",
		}).then((response) => {
			let codeAilmentMap: Map<string, AILMENT_BY_CODE> = new Map();
			response.forEach((cat: ICD_PDF_Category) => {
				cat.ICD10.forEach((icd10_code) => {
					let simple_code = icd10_code.replace(".", "");
					if (codeAilmentMap.has(simple_code)) {
						if (codeAilmentMap.get(simple_code)?.ailment.indexOf(cat.diagnosis) === -1) {
							codeAilmentMap.get(simple_code)?.ailment.push(cat.diagnosis);
						}
					} else {
						codeAilmentMap.set(simple_code, {
							prefix: simple_code.slice(0, 3),
							simple_code: simple_code,
							code: icd10_code,
							ailment: [cat.diagnosis],
						});
					}
				});
			});
			return codeAilmentMap;
		});
	},
	/*
██████   █████  ███    ██  ██████  ███████ ███████ 
██   ██ ██   ██ ████   ██ ██       ██      ██      
██████  ███████ ██ ██  ██ ██   ███ █████   ███████ 
██   ██ ██   ██ ██  ██ ██ ██    ██ ██           ██ 
██   ██ ██   ██ ██   ████  ██████  ███████ ███████
*/
	//ICD10CM_FindACodeExtract.json
	retrieve_ICD10AilmentGroups_Ranges: async function (): Promise<any> {
		// Categorized ICD10s from PDF files
		return apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/supporting_files/ICD10CM_FindACodeExtract.json`,
			method: "GET",
		}).then((response) => {
			return response.map((r: any) => {
				r.start = r.range.split("-")[0].replace(".", "");
				r.end = r.range.split("-")[1].replace(".", "");
				return r;
			});
		});
	},

	assign_codes_to_range: function (
		// uses retrieve_ICD10AilmentGroups_Ranges for ranges
		codes: Array<ICD10>,
		ranges: Array<{ start: string; end: string; range: string }>
	) {
		codes.forEach((c) => {
			ranges.some((br: any) => {
				let code = c.ICD10Code.replace(".", "");
				// check
				if (code.localeCompare(br.end, "en") <= 0) {
					// end term is greater than ICD10
					if (code.localeCompare(br.start, "en") >= 0) {
						// beginning term is less than ICD10
						c.rangeCategory = br.range;
						return true;
					}
				}
				return false;
			});
		});
	},

	/*
██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████     ████████ ██   ██ ████████ 
██ ██      ██   ██ ███ ██  ████     ██      ██    ██ ██   ██ ██      ██             ██     ██ ██     ██    
██ ██      ██   ██  ██ ██ ██ ██     ██      ██    ██ ██   ██ █████   ███████        ██      ███      ██    
██ ██      ██   ██  ██ ████  ██     ██      ██    ██ ██   ██ ██           ██        ██     ██ ██     ██    
██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████        ██    ██   ██    ██    
                                                                                                        
*/

	retrieve_flat_ICD10Codes: async function () {
		const codes: Array<ICD10> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/source_files/icd10_2022.json`,
			method: "GET",
		}).then((response) => {
			return response.map((x: any) => ({
				ICD10Code: x.code,
				Ailment: [],
				Description: x.description,
			}));
		});
		return codes;
	},

	/*
██████  ██████   ██████   ██████ ███████ ███████ ███████ ███████ ██████      ██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████ 
██   ██ ██   ██ ██    ██ ██      ██      ██      ██      ██      ██   ██     ██ ██      ██   ██ ███ ██  ████     ██      ██    ██ ██   ██ ██      ██      
██████  ██████  ██    ██ ██      █████   ███████ ███████ █████   ██   ██     ██ ██      ██   ██  ██ ██ ██ ██     ██      ██    ██ ██   ██ █████   ███████ 
██      ██   ██ ██    ██ ██      ██           ██      ██ ██      ██   ██     ██ ██      ██   ██  ██ ████  ██     ██      ██    ██ ██   ██ ██           ██ 
██      ██   ██  ██████   ██████ ███████ ███████ ███████ ███████ ██████      ██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████
*/
	retrieve_ICD10_Codes_Package: async function () {
		const codes: Array<ICD10> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/CM/ICD10CM_Package.json`,
			method: "GET",
		}).then((response) => {
			let alpha_category_map = new Map();
			response.structuralDefinitions.ICD10CMAlphaCategories.forEach((x: any) => {
				alpha_category_map.set(x.alpha, x);
			});
			let range_category_map = new Map();
			response.structuralDefinitions.ICD10CMRangeCategories.forEach((x: any) => {
				range_category_map.set(x.range, x);
			});

			console.debug("range_category_map", range_category_map);
			console.debug("alpha_category_map", alpha_category_map);
			return response.codes.map((x: ICD10, i: number) => {
				let alpha_category = alpha_category_map.get(x.ICD10Code.slice(0, 1).toUpperCase());
				if (alpha_category) {
					x.alphaCategory = alpha_category.category;
				}

				let range_category = range_category_map.get(x.rangeCategory);
				if (range_category) {
					x.rangeCategory = range_category.description;
				}
				return x;
			});
		});
		return codes;
	},
	/*
 ██████  █████  ████████ ███████  ██████   ██████  ██████  ██ ███████ ███████ 
██      ██   ██    ██    ██      ██       ██    ██ ██   ██ ██    ███  ██      
██      ███████    ██    █████   ██   ███ ██    ██ ██████  ██   ███   █████   
██      ██   ██    ██    ██      ██    ██ ██    ██ ██   ██ ██  ███    ██      
 ██████ ██   ██    ██    ███████  ██████   ██████  ██   ██ ██ ███████ ███████
*/
	categorize_ICD10Codes: async function () {
		let codes = await ICD10CodeCategorizer.retrieve_ICD10_Codes_Package();
		const codeAilmentMap = await ICD10CodeCategorizer.retrieve_processed_ICD10AilmentGroups();
		let codeAilmentPrefixMap = new Map();
		Array.from(codeAilmentMap.values()).forEach((x) => {
			if (codeAilmentPrefixMap.has(x.prefix)) {
				let cur = codeAilmentPrefixMap.get(x.prefix);
				x.ailment.forEach((a: string) => {
					cur.ailments.add(a);
				});
				cur.codes.push(x);
			} else {
				codeAilmentPrefixMap.set(x.prefix, {
					ailments: new Set(x.ailment),
					codes: [x],
				});
			}
		});

		console.debug("codeAilmentPrefixMap", codeAilmentPrefixMap);

		// add ailments and alphaCategory to codes

		codes.forEach((code) => {
			let icd10Code = codeAilmentMap.get(code.ICD10Code);
			if (icd10Code) {
				code.Ailment = icd10Code.ailment;
				icd10Code.hasMatch = (icd10Code.hasMatch ?? 0) + 1;
			} else {
				let prefix = code.ICD10Code.slice(0, 3).toUpperCase();
				code.prefixAilments = codeAilmentPrefixMap.get(prefix)?.ailments;
			}
		});

		let no_matches_codeAilmentMap = Array.from(codeAilmentMap.values()).filter((x) => !x.hasMatch);
		console.debug("no_matches_codeAilmentMap", no_matches_codeAilmentMap);

		console.debug(JSON.stringify(Array.from(codeAilmentMap.values())));

		console.debug(codeAilmentPrefixMap);

		codes.forEach((x) => {
			delete x.alphaCategory;
		});
		console.log(JSON.stringify(codes));
		console.debug(codes);
	},
};

export { ICD10CodeCategorizer };

const ICD10PCSCodeCategorizer: {
	[key: string]: any;
	data: {
		category_code_groups: Array<ICD10PCSRangeCategory>;
		codes: Array<ICD10PCS>;
	};
} = {
	/*
  ██  ██████ ██████   ██  ██████         █████  ██ ██      ███    ███ ███████ ███    ██ ████████        ██████  ██████   ██████  ██    ██ ██████  ███████     ██████  ██████  ███████ 
  ██ ██      ██   ██ ███ ██  ████       ██   ██ ██ ██      ████  ████ ██      ████   ██    ██          ██       ██   ██ ██    ██ ██    ██ ██   ██ ██          ██   ██ ██   ██ ██      
  ██ ██      ██   ██  ██ ██ ██ ██ █████ ███████ ██ ██      ██ ████ ██ █████   ██ ██  ██    ██    █████ ██   ███ ██████  ██    ██ ██    ██ ██████  ███████     ██████  ██   ██ █████   
  ██ ██      ██   ██  ██ ████  ██       ██   ██ ██ ██      ██  ██  ██ ██      ██  ██ ██    ██          ██    ██ ██   ██ ██    ██ ██    ██ ██           ██     ██      ██   ██ ██      
  ██  ██████ ██████   ██  ██████        ██   ██ ██ ███████ ██      ██ ███████ ██   ████    ██           ██████  ██   ██  ██████   ██████  ██      ███████     ██      ██████  ██      
                                                                                                                                                                              
  */
	data: {
		category_code_groups: [],
		codes: [],
	},
	retrieve_ICD10PCS_Categories: async function () {
		const category_code_groups: Array<ICD10PCSRangeCategory> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/PCS/ICD10PCS_Categories.json`,
			method: "GET",
		}).then((response) => {
			return response.map((x: any) => {
				x.range = x.start + "-" + x.end;
				x.categoryID = x.name.replaceAll(" ", "_").toUpperCase();
				return x;
			});
		});
		ICD10PCSCodeCategorizer.data.category_code_groups = category_code_groups;
		return category_code_groups;
	},
	process_ICD10PCS_Text: async function () {
		const PCS_codes: Array<any> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/PCS/ICD10PCS_Codes.txt`,
			method: "GET",
			response_type: "text",
		}).then((response) => {
			console.debug("process_ICD10PCS_Text");
			let codes = response
				.split("\r\n")
				.filter((l: string) => {
					return l.length > 1;
				})
				.map((l: string, x: number) => {
					let i = l.indexOf(" ");
					return {
						code: l.slice(0, i).trim(),
						description: l.slice(i + 1).trim(),
					};
				});
			console.debug(codes);
			return codes;
		});
		ICD10PCSCodeCategorizer.data.codes = PCS_codes as any;
	},
	categorize_ICD10PCSCodes: async function () {
		if (ICD10PCSCodeCategorizer.data.category_code_groups.length < 1) {
			await ICD10PCSCodeCategorizer.retrieve_ICD10PCS_Categories();
		}
		if (ICD10PCSCodeCategorizer.data.codes.length < 1) {
			await ICD10PCSCodeCategorizer.process_ICD10PCS_Text();
		}

		if (ICD10PCSCodeCategorizer.data.category_code_groups.length < 1 || ICD10PCSCodeCategorizer.data.codes.length < 1) {
			console.debug("categorize_ICD10PCSCodes did not have have required data");
			return;
		}

		console.groupCollapsed("categorize_ICD10PCSCodes");
		let code_groups = ICD10PCSCodeCategorizer.data.category_code_groups;
		let codes = ICD10PCSCodeCategorizer.data.codes;
		console.debug(code_groups);
		console.debug(codes);

		codes.forEach((c: { code: string; description: string; rangeCategory: string }) => {
			code_groups.some((cg: { start: string; end: string; range: string }) => {
				if (c.code.localeCompare(cg.end, "en") <= 0) {
					// end term is greater than ICD10
					if (c.code.localeCompare(cg.start, "en") >= 0) {
						// beginning term is less than ICD10
						c.rangeCategory = cg.range;
						return true;
					}
				}
				return false;
			});
		});
		console.debug(codes);
		console.log(JSON.stringify(codes));

		console.groupEnd();
	},

	retrieve_ICD10PCS_Codes_Package: async function (): Promise<PackagedICD10PCSCodes> {
		if (ICD10PCSCodeCategorizer.data.category_code_groups.length < 1) {
			await ICD10PCSCodeCategorizer.retrieve_ICD10PCS_Categories();
		}
		if (ICD10PCSCodeCategorizer.data.category_code_groups.length < 1) {
			console.error("retrieve_ICD10PCS_Codes_Package did not have have required data");
			return undefined as unknown as PackagedICD10PCSCodes;
		}
		console.groupCollapsed("retrieve_ICD10PCS_Codes_Package");
		let code_groups = ICD10PCSCodeCategorizer.data.category_code_groups;
		let range_map = new Map();
		code_groups.forEach((cg) => {
			range_map.set(cg.range, cg);
		});
		let codes: Array<ICD10PCS> = await apiRequest({
			url: `${process.env.PUBLIC_URL}/code_definitions/ICD10_2022/PCS/ICD10PCS_Codes.json`,
			method: "GET",
		}).then((codes_) => {
			codes_.forEach((c: { code: string; description: string; rangeCategory: string; [key: string]: any }) => {
				let cg = range_map.get(c.rangeCategory);
				if (cg) {
					c.rangeCategory = cg.name;
				} else {
					c.rangeCategoryError = true;
					console.warn(`Range Category for PCS ${c.code} was not found`);
				}
			});
			return codes_;
		});
		console.groupEnd();

		let code_map: Map<string, ICD10PCS> = new Map();
		codes.forEach((x: ICD10PCS) => {
			code_map.set(x.code, x);
		});

		return {
			codes: codes,
			rangeCategories: code_groups,
			lookups: {
				code: code_map,
			},
		};
	},
};

export { ICD10PCSCodeCategorizer };
