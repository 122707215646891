import { Box, Grid, Card, CardContent, CardHeader } from "@mui/material";
import RevenueOverviewAnalytics from "./components/RevenueOverviewAnalytics";
import { displayConverter } from "../../shared/utilities/displayConverter";
import { palette } from "theme";
import { RequestModelsCacheMonthlyQueryRequest } from "api";

import { Loader } from "shared/components/Loaders/Loader";
import { useX_getSpendSummaryCharts } from "api_extension/useQueryX/cacheMonthly/useX_getSpendGraphModels";
import NoDataAvailable from "shared/components/NoDataAvailable";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";

let colors = [
	palette.red.main,
	palette.purple.main,
	palette.green.main,
	palette.primary.main,
	palette.lightBlue.main,
	palette.red.main,
	palette.orange.main,
	palette.lightBlue.main,
	palette.purple.light,
	palette.primary.light,
	palette.green.light,
	palette.orange.light,
	palette.info.main,
	palette.red.light,
	palette.purple.dark,
	palette.green.dark,
	palette.lightBlue.dark,
	palette.orange.dark,
	palette.primary.dark,
	palette.secondary.main,
	palette.purple.main,
	palette.green.main,
	palette.lightBlue.main,
];

function RevenueOverview() {
	const queryRequest: RequestModelsCacheMonthlyQueryRequest = {};
	const apiResult = useX_getSpendSummaryCharts(queryRequest);
	let data = apiResult.data?.response?.revenueOverview;

	let medicationGraph =
		data?.medicationSpendGraph && data.medicationSpendGraph.length > 0 ? (
			<StandardPieChart
				data={data?.medicationSpendGraph?.map((x) => ({
					name: x.name ?? "N/A",
					value: Number(x.value),
				}))}
				colors={colors}
				showLegend
				dollarAboveLegend
				legendIcon="circle"
				fullLegendSx={{ width: "100%" }}
				displayPercentage
				innerRadius={32}
				responsiveHeight={500}
				tooltipFormatter={(value) => {
					return displayConverter.toUSD(value);
				}}
			/>
		) : (
			<NoDataAvailable />
		);

	let procedureGraph =
		data?.productServiceSpendGraph && data?.productServiceSpendGraph.length > 0 ? (
			<StandardPieChart
				data={data?.productServiceSpendGraph?.map((x) => ({
					name: x.name ?? "N/A",
					value: Number(x.value),
				}))}
				colors={colors}
				showLegend
				dollarAboveLegend
				legendIcon="circle"
				fullLegendSx={{ width: "100%" }}
				displayPercentage
				innerRadius={32}
				responsiveHeight={500}
				tooltipFormatter={(value) => {
					return displayConverter.toUSD(value);
				}}
			/>
		) : (
			<NoDataAvailable />
		);

	let conditionsGraph =
		data?.conditionSpendGraph && data?.conditionSpendGraph.length > 0 ? (
			<StandardPieChart
				data={data?.conditionSpendGraph?.map((x) => ({
					name: x.name ?? "N/A",
					value: Number(x.value),
				}))}
				colors={colors}
				showLegend
				dollarAboveLegend
				legendIcon="circle"
				fullLegendSx={{ width: "100%" }}
				displayPercentage
				innerRadius={32}
				responsiveHeight={500}
				tooltipFormatter={(value) => {
					return displayConverter.toUSD(value);
				}}
			/>
		) : (
			<NoDataAvailable />
		);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader title="Summary" />
						<CardContent sx={{ pt: 0 }}>
							<RevenueOverviewAnalytics />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} xl={6}>
					<Card elevation={4} sx={{ height: "100%" }}>
						<CardHeader title="Medication Spend" />
						<CardContent sx={{ pt: 0, "& .chart-legend-container": { pr: 2, maxHeight: "500px", overflow: "auto" } }}>
							{data?.medicationSpendGraph !== undefined ? medicationGraph : <Loader />}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} xl={6}>
					<Card elevation={4} sx={{ height: "100%" }}>
						<CardHeader title="Procedure Spend" />
						<CardContent sx={{ pt: 0, "& .chart-legend-container": { pr: 2, maxHeight: "500px", overflow: "auto" } }}>
							{data?.productServiceSpendGraph !== undefined ? procedureGraph : <Loader />}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} xl={6}>
					<Card elevation={4} sx={{ height: "100%" }}>
						<CardHeader title="Condition Spend" />
						<CardContent sx={{ pt: 0, "& .chart-legend-container": { pr: 2, maxHeight: "500px", overflow: "auto" } }}>
							{data?.conditionSpendGraph !== undefined ? conditionsGraph : <Loader />}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader title="Spend By Facility"></CardHeader>
						<CardContent>
							{data?.facilityRevenueGraph ? (
								<StandardBarChart
									data={data?.facilityRevenueGraph?.map((x) => ({
										name: x.name ?? "N/A",
										value: Number(x.value),
									}))}
									dataKey="name"
									responsiveHeight={500}
									showYAxis
									bar_definitions={[
										{
											dataKey: "value",
											name: "Facility Spend",
											fill: palette.green.main,
											legendType: "circle",
										},
									]}
									stackId="a"
									xAxisTickFormatter={(value) => {
										return displayConverter.toUSD(value);
									}}
									tooltipFormatter={(value) => {
										return displayConverter.toUSD(value);
									}}
									axisLabel={{ value: "Spend", position: "bottom", dx: -20 }}
									margin={{ top: 5, left: 10, right: 50, bottom: 20 }}
									angle={15}
									textAnchor="left"
									xAxisInterval={0}
									layout="vertical"
									yAxisWidth={350}
									legendStyle={{ paddingBottom: 20 }}
								/>
							) : (
								<Loader />
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export { RevenueOverview };
