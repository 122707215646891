/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetDiagnosisRangeAggregationsResult,
  AdminGetDiagnosisRangeAggregationsQuery,
  AdminGetDiagnosisRangeGenderAndAgeResult,
  AdminGetDiagnosisRangeGenderAndAgeQuery,
  AdminGetMedicationsByDiagnosisResult,
  AdminGetMedicationsByDiagnosisQuery,
  CacheDiagnosisDataGetDiagnosisImpactResult,
  CacheDiagnosisDataGetDiagnosisImpactQuery,
  AdminGetProductServicesByDiagnosisResult,
  AdminGetProductServicesByDiagnosisQuery,
  AdminGetDiagnosisCodeAggregationsResult,
  AdminGetDiagnosisCodeAggregationsQuery,
  AdminUpdateParentRangesCacheDiagnosisMonthlyResult,
  AdminUpdateTopParentRangeCacheDiagnosisMonthlyResult
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDiagnosisRangeAggregations = (
    adminGetDiagnosisRangeAggregationsQuery: AdminGetDiagnosisRangeAggregationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDiagnosisRangeAggregationsResult>(
      {url: `/api/admin/CacheDiagnosisData/GetDiagnosisRangeAggregations`, method: 'post',
      data: adminGetDiagnosisRangeAggregationsQuery
    },
      options);
    }
  


    export const useGetDiagnosisRangeAggregations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDiagnosisRangeAggregations>, TError,{data: AdminGetDiagnosisRangeAggregationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDiagnosisRangeAggregations>, {data: AdminGetDiagnosisRangeAggregationsQuery}> = (props) => {
          const {data} = props || {};

          return  getDiagnosisRangeAggregations(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDiagnosisRangeAggregations>, TError, {data: AdminGetDiagnosisRangeAggregationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDiagnosisRangeGenderAndAge = (
    adminGetDiagnosisRangeGenderAndAgeQuery: AdminGetDiagnosisRangeGenderAndAgeQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDiagnosisRangeGenderAndAgeResult>(
      {url: `/api/admin/CacheDiagnosisData/GetDiagnosisRangeGenderAndAge`, method: 'post',
      data: adminGetDiagnosisRangeGenderAndAgeQuery
    },
      options);
    }
  


    export const useGetDiagnosisRangeGenderAndAge = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDiagnosisRangeGenderAndAge>, TError,{data: AdminGetDiagnosisRangeGenderAndAgeQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDiagnosisRangeGenderAndAge>, {data: AdminGetDiagnosisRangeGenderAndAgeQuery}> = (props) => {
          const {data} = props || {};

          return  getDiagnosisRangeGenderAndAge(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDiagnosisRangeGenderAndAge>, TError, {data: AdminGetDiagnosisRangeGenderAndAgeQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationsByDiagnosis = (
    adminGetMedicationsByDiagnosisQuery: AdminGetMedicationsByDiagnosisQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetMedicationsByDiagnosisResult>(
      {url: `/api/admin/CacheDiagnosisData/GetMedicationsByDiagnosis`, method: 'post',
      data: adminGetMedicationsByDiagnosisQuery
    },
      options);
    }
  


    export const useGetMedicationsByDiagnosis = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getMedicationsByDiagnosis>, TError,{data: AdminGetMedicationsByDiagnosisQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getMedicationsByDiagnosis>, {data: AdminGetMedicationsByDiagnosisQuery}> = (props) => {
          const {data} = props || {};

          return  getMedicationsByDiagnosis(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getMedicationsByDiagnosis>, TError, {data: AdminGetMedicationsByDiagnosisQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDiagnosisImpact = (
    cacheDiagnosisDataGetDiagnosisImpactQuery: CacheDiagnosisDataGetDiagnosisImpactQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<CacheDiagnosisDataGetDiagnosisImpactResult>(
      {url: `/api/admin/CacheDiagnosisData/GetDiagnosisImpact`, method: 'post',
      data: cacheDiagnosisDataGetDiagnosisImpactQuery
    },
      options);
    }
  


    export const useGetDiagnosisImpact = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDiagnosisImpact>, TError,{data: CacheDiagnosisDataGetDiagnosisImpactQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDiagnosisImpact>, {data: CacheDiagnosisDataGetDiagnosisImpactQuery}> = (props) => {
          const {data} = props || {};

          return  getDiagnosisImpact(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDiagnosisImpact>, TError, {data: CacheDiagnosisDataGetDiagnosisImpactQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServicesByDiagnosis = (
    adminGetProductServicesByDiagnosisQuery: AdminGetProductServicesByDiagnosisQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServicesByDiagnosisResult>(
      {url: `/api/admin/CacheDiagnosisData/GetProductServicesByDiagnosis`, method: 'post',
      data: adminGetProductServicesByDiagnosisQuery
    },
      options);
    }
  


    export const useGetProductServicesByDiagnosis = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServicesByDiagnosis>, TError,{data: AdminGetProductServicesByDiagnosisQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServicesByDiagnosis>, {data: AdminGetProductServicesByDiagnosisQuery}> = (props) => {
          const {data} = props || {};

          return  getProductServicesByDiagnosis(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServicesByDiagnosis>, TError, {data: AdminGetProductServicesByDiagnosisQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDiagnosisCodeAggregations = (
    adminGetDiagnosisCodeAggregationsQuery: AdminGetDiagnosisCodeAggregationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetDiagnosisCodeAggregationsResult>(
      {url: `/api/admin/CacheDiagnosisData/GetDiagnosisCodeAggregations`, method: 'post',
      data: adminGetDiagnosisCodeAggregationsQuery
    },
      options);
    }
  


    export const useGetDiagnosisCodeAggregations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDiagnosisCodeAggregations>, TError,{data: AdminGetDiagnosisCodeAggregationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDiagnosisCodeAggregations>, {data: AdminGetDiagnosisCodeAggregationsQuery}> = (props) => {
          const {data} = props || {};

          return  getDiagnosisCodeAggregations(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDiagnosisCodeAggregations>, TError, {data: AdminGetDiagnosisCodeAggregationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const updateParentRangesCacheDiagnosisMonthly = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminUpdateParentRangesCacheDiagnosisMonthlyResult>(
      {url: `/api/admin/CacheDiagnosisData/UpdateParentRanges_CacheDiagnosisMonthly`, method: 'get'
    },
      options);
    }
  

export const getUpdateParentRangesCacheDiagnosisMonthlyQueryKey = () => [`/api/admin/CacheDiagnosisData/UpdateParentRanges_CacheDiagnosisMonthly`];

    
export const useUpdateParentRangesCacheDiagnosisMonthly = <TData = AsyncReturnType<typeof updateParentRangesCacheDiagnosisMonthly>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof updateParentRangesCacheDiagnosisMonthly>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getUpdateParentRangesCacheDiagnosisMonthlyQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof updateParentRangesCacheDiagnosisMonthly>> = () => updateParentRangesCacheDiagnosisMonthly(requestOptions);

  const query = useQuery<AsyncReturnType<typeof updateParentRangesCacheDiagnosisMonthly>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const updateTopParentRangeCacheDiagnosisMonthly = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminUpdateTopParentRangeCacheDiagnosisMonthlyResult>(
      {url: `/api/admin/CacheDiagnosisData/UpdateTopParentRange_CacheDiagnosisMonthly`, method: 'get'
    },
      options);
    }
  

export const getUpdateTopParentRangeCacheDiagnosisMonthlyQueryKey = () => [`/api/admin/CacheDiagnosisData/UpdateTopParentRange_CacheDiagnosisMonthly`];

    
export const useUpdateTopParentRangeCacheDiagnosisMonthly = <TData = AsyncReturnType<typeof updateTopParentRangeCacheDiagnosisMonthly>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof updateTopParentRangeCacheDiagnosisMonthly>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getUpdateTopParentRangeCacheDiagnosisMonthlyQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof updateTopParentRangeCacheDiagnosisMonthly>> = () => updateTopParentRangeCacheDiagnosisMonthly(requestOptions);

  const query = useQuery<AsyncReturnType<typeof updateTopParentRangeCacheDiagnosisMonthly>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

