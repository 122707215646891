import {
	Box,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Button,
	Typography,
	TextField,
} from "@mui/material";
import { adminEditConsumerUser, useGetConsumerUserById, resetConsumerUserPassword } from "api/consumer-users/consumer-users";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { EmailMaskedInput, PhoneMaskedInput } from "shared/forms/InputMasks";
import { AdminGetConsumerUserByIdResult, Contact } from "api";

interface EditConsumerUserProps {
	email: string;
	name: string;
	gender: string;
	dateOfBirth: string;
	phoneNumber: string;
	zipCode: string;
}

interface ConsumerUserDetailsFormProps {
	data: AdminGetConsumerUserByIdResult;
}

function ConsumerUserDetailsForm({ data }: ConsumerUserDetailsFormProps) {
	const { enqueueSnackbar } = useSnackbar();
	
	const { control, handleSubmit } = useForm<EditConsumerUserProps>({
		defaultValues: {
			name: data?.consumerUser?.name ?? "",
			email: data?.consumerUser?.contact?.email ?? data?.consumerUser?.email ?? "",
			gender: data?.consumerUser?.gender ?? "",
			phoneNumber: data?.consumerUser?.contact?.phone ?? "",
			zipCode: data?.consumerUser?.location?.zip ?? "",
			dateOfBirth: data?.consumerUser?.dateOfBirth?.toString().substring(0, 10) ?? "",
		},
	});

	const onSubmit = handleSubmit((form) => {
		const contact: Contact = {
			email: form.email,
			phone: form.phoneNumber,
		};
		adminEditConsumerUser(data?.consumerUser?.id ?? 0, {
			id: data?.consumerUser?.id,
			name: form.name,
			gender: form.gender,
			contact: contact,
			zip: form.zipCode,
			dateOfBirth: form.dateOfBirth,
		}).then(successCallback, failureCallback);
	});

	function successCallback() {
		enqueueSnackbar("Consumer User Updated", {
			variant: "success",
		});
	}

	function failureCallback() {
		enqueueSnackbar("Error while saving changes", {
			variant: "error",
		});
	}

	function passwordSuccessCallback() {
		enqueueSnackbar("Password Reset email sent successfully", {
			variant: "success",
		});
	}

	function passwordFailureCallback() {
		enqueueSnackbar("Error while resetting password", {
			variant: "error",
		});
	}
	return (
		<Box sx={{ width: "100%" }} onSubmit={onSubmit} component="form">
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardHeader title="Edit Consumer User" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "grid", height: "100%" }}>
							<Box sx={{ display: "flex", height: "100%" }}>
								<Box
									sx={{
										display: "flex",
										borderRadius: "1.5rem",
										backgroundColor: "#EBEBEB",
										p: 1,
										mb: 2,
										pl: 2,
										pr: 2,
										boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
									}}
								>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Consumer User Name:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.consumerUser?.name} |</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Member Id:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.consumerUser?.id}</Typography>
								</Box>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid Email Address",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem" }}
											error={!!error}
											helperText={error && "Invalid email"}
											onChange={onChange}
											value={value}
											required
											id="email"
											label="Email"
											placeholder="jdoe@example.com"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: EmailMaskedInput as any,
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="phoneNumber"
									rules={{
										required: true,
										pattern: {
											value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
											message: "Invalid Phone Number",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem", pl: 2 }}
											onChange={onChange}
											value={value}
											error={!!error}
											helperText={error && "Invalid phone number"}
											required
											id="phoneNumber"
											label="Phone"
											placeholder="+1 (555) 555-5555"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: PhoneMaskedInput as any,
											}}
										/>
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="name"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="name" label="Name" />
									)}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Controller
									control={control}
									name="zipCode"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="zipCode" label="Zip Code" />
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="gender"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="gender" label="Gender" />
									)}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Controller
									control={control}
									name="dateOfBirth"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="dateOfBirth" label="Date Of Birth" />
									)}
								/>
							</Box>
						</Box>
						<br />
						<Box sx={{ display: "flex" }}>
							<Button type="submit">Save Changes</Button>
							<Button
								sx={{ ml: 2 }}
								onClick={() => {
									console.log("Starting password change");

									resetConsumerUserPassword(data?.consumerUser?.id!).then(passwordSuccessCallback, passwordFailureCallback);
								}}
							>
								Reset Password
							</Button>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

function ConsumerUserDetails() {
	let { id } = useParams();
	const { data } = useGetConsumerUserById(Number(id));

	return data !== undefined ? <ConsumerUserDetailsForm data={data} /> : null;
}

export default ConsumerUserDetails;
