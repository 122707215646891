import { CognitoUser, IAuthenticationCallback, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Dispatch } from "react";
import { userPool } from "./context";
import { FlowAction, FlowState, useFlow, DispatchRef, createUser } from "./flow";
import { special_instructions } from "shared/components/Redirecter";
import { redirect, useSearchParams } from "react-router-dom";

type AuthFlowStep = SignInStep | NewPasswordRequiredStep | AuthenticatedStep;
type AuthFlowAction = FlowAction<AuthFlowStep>;
export type AuthFlow = FlowState & AuthFlowStep;

export function useAuthFlow(): AuthFlow {
	const [searchParams] = useSearchParams();
	const signInRedirect = searchParams.get("redirect");
	return useFlow<AuthFlowStep>((dispatch, user) => signInStep(dispatch, signInRedirect, user));
}

function authCallbacks(dispatch: Dispatch<AuthFlowAction>, user: CognitoUser, signInRedirect: string | null): IAuthenticationCallback {
	return {
		onSuccess(session) {
			dispatch({ type: "session", user, session });
			dispatch({
				type: "next",
				step: authenticatedStep(dispatch, user),
			});
			if (signInRedirect) {
				redirect(JSON.parse(signInRedirect));
			}
		},
		onFailure(err) {
			dispatch({ type: "error", err });
		},
		newPasswordRequired() {
			dispatch({
				type: "next",
				step: newPasswordRequiredStep(dispatch, user),
			});
		},
	};
}

interface SignInStep {
	step: "SignIn";
	username: string | undefined;
	onSignIn: (username: string, password: string) => Promise<CognitoUser>;
}

function signInStep(dispatchRef: DispatchRef<AuthFlowAction>, signInRedirect: string | null, user = userPool.getCurrentUser()): SignInStep {
	return {
		step: "SignIn",
		username: user?.getUsername(),
		async onSignIn(username: string, password: string, target?: string) {
			const newUser = createUser(username);
			const dispatch = dispatchRef();
			dispatch({ type: "user", user: newUser });
			dispatch({ type: "pending" });

			const credentials = new AuthenticationDetails({
				Username: username,
				Password: password,
			});

			return new Promise((resolve) => {
				newUser.authenticateUser(
					credentials,
					authCallbacks(
						(x) => {
							dispatch(x);
							resolve(newUser);
						},
						newUser,
						signInRedirect
					)
				);
				if (target) {
					special_instructions.redirect(target);
				}
			});
		},
	};
}

interface AuthenticatedStep {
	step: "Authenticated";
	username: string;
	target?: string;
}

function authenticatedStep(_: Dispatch<AuthFlowAction>, user: CognitoUser): AuthenticatedStep {
	return {
		step: "Authenticated",
		username: user.getUsername(),
		target: special_instructions.target,
	};
}

interface NewPasswordRequiredStep {
	step: "NewPasswordRequired";
	onNewPassword: (password: string) => void;
}

function newPasswordRequiredStep(dispatch: Dispatch<AuthFlowAction>, user: CognitoUser): NewPasswordRequiredStep {
	return {
		step: "NewPasswordRequired",
		onNewPassword(password: string) {
			dispatch({ type: "pending" });
			user.completeNewPasswordChallenge(password, {}, authCallbacks(dispatch, user, null));
		},
	};
}

// ... there are some other possible steps for auth flow
