import React from "react";
import { Link } from "react-router-dom";
import { Box, Menu, MenuItem, ButtonBase, Stack } from "@mui/material";
import Icon from "../shared/Icon";
import { icons } from "../theme";

function MyProfileOptions() {
	/* Risk Anchor */
	const [anchorRiskEl, setAnchorRiskEl] = React.useState<null | HTMLElement>(null);
	const openRisk = Boolean(anchorRiskEl);
	const handleRiskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorRiskEl(event.currentTarget);
	};
	const handleRiskClose = () => {
		setAnchorRiskEl(null);
	};

	return (
		<Stack
			direction="row"
			sx={{
				height: "2.5rem",
				width: "2.5rem",
				borderRadius: "2.5rem",
				border: "2px solid #fff",
				backgroundColor: "gray.main",
				color: "gray.dark",
				boxShadow: 4,

				"& .MuiButtonBase-root": {
					height: "100%",
					px: 1,
					fontSize: ".85rem",

					"& svg": {
						color: "primary.main",
						fontSize: "1.25rem",
					},
				},
			}}
		>
			<Box>
				<ButtonBase
					id="risk-button"
					aria-controls="risk-menu"
					aria-haspopup="true"
					aria-expanded={openRisk ? "true" : undefined}
					onClick={handleRiskClick}
				>
					<Icon fixedWidth icon={icons.user} />
				</ButtonBase>
				<Menu
					id="risk-menu"
					anchorEl={anchorRiskEl}
					open={openRisk}
					onClose={handleRiskClose}
					MenuListProps={{
						"aria-labelledby": "risk-button",
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					sx={{
						"& a": {
							color: "gray.darker",
							display: "block",
							width: "100%",
							height: "100%",
						},
					}}
				>
					<MenuItem component={Link} to="/account/myAccount">
						My Account
					</MenuItem>
					<MenuItem component={Link} to="/signout">
						Logout
					</MenuItem>
				</Menu>
			</Box>
		</Stack>
	);
}

export default MyProfileOptions;
