type FollowedItemTypeDefinition = { DisplayName: string };

const FollowedItemTypeDefinitions: { [key in UserFollowedItemTypeNames]: FollowedItemTypeDefinition } = {
	DIAGNOSIS: { DisplayName: "Diagnosis" },
	DIAGNOSIS_RANGE: { DisplayName: "Diagnosis Range" },
	PRODUCTSERVICE: { DisplayName: "Procedure" },
	PATIENT: { DisplayName: "Member" },
	PROVIDER: { DisplayName: "Provider" },
	MEDICATION: { DisplayName: "Medication" },
	ORGANIZATION: { DisplayName: "Organization" }, // -- added 2023/01/05 not fully integrated to followed items
};

const FollowedItemTypes: Array<UserFollowedItemTypeNames> = ["DIAGNOSIS", "DIAGNOSIS_RANGE", "PRODUCTSERVICE", "PATIENT", "PROVIDER", "MEDICATION"];
export { FollowedItemTypeDefinitions, FollowedItemTypes };

function typeCheck_FollowedItemTypes(value: any): value is UserFollowedItemTypeNames {
	return ["PROVIDER", "DIAGNOSIS", "DIAGNOSIS_RANGE", "MEDICATION", "PRODUCTSERVICE", "PATIENT", "ORGANIZATION"].indexOf(value) !== -1;
}

export { typeCheck_FollowedItemTypes };
