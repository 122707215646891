import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { DoctorQueryDoctorBySearchTermQuery, ModelsNPIEnumerationType, queryDoctorBySearchTerm } from "api";
import { useQuery } from "react-query";

export const useX_getDoctorsBySearchTerm = (req: DoctorQueryDoctorBySearchTermQuery) => {
	let fqrX = useFilterQuery();

	let request: DoctorQueryDoctorBySearchTermQuery = {
		jobIds: fqrX.memberTypes.toString(),
		searchQuery: req.searchQuery,
		size: req.size,
		enumerationType: req.enumerationType ?? ModelsNPIEnumerationType.NUMBER_1,
	};

	let cacheKeys = ["queryDoctorBySearchTerm", "queryDoctorBySearchTerm_" + JSON.stringify(request)];
	return useQuery(cacheKeys, queryDoctorBySearchTerm.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
