import { Box } from "@mui/material";
import { DataGridPro, GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import { useContextUserFollowedItems } from "DataProvider/contexts/Context_UserFollowedItems";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { ModelsPaginationAndSortRequest, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts, RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyAggregation } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyAggregation";
import { useCallback, useMemo, useReducer, useState } from "react";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";
import { ReducerUtility } from "shared/utilities/ReducerUtility";
import { useQueryClient } from "react-query";

function FollowedCacheMonthlyType({ itemType, titleText }: { itemType: UserFollowedItemTypeNames; titleText?: string }) {
	const ufi = useContextUserFollowedItems();

	const queryClient = useQueryClient();
	const { values: pfValues } = usePrimaryFilters();

	const items = useMemo(() => {
		return ufi.itemsByType[itemType];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		itemType,
		//ufi.itemsByType
	]);

	const columns = useMemo(() => {
		return DataGridColumns.CacheMonthly(itemType);
	}, [itemType]);

	const [sortItem, set_sortItem] = useState<GridSortItem>({ field: "total_amount", sort: "desc" });

	const [paginationRequest, dispatch_paginationRequest] = useReducer(
		(x: ModelsPaginationAndSortRequest, action: Partial<ModelsPaginationAndSortRequest>) => {
			return ReducerUtility.UpdatePaginationRequestWithPartialValue(x, action);
		},
		{
			field: "total_amount",
			descending: true,
			skip: 0,
			take: 100,
		}
	);

	const followedCodes = useMemo(() => {
		if (items) {
			return items.map((i) => i.code);
		}
		return null;
	}, [items]);

	const queryRequest: RequestModelsCacheMonthlyQueryRequest = useMemo(() => {
		return {
			jobIds: [...pfValues.Members],
			// codes: ["1790070886", "1003201518"],
			codes: followedCodes,
			itemType: itemType,
			// paginationRequest: { field: "total_amount", descending: true, skip: 0, take: 50 },
			//paginationRequest: { field: "productservice_counts", descending: true, skip: 0, take: 50 },
			paginationRequest: paginationRequest,
			includeLookup: true,
			includeMissingItemsLookup: true,
		};
	}, [pfValues.Members, itemType, paginationRequest, followedCodes]);

	const apiResult = useX_getCacheMonthlyAggregation(queryRequest, followedCodes ? false : true);

	const itemsWithNoResult = useMemo(() => {
		console.log(apiResult.data?.response);
		if (apiResult.data?.response?.items && items) {
			let codesReturned = apiResult.data?.response?.buckets?.map((i) => i.code);
			let iwnr = items.filter((i) => codesReturned?.indexOf(i.code.split("||")[0]) === -1);
			return iwnr.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 0));
		}
	}, [apiResult.data?.response, items]);

	const toolbar = useCallback(
		(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) => {
			return <DataGridToolbar header={titleText} {...props} enableExport={false} />;
		},
		[titleText]
	);

	const [totalItemCount, buckets] = useMemo(() => {
		let b: Array<ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts> = [];
		if (apiResult.data?.response?.buckets) {
			b = [...apiResult.data.response.buckets];
			if (itemsWithNoResult) {
				b = [
					...b,
					...itemsWithNoResult.map((i) => {
						return {
							code: i.code.split("||")[0],

							periodDate: "N/A",
							// lookUpItem?: ModelsLookUpItem;
							jobIds: ["N/A"],
							eobCount: 0,
							totalAmount: 0,
							// diagnosis: [],
							// medication: [],
							// provider: [],
							// organization: [],
							// patient: [],
							// productservice: [],
						} as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
					}),
				];
			}
		}
		return [(apiResult?.data?.response?.totalItems ?? 0) + (itemsWithNoResult?.length ?? 0), b];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsWithNoResult, apiResult.data?.response?.buckets, apiResult?.data?.response?.totalItems]);

	const gridDisplay = () => {
		return apiResult?.data?.response?.buckets ? (
			// CLIENT SORTING
			// <DataGridPro
			// 	// columns={gridColumns_CacheMonthly_Basic}
			// 	columns={columns}
			// 	autoHeight
			// 	rowHeight={80}
			// 	getRowId={(row) => row.code}
			// 	rows={apiResult?.response?.buckets}
			// 	pagination
			// 	pageSize={10}
			// 	rowsPerPageOptions={[10]}
			// 	sx={{ pt: 2 }}
			// 	disableColumnFilter
			// 	disableColumnMenu
			// 	sortingMode="client"
			// />
			// SERVER SORTING

			<DataGridPro
				columns={columns}
				autoHeight
				rowHeight={80}
				getRowId={(row) => row.code}
				rows={buckets}
				pagination
				pageSize={10}
				rowsPerPageOptions={[10]}
				sx={{ pt: 2 }}
				disableColumnFilter
				disableColumnMenu
				sortingMode="server"
				sortModel={[sortItem]}
				rowCount={totalItemCount}
				onSortModelChange={(sm: GridSortModel) => {
					if (sm.length > 0) {
						dispatch_paginationRequest({ field: sm[0].field, descending: sm[0].sort === "desc" });
						set_sortItem(sm[0]);
					} else {
						// every third click calls with an empty sm array. It's to 'reset' the filters.... so apply this default and flip the sort if already applied
						let sortItem: GridSortItem = { field: "total_amount", sort: "desc" };
						if (paginationRequest.field === sortItem.field) {
							sortItem.sort = paginationRequest.descending ? "asc" : "desc";
						}
						dispatch_paginationRequest({ field: sortItem.field, descending: sortItem.sort === "desc" });
						set_sortItem(sortItem);
					}
				}}
				components={{ Toolbar: toolbar }}
			/>
		) : (
			<p>No Data To Display</p>
		);
	};

	return (
		<Box>
			<button
				style={{ display: "none" }}
				onClick={() => {
					queryClient.invalidateQueries(["getCacheMonthlyAggregation"]);
				}}
			>
				ReSend
			</button>

			<QueryStatusDisplay queryResult={apiResult} loaderSize="huge">
				{gridDisplay()}
			</QueryStatusDisplay>
		</Box>
	);
}

export { FollowedCacheMonthlyType };
