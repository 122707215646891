import { useAuthFlow } from "./auth/useAuthFlow";
import NewPasswordForm from "./forms/NewPasswordForm";
import SignInForm from "./forms/SignInForm";
import { Navigate } from "react-router-dom";

function SignInPage() {
	const flow = useAuthFlow();

	switch (flow.step) {
		case "SignIn":
			return <SignInForm {...flow} />;

		case "NewPasswordRequired":
			return <NewPasswordForm {...flow} />;

		case "Authenticated":
			console.log("AUTHENTICATED");
			return <Navigate replace to={flow.target ?? "/"} />;
	}
}

export { SignInPage };
