import {
	Box,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Button,
	Typography,
	Alert,
	TextField,
	Checkbox,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormGroup,
} from "@mui/material";
import { adminEditUser, resetUserPassword, useGetUserById } from "api/users/users";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useGetAllDistinctJobIds } from "../../api/overview/overview";
import { EmailMaskedInput, PhoneMaskedInput } from "shared/forms/InputMasks";
import { AdminGetUserByIdResult, Contact, Name } from "api";

interface EditUserProps {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	userRole: string;
	allowedInsuranceTypes: string[];
}

interface UserDetailsFormProps {
	data: AdminGetUserByIdResult;
}

function UserDetailsForm({ data }: UserDetailsFormProps) {
	const { enqueueSnackbar } = useSnackbar();

	let eobDataTypes = useGetAllDistinctJobIds();
	let allRoles = ["Administrator", "Limited"];

	const memberTypes = useMemo(() => {
		return eobDataTypes?.data?.dataTypes;
	}, [eobDataTypes?.data?.dataTypes]);

	const { control, handleSubmit } = useForm<EditUserProps>({
		defaultValues: {
			firstName: data?.user?.name?.first ?? "",
			lastName: data?.user?.name?.last ?? "",
			email: data?.user?.contact?.email ?? "",
			phoneNumber: data?.user?.contact?.phone ?? "",
			userRole: data?.user?.userRole ?? "Limited",
			allowedInsuranceTypes: data?.user?.insuranceTypes,
		},
	});

	const onSubmit = handleSubmit((form) => {
		const name: Name = {
			first: form.firstName,
			last: form.lastName,
		};
		const contact: Contact = {
			email: form.email,
			phone: form.phoneNumber,
		};
		adminEditUser(data?.user?.id!, {
			name: name,
			contact: contact,
			userRole: form.userRole,
			insuranceTypes: form.allowedInsuranceTypes,
		}).then(successCallback, failureCallback);
	});

	function successCallback() {
		enqueueSnackbar("User Updated", {
			variant: "success",
		});
	}

	function failureCallback() {
		enqueueSnackbar("Error while saving changes", {
			variant: "error",
		});
	}

	function passwordSuccessCallback() {
		enqueueSnackbar("Password Reset Successful", {
			variant: "success",
		});
	}

	function passwordFailureCallback() {
		enqueueSnackbar("Error while resetting user password", {
			variant: "error",
		});
	}
	return (
		<Box sx={{ width: "100%" }} onSubmit={onSubmit} component="form">
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardHeader title="Edit User" />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "grid", height: "100%" }}>
							<Box sx={{ display: "flex", height: "100%" }}>
								<Box
									sx={{
										display: "flex",
										borderRadius: "1.5rem",
										backgroundColor: "#EBEBEB",
										p: 1,
										mb: 2,
										pl: 2,
										pr: 2,
										boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
									}}
								>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Username:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.user?.username} |</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Member Id:</Typography>
									<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>{data?.user?.id}</Typography>
								</Box>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid Email Address",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem" }}
											error={!!error}
											helperText={error && "Invalid email"}
											onChange={onChange}
											value={value}
											required
											id="email"
											label="Email"
											placeholder="jdoe@example.com"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: EmailMaskedInput as any,
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name="phoneNumber"
									rules={{
										required: true,
										pattern: {
											value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
											message: "Invalid Phone Number",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextField
											sx={{ width: "20rem", pl: 2 }}
											onChange={onChange}
											value={value}
											error={!!error}
											helperText={error && "Invalid phone number"}
											required
											id="phoneNumber"
											label="Phone"
											placeholder="+1 (555) 555-5555"
											inputProps={{ unmask: true }}
											InputProps={{
												inputComponent: PhoneMaskedInput as any,
											}}
										/>
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", pb: 2 }}>
								<Controller
									control={control}
									name="firstName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem" }} onChange={onChange} value={value} id="firstName" label="First Name" />
									)}
								/>
								<Controller
									control={control}
									name="lastName"
									render={({ field: { onChange, value } }) => (
										<TextField sx={{ width: "20rem", pl: 2 }} onChange={onChange} value={value} id="lastName" label="Last Name" />
									)}
								/>
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column", pb: 2, width: "6rem" }}>
								<Controller
									name="userRole"
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											<FormLabel id="user-role-label">User Role</FormLabel>

											<RadioGroup onChange={onChange} value={value} aria-labelledby="user-role-label" name="radio-buttons-group">
												{allRoles.map((item) => {
													return <FormControlLabel key={item} value={item} control={<Radio />} label={item} />;
												})}
											</RadioGroup>

											{error && <Alert severity="error">{error.message}</Alert>}
										</>
									)}
								/>
							</Box>
							{memberTypes && (
								<>
									<FormLabel id="insurance-types-label">Insurance Types</FormLabel>
									<FormGroup aria-labelledby="insurance-types-label" sx={{ width: "4rem" }}>
										<Controller
											name="allowedInsuranceTypes"
											control={control}
											render={({ field }) => (
												<>
													{memberTypes.map((item) => {
														return (
															<FormControlLabel
																key={item}
																control={
																	<Checkbox
																		checked={field.value.some((x) => x === item)}
																		value={item}
																		onChange={(event, checked) => {
																			if (checked) {
																				field.onChange([...field.value, event.target.value]);
																			} else {
																				field.onChange(field.value.filter((value) => value !== event.target.value));
																			}
																		}}
																	/>
																}
																label={item}
															/>
														);
													})}
												</>
											)}
										/>
									</FormGroup>
								</>
							)}
						</Box>
						<br />
						<Box sx={{ display: "flex" }}>
							<Button type="submit">Save Changes</Button>
							<Button
								sx={{ ml: 2 }}
								onClick={() => {
									resetUserPassword(data?.user?.id!).then(passwordSuccessCallback, passwordFailureCallback);
								}}
							>
								Reset Password
							</Button>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	);
}

function UserDetails() {
	let { username } = useParams();
	const { data } = useGetUserById(Number(username));

	return data !== undefined ? <UserDetailsForm data={data} /> : null;
}

export default UserDetails;
