import { ReactNode } from "react";
import { ButtonBase, Typography, Box, Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";
import { Link, useLocation, useMatch } from "react-router-dom";
import { palette } from "../../theme";

interface Props {
	label: string;
	icon: ReactNode;
	to: string;
	additionalMatches?: Array<string>;
}

const NavTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: palette.primary.main,
		color: "#fff",
		boxShadow: theme.shadows[4],
		fontSize: "1rem",
		p: 2,
		fontWeight: 400,
		textTransform: "Uppercase",
	},
}));

function NavMenuItem({ label, icon, to, additionalMatches }: Props) {
	const location = useLocation();
	const partialPathname = location.pathname.split("/")[1];
	let match: any = useMatch({
		path: to,
	});
	if (!match && additionalMatches !== undefined) {
		match = additionalMatches.some((x) => x === partialPathname);
	}

	return (
		<ButtonBase
			component={Link}
			to={to}
			className={match ? "active" : undefined}
			sx={{
				pl: 0,
				pr: 1,
				py: 0,
				mt: 1,
				mb: 1,
				ml: 1,
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
				height: "3rem",
				fontSize: "1.5rem",
				color: "#fff",
				"&:hover": {
					color: "secondary.main",
				},
				"&:hover .icon": {
					color: "secondary.main",
				},
				"&.active:hover .icon": {
					color: "primary.main",
				},
				"&.active, &.active:hover": {
					color: { xs: "secondary.main", sm: "primary.main" },
					backgroundColor: { xs: "none", sm: "gray.light" },
					borderRadius: "4rem 0 0 4rem",
				},
				"&.active::before": {
					content: `''`,
					position: "absolute",
					display: { xs: "none", sm: "block" },
					top: "-1.5rem",
					right: "0",
					width: "1.5rem",
					height: "1.5rem",
					borderRadius: "50%",
					backgroundColor: "Transparent",
					boxShadow: "1rem 1rem 0 .125rem #F9F9F9",
				},
				"&.active::after": {
					content: `''`,
					position: "absolute",
					display: { xs: "none", sm: "block" },
					bottom: "-1.5rem",
					right: "0",
					width: "1.5rem",
					height: "1.5rem",
					borderRadius: "50%",
					backgroundColor: "Transparent",
					boxShadow: "1rem -1rem 0 .125rem #F9F9F9",
				},
			}}
		>
			<NavTooltip title={label} placement="right">
				<Box className="icon-container">{icon}</Box>
			</NavTooltip>
			<Typography component="div" sx={{ flex: "1" }}>
				{label}
			</Typography>
		</ButtonBase>
	);
}

export default NavMenuItem;
