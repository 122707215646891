import { ModelsOperationStatus } from "api";

type ProcessedResponse<T> = {
	data: T;
	success: boolean;
	errorMessage: string;
	errorType: ProcessedResponseErrorType;
	errorCode: ProcessedResponseErrorCode;
	responseType: "ProcessedResponse";
	_debug?: any;
};

type ProcessedResponseErrorCode = null | "GENERIC" | "NO-REQUEST" | "AXIOS-ERROR" | "NO_CARE_PLAN";
type ProcessedResponseErrorType = null | "GENERIC" | "POSITIVE" | "NEUTRAL" | "LOGICAL" | "TRANSACTIONAL";

const apiResponseReader = {
	processResponse: <T2>(response: T2): ProcessedResponse<T2> => {
		// console.debug("processResponse", response);

		// if response is already a ProcessedResponse
		if (apiResponseReader.typeCheck_ProcessedResponse(response)) {
			return response;
		}

		let processedResponse: ProcessedResponse<T2> = {
			data: response,
			success: true,
			errorMessage: "",
			errorType: null,
			errorCode: null,
			responseType: "ProcessedResponse",
		};

		if ((response as any).response) {
			response = (response as any).response;
		}
		let response_: any = response as any;

		// console.debug("response", response);

		if (apiResponseReader.typeCheck_ModelsOperationStatus(response_)) {
			// alert(JSON.stringify(response_, null, 4));
			processedResponse.success = response_.success;
			processedResponse.errorMessage = response_.errorMessage ?? "";
			// processedResponse.errorType = response_.errorType ?? null;
			processedResponse.success = response_.success;
		}

		if (typeof response_ === "object") {
			if (response_?.errorCode === "NO_CARE_PLAN") {
				Object.assign(processedResponse, response_);
				return processedResponse;
			}

			if (response_?.errorResult && (response_.errorResult?.errorType || response_?.errorMessage)) {
				processedResponse.success = false;
				processedResponse.errorMessage = response_?.errorResult?.errorMessage ?? "The request could not be completed";
				processedResponse.errorType = response_?.errorResult?.errorType;
			} else if (response_?.isAxiosError) {
				processedResponse.success = false;
				processedResponse.errorCode = "AXIOS-ERROR";
				processedResponse.errorType = "TRANSACTIONAL";
				processedResponse.errorMessage = response_?.errorResult?.errorMessage ?? "The request could not be completed";
			}
		} else {
			// Status/Error Conditions
			if ((response as any) === "NO-REQUEST") {
				processedResponse.errorMessage = "";
				processedResponse.errorType = "NEUTRAL";
				processedResponse.errorCode = "NO-REQUEST";
				processedResponse.success = false;
			}
		}
		return processedResponse;
	},

	typeCheck_ModelsOperationStatus: (responseData: any): responseData is ModelsOperationStatus => {
		if (responseData && responseData.hasOwnProperty("responseType") && responseData.responseType === "OperationStatus") {
			return true;
		}
		return false;
	},

	typeCheck_ProcessedResponse: (responseData: any): responseData is ProcessedResponse<any> => {
		if (responseData && responseData.hasOwnProperty("responseType") && responseData.responseType === "ProcessedResponse") {
			return true;
		}
		return false;
	},

	createProcessedResponse_NOREQUEST: <T>(errorMessage?: string): ProcessedResponse<T | null> => {
		return apiResponseReader.createProcessedResponse({
			errorMessage: errorMessage,
			errorCode: "NO-REQUEST",
			errorType: "NEUTRAL",
		});
	},

	createProcessedResponse_FAIL: <T>(
		errorMessage?: string,
		errorCode?: ProcessedResponseErrorCode,
		errorType?: ProcessedResponseErrorType
	): ProcessedResponse<T | null> => {
		return apiResponseReader.createProcessedResponse({
			errorMessage: errorMessage,
			errorCode: errorCode ?? "GENERIC",
			errorType: errorType ?? "GENERIC",
		});
	},

	createProcessedResponse: <T>(config?: {
		data?: T;
		success?: boolean;
		errorMessage?: string;
		errorCode?: ProcessedResponseErrorCode;
		errorType?: ProcessedResponseErrorType;
		_debug?: any;
	}): ProcessedResponse<T | null> => {
		let data = config?.data !== undefined ? config.data : null;
		let success = config?.success !== undefined ? config.success : false;
		let errorMessage = config?.errorMessage ?? "The request could not be completed";
		let errorType = config?.errorType ?? "GENERIC";
		let errorCode = config?.errorCode ?? "GENERIC";
		return {
			data: data,
			success: success,
			errorMessage: success ? "" : errorMessage,
			errorType: success ? null : errorType,
			errorCode: success ? null : errorCode,
			responseType: "ProcessedResponse",
			_debug: config?._debug,
		};
	},
};

export { apiResponseReader };
export type { ProcessedResponse, ProcessedResponseErrorType };
