import React from "react";
import { Alert, Typography } from "@mui/material";
import useFormErrors from "./useFormErrors";

interface Props {
  label?: string;
}

function FormErrorAlert({ label }: Props) {
  const errors = useFormErrors();

  if (!errors.isError) return null;

  const { message } = errors;

  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {label || "Your request could not be completed."}
      </Typography>
      <Typography variant="body2">{message}</Typography>
    </Alert>
  );
}

export default FormErrorAlert;
