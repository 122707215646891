import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useQueryString from "shared/useQueryString";
import { FormErrorAlert, SubmitButton } from "shared/forms";

function InvitationLandingPage() {
  const { register, handleSubmit } = useForm<{
    email: string;
  }>();

  const { query } = useQueryString();
  const code = query.get("code") ?? undefined;
  const onSubmit = handleSubmit(
    (form) =>
      (window.location.href = `/reset-password?username=${form.email}?code=${code}`)
  );

  return (
    <Card
      component="form"
      onSubmit={onSubmit}
      sx={{ maxWidth: 400, width: "100%" }}
      elevation={4}
    >
      <CardHeader title="Reset Password" />
      <CardContent>
        <Stack spacing={2}>
          <TextField label="Email" {...register("email", { required: true })} />
        </Stack>
      </CardContent>
      <FormErrorAlert />
      <CardActions sx={{ justifyContent: "center" }}>
        <SubmitButton sx={{ mx: 1 }}>Continue</SubmitButton>
        <Button sx={{ mx: 1 }} component={Link} to="/signin" variant="text">
          Cancel
        </Button>
      </CardActions>
    </Card>
  );
}

export default InvitationLandingPage;
