import { Navigate, Route, Routes } from "react-router-dom";
import { CacheToolsRouter } from "./cache_tools/CacheToolsRouterMenu";
import { DeveloperTestArea } from "./DeveloperTestArea";

const DeveloperRouter = () => {
	return (
		<div>
			<Routes>
				<Route path="/cacheTools/*" element={<CacheToolsRouter />} />
				<Route path="/testArea/*" element={<DeveloperTestArea />} />
				<Route path="*" element={<Navigate to="/overview" />} />
			</Routes>
		</div>
	);
};

export { DeveloperRouter };
