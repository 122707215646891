import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { DiagnosisNotificationDetails } from "./hooks/useDiagnosisNotificationDetails";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

const columns: GridColumns = [
	{
		field: "MemberID",
		headerName: "Member ID",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Age",
		headerName: "Age",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Gender",
		headerName: "Gender",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Provider",
		headerName: "Provider",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Doctor",
		headerName: "Doctor",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Viewed",
		headerName: "Viewed",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "Conversion",
		headerName: "Conversion",
		headerAlign: "left",
		align: "left",
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Notifications Recipients"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

type PanelDiagnosisNotificationRecipientsProps = {
	data?: DiagnosisNotificationDetails;
};

function PanelDiagnosisNotificationRecipients(props: PanelDiagnosisNotificationRecipientsProps) {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("recipients", columns);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}
	return (
		<Box sx={{ display: "flex" }}>
			<Box
				sx={{
					flexGrow: 1,
				}}
			>
				{props.data ? (
					<DataGridPro
						{...grid}
						columns={columns}
						autoHeight
						rows={props.data?.recipient_data}
						components={{ Toolbar: Toolbar }}
						componentsProps={{
							toolbar: {
								onSearch: (searchString: string) => {
									onSearchEntered(searchString);
								},
								initialSearch: query.searchQuery ?? "",
							},
						}}
					/>
				) : null}
			</Box>
		</Box>
	);
}

export default PanelDiagnosisNotificationRecipients;
