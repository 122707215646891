import React from "react";
import { Box, Typography } from "@mui/material";
import { DiagnosisNotificationSummary } from "./DiagnosisNotificationSummary";
import {
	useDiagnosisDetails_MemberNotifications,
	MemberNotification,
} from "../sections/diagnosis/hooks/diagnosisDetails/useDiagnosisDetailsMemberNotifications";
type DiagnosisNotificationListProps = {
	diagnosisID: number | string;
};

function dateToMonthText(date: string | Date) {
	let d = new Date(date);
	let f = new Intl.DateTimeFormat("default", { month: "long" });
	let m = f.format(d);
	return m;
}
function DiagnosisNotificationList(props: DiagnosisNotificationListProps) {
	const query = useDiagnosisDetails_MemberNotifications({
		diagnosisID: props.diagnosisID,
	});

	if (query.data) {
		const ordered = query.data.sort((a, b) => {
			return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
		});
		const grouped: Array<Array<Array<MemberNotification>>> = [[[]]];
		let prev_year: any, prev_month: any;
		ordered.forEach((n) => {
			// group into [year][month][notification] - likely will not be used with real data
			// can be removed for pre-sorted/grouped data or hardened and moved elsewhere
			let [year, month, day]: [number, number, number] = n.date.split("/").map((x) => {
				return parseInt(x);
			}) as unknown as [number, number, number];
			if (!year || !month || !day) {
				return;
			}
			if (!prev_year) prev_year = year;
			if (!prev_month) prev_month = month;
			if (prev_year !== year) {
				grouped[grouped.length] = [];
				prev_year = year;
				grouped[grouped.length - 1][grouped[grouped.length - 1].length] = [];
				prev_month = month;
			} else if (prev_month !== month) {
				grouped[grouped.length - 1][grouped[grouped.length - 1].length] = [];
				prev_month = month;
			}
			grouped[grouped.length - 1][grouped[grouped.length - 1].length - 1].push(n);
		});
		return (
			<Box>
				{grouped.map((y, yi) => {
					return grouped[yi].map((m, mi) => {
						return (
							<React.Fragment key={mi}>
								<Box
									sx={{
										backgroundColor: "gray.main",
										p: 1,
										textAlign: "center",
									}}
								>
									<Typography fontWeight={700}>{dateToMonthText(m[0].date) + " " + m[0]?.date.split("/")[0]}</Typography>
								</Box>
								<Box>
									{m.map((notification, ni) => {
										return <DiagnosisNotificationSummary memberNotification={notification} key={ni} />;
									})}
								</Box>
							</React.Fragment>
						);
					});
				})}
			</Box>
		);
	} else {
		return null;
	}
}

export { DiagnosisNotificationList };
