import { useContext } from "react";
import { PrimaryFilters, PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { ProvidersRiskModelFilterCriteria } from "api/backend.schemas";
import { getFilterDates } from "./useRunFilterQuery";

const usePrimaryFilters = () => {
	return useContext(PrimaryFiltersContext);
};

const usePrimaryFiltersProvidersRiskModelFilterCriteria = () => {
	return constructProvidersRiskModelFilterCriteria(useContext(PrimaryFiltersContext).values);
};

const constructProvidersRiskModelFilterCriteria = (primaryFilters: PrimaryFilters): ProvidersRiskModelFilterCriteria | null => {
	if (!primaryFilters.MembersInitialized) {
		return null;
	}

	let dates = getFilterDates(primaryFilters);
	const filterCriteria: ProvidersRiskModelFilterCriteria = {
		memberTypes: primaryFilters.Members,
		riskModel: primaryFilters.RiskModel,
		startDate: dates.start,
		endDate: dates.end,
	};

	return filterCriteria;
};
export { usePrimaryFilters, constructProvidersRiskModelFilterCriteria, usePrimaryFiltersProvidersRiskModelFilterCriteria };
