import { palette } from "theme";

const chart_colors: { default: string; [key: string]: string } = {
	male: palette.green.main,
	female: palette.purple.main,
	default: palette.gray.dark,
};

const ChartColorHelper = {
	colorArrayForNameValuesData(data_array: Array<{ name: string }>) {
		let colorsArray: Array<string> = [];
		data_array.forEach((el) => {
			colorsArray.push(chart_colors[el.name.toLowerCase()] ?? chart_colors["default"]);
		});
		return colorsArray;
	},
};

export { ChartColorHelper, chart_colors };
