import { RevenueOverview } from "./RevenueOverview";
import { RevenueServices } from "./RevenueServices";
import { Route, Routes, Navigate } from "react-router-dom";

function RevenueRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<Navigate to={`overview`} />} />
			<Route path={`overview/*`} element={<RevenueOverview />} />
			<Route path={`services/*`} element={<RevenueServices />} />
		</Routes>
	);
}

export { RevenueRoutes };
