/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  MedicationGetConditionsByMedicationResult,
  MedicationGetConditionsByMedicationQuery,
  MedicationGetProceduresByMedicationsResult,
  MedicationGetProceduresByMedicationsQuery,
  MedicationGetMedicationImpactResult,
  MedicationGetMedicationImpactQuery,
  AdminGetMedicationRangeAggregationsResult,
  AdminGetMedicationRangeAggregationsQuery,
  AdminGetMedicationAggregationsResult,
  RequestModelsCacheMonthlyQueryRequest,
  AdminGetMedicationRangeGenderAndAgeResult,
  AdminGetMedicationDropdownListResult,
  GetMedicationDropdownListParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getConditionsByMedication = (
    medicationGetConditionsByMedicationQuery: MedicationGetConditionsByMedicationQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<MedicationGetConditionsByMedicationResult>(
      {url: `/api/admin/CacheMedicationData/GetConditionsByMedication`, method: 'post',
      data: medicationGetConditionsByMedicationQuery
    },
      options);
    }
  


    export const useGetConditionsByMedication = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionsByMedication>, TError,{data: MedicationGetConditionsByMedicationQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionsByMedication>, {data: MedicationGetConditionsByMedicationQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionsByMedication(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionsByMedication>, TError, {data: MedicationGetConditionsByMedicationQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProceduresByMedication = (
    medicationGetProceduresByMedicationsQuery: MedicationGetProceduresByMedicationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<MedicationGetProceduresByMedicationsResult>(
      {url: `/api/admin/CacheMedicationData/GetProceduresByMedication`, method: 'post',
      data: medicationGetProceduresByMedicationsQuery
    },
      options);
    }
  


    export const useGetProceduresByMedication = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProceduresByMedication>, TError,{data: MedicationGetProceduresByMedicationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProceduresByMedication>, {data: MedicationGetProceduresByMedicationsQuery}> = (props) => {
          const {data} = props || {};

          return  getProceduresByMedication(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProceduresByMedication>, TError, {data: MedicationGetProceduresByMedicationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationImpact = (
    medicationGetMedicationImpactQuery: MedicationGetMedicationImpactQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<MedicationGetMedicationImpactResult>(
      {url: `/api/admin/CacheMedicationData/GetMedicationImpact`, method: 'post',
      data: medicationGetMedicationImpactQuery
    },
      options);
    }
  


    export const useGetMedicationImpact = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getMedicationImpact>, TError,{data: MedicationGetMedicationImpactQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getMedicationImpact>, {data: MedicationGetMedicationImpactQuery}> = (props) => {
          const {data} = props || {};

          return  getMedicationImpact(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getMedicationImpact>, TError, {data: MedicationGetMedicationImpactQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationRangeAggregations = (
    adminGetMedicationRangeAggregationsQuery: AdminGetMedicationRangeAggregationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetMedicationRangeAggregationsResult>(
      {url: `/api/admin/CacheMedicationData/GetMedicationRangeAggregations`, method: 'post',
      data: adminGetMedicationRangeAggregationsQuery
    },
      options);
    }
  


    export const useGetMedicationRangeAggregations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getMedicationRangeAggregations>, TError,{data: AdminGetMedicationRangeAggregationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getMedicationRangeAggregations>, {data: AdminGetMedicationRangeAggregationsQuery}> = (props) => {
          const {data} = props || {};

          return  getMedicationRangeAggregations(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getMedicationRangeAggregations>, TError, {data: AdminGetMedicationRangeAggregationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationAggregations = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetMedicationAggregationsResult>(
      {url: `/api/admin/CacheMedicationData/GetMedicationAggregations`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetMedicationAggregations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getMedicationAggregations>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getMedicationAggregations>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getMedicationAggregations(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getMedicationAggregations>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationGenderAndAge = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetMedicationRangeGenderAndAgeResult>(
      {url: `/api/admin/CacheMedicationData/GetMedicationGenderAndAge`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetMedicationGenderAndAge = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getMedicationGenderAndAge>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getMedicationGenderAndAge>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getMedicationGenderAndAge(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getMedicationGenderAndAge>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationDropdownList = (
    params?: GetMedicationDropdownListParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetMedicationDropdownListResult>(
      {url: `/api/admin/CacheMedicationData/GetMedicationDropdownList`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetMedicationDropdownListQueryKey = (params?: GetMedicationDropdownListParams,) => [`/api/admin/CacheMedicationData/GetMedicationDropdownList`, ...(params ? [params]: [])];

    
export const useGetMedicationDropdownList = <TData = AsyncReturnType<typeof getMedicationDropdownList>, TError = unknown>(
 params?: GetMedicationDropdownListParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getMedicationDropdownList>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetMedicationDropdownListQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getMedicationDropdownList>> = () => getMedicationDropdownList(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getMedicationDropdownList>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

