/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetConditionsByProductServiceResult,
  AdminGetConditionsByProductServiceQuery,
  AdminGetProductServiceImpactResult,
  AdminGetProductServiceImpactQuery,
  AdminGetProductServiceDetailsMedicationsResult,
  AdminGetProductServiceDetailsMedicationsQuery,
  AdminGetProductServiceDetailsPatientsResult,
  AdminGetProductServiceDetailsPatientsQuery,
  AdminGetProductServiceAggregationsResult,
  AdminGetProductServiceAggregationsQuery,
  AdminGetProductServiceGenderAndAgeResult,
  RequestModelsCacheMonthlyQueryRequest,
  AdminGetProductServiceDropdownListResult,
  GetProductServiceDropdownListParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getConditionsByProductService = (
    adminGetConditionsByProductServiceQuery: AdminGetConditionsByProductServiceQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionsByProductServiceResult>(
      {url: `/api/admin/_CacheProductServiceData/GetConditionsByProductService`, method: 'post',
      data: adminGetConditionsByProductServiceQuery
    },
      options);
    }
  


    export const useGetConditionsByProductService = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionsByProductService>, TError,{data: AdminGetConditionsByProductServiceQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionsByProductService>, {data: AdminGetConditionsByProductServiceQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionsByProductService(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionsByProductService>, TError, {data: AdminGetConditionsByProductServiceQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceImpact = (
    adminGetProductServiceImpactQuery: AdminGetProductServiceImpactQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceImpactResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceImpact`, method: 'post',
      data: adminGetProductServiceImpactQuery
    },
      options);
    }
  


    export const useGetProductServiceImpact = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServiceImpact>, TError,{data: AdminGetProductServiceImpactQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServiceImpact>, {data: AdminGetProductServiceImpactQuery}> = (props) => {
          const {data} = props || {};

          return  getProductServiceImpact(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServiceImpact>, TError, {data: AdminGetProductServiceImpactQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceDetailsMedications = (
    adminGetProductServiceDetailsMedicationsQuery: AdminGetProductServiceDetailsMedicationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceDetailsMedicationsResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceDetailsMedications`, method: 'post',
      data: adminGetProductServiceDetailsMedicationsQuery
    },
      options);
    }
  


    export const useGetProductServiceDetailsMedications = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServiceDetailsMedications>, TError,{data: AdminGetProductServiceDetailsMedicationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServiceDetailsMedications>, {data: AdminGetProductServiceDetailsMedicationsQuery}> = (props) => {
          const {data} = props || {};

          return  getProductServiceDetailsMedications(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServiceDetailsMedications>, TError, {data: AdminGetProductServiceDetailsMedicationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceDetailsPatients = (
    adminGetProductServiceDetailsPatientsQuery: AdminGetProductServiceDetailsPatientsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceDetailsPatientsResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceDetailsPatients`, method: 'post',
      data: adminGetProductServiceDetailsPatientsQuery
    },
      options);
    }
  


    export const useGetProductServiceDetailsPatients = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServiceDetailsPatients>, TError,{data: AdminGetProductServiceDetailsPatientsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServiceDetailsPatients>, {data: AdminGetProductServiceDetailsPatientsQuery}> = (props) => {
          const {data} = props || {};

          return  getProductServiceDetailsPatients(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServiceDetailsPatients>, TError, {data: AdminGetProductServiceDetailsPatientsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceAggregations = (
    adminGetProductServiceAggregationsQuery: AdminGetProductServiceAggregationsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceAggregationsResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceAggregations`, method: 'post',
      data: adminGetProductServiceAggregationsQuery
    },
      options);
    }
  


    export const useGetProductServiceAggregations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServiceAggregations>, TError,{data: AdminGetProductServiceAggregationsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServiceAggregations>, {data: AdminGetProductServiceAggregationsQuery}> = (props) => {
          const {data} = props || {};

          return  getProductServiceAggregations(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServiceAggregations>, TError, {data: AdminGetProductServiceAggregationsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceGenderAndAge = (
    requestModelsCacheMonthlyQueryRequest: RequestModelsCacheMonthlyQueryRequest,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceGenderAndAgeResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceGenderAndAge`, method: 'post',
      data: requestModelsCacheMonthlyQueryRequest
    },
      options);
    }
  


    export const useGetProductServiceGenderAndAge = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getProductServiceGenderAndAge>, TError,{data: RequestModelsCacheMonthlyQueryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getProductServiceGenderAndAge>, {data: RequestModelsCacheMonthlyQueryRequest}> = (props) => {
          const {data} = props || {};

          return  getProductServiceGenderAndAge(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getProductServiceGenderAndAge>, TError, {data: RequestModelsCacheMonthlyQueryRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getProductServiceDropdownList = (
    params?: GetProductServiceDropdownListParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetProductServiceDropdownListResult>(
      {url: `/api/admin/_CacheProductServiceData/GetProductServiceDropdownList`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetProductServiceDropdownListQueryKey = (params?: GetProductServiceDropdownListParams,) => [`/api/admin/_CacheProductServiceData/GetProductServiceDropdownList`, ...(params ? [params]: [])];

    
export const useGetProductServiceDropdownList = <TData = AsyncReturnType<typeof getProductServiceDropdownList>, TError = unknown>(
 params?: GetProductServiceDropdownListParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProductServiceDropdownList>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProductServiceDropdownListQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProductServiceDropdownList>> = () => getProductServiceDropdownList(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getProductServiceDropdownList>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

