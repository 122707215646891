import { atom, useAtom, useAtomValue } from "jotai";
import { useCallback, useState } from "react";

const debounceInterval = 300;
export const searchTextAtom = atom("");

/** Provides the debounced text value of the search input.  */
export function useSearchText() {
	return useAtomValue(searchTextAtom);
}

/** Provides the immediate text value of the search input. */
export function useSearchInput() {
	const [searchText, setSearchText] = useAtom(searchTextAtom);
	const [value, setValue] = useState(searchText);

	const [handle, setHandle] = useState(0);

	const setValueDebounced = useCallback(
		(next: string) => {
			if (handle) clearTimeout(handle);

			setValue(next);
			setHandle(+setTimeout(() => setSearchText(next), debounceInterval));
		},
		[handle, setSearchText]
	);

	return { value, setValue: setValueDebounced };
}
