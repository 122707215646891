import { GridColumns, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { ColumnsConstruct_CacheMonthly } from "./dataGridLayouts/gridColumns_cacheMonthly";

import { CSSProperties, ReactNode } from "react";
import { Box } from "@mui/material";
import { palette } from "theme";
import { ColumnsConstruct_CacheNestedItems } from "shared/utilities/DataGridColumns/dataGridLayouts/gridColumns_cacheNestedItems";
import { ColumnConstruct_CacheMonthlyDetails } from "./dataGridLayouts/gridColumns_cacheMonthlyDetails";

const DataGridColumns = {
	CacheMonthly: ColumnsConstruct_CacheMonthly,
	CacheMonthlyDetails: ColumnConstruct_CacheMonthlyDetails,
	CacheNestedItems: ColumnsConstruct_CacheNestedItems,
};

//THESE ARE THE BASIC UTILITIES

function DataGridColumnsOverRide(columns: GridColumns, overrides: Array<Partial<GridEnrichedColDef>>) {
	let o: Partial<GridEnrichedColDef> | null = null;
	return columns.map((column) => {
		o = null;
		overrides.some((override) => {
			if (override.field === column.field) {
				o = override;
				return true;
			}
			return false;
		});
		if (o) {
			let new_ = Object.assign({}, column, o);
			return new_;
		}
		return column;
	});
}

function DataGridColumnsDelete(columns: GridColumns, deleteFields: Array<string>) {
	return columns.filter((column) => {
		if (deleteFields.indexOf(column.field) !== -1) {
			return false;
		}
		return true;
	});
}

function DataGridColumnsAdd(columns: GridColumns, addColumn: GridEnrichedColDef, index = 0) {
	let columnsX = [...columns.slice(0, index), addColumn, ...columns.slice(index)];
	return columnsX;
}

function DataGridColumnsShuffle(columns: GridColumns, shuffle: Array<[number, number]>) {
	// give array of [position, moveToPosition] and it will re-order the columns
	let columnsX = columns;
	shuffle.forEach((shuf) => {
		let c = columnsX.splice(shuf[0], 1);
		columnsX.splice(shuf[1], 0, c[0]);
	});

	return columnsX;
}

const DataGridStyles = {
	standard_custom: {
		"& .MuiDataGrid-columnHeaderTitle": {
			whiteSpace: "normal",
			lineHeight: "1.2rem",
			wordBreak: "break-word",
		},
		"& .MuiDataGrid-cell": {
			overflowY: "hidden",
		},
	},
	ScrollableCellContainer: (props: { children: ReactNode; irony?: boolean }) => {
		return (
			<Box
				onClick={(ev) => {
					if (ev.nativeEvent?.target) {
						(ev.nativeEvent?.target as CIM).focus();
					}
				}}
				tabIndex={0}
				sx={
					props.irony
						? {}
						: {
								height: "100%",
								maxHeight: "100%",
								width: "100%",
								boxSizing: "border-box",
								overflowY: "auto",
								overflowX: "hidden",
								"&:focus-within": {
									overflowY: "scroll",
									paddingTop: "2rem",
									paddingBottom: "2rem",
								},
						  }
				}
			>
				<Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", minHeight: "100%" }}>
					{props.children}
				</Box>
			</Box>
		);
	},
	wordBreak: {
		overflowWrap: "break-word",
		wordWrap: "break-word",
		whiteSpace: "normal",
		paddingTop: "2px",
		paddingBottom: "2px",
	},
	wordBreakAndLineSeperator: (first: boolean, last: boolean): CSSProperties => {
		return {
			...DataGridStyles.wordBreak,
			...(first ? null : { borderTopWidth: "1px", borderTopColor: "white", borderTopStyle: "solid" }),
			...(last ? null : { borderBottomWidth: "1px", borderBottomColor: palette.gray.main, borderBottomStyle: "solid" }),
		} as CSSProperties;
	},
};

export { DataGridColumns, DataGridColumnsOverRide, DataGridColumnsDelete, DataGridColumnsAdd, DataGridColumnsShuffle, DataGridStyles };
