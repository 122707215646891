import { Box } from "@mui/material";
import { icons, palette } from "../../../theme";
import SummaryTile from "../../../shared/components/SummaryTile";
import { displayConverter } from "../../../shared/utilities/displayConverter";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { RequestModelsCacheMonthlyQueryRequest } from "api";
import { Loader } from "shared/components/Loaders/Loader";
import { useX_getSpendSummaryCharts } from "api_extension/useQueryX/cacheMonthly/useX_getSpendGraphModels";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";

SwiperCore.use([Pagination]);

function RevenueOverviewAnalytics() {
	const queryRequest: RequestModelsCacheMonthlyQueryRequest = {};
	const apiResult = useX_getSpendSummaryCharts(queryRequest);
	let data = apiResult.data?.response;

	return (
		<>
			{data ? (
				<Box>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, xl: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								"700": {
									slidesPerView: 1,
								},
								"1200": {
									slidesPerView: 3,
								},
								"1900": {
									slidesPerView: 3,
								},
							}}
						>
							{data?.diagnosisSummaryGraph?.graph && (
								<SwiperSlide>
									<SummaryTile
										title="Condition Spend"
										dataKey="value"
										data={data?.diagnosisSummaryGraph?.graph.map((x) => {
											return {
												value: x.value as string | number,
												name: x.name as string,
											};
										})}
										graphPointLabel="Conditions"
										primaryValue={
											data.diagnosisSummaryGraph.total === 0
												? "No Data Available"
												: displayConverter.toUSD(data.diagnosisSummaryGraph.total)
										}
										icon={icons.calendar}
										color={palette.red.main}
										backgroundColor={palette.gray.main}
										tooltipFormat={(value: number) => displayConverter.toUSD(value)}
									></SummaryTile>
								</SwiperSlide>
							)}
							{data?.productserviceSummaryGraph?.graph && (
								<SwiperSlide>
									<SummaryTile
										title="Procedure Spend"
										dataKey="value"
										data={data?.productserviceSummaryGraph?.graph.map((x) => {
											return {
												value: x.value as string | number,
												name: x.name as string,
											};
										})}
										graphPointLabel="Procedures"
										primaryValue={
											data.productserviceSummaryGraph.total === 0
												? "No Data Available"
												: displayConverter.toUSD(data.productserviceSummaryGraph.total)
										}
										icon={icons.procedure}
										color={palette.lightBlue.main}
										tooltipFormat={(value: number) => displayConverter.toUSD(value)}
									></SummaryTile>
								</SwiperSlide>
							)}
							{data?.medicationSummaryGraph?.graph && (
								<SwiperSlide>
									<SummaryTile
										title="Prescription Spend"
										dataKey="value"
										data={data?.medicationSummaryGraph?.graph.map((x) => {
											return {
												value: x.value as string | number,
												name: x.name as string,
											};
										})}
										graphPointLabel="Prescriptions"
										primaryValue={
											data.medicationSummaryGraph.total === 0
												? "No Data Available"
												: displayConverter.toUSD(data.medicationSummaryGraph.total)
										}
										icon={icons.prescription}
										color={palette.purple.main}
										tooltipFormat={(value: number) => displayConverter.toUSD(value)}
									></SummaryTile>
								</SwiperSlide>
							)}
						</Swiper>
					</Box>
					<ResendQueryButton queryCacheName="getSpendSummaryCharts" />
				</Box>
			) : (
				<Loader />
			)}
		</>
	);
}
export default RevenueOverviewAnalytics;
