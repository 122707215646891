import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import Icon from "../Icon";
import { icons } from "../../theme";

interface FullWidthDialogProps {
  open: boolean;
  handleClose: any;
  component: JSX.Element;
  title: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide timeout={8000} direction="up" ref={ref} {...props} />;
});

function FullWidthDialog(props: FullWidthDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen={true}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            {props.title}
          </Typography>
          <ButtonBase
            onClick={props.handleClose}
            sx={{
              height: "2.5rem",
              width: "2.5rem",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "gray.main",
              color: "gray.dark",
              boxShadow: 4,

              "& svg": {
                color: "primary.main",
                fontSize: "1.25rem",
              },
            }}
          >
            <Icon fixedWidth icon={icons.close} />
          </ButtonBase>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex" }}>{props.component}</DialogContent>
    </Dialog>
  );
}

export default FullWidthDialog;
