const randomizeDemoData = (data: Array<any>) => {
  data.forEach((item: any) => {
    if (typeof item === "object") {
      let keys = Object.getOwnPropertyNames(item);
      keys.forEach((key) => {
        if (
          typeof item[key] === "number" &&
          key.indexOf("ID") < key.length - 2
        ) {
          item[key] = randomizeValue(item[key]);
        }
      });
    }
  });
};

const randomizeValue = (value: number) => {
  if (value < 1) {
    value += Math.random() / 2;
    if (value > 1) {
      value = 1;
    }
    return value.toFixed(2);
  } else {
    return Math.floor((Math.random() + 0.25) * value);
  }
};

export { randomizeDemoData, randomizeValue };
