import React from "react";
import useQueryString from "shared/useQueryString";
import { useResetPasswordFlow } from "./auth/useForgotPasswordFlow";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import { useSnackbar } from "notistack";
import { Navigate } from "react-router-dom";

function ResetPasswordPage() {
	const { query } = useQueryString();
	const username = query.get("username");
	const code = query.get("code") ?? undefined;
	const { enqueueSnackbar } = useSnackbar();
	if (!username) throw new Error("Username is required.");

	const flow = useResetPasswordFlow(username, enqueueSnackbar);

	switch (flow.step) {
		case "ResetPasswordStep":
			return <ResetPasswordForm {...flow} verificationCode={code} />;

		case "ForgotPasswordCompleteStep":
			return <Navigate to="/" />;

		default:
			return <Navigate to="/forgot-password" />;
	}
}

export default ResetPasswordPage;
