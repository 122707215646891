import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { RequestModelsCacheMonthlyDetailsQueryRequest, retrieveTopCodes } from "api";
import { useQuery } from "react-query";

function retrieveTopCodesX(request: RequestModelsCacheMonthlyDetailsQueryRequest) {
	console.log({ request });
	return retrieveTopCodes(request);
}

const useX_retrieveTopCodes = (request_: RequestModelsCacheMonthlyDetailsQueryRequest | null) => {
	let filterQ = useFilterQuery();
	let r = Object.assign({}, filterQ, request_);

	let request: RequestModelsCacheMonthlyDetailsQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes: r.codes,
		nestedCodes: r.nestedCodes,
		paginationRequest: r.paginationRequest,
		itemType: r.itemType,
		includeLookup: r.includeLookup,
		codeMonthAggregations: r.codeMonthAggregations,
		codeAggregations: r.codeAggregations,
	};

	let cacheKeys = ["retrieveTopCodes", "retrieveTopCodes_" + JSON.stringify(request)];

	return useQuery(cacheKeys, retrieveTopCodesX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_retrieveTopCodes };
