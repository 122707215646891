import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { CachePatientDataGetTotalMembersResult, getTotalMembers } from "api";
import { createContext, useEffect, useMemo, useState } from "react";

type BasicDateRangeDates = { start: null | Date; end: null | Date };
type BasicDateRangeStrings = { start: null | string; end: null | string };
type UserMetrics = {
	InsuranceTypeMembersCount: { [key: string]: number };
};

let initial_user_metrics: CachePatientDataGetTotalMembersResult = {
	insuranceTypeMembersCount: {},
};

const UserMetricsContext = createContext({
	values: initial_user_metrics,
});

function Context_UserMetrics_Provider(props: Readonly<{ children: React.ReactNode }>) {
	const [userMetrics, setUserMetrics] = useState<CachePatientDataGetTotalMembersResult>(initial_user_metrics);
	let filters = useFilterQuery();

	useEffect(() => {
		async function fetchData() {
			const totalMembers = await getTotalMembers({
				dateRangeRequest: { startDate: filters.startDate, endDate: filters.endDate },
				jobIds: filters.memberTypes,
			});
			setUserMetrics(totalMembers);
		}
		fetchData();
	}, [filters.startDate, filters.endDate, filters.memberTypes]);

	const providerValue = useMemo(() => {
		return {
			values: userMetrics,
		};
	}, [userMetrics]);

	return <UserMetricsContext.Provider value={providerValue}>{props.children}</UserMetricsContext.Provider>;
}

export { UserMetricsContext, Context_UserMetrics_Provider as ContextUserMetricsProvider };

export type { UserMetrics, BasicDateRangeDates, BasicDateRangeStrings };
