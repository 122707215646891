import Axios, { AxiosRequestConfig } from "axios";

import getRuntimeConfig from "../config";
import { apiResponseReader } from "shared/utilities/apiResponseReader";
const { APIConfig } = getRuntimeConfig();

type TokenRef = () => Promise<string | undefined>;
const unauthenticated: TokenRef = () => Promise.resolve(undefined);

let getToken: TokenRef = unauthenticated;

export function setJwtToken(tokenRef: TokenRef) {
	getToken = tokenRef;
}

export function clearJwtToken() {
	getToken = unauthenticated;
}

export const AXIOS_INSTANCE = Axios.create({
	baseURL: APIConfig.BaseUrl,
});

AXIOS_INSTANCE.interceptors.request.use(async (config) => {
	const token = await getToken();
	if (token && config.headers) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export const apiRequest = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = Axios.CancelToken.source();
	const promise = AXIOS_INSTANCE({
		...config,
		...(options ?? {}),
		cancelToken: source.token,
	})
		.then(({ data }) => {
			return data;
		})
		.catch((error) => {
			if (typeof error.response === "object" && error.response?.data) {
				if (apiResponseReader.typeCheck_ModelsOperationStatus(error.response.data)) {
					return error.response;
				}
			}
			return error;
		});

	// @ts-expect-error
	promise.cancel = source.cancel;
	return promise;
};
