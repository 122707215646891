import { createContext, useContext, useMemo } from "react";
import { useCPTCodes, useConditions, useICD10Codes, useICD10PCSCodes } from "../../shared/hooks/useDiagnosisCodes";
import { Condition, PackagedICD10Codes, PackagedCPTCodes, PackagedICD10PCSCodes } from "../../shared/schemas/dataStructures";
import { UseQueryResult } from "react-query";
import { useFHIRCodeSetValues } from "shared/hooks/useFHIRCodeSetValues";
import { FullscreenLoader } from "shared/components/Loaders/FullscreenLoader";

interface DataProviderProps {
	children: React.ReactNode;
}

/*
██████  ██  █████   ██████  ███    ██  ██████  ███████ ██ ███████      ██████  ██████  ██████  ███████ ███████ 
██   ██ ██ ██   ██ ██       ████   ██ ██    ██ ██      ██ ██          ██      ██    ██ ██   ██ ██      ██      
██   ██ ██ ███████ ██   ███ ██ ██  ██ ██    ██ ███████ ██ ███████     ██      ██    ██ ██   ██ █████   ███████ 
██   ██ ██ ██   ██ ██    ██ ██  ██ ██ ██    ██      ██ ██      ██     ██      ██    ██ ██   ██ ██           ██ 
██████  ██ ██   ██  ██████  ██   ████  ██████  ███████ ██ ███████      ██████  ██████  ██████  ███████ ███████
*/
const ContextCodeDefinitions = createContext<{
	ICD10: UseQueryResult<PackagedICD10Codes> | null;
	CPT: UseQueryResult<PackagedCPTCodes> | null;
	Conditions: UseQueryResult<Array<Condition>> | null;
}>({ ICD10: null, CPT: null, Conditions: null });

const diagnosisCodes: {
	ICD10: PackagedICD10Codes;
	ICD10PCS: PackagedICD10PCSCodes;
	CPT: PackagedCPTCodes;
	Conditions: Array<Condition>;
} = {
	ICD10: undefined,
	ICD10PCS: undefined,
	CPT: undefined,
	Conditions: undefined,
} as unknown as {
	ICD10: PackagedICD10Codes;
	ICD10PCS: PackagedICD10PCSCodes;
	CPT: PackagedCPTCodes;
	Conditions: Array<Condition>;
};

if (window.RadiusCare?.data) {
	window.RadiusCare.data.diagnosisCodes = diagnosisCodes;
}

const codingData: {
	FHIRCodeSetValues: { [key: string]: any };
} = {
	FHIRCodeSetValues: undefined,
} as unknown as {
	FHIRCodeSetValues: Map<string, { name: string; value_map: Map<string, string> }>;
};

function Context_CodeDefinitions_Provider(props: DataProviderProps) {
	const cptcodes = useCPTCodes();
	const icd10codes = useICD10Codes();
	const icd10PCScodes = useICD10PCSCodes();
	const conditions = useConditions();
	const FHIRCodeSetValues = useFHIRCodeSetValues();
	// diagnosisCodes (static data) can be accessed outside of react components
	if (!diagnosisCodes.ICD10 && icd10codes.data) {
		diagnosisCodes.ICD10 = icd10codes.data;
	}
	if (!diagnosisCodes.CPT && cptcodes.data) {
		diagnosisCodes.CPT = cptcodes.data;
	}
	console.log({ conditions_data: conditions.data, conditions: conditions });
	if (!diagnosisCodes.Conditions && conditions.data) {
		diagnosisCodes.Conditions = conditions.data;
	}
	if (!diagnosisCodes.ICD10PCS && icd10PCScodes.data) {
		diagnosisCodes.ICD10PCS = icd10PCScodes.data;
	}
	if (!codingData.FHIRCodeSetValues && FHIRCodeSetValues.data) {
		codingData.FHIRCodeSetValues = FHIRCodeSetValues.data;
	}

	let providerParams = useMemo(() => {
		return { ICD10: icd10codes, CPT: cptcodes, Conditions: conditions };
	}, [icd10codes, cptcodes, conditions]);

	// console.debug({
	// 	cptcodes,
	// 	icd10codes,
	// 	icd10PCScodes,
	// 	conditions,
	// 	FHIRCodeSetValues,
	// });
	// console.debug({ diagnosisCodes, codingData });

	console.log({
		icd10: diagnosisCodes.ICD10,
		cpt: diagnosisCodes.CPT,
		conditions: diagnosisCodes.Conditions,
		fhir_setValues: codingData.FHIRCodeSetValues,
	});
	return (
		<ContextCodeDefinitions.Provider value={providerParams}>
			{diagnosisCodes.ICD10 && diagnosisCodes.CPT && diagnosisCodes.Conditions && codingData.FHIRCodeSetValues ? (
				props.children
			) : (
				<>
					<FullscreenLoader />
					{!conditions.isFetching && !conditions.isLoading && conditions.data === undefined ? (
						<div style={{ display: "flex", margin: "0rem 1rem", alignItems: "center" }}>
							<p style={{ padding: "0rem 1rem" }}>There was a problem retrieving data definitions</p>
							<button
								onClick={() => {
									window.location.reload();
								}}
							>
								Reload
							</button>
						</div>
					) : null}
				</>
			)}
		</ContextCodeDefinitions.Provider>
	);
}

export { ContextCodeDefinitions, Context_CodeDefinitions_Provider as ContextCodeDefinitionsProvider, diagnosisCodes, codingData };

const useContextCodeDefinitions = () => {
	return useContext(ContextCodeDefinitions);
};

export { useContextCodeDefinitions };
