/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetConditionsListResult,
  AdminGetConditionsListQuery,
  AdminGetTopLevelRangesResult,
  AdminGetSubLevelRangesResult,
  GetSubLevelRangesParams,
  AdminGetIcdCodesFromRangesResult,
  AdminGetConditionDataResult,
  AdminGetConditionDataQuery,
  AdminGetConditionDetailsResult,
  AdminGetConditionDetailsQuery,
  AdminGetConditionDetailsPatientsResult,
  AdminGetConditionDetailsPatientsQuery,
  AdminGetConditionDetailsDoctorsResult,
  AdminGetConditionDetailsDoctorsQuery,
  AdminGetConditionDetailsServicesResult,
  AdminGetConditionDetailsServicesQuery,
  AdminGetConditionDetailsImpactResult,
  AdminGetConditionDetailsImpactQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllConditions = (
    adminGetConditionsListQuery: AdminGetConditionsListQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionsListResult>(
      {url: `/api/admin/Conditions/GetAll`, method: 'post',
      data: adminGetConditionsListQuery
    },
      options);
    }
  


    export const useGetAllConditions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getAllConditions>, TError,{data: AdminGetConditionsListQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getAllConditions>, {data: AdminGetConditionsListQuery}> = (props) => {
          const {data} = props || {};

          return  getAllConditions(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getAllConditions>, TError, {data: AdminGetConditionsListQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getTopLevelRanges = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetTopLevelRangesResult>(
      {url: `/api/admin/Conditions/GetTopLevelRanges`, method: 'get'
    },
      options);
    }
  

export const getGetTopLevelRangesQueryKey = () => [`/api/admin/Conditions/GetTopLevelRanges`];

    
export const useGetTopLevelRanges = <TData = AsyncReturnType<typeof getTopLevelRanges>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTopLevelRanges>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTopLevelRangesQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTopLevelRanges>> = () => getTopLevelRanges(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTopLevelRanges>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getSubLevelRanges = (
    params?: GetSubLevelRangesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetSubLevelRangesResult>(
      {url: `/api/admin/Conditions/GetSubLevelRanges`, method: 'post',
        params,
    },
      options);
    }
  


    export const useGetSubLevelRanges = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getSubLevelRanges>, TError,{params?: GetSubLevelRangesParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getSubLevelRanges>, {params?: GetSubLevelRangesParams}> = (props) => {
          const {params} = props || {};

          return  getSubLevelRanges(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getSubLevelRanges>, TError, {params?: GetSubLevelRangesParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getIcdCodesFromRanges = (
    getIcdCodesFromRangesBody: string[],
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetIcdCodesFromRangesResult>(
      {url: `/api/admin/Conditions/GetIcdCodesFromRanges`, method: 'post',
      data: getIcdCodesFromRangesBody
    },
      options);
    }
  


    export const useGetIcdCodesFromRanges = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getIcdCodesFromRanges>, TError,{data: string[]}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getIcdCodesFromRanges>, {data: string[]}> = (props) => {
          const {data} = props || {};

          return  getIcdCodesFromRanges(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getIcdCodesFromRanges>, TError, {data: string[]}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionData = (
    adminGetConditionDataQuery: AdminGetConditionDataQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDataResult>(
      {url: `/api/admin/Conditions/GetData`, method: 'post',
      data: adminGetConditionDataQuery
    },
      options);
    }
  


    export const useGetConditionData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionData>, TError,{data: AdminGetConditionDataQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionData>, {data: AdminGetConditionDataQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionData(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionData>, TError, {data: AdminGetConditionDataQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionDetails = (
    adminGetConditionDetailsQuery: AdminGetConditionDetailsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDetailsResult>(
      {url: `/api/admin/Conditions/GetConditionDetails`, method: 'post',
      data: adminGetConditionDetailsQuery
    },
      options);
    }
  


    export const useGetConditionDetails = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionDetails>, TError,{data: AdminGetConditionDetailsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionDetails>, {data: AdminGetConditionDetailsQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionDetails(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionDetails>, TError, {data: AdminGetConditionDetailsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionDetailsPatients = (
    adminGetConditionDetailsPatientsQuery: AdminGetConditionDetailsPatientsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDetailsPatientsResult>(
      {url: `/api/admin/Conditions/GetDetailsPatients`, method: 'post',
      data: adminGetConditionDetailsPatientsQuery
    },
      options);
    }
  


    export const useGetConditionDetailsPatients = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionDetailsPatients>, TError,{data: AdminGetConditionDetailsPatientsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionDetailsPatients>, {data: AdminGetConditionDetailsPatientsQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionDetailsPatients(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionDetailsPatients>, TError, {data: AdminGetConditionDetailsPatientsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionDetailsDoctors = (
    adminGetConditionDetailsDoctorsQuery: AdminGetConditionDetailsDoctorsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDetailsDoctorsResult>(
      {url: `/api/admin/Conditions/GetDetailsDoctors`, method: 'post',
      data: adminGetConditionDetailsDoctorsQuery
    },
      options);
    }
  


    export const useGetConditionDetailsDoctors = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionDetailsDoctors>, TError,{data: AdminGetConditionDetailsDoctorsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionDetailsDoctors>, {data: AdminGetConditionDetailsDoctorsQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionDetailsDoctors(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionDetailsDoctors>, TError, {data: AdminGetConditionDetailsDoctorsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionDetailsServices = (
    adminGetConditionDetailsServicesQuery: AdminGetConditionDetailsServicesQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDetailsServicesResult>(
      {url: `/api/admin/Conditions/GetDetailsServices`, method: 'post',
      data: adminGetConditionDetailsServicesQuery
    },
      options);
    }
  


    export const useGetConditionDetailsServices = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionDetailsServices>, TError,{data: AdminGetConditionDetailsServicesQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionDetailsServices>, {data: AdminGetConditionDetailsServicesQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionDetailsServices(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionDetailsServices>, TError, {data: AdminGetConditionDetailsServicesQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getConditionDetailsImpact = (
    adminGetConditionDetailsImpactQuery: AdminGetConditionDetailsImpactQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConditionDetailsImpactResult>(
      {url: `/api/admin/Conditions/GetDetailsImpact`, method: 'post',
      data: adminGetConditionDetailsImpactQuery
    },
      options);
    }
  


    export const useGetConditionDetailsImpact = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getConditionDetailsImpact>, TError,{data: AdminGetConditionDetailsImpactQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getConditionDetailsImpact>, {data: AdminGetConditionDetailsImpactQuery}> = (props) => {
          const {data} = props || {};

          return  getConditionDetailsImpact(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getConditionDetailsImpact>, TError, {data: AdminGetConditionDetailsImpactQuery}, TContext>(mutationFn, mutationOptions)
    }
    