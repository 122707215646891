import { Box, Stack, Typography } from "@mui/material";
import Icon from "../Icon";
import { icons } from "../../theme";
import { usePrimaryFilters } from "../../DataProvider/hooks/useContextPrimaryFilters";
import { DropDownSelect } from "../components/DropDownSelect";
import { AccountManagerContext } from "DataProvider/contexts/AccountManager";
import { useContext } from "react";
import { DateRangeDropDown } from "shared/components/DateRangeFilters/DateRangeDropDown";

function SiteFilterData() {
	let primaryFilters = usePrimaryFilters();
	let user = useContext(AccountManagerContext);
	const dataTypes = user.accountManager?.userDetails?.user?.insuranceTypes;

	let selectedMembers = primaryFilters.values.Members;
	if (!primaryFilters.values.MembersInitialized && dataTypes && dataTypes.length) {
		primaryFilters.values.MembersInitialized = true;
		primaryFilters.values.Members = Object.assign([], dataTypes); //clone array so remove op doesn't affect API data
	}

	return (
		<>
			<Stack
				data-component_name={SiteFilterData.name}
				direction="row"
				alignItems="center"
				sx={{
					"& .filter-label": {
						display: {
							xs: "none",
							md: "inline-block",
						},
					},
				}}
			>
				<Typography className="filter-label" component="span" sx={{ fontWeight: "700", pr: 1 }}>
					Filter Data:
				</Typography>
				<Stack
					direction="row"
					spacing="1"
					sx={{
						height: "2.5rem",
						borderRadius: "2.5rem",
						border: "2px solid #fff",
						backgroundColor: "gray.main",
						px: 1.5,
						color: "gray.dark",
						boxShadow: 4,

						"& .MuiButtonBase-root": {
							height: "100%",
							px: 1,
							fontSize: ".85rem",

							"& svg": {
								color: "primary.main",
								fontSize: "1.25rem",
							},

							"& > .MuiBox-root": {
								display: {
									xs: "none",
									lg: "block",
								},
							},
						},
					}}
				>
					<DropDownSelect
						title="Risk Model"
						icon={<Icon fixedWidth icon={icons.riskScore} />}
						type="radio"
						options={[
							{ value: "TestRiskModel", label: "Test Risk Model" },
							{ value: "RadiusCare", label: "TestRiskModel: RadiusCare" },
						]}
						ariaLabel="RiskModel"
						selectedValues={[primaryFilters.values.RiskModel]}
						setValues={(v: Array<string>) => {
							primaryFilters.set_PrimaryFiltersValues({ RiskModel: v[0] ?? null });
						}}
					/>

					<DropDownSelect
						title="Members"
						icon={<Icon fixedWidth icon={icons.users} />}
						handleChange={(event: CIM, value: Array<string>, selected?: boolean) => {
							if (!primaryFilters.values.Members) {
								primaryFilters.values.Members = [];
							}

							const existLoc: number = primaryFilters.values.Members.indexOf(value[0], 0);

							if (selected) {
								if (existLoc === -1) primaryFilters.values.Members.push(value[0]);
							} else {
								if (existLoc > -1) primaryFilters.values.Members.splice(existLoc, 1);
							}
						}}
						type="checkbox"
						options={dataTypes?.map((item) => {
							return { value: item, label: item };
						})}
						selectedValues={selectedMembers}
						setValues={(v: Array<string>) => {
							console.log("DropDownSelect>Members", v);
							primaryFilters.set_PrimaryFiltersValues({ Members: v });
						}}
					/>

					<DateRangeDropDown
						ariaLabel="Date"
						selectedValues={primaryFilters.values.DateRange}
						initialStartDate={primaryFilters.values.DateRangeCustomDates.start}
						initialEndDate={primaryFilters.values.DateRangeCustomDates.end}
						setValues={(v: Array<string>) => {
							console.debug("setValues DateRangeDropDown argument");
							primaryFilters.set_PrimaryFiltersValues({ DateRange: v });
						}}
					/>
				</Stack>
			</Stack>
			<Box
				style={{
					display: "none",
				}}
			>
				<Box style={{ display: "flex" /*flex*/, flexDirection: "row" }}>
					<Typography> {primaryFilters.values.DateRangeCustomDates.start?.toDateString()}</Typography>&nbsp;&nbsp;-&nbsp;&nbsp;
					<Typography> {primaryFilters.values.DateRangeCustomDates.end?.toDateString()}</Typography>
				</Box>
				<Box style={{ display: "flex" /*flex*/, flexDirection: "row" }}>
					<Typography> {primaryFilters.values.DateRangeLimits.start_limit?.toDateString()}</Typography>&nbsp;&nbsp;-&nbsp;&nbsp;
					<Typography> {primaryFilters.values.DateRangeLimits.end_limit?.toDateString()}</Typography>
				</Box>
			</Box>
		</>
	);
}

export { SiteFilterData };
