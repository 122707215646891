import { FilterQueryRequest, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetProductServiceAggregationsQuery, getProductServiceAggregations } from "api";
import { useQuery } from "react-query";

function getProductServiceAggregationsX(request: Partial<AdminGetProductServiceAggregationsQuery>) {
	return getProductServiceAggregations(request);
}

const useX_getProductServiceAggregations = (
	codes: Array<string>,
	fqr?: Partial<FilterQueryRequest>,
	includeItemLookup: boolean = false,
	sortOrderRequest: SortOrderRequest | null = null
) => {
	let fqrX = { ...useFilterQuery(), ...fqr };

	let request: AdminGetProductServiceAggregationsQuery = {
		startDate: fqrX.startDate,
		endDate: fqrX.endDate,
		jobIds: fqrX.memberTypes,
		codes: codes,
		includeItemLookup: includeItemLookup,
	};
	if (sortOrderRequest) {
		request.sortBy = sortOrderRequest.sortBy;
		request.orderBy = sortOrderRequest.orderBy;
	}

	let cacheKeys = ["getProductServiceAggregations", "getProductServiceAggregations_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getProductServiceAggregationsX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getProductServiceAggregations };
