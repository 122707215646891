import { Route, Routes, Link, useLocation } from "react-router-dom";
import { PatientCacheTools } from "./patient/PatientCacheTools";
import { TreatmentCacheTools } from "./TreatmentCacheTools";
import { RiskModelCacheTools } from "./risk_models/RiskModelCacheTools";
import { DoctorCacheTools } from "./doctor/DoctorCacheTools";
import { DiagnosisCacheTools } from "./diagnosis/DiagnosisCacheTools";
import { useCallback } from "react";
import { MedicationCacheTools } from "./medication/MedicationCacheTools";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*













*/
const CacheToolsRouter = () => {
	return (
		<>
			<CacheToolsMenu />
			<Routes>
				<Route path="/riskModel" element={<RiskModelCacheTools />} />
				<Route path="/patient" element={<PatientCacheTools />} />
				<Route path="/doctor" element={<DoctorCacheTools />} />
				<Route path="/diagnosis" element={<DiagnosisCacheTools />} />
				<Route path="/treatment" element={<TreatmentCacheTools />} />
				<Route path="/medication" element={<MedicationCacheTools />} />
			</Routes>
		</>
	);
};

export { CacheToolsRouter };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*













*/
const linkStyle = { textDecoration: "none", color: "blue", fontWeight: "bold" };
const divStyle = { border: "solid black 2px", margin: "8px", padding: "8px" };
const activeLinkStyle = { background: "rgb(200,200,220)" };
const CacheToolsMenu = () => {
	const location = useLocation();
	console.log(location);

	const activePath = location.pathname.toLowerCase().split("/cachetools/")[1]?.split("/")[0];

	const checkIfActivePath = useCallback(
		(path: string) => {
			if (path === activePath) {
				return activeLinkStyle;
			}
			return {};
		},
		[activePath]
	);

	console.log(activePath);
	return (
		<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
			<div style={{ ...divStyle, ...checkIfActivePath("riskmodel") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/riskModel"}>
					Risk-Model
				</Link>
			</div>
			<div style={{ ...divStyle, ...checkIfActivePath("patient") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/patient"}>
					Patient
				</Link>
			</div>
			<div style={{ ...divStyle, ...checkIfActivePath("doctor") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/doctor"}>
					Doctor
				</Link>
			</div>
			<div style={{ ...divStyle, ...checkIfActivePath("diagnosis") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/diagnosis"}>
					Diagnosis
				</Link>
			</div>
			<div style={{ ...divStyle, ...checkIfActivePath("treatment") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/treatment"}>
					Treatment
				</Link>
			</div>
			<div style={{ ...divStyle, ...checkIfActivePath("medication") }}>
				<Link style={linkStyle} to={"/developer/cacheTools/medication"}>
					Medication
				</Link>
			</div>
		</div>
	);
};

export { CacheToolsMenu };
