/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerAddConsumerUserResult,
  ConsumerAddConsumerUserForm,
  ConsumerEditConsumerUserInfoResult,
  ConsumerEditConsumerUserInfoForm,
  ConsumerEditAlertPreferencesResult,
  EditAlertPreferencesParams,
  ConsumerDismissUserAlertResult,
  DismissUserAlertParams,
  ConsumerEditConsumerUserLocationResult,
  EditConsumerUserLocationParams,
  ConsumerOperationStatusUpdateConsumerUserPreferences,
  ConsumerUpdateConsumerUserPreferencesRequestModel,
  ConsumerSendUserFeedbackResult,
  SendUserFeedbackParams,
  ConsumerConfirmVerificationCodeNotSignedInResult,
  ConsumerConfirmVerificationCodeNotSignedInForm,
  ConsumerResendVerificationCodeResult,
  ConsumerResendVerificationCodeCommand,
  ProvidersConfigDebugReport,
  GetConfigDebugReportParams,
  ConsumerGetConsumerUserDetailsResult,
  ConsumerGetAllConsumerUsersResult,
  GetAllConsumerUsersParams,
  AdminGetConsumerUserByIdResult,
  AdminConsumerUserResetPasswordResult
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const addConsumerUser = (
    consumerAddConsumerUserForm: ConsumerAddConsumerUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerAddConsumerUserResult>(
      {url: `/api/consumer/ConsumerUsers`, method: 'put',
      data: consumerAddConsumerUserForm
    },
      options);
    }
  


    export const useAddConsumerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addConsumerUser>, TError,{data: ConsumerAddConsumerUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addConsumerUser>, {data: ConsumerAddConsumerUserForm}> = (props) => {
          const {data} = props || {};

          return  addConsumerUser(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addConsumerUser>, TError, {data: ConsumerAddConsumerUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const editConsumerUser = (
    consumerEditConsumerUserInfoForm: ConsumerEditConsumerUserInfoForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerEditConsumerUserInfoResult>(
      {url: `/api/consumer/ConsumerUsers/EditConsumerUser`, method: 'post',
      data: consumerEditConsumerUserInfoForm
    },
      options);
    }
  


    export const useEditConsumerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof editConsumerUser>, TError,{data: ConsumerEditConsumerUserInfoForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof editConsumerUser>, {data: ConsumerEditConsumerUserInfoForm}> = (props) => {
          const {data} = props || {};

          return  editConsumerUser(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof editConsumerUser>, TError, {data: ConsumerEditConsumerUserInfoForm}, TContext>(mutationFn, mutationOptions)
    }
    export const editAlertPreferences = (
    params?: EditAlertPreferencesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerEditAlertPreferencesResult>(
      {url: `/api/consumer/ConsumerUsers/EditAlertPreferences`, method: 'post',
        params,
    },
      options);
    }
  


    export const useEditAlertPreferences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof editAlertPreferences>, TError,{params?: EditAlertPreferencesParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof editAlertPreferences>, {params?: EditAlertPreferencesParams}> = (props) => {
          const {params} = props || {};

          return  editAlertPreferences(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof editAlertPreferences>, TError, {params?: EditAlertPreferencesParams}, TContext>(mutationFn, mutationOptions)
    }
    export const dismissUserAlert = (
    params?: DismissUserAlertParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerDismissUserAlertResult>(
      {url: `/api/consumer/ConsumerUsers/DismissUserAlert`, method: 'post',
        params,
    },
      options);
    }
  


    export const useDismissUserAlert = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof dismissUserAlert>, TError,{params?: DismissUserAlertParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof dismissUserAlert>, {params?: DismissUserAlertParams}> = (props) => {
          const {params} = props || {};

          return  dismissUserAlert(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof dismissUserAlert>, TError, {params?: DismissUserAlertParams}, TContext>(mutationFn, mutationOptions)
    }
    export const editConsumerUserLocation = (
    params?: EditConsumerUserLocationParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerEditConsumerUserLocationResult>(
      {url: `/api/consumer/ConsumerUsers/EditConsumerUserLocation`, method: 'post',
        params,
    },
      options);
    }
  


    export const useEditConsumerUserLocation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof editConsumerUserLocation>, TError,{params?: EditConsumerUserLocationParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof editConsumerUserLocation>, {params?: EditConsumerUserLocationParams}> = (props) => {
          const {params} = props || {};

          return  editConsumerUserLocation(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof editConsumerUserLocation>, TError, {params?: EditConsumerUserLocationParams}, TContext>(mutationFn, mutationOptions)
    }
    export const updateConsumerUserPreferences = (
    consumerUpdateConsumerUserPreferencesRequestModel: ConsumerUpdateConsumerUserPreferencesRequestModel,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerOperationStatusUpdateConsumerUserPreferences>(
      {url: `/api/consumer/ConsumerUsers/UpdateConsumerUserPreferences`, method: 'post',
      data: consumerUpdateConsumerUserPreferencesRequestModel
    },
      options);
    }
  


    export const useUpdateConsumerUserPreferences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateConsumerUserPreferences>, TError,{data: ConsumerUpdateConsumerUserPreferencesRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateConsumerUserPreferences>, {data: ConsumerUpdateConsumerUserPreferencesRequestModel}> = (props) => {
          const {data} = props || {};

          return  updateConsumerUserPreferences(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateConsumerUserPreferences>, TError, {data: ConsumerUpdateConsumerUserPreferencesRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const sendUserFeedback = (
    params?: SendUserFeedbackParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerSendUserFeedbackResult>(
      {url: `/api/consumer/ConsumerUsers/SendUserFeedback`, method: 'post',
        params,
    },
      options);
    }
  


    export const useSendUserFeedback = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof sendUserFeedback>, TError,{params?: SendUserFeedbackParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof sendUserFeedback>, {params?: SendUserFeedbackParams}> = (props) => {
          const {params} = props || {};

          return  sendUserFeedback(params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof sendUserFeedback>, TError, {params?: SendUserFeedbackParams}, TContext>(mutationFn, mutationOptions)
    }
    export const confirmVerificationCode = (
    consumerConfirmVerificationCodeNotSignedInForm: ConsumerConfirmVerificationCodeNotSignedInForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerConfirmVerificationCodeNotSignedInResult>(
      {url: `/api/consumer/ConsumerUsers/ConfirmVerificationCode`, method: 'post',
      data: consumerConfirmVerificationCodeNotSignedInForm
    },
      options);
    }
  


    export const useConfirmVerificationCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof confirmVerificationCode>, TError,{data: ConsumerConfirmVerificationCodeNotSignedInForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof confirmVerificationCode>, {data: ConsumerConfirmVerificationCodeNotSignedInForm}> = (props) => {
          const {data} = props || {};

          return  confirmVerificationCode(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof confirmVerificationCode>, TError, {data: ConsumerConfirmVerificationCodeNotSignedInForm}, TContext>(mutationFn, mutationOptions)
    }
    export const resendVerificationCode = (
    consumerResendVerificationCodeCommand: ConsumerResendVerificationCodeCommand,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerResendVerificationCodeResult>(
      {url: `/api/consumer/ConsumerUsers/ResendVerificationCode`, method: 'post',
      data: consumerResendVerificationCodeCommand
    },
      options);
    }
  


    export const useResendVerificationCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof resendVerificationCode>, TError,{data: ConsumerResendVerificationCodeCommand}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof resendVerificationCode>, {data: ConsumerResendVerificationCodeCommand}> = (props) => {
          const {data} = props || {};

          return  resendVerificationCode(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof resendVerificationCode>, TError, {data: ConsumerResendVerificationCodeCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const changePasswordComplete = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<void>(
      {url: `/api/consumer/ConsumerUsers/ChangePasswordComplete`, method: 'get'
    },
      options);
    }
  

export const getChangePasswordCompleteQueryKey = () => [`/api/consumer/ConsumerUsers/ChangePasswordComplete`];

    
export const useChangePasswordComplete = <TData = AsyncReturnType<typeof changePasswordComplete>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof changePasswordComplete>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getChangePasswordCompleteQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof changePasswordComplete>> = () => changePasswordComplete(requestOptions);

  const query = useQuery<AsyncReturnType<typeof changePasswordComplete>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getConfigDebugReport = (
    params?: GetConfigDebugReportParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ProvidersConfigDebugReport>(
      {url: `/api/consumer/ConsumerUsers/GetConfigDebugReport`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetConfigDebugReportQueryKey = (params?: GetConfigDebugReportParams,) => [`/api/consumer/ConsumerUsers/GetConfigDebugReport`, ...(params ? [params]: [])];

    
export const useGetConfigDebugReport = <TData = AsyncReturnType<typeof getConfigDebugReport>, TError = unknown>(
 params?: GetConfigDebugReportParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getConfigDebugReport>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetConfigDebugReportQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getConfigDebugReport>> = () => getConfigDebugReport(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getConfigDebugReport>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getUserDetails = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerGetConsumerUserDetailsResult>(
      {url: `/api/consumer/ConsumerUsers/GetUserDetails`, method: 'get'
    },
      options);
    }
  

export const getGetUserDetailsQueryKey = () => [`/api/consumer/ConsumerUsers/GetUserDetails`];

    
export const useGetUserDetails = <TData = AsyncReturnType<typeof getUserDetails>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUserDetails>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserDetailsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserDetails>> = () => getUserDetails(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUserDetails>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getAllConsumerUsers = (
    params?: GetAllConsumerUsersParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerGetAllConsumerUsersResult>(
      {url: `/api/admin/ConsumerUsers`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllConsumerUsersQueryKey = (params?: GetAllConsumerUsersParams,) => [`/api/admin/ConsumerUsers`, ...(params ? [params]: [])];

    
export const useGetAllConsumerUsers = <TData = AsyncReturnType<typeof getAllConsumerUsers>, TError = unknown>(
 params?: GetAllConsumerUsersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllConsumerUsers>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllConsumerUsersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllConsumerUsers>> = () => getAllConsumerUsers(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllConsumerUsers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getConsumerUserById = (
    consumerUserId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetConsumerUserByIdResult>(
      {url: `/api/admin/ConsumerUsers/${consumerUserId}`, method: 'get'
    },
      options);
    }
  

export const getGetConsumerUserByIdQueryKey = (consumerUserId: number,) => [`/api/admin/ConsumerUsers/${consumerUserId}`];

    
export const useGetConsumerUserById = <TData = AsyncReturnType<typeof getConsumerUserById>, TError = unknown>(
 consumerUserId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getConsumerUserById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetConsumerUserByIdQueryKey(consumerUserId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getConsumerUserById>> = () => getConsumerUserById(consumerUserId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getConsumerUserById>, TError, TData>(queryKey, queryFn, {enabled: !!(consumerUserId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const adminEditConsumerUser = (
    consumerUserId: number,
    consumerEditConsumerUserInfoForm: ConsumerEditConsumerUserInfoForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ConsumerEditConsumerUserInfoResult>(
      {url: `/api/admin/ConsumerUsers/adminEdit/${consumerUserId}`, method: 'post',
      data: consumerEditConsumerUserInfoForm
    },
      options);
    }
  


    export const useAdminEditConsumerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof adminEditConsumerUser>, TError,{consumerUserId: number;data: ConsumerEditConsumerUserInfoForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof adminEditConsumerUser>, {consumerUserId: number;data: ConsumerEditConsumerUserInfoForm}> = (props) => {
          const {consumerUserId,data} = props || {};

          return  adminEditConsumerUser(consumerUserId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof adminEditConsumerUser>, TError, {consumerUserId: number;data: ConsumerEditConsumerUserInfoForm}, TContext>(mutationFn, mutationOptions)
    }
    export const resetConsumerUserPassword = (
    consumerUserId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminConsumerUserResetPasswordResult>(
      {url: `/api/admin/ConsumerUsers/adminReset/${consumerUserId}`, method: 'post'
    },
      options);
    }
  


    export const useResetConsumerUserPassword = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof resetConsumerUserPassword>, TError,{consumerUserId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof resetConsumerUserPassword>, {consumerUserId: number}> = (props) => {
          const {consumerUserId} = props || {};

          return  resetConsumerUserPassword(consumerUserId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof resetConsumerUserPassword>, TError, {consumerUserId: number}, TContext>(mutationFn, mutationOptions)
    }
    