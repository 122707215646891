/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  DoctorGetDoctorsByLocationResult,
  DoctorGetDoctorsByLocationQuery,
  DoctorGetDoctorDetailsResult,
  DoctorGetDoctorDetailsQuery,
  DoctorGetDoctorDetailsServicesResult,
  DoctorGetDoctorDetailsServicesQuery,
  DoctorGetDoctorTopConditionsResult,
  GetDoctorTopCondtionsParams,
  DoctorGetDoctorTopIcdSpendResult,
  GetDoctorTopIcdSpendParams,
  DoctorGetDoctorTopNpisByTypeResult,
  GetTopProvidersAssociatedParams,
  DoctorQueryDoctorBySearchTermResult,
  DoctorQueryDoctorBySearchTermQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllDoctors = (
    doctorGetDoctorsByLocationQuery: DoctorGetDoctorsByLocationQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorsByLocationResult>(
      {url: `/api/admin/Doctor`, method: 'post',
      data: doctorGetDoctorsByLocationQuery
    },
      options);
    }
  


    export const useGetAllDoctors = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getAllDoctors>, TError,{data: DoctorGetDoctorsByLocationQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getAllDoctors>, {data: DoctorGetDoctorsByLocationQuery}> = (props) => {
          const {data} = props || {};

          return  getAllDoctors(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getAllDoctors>, TError, {data: DoctorGetDoctorsByLocationQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorDetails = (
    doctorGetDoctorDetailsQuery: DoctorGetDoctorDetailsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorDetailsResult>(
      {url: `/api/admin/Doctor/GetDoctorDetails`, method: 'post',
      data: doctorGetDoctorDetailsQuery
    },
      options);
    }
  


    export const useGetDoctorDetails = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorDetails>, TError,{data: DoctorGetDoctorDetailsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorDetails>, {data: DoctorGetDoctorDetailsQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorDetails(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorDetails>, TError, {data: DoctorGetDoctorDetailsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorDetailsServices = (
    doctorGetDoctorDetailsServicesQuery: DoctorGetDoctorDetailsServicesQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorDetailsServicesResult>(
      {url: `/api/admin/Doctor/GetDoctorDetailsServices`, method: 'post',
      data: doctorGetDoctorDetailsServicesQuery
    },
      options);
    }
  


    export const useGetDoctorDetailsServices = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDoctorDetailsServices>, TError,{data: DoctorGetDoctorDetailsServicesQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDoctorDetailsServices>, {data: DoctorGetDoctorDetailsServicesQuery}> = (props) => {
          const {data} = props || {};

          return  getDoctorDetailsServices(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDoctorDetailsServices>, TError, {data: DoctorGetDoctorDetailsServicesQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorTopCondtions = (
    params?: GetDoctorTopCondtionsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorTopConditionsResult>(
      {url: `/api/admin/Doctor/GetDoctorTopCondtions`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDoctorTopCondtionsQueryKey = (params?: GetDoctorTopCondtionsParams,) => [`/api/admin/Doctor/GetDoctorTopCondtions`, ...(params ? [params]: [])];

    
export const useGetDoctorTopCondtions = <TData = AsyncReturnType<typeof getDoctorTopCondtions>, TError = unknown>(
 params?: GetDoctorTopCondtionsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDoctorTopCondtions>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDoctorTopCondtionsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDoctorTopCondtions>> = () => getDoctorTopCondtions(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDoctorTopCondtions>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getDoctorTopIcdSpend = (
    params?: GetDoctorTopIcdSpendParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorTopIcdSpendResult>(
      {url: `/api/admin/Doctor/GetDoctorTopIcdSpend`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDoctorTopIcdSpendQueryKey = (params?: GetDoctorTopIcdSpendParams,) => [`/api/admin/Doctor/GetDoctorTopIcdSpend`, ...(params ? [params]: [])];

    
export const useGetDoctorTopIcdSpend = <TData = AsyncReturnType<typeof getDoctorTopIcdSpend>, TError = unknown>(
 params?: GetDoctorTopIcdSpendParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDoctorTopIcdSpend>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDoctorTopIcdSpendQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDoctorTopIcdSpend>> = () => getDoctorTopIcdSpend(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDoctorTopIcdSpend>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getTopProvidersAssociated = (
    params?: GetTopProvidersAssociatedParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorGetDoctorTopNpisByTypeResult>(
      {url: `/api/admin/Doctor/GetTopProvidersAssociated`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetTopProvidersAssociatedQueryKey = (params?: GetTopProvidersAssociatedParams,) => [`/api/admin/Doctor/GetTopProvidersAssociated`, ...(params ? [params]: [])];

    
export const useGetTopProvidersAssociated = <TData = AsyncReturnType<typeof getTopProvidersAssociated>, TError = unknown>(
 params?: GetTopProvidersAssociatedParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getTopProvidersAssociated>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTopProvidersAssociatedQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getTopProvidersAssociated>> = () => getTopProvidersAssociated(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getTopProvidersAssociated>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const queryDoctorBySearchTerm = (
    doctorQueryDoctorBySearchTermQuery: DoctorQueryDoctorBySearchTermQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DoctorQueryDoctorBySearchTermResult>(
      {url: `/api/admin/Doctor/QueryDoctorBySearchTerm`, method: 'post',
      data: doctorQueryDoctorBySearchTermQuery
    },
      options);
    }
  


    export const useQueryDoctorBySearchTerm = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof queryDoctorBySearchTerm>, TError,{data: DoctorQueryDoctorBySearchTermQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof queryDoctorBySearchTerm>, {data: DoctorQueryDoctorBySearchTermQuery}> = (props) => {
          const {data} = props || {};

          return  queryDoctorBySearchTerm(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof queryDoctorBySearchTerm>, TError, {data: DoctorQueryDoctorBySearchTermQuery}, TContext>(mutationFn, mutationOptions)
    }
    