import React from "react";
import FormStatusProvider, { FormStatus } from "./FormStatusProvider";

type PropsWithStatus<P> = P & {
  status: FormStatus;
};

function withFormStatus<P>(WrappedComponent: React.ComponentType<P>) {
  const ComponentWithStatus = ({ status, ...props }: PropsWithStatus<P>) => {
    return (
      <FormStatusProvider status={status}>
        <WrappedComponent {...(props as unknown as P)} />
      </FormStatusProvider>
    );
  };

  return ComponentWithStatus;
}

export default withFormStatus;
