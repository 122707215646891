import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetDoctorsByDiagnosisQuery, ModelsPaginationAndSortRequest, getDoctorsByDiagnosis } from "api";
import { useQuery } from "react-query";
import { DiagnosisTypeApiRequest } from "sections/item_details/diagnosis/DiagnosisDetailsMain";

export const useX_GetDoctorByDiagnosis = (req: DiagnosisTypeApiRequest, pagination?: ModelsPaginationAndSortRequest) => {
	let fqrX = useFilterQuery();

	let request: AdminGetDoctorsByDiagnosisQuery = {
		dateRangeRequest: {
			startDate: req.startDate,
			endDate: req.endDate,
		},
		jobIds: fqrX.memberTypes,
		ranges: req.subRangeQuery ? req.subRangeQuery.split(",") : req.searchQuery?.split(","),
		codes: req.icd10Query?.split(","),
		paginationSortRequest: pagination,
	};

	console.debug(request);

	let cacheKeys = ["getDoctorByDiagnosis", "GetDoctorByDiagnosis_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDoctorsByDiagnosis.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
