import React, { useEffect, useMemo, useState } from "react";
import { Box, ButtonBase, Menu, MenuItem, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import Icon from "shared/Icon";
import { icons } from "theme";
import { MonthRangeSelection } from "./MonthRangeSelection";
import { SelectOptions } from "../DropDownSelect";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";

type DateRangeDropDownProps = {
	// title: string;
	// icon?: React.ReactNode;
	handleChange?: (ev: React.ChangeEvent, value: Array<string>, selected?: boolean) => any;

	ariaLabel?: string;
	defaultValue?: string;
	selectedValues?: Array<string>;
	initialStartDate?: Date | null;
	initialEndDate?: Date | null;
	// labelSx?: SxProps<Theme>;
	setValues?: React.Dispatch<React.SetStateAction<string[]>> | ((x: Array<string>) => any);
};

const options: Array<SelectOptions> = [
	{ value: "All", label: "All" },
	{ value: "Monthly", label: "Current Month" },
	{ value: "Yearly", label: "Current Year" },
	{ value: "Custom", label: "Custom" },
];

const forceOnlyCustomDateSelection = true;
function DateRangeDropDown(props: DateRangeDropDownProps) {
	const [anchor, setAnchor] = useState<null | HTMLElement>(null);

	const primaryFilters = usePrimaryFilters();

	const handleChangeRadio = (ev: React.ChangeEvent, value: string) => {
		if (props.setValues) {
			props.setValues([value]);
		}
		if (props.handleChange) {
			props.handleChange(ev, [value]);
		}
	};

	const open = Boolean(anchor);

	const id = "DateRangeDropDown";

	const [startDate, set_startDate] = useState<Date | null>(props.initialStartDate ?? null);
	const [endDate, set_endDate] = useState<Date | null>(props.initialEndDate ?? null);

	useEffect(() => {
		if (startDate && endDate) {
			if (
				primaryFilters.values.DateRangeCustomDates.start?.getTime() !== startDate?.getTime() ||
				primaryFilters.values.DateRangeCustomDates.end?.getTime() !== endDate?.getTime()
			) {
				primaryFilters.set_PrimaryFiltersValues({ DateRangeCustomDates: { start: startDate, end: endDate } });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	const customSelection = useMemo(() => {
		return (
			<Box
				sx={{
					"& .date-range-container": {
						height: "auto !important",
						borderRadius: "0 !important",
						border: "none !important",
						backgroundColor: "transparent !important",
						px: 0,
						padding: "0 1rem 0 2.2rem",
						color: "gray.dark !important",
						boxShadow: 0,
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					},
					"& .date-range-input": {
						flexDirection: "column",
						pb: 1,
					},
				}}
			>
				<MonthRangeSelection
					startDate={startDate}
					set_startDate={set_startDate}
					endDate={endDate}
					set_endDate={set_endDate}
					inheritGlobalFilter={false}
					layout="vertical"
					styleType="simple"
					initialStartDate={props.initialStartDate}
					initialEndDate={props.initialEndDate}
					start_limit_date={primaryFilters.values.DateRangeLimits.start_limit}
					end_limit_date={primaryFilters.values.DateRangeLimits.end_limit}
				/>
			</Box>
		);
	}, [
		props.initialStartDate,
		props.initialEndDate,
		startDate,
		set_startDate,
		endDate,
		set_endDate,
		primaryFilters.values.DateRangeLimits.start_limit,
		primaryFilters.values.DateRangeLimits.end_limit,
	]);

	return (
		<Box>
			<ButtonBase
				id={`${id}-button`}
				aria-controls={`${id}-menu`}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					setAnchor(event.currentTarget);
				}}
			>
				<Icon fixedWidth icon={icons.calendar} />
				<Box sx={{ ml: 0.5, mr: 0.5 }}>Date Range</Box>
				<Icon fixedWidth icon={icons.angleDown} />
			</ButtonBase>

			<Menu
				id={`${id}-menu`}
				anchorEl={anchor}
				open={open}
				onClose={() => {
					setAnchor(null);
				}}
				MenuListProps={{
					"aria-labelledby": `${id}-button`,
				}}
			>
				<MenuItem>
					{forceOnlyCustomDateSelection ? (
						customSelection
					) : (
						<FormControl component="fieldset">
							<RadioGroup
								aria-label={props.ariaLabel}
								name={"Date Range"}
								defaultValue={!props.selectedValues ? props.defaultValue : undefined}
								onChange={handleChangeRadio}
							>
								{options?.map((item) => {
									return (
										<FormControlLabel
											value={item.value}
											control={<Radio checked={props.selectedValues ? props.selectedValues.indexOf(item.value) !== -1 : undefined} />}
											label={item.label}
											key={item.value}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					)}
				</MenuItem>
				{!forceOnlyCustomDateSelection ? (props.selectedValues && props.selectedValues[0] === "Custom" ? customSelection : null) : null}
			</Menu>
		</Box>
	);
}

export { DateRangeDropDown };
