import { icons, palette } from "../../theme";
import SummaryTile from "../../shared/components/SummaryTile";
import { displayConverter } from "../../shared/utilities/displayConverter";
import { Box } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";

//queries
import { AdminGetSummaryGraphsResult, ModelsSummaryGraphModels, OverviewGetRevenueSummaryChartsResult, getRevenueSummaryCharts, getSummaryGraphs } from "api";
import { useRunFilterQuery } from "../../DataProvider/hooks/useRunFilterQuery";
import { useMemo } from "react";
import { Loader } from "shared/components/Loaders/Loader";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";

SwiperCore.use([Pagination]);

function DashboardAnalytics() {
	let summaryGraphData = useRunFilterQuery<AdminGetSummaryGraphsResult>(getSummaryGraphs).data;
	let revenueData = useRunFilterQuery<OverviewGetRevenueSummaryChartsResult>(getRevenueSummaryCharts).data;
	let sgm: ModelsSummaryGraphModels | null = useMemo(() => {
		if (summaryGraphData?.summaryGraphModels) {
			return summaryGraphData.summaryGraphModels;
		}
		return null;
	}, [summaryGraphData?.summaryGraphModels]);

	return (
		<Box
			sx={{
				"& .swiper-container": {
					pb: { xs: 4, xl: 0 },
				},
				"& .swiper-pagination-bullets": {
					bottom: "0",
				},
			}}
		>
			<Swiper
				spaceBetween={16}
				slidesPerView={4}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"700": {
						slidesPerView: 2,
					},
					"1200": {
						slidesPerView: 3,
					},
					"1900": {
						slidesPerView: 3,
					},
				}}
			>
				{sgm ? (
					<>
						{sgm.totalPatientsGraphModel?.graph ? (
							<SwiperSlide>
								<SummaryTile
									title="Total Members"
									dataKey="value"
									data={sgm?.totalPatientsGraphModel?.graph.map((x) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									graphPointLabel="Members"
									primaryValue={displayConverter.insertThousandsCommas(sgm.totalPatientsGraphModel.currentMonth)}
									icon={icons.users}
									color={palette.red.main}
									tooltipFormat={(value: string) => displayConverter.insertThousandsCommas(value)}
									backgroundColor={palette.gray.main}
								></SummaryTile>
							</SwiperSlide>
						) : null}
						{sgm.pmpmBillablesGraph?.graph ? (
							<SwiperSlide>
								<SummaryTile
									title="AVG PMPM SPEND"
									dataKey="value"
									data={sgm.pmpmBillablesGraph?.graph.map((x) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									graphPointLabel="PMPM"
									primaryValue={displayConverter.toUSD(sgm.pmpmBillablesGraph?.currentMonth)}
									icon={icons.moneyCircle}
									tooltipFormat={(value: number) => displayConverter.toUSD(value)}
									color={palette.lightBlue.main}
								></SummaryTile>
							</SwiperSlide>
						) : null}
						{sgm.billingTrendGraphModel?.graph ? (
							<SwiperSlide>
								<SummaryTile
									title="TOTAL SPEND"
									dataKey="value"
									data={sgm.billingTrendGraphModel?.graph.map((x) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									primaryValue={displayConverter.toUSD(sgm.billingTrendGraphModel.currentMonth)}
									icon={icons.lineChart}
									graphPointLabel="Spend"
									tooltipFormat={(value: number) => displayConverter.toUSD(value)}
									color={palette.purple.main}
								></SummaryTile>
							</SwiperSlide>
						) : null}
						{revenueData?.summaryGraphModels?.diagnosisSummaryGraph?.graph && (
							<SwiperSlide>
								<SummaryTile
									title="Total Visits"
									dataKey="value"
									data={revenueData?.summaryGraphModels?.diagnosisSummaryGraph?.graph.map((x: any) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									graphPointLabel="Visits"
									primaryValue={displayConverter.insertThousandsCommas(revenueData?.summaryGraphModels.diagnosisSummaryGraph.currentMonth)}
									icon={icons.calendar}
									color={palette.red.main}
									backgroundColor={palette.gray.main}
									tooltipFormat={(value: number) => displayConverter.insertThousandsCommas(value)}
								></SummaryTile>
							</SwiperSlide>
						)}
						{revenueData?.summaryGraphModels?.productserviceSummaryGraph?.graph && (
							<SwiperSlide>
								<SummaryTile
									title="Total Procedures"
									dataKey="value"
									data={revenueData?.summaryGraphModels?.productserviceSummaryGraph?.graph.map((x: any) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									graphPointLabel="Procedures"
									primaryValue={displayConverter.insertThousandsCommas(
										revenueData?.summaryGraphModels.productserviceSummaryGraph.currentMonth
									)}
									icon={icons.procedure}
									color={palette.lightBlue.main}
									tooltipFormat={(value: number) => displayConverter.insertThousandsCommas(value)}
								></SummaryTile>
							</SwiperSlide>
						)}
						{revenueData?.summaryGraphModels?.medicationSummaryGraph?.graph && (
							<SwiperSlide>
								<SummaryTile
									title="Total Prescriptions"
									dataKey="value"
									data={revenueData?.summaryGraphModels?.medicationSummaryGraph?.graph.map((x: any) => {
										return {
											value: x.value as string | number,
											name: x.name as string,
										};
									})}
									graphPointLabel="Prescriptions"
									primaryValue={displayConverter.insertThousandsCommas(revenueData?.summaryGraphModels.medicationSummaryGraph.currentMonth)}
									icon={icons.prescription}
									color={palette.purple.main}
									tooltipFormat={(value: number) => displayConverter.insertThousandsCommas(value)}
								></SummaryTile>
							</SwiperSlide>
						)}
					</>
				) : (
					<Loader />
				)}
			</Swiper>
			<ResendQueryButton queryCacheName="getSummaryGraphs" />
			<ResendQueryButton queryCacheName="getRevenueSummaryCharts" />
		</Box>
	);
}
export { DashboardAnalytics };
