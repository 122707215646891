import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { UserMetricsContext } from "DataProvider/contexts/Context_UserMetrics_Provider";
import { useMemo, useContext } from "react";

function useMemo_totalMembersInFilter() {
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);
	const { values: userMetricsValues } = useContext(UserMetricsContext);

	return useMemo(() => {
		let tm = 0;
		let counts = userMetricsValues.insuranceTypeMembersCount ?? {};
		Object.keys(counts).forEach((mc) => {
			if (counts[mc] && primaryFilterValues.Members.indexOf(mc) !== -1) {
				tm = tm + counts[mc];
			}
		});
		return tm;
	}, [primaryFilterValues.Members, userMetricsValues.insuranceTypeMembersCount]);
}

export { useMemo_totalMembersInFilter };
