import React from "react";
import { Stack, Avatar, Box, Typography, Rating } from "@mui/material";

interface DoctorAvatarProps {
	doctorName: string;
	doctorSpecialty: string;
	doctorAffiliation: string;
	doctorRating: number;
}

function DoctorAvatar(props: DoctorAvatarProps) {
	return (
		<Stack direction="row">
			<Box>
				<Avatar></Avatar>
			</Box>
			<Box pl={2}>
				<Typography variant="h5" sx={{ textTransform: "uppercase", fontWeight: 700 }}>
					{props.doctorName}
				</Typography>
				<Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
					{props.doctorSpecialty}
				</Typography>
				{/* <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
          {props.doctorAffiliation}
        </Typography> */}
				{props.doctorRating !== null && <Rating name="read-only" value={props.doctorRating} readOnly />}
			</Box>
		</Stack>
	);
}

export default DoctorAvatar;
