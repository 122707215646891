import { AdminGetConditionDetailsQuery, getConditionDetails } from "api";
import { useQuery } from "react-query";

function getConditionDetailsX(request: Partial<AdminGetConditionDetailsQuery>) {
	return getConditionDetails(request);
}

const useX_getConditionDetails = (request: Partial<AdminGetConditionDetailsQuery>) => {
	let cacheKeys = ["getConditionDetails", "getConditionDetails_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getConditionDetailsX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getConditionDetails };
