import { apiRequest } from "../../api_extension/apiRequest";
import { useQuery, UseQueryResult } from "react-query";
import { randomizeDemoData } from "../../shared/utilities/demoDataRandomizer";

type DiagnosisNotificationDetails_QueryParams = {
	notificationID: number;
};

type DiagnosisNotificationDetails = {
	diagnosisNotificationID: number;
	date_sent: string;
	recipients: number;
	views: number;
	conversions: number;
	success_rate: number;
	gender_data: Array<{ name: string; value: number }>;
	age_data: Array<{
		name: string;
		female: number;
		male: number;
	}>;
	recipient_data: Array<{
		id: 1;
		MemberID: number;
		Age: number;
		Gender: string;
		Provider: string;
		Doctor: string;
		Viewed: string;
		Conversion: string;
	}>;
	provider_conversion_data: Array<{ name: string; value: number }>;
	doctor_conversion_data: Array<any>;

	//   category_percent: number;
	//   name: string;
	//   members: number;
	//   new_diagnoses: number;
	//   month_change_percent: number;
	//   pmpm: number;
	//   gender_data: Array<{ name: string; value: number }>;
	//   age_data: Array<{ name: string; female: number; male: number }>;
};

async function retrieveDiagnosisNotificationDetails(params: DiagnosisNotificationDetails_QueryParams): Promise<DiagnosisNotificationDetails> {
	let data = await apiRequest({
		url: `${process.env.PUBLIC_URL}/test_data/notifications/DiagnosisNotificationDetail.json`,
		method: "GET",
	}).then((d) => {
		randomizeDemoData([d]);
		randomizeDemoData(d.recipient_data);
		randomizeDemoData(d.age_data);
		randomizeDemoData(d.provider_conversion_data);
		randomizeDemoData(d.gender_data);

		// randomizeDemoData(data.age_data);
		return d;
	});
	return data;
}

function useDiagnosisNotificationDetails(params: DiagnosisNotificationDetails_QueryParams): UseQueryResult<DiagnosisNotificationDetails> {
	return useQuery(
		["DiagnosisNotificationDetails", `DiagnosisNotificationDetails_${params.notificationID}`],
		retrieveDiagnosisNotificationDetails.bind(null, params)
	);
}

export { useDiagnosisNotificationDetails };
export type { DiagnosisNotificationDetails };
