import React from "react";
import { Box, Tabs } from "@mui/material";
import PanelDiagnosisNotificationRecipients from "./PanelDiagnosisNotificationRecipients";
import PanelDiagnosisNotificationCompLandscape from "./PanelDiagnosisNotificationCompLandscape";
import ValueTile from "../shared/components/ValueTile";
import { useDiagnosisNotificationDetails } from "./hooks/useDiagnosisNotificationDetails";
import { displayConverter } from "../shared/utilities/displayConverter";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { PanelDiagnosisNotificationOverview } from "./PanelDiagnosisNotificationOverview";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
SwiperCore.use([Pagination]);

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("diagnosis-notification-details");

function DiagnosisNotificationDetails(props: { notificationID: number }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const { data } = useDiagnosisNotificationDetails({
		notificationID: props.notificationID,
	});

	return (
		<Box width={"100%"}>
			<Box sx={{ pb: 2, pt: 1, width: "100%" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="Dashboard Overview tabs"
					variant="scrollable"
					scrollButtons={false}
					sx={{
						width: "100%",
						"& .MuiTab-root": {
							flex: { xs: "none", sm: "1" },
							whiteSpace: "nowrap",
							maxWidth: "100%",
						},
					}}
				>
					<TabControl label={"Overview"} value={0} />
					<TabControl label="Recipients" value={1} />
					<TabControl label="Comp Landscape" value={2} />
				</Tabs>
			</Box>
			<Box pb={2}>
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 3,
							},
							"1200": {
								slidesPerView: 5,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile title="Date Sent" value={data?.date_sent} minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile title="Recipients" value={displayConverter.insertThousandsCommas(data?.recipients)} minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile title="Views" value={displayConverter.insertThousandsCommas(data?.views)} minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile title="Conversions" value={displayConverter.insertThousandsCommas(data?.conversions)} minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile title="Success Rate" value={displayConverter.toPercent(data?.success_rate)} minHeight="7rem" />
						</SwiperSlide>
					</Swiper>
				</Box>
			</Box>
			<TabPanel value={value} index={0}>
				<PanelDiagnosisNotificationOverview data={data} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<PanelDiagnosisNotificationRecipients data={data} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<PanelDiagnosisNotificationCompLandscape data={data} />
			</TabPanel>
		</Box>
	);
}

export { DiagnosisNotificationDetails };
