import { useState, useEffect, ReactNode } from "react";
import { BarChart, Tooltip, XAxis, Bar, Legend, LegendType, YAxis, ResponsiveContainer } from "recharts";
import { AxisInterval, Margin } from "recharts/types/util/types";

interface StandardBarChartProps {
	data?: Array<{ name: string; [attr: string]: string | number }>;
	dataKey: string;
	bars?: Array<Bar>;
	bar_definitions?: Array<{
		dataKey: string;
		name: string;
		fill: any;
		legendType?: LegendType;
		yAxisId?: number;
	}>;
	showYAxis?: boolean;
	showLegend?: boolean;
	showGrid?: boolean;
	layout?: "horizontal" | "vertical";
	responsiveHeight?: number;
	stackId?: string;
	tooltipFormatter?: (value: any, name: any, props: any) => string | Array<string>;
	tooltipLabelFormatter?: (label: any, payload: any) => string | Array<string>;
	yAxisTickFormatter?: (value: any, index: number) => string;
	xAxisTickFormatter?: (value: any, index: number) => string;
	cartesianGrid?: ReactNode;
	margin?: Margin;
	angle?: number;
	textAnchor?: string;
	xAxisInterval?: AxisInterval;
	yAxisWidth?: number;
	legendStyle?: React.CSSProperties;
	axisLabel?: string | number | object | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

function StandardBarChart(props: StandardBarChartProps) {
	const [dataTimeStamp, setDataTimeStamp] = useState(Date.now());
	useEffect(() => {
		// force rerender and animate
		setDataTimeStamp(Date.now());
	}, [props.data, props.bars, props.bar_definitions]);

	return (
		<ResponsiveContainer height={props.responsiveHeight} width="100%">
			<BarChart key={dataTimeStamp} data={props.data} layout={props.layout} margin={props.margin}>
				{props.showGrid !== true ? null : props.cartesianGrid}
				{props.layout === "vertical" ? (
					<>
						<YAxis type="category" dataKey={props.dataKey} width={props.yAxisWidth ?? 90} tickFormatter={props.yAxisTickFormatter} />
						<XAxis
							type="number"
							tickFormatter={props.xAxisTickFormatter}
							angle={props.angle}
							textAnchor={props.textAnchor}
							interval={props.xAxisInterval}
							label={props.axisLabel}
						/>
					</>
				) : (
					<>
						<XAxis
							dataKey={props.dataKey}
							tickFormatter={props.xAxisTickFormatter}
							angle={props.angle}
							textAnchor={props.textAnchor}
							interval={props.xAxisInterval}
						/>
						{props.showYAxis && <YAxis type="number" tickFormatter={props.yAxisTickFormatter} label={props.axisLabel} />}
					</>
				)}

				<Tooltip formatter={props.tooltipFormatter} labelFormatter={props.tooltipLabelFormatter} />
				<>{props.showLegend !== false ? <Legend verticalAlign="top" iconSize={24} wrapperStyle={props.legendStyle} /> : null}</>
				<>
					{(() => {
						if (props.bars) {
							return props.bars;
						}
						if (props.bar_definitions) {
							return props.bar_definitions.map((bar_def, x) => (
								<Bar
									key={bar_def.dataKey + bar_def.name}
									dataKey={bar_def.dataKey}
									name={bar_def.name}
									fill={bar_def.fill}
									stackId={props.stackId}
									legendType={bar_def.legendType}
									yAxisId={bar_def.yAxisId}
								/>
							));
						}
						return null;
					})()}
				</>
			</BarChart>
		</ResponsiveContainer>
	);
}

export { StandardBarChart };
