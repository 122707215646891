import { useContextAppSettings } from "DataProvider/contexts/Context_AppSettings";
import { useContextUserFollowedItems } from "DataProvider/contexts/Context_UserFollowedItems";
import { modifyFollowedItem } from "api";
import { CSSProperties, useMemo } from "react";
import Icon from "shared/Icon";
import { icons, palette } from "theme";

import { useSnackbar } from "notistack";
import { FollowedItemTypeDefinitions } from "shared/utilities/FollowedItemsUtility";

type UserFollowedItemButtonProps = {
	itemType: UserFollowedItemTypeNames;
	code: string;
};

const style: CSSProperties = { marginLeft: ".5rem", marginRight: ".5rem" };
const UserFollowedItemButton = ({ itemType, code }: UserFollowedItemButtonProps) => {
	const ufi = useContextUserFollowedItems();
	const settings = useContextAppSettings();

	const notifier = useSnackbar();

	const isFollowed = useMemo(() => {
		if (ufi?.itemsByType[itemType]) {
			return ufi.itemsByType[itemType]?.some((i) => {
				return i.code === code;
			});
		}
		return false;
	}, [ufi, itemType, code]);

	if (settings.disable_following_items) {
		return null;
	}

	return isFollowed ? (
		<Icon
			onClick={async () => {
				let result = await modifyFollowedItem({ itemType: itemType, operationType: 5, code: code });
				if (result?.result?.success) {
					notifier.enqueueSnackbar(`Stopped Following ${FollowedItemTypeDefinitions[itemType].DisplayName} Item`, {
						variant: "info",
					});
				} else {
					notifier.enqueueSnackbar(`An Error Occurred`, {
						variant: "error",
					});
				}
				ufi.trigger_refresh();
			}}
			className="menu-icon"
			style={style}
			sx={{
				cursor: "pointer",
				color: palette.success.main,
				transition: "transform .25s ease",
				"&:hover": {
					opacity: 0.5,
				},
			}}
			fixedWidth
			icon={icons.eye}
		/>
	) : (
		<Icon
			onClick={async () => {
				let result = await modifyFollowedItem({ itemType: itemType, operationType: 1, code: code });
				if (result?.result?.success) {
					notifier.enqueueSnackbar(`Following ${FollowedItemTypeDefinitions[itemType].DisplayName} Item`, {
						variant: "success",
					});
				} else {
					notifier.enqueueSnackbar(`An Error Occurred`, {
						variant: "error",
					});
				}
				ufi.trigger_refresh();
			}}
			className="menu-icon"
			style={style}
			sx={{
				cursor: "pointer",
				color: palette.gray.dark,
				transition: "transform .25s ease",
				"&:hover": {
					opacity: 0.5,
				},
			}}
			fixedWidth
			icon={icons.eye}
		/>
	);
};

export { UserFollowedItemButton };
