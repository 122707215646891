import React, { useEffect, useMemo, useState } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthContext, AuthState, getAuthSession, userPool } from "./context";
import { AccountManager } from "DataProvider/contexts/AccountManager";
import { redirect, useLocation } from "react-router-dom";

interface AuthProviderProps {
	publicRoutes: React.ReactNode;
	onStateChange?: (state: AuthState) => void;
}

function AuthProvider({ onStateChange, publicRoutes, children: protectedRoutes }: React.PropsWithChildren<AuthProviderProps>) {
	const [pending, setPending] = useState(true);
	const [state, update] = useState<AuthState>({ authenticated: false });
	const value = useMemo(() => ({ ...state, update }), [state, update]);
	const location = useLocation();

	useEffect(() => {
		// attempt to sign in automatically
		const user = userPool.getCurrentUser();
		if (!user) {
			// there is no user to sign in
			setPending(false);
			return;
		}
		// try to get the user's session
		user.getSession(function (_: null, session: CognitoUserSession) {
			setPending(false);
			// if we have a session, then we're re-authenticated
			if (session?.isValid()) {
				update({
					authenticated: true,
					user,
				});
			}
		});
	}, []);

	useEffect(() => {
		if (value.authenticated === true) {
			getAuthSession(value.user).catch(() => {
				value.user.signOut();
				redirect("/signin?redirect" + location.state);
			});
			if (value.user !== AccountManager.user) {
				AccountManager.setUser(value.user);
			}
		} else if (value.authenticated === false) {
			if (AccountManager.user) {
				AccountManager.clear();
			}
		}

		onStateChange?.(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, onStateChange, value.authenticated, (value as any).user]);

	// waiting on automatic sign-in
	if (pending) return null;

	return <AuthContext.Provider value={value}>{state.authenticated ? protectedRoutes : publicRoutes}</AuthContext.Provider>;
}

export { AuthProvider };
