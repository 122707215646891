import { Box, Typography } from "@mui/material";
import { GridColumns, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";

import { displayConverter } from "shared/utilities/displayConverter";
import { DataGridColumnsOverRide } from "../DataGridColumns";
import { SimpleTooltipColumn } from "shared/components/grid/GridColumn/SimpleTooltipColumn";
import { ProvidersCacheMonthlyRepositoryCodeTotalsResult } from "api";

const ColumnsConstruct_TopCodes = (type: CacheMonthlyTypes) => {
	let columns: GridEnrichedColDef[] = construct_GRIDCOLUMNS_TOPCODES(type);

	if (type === "DIAGNOSIS") {
		columns = DataGridColumnsOverRide(columns, [{ field: "eobCount", headerName: "Total Diagnosis" }]);
	} else if (type === "PRODUCTSERVICE") {
		columns = DataGridColumnsOverRide(columns, [{ field: "eobCount", headerName: "Total Procedures" }]);
	} else if (type === "MEDICATION") {
		columns = DataGridColumnsOverRide(columns, [{ field: "eobCount", headerName: "Total Fills" }]);
	}

	return columns;
};

function construct_GRIDCOLUMNS_TOPCODES(itemType: CacheMonthlyTypes) {
	const GRIDCOLUMNS_TOPCODES: GridColumns = [
		{
			field: "rank",
			headerName: "Rank",
			headerAlign: "left",
			align: "left",
			sortable: false,
			minWidth: 40,
			flex: 0.2,
		},
		{
			field: "_",
			headerName: "Name",
			flex: 2.2,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x: ProvidersCacheMonthlyRepositoryCodeTotalsResult = value.row;

				/// Reminder that <RowDisplayForEntityType item={value.row} /> exists and could potentially be used here;

				let name: string;
				if (itemType === "PRODUCTSERVICE") {
					if (x.lookUpItem?.universalItemDisplay?.description) {
						name = displayConverter.limitStringLength(x.lookUpItem.universalItemDisplay.description, 30);
					} else if (x.lookUpItem?.universalItemDisplay?.name) {
						name = x.lookUpItem.universalItemDisplay.name;
					} else {
						name = x.code ?? "[UNKNOWN]";
					}
				} else {
					name = x.lookUpItem?.universalItemDisplay?.name ?? "";
					if (x.lookUpItem?.universalItemDisplay?.description) {
						name += ` (${displayConverter.limitStringLength(x.lookUpItem?.universalItemDisplay?.description, 20)})`;
					}
				}
				return (
					<SimpleTooltipColumn
						code={x.code}
						name={name}
						tooltipTitle={
							<Box>
								<Typography>{itemType === "DIAGNOSIS" ? displayConverter.periodizeIcdCode(x.code) : x.code}</Typography>
								{itemType === "PRODUCTSERVICE" && x.lookUpItem?.universalItemDisplay?.name ? (
									<Typography>{x.lookUpItem.universalItemDisplay.name}</Typography>
								) : null}
								{x.lookUpItem?.universalItemDisplay?.description ? (
									<Typography>{x.lookUpItem.universalItemDisplay.description}</Typography>
								) : null}
							</Box>
						}
						followItemType="DIAGNOSIS"
					/>
				);
			},
		},
		{
			field: "eobCount",
			headerName: "Total Count",
			flex: 1,
			headerAlign: "right",
			align: "right",
			renderCell(value) {
				return displayConverter.insertThousandsCommas(parseFloat(value.row.eobCount));
			},
			sortable: false,
			minWidth: 80,
		},
		{
			field: "totalAmount",
			headerName: "Total Spend",
			flex: 1,
			headerAlign: "right",
			align: "right",
			renderCell(value) {
				return displayConverter.toUSD(parseFloat(value.row.totalAmount));
			},
			sortable: false,
			minWidth: 40,
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			minWidth: 128,
			flex: 1,
			sortable: false,
			renderCell: ({ row }) => <ViewEntityDetailButton itemType={"ALLOW_CACHEITEMTYPE"} cacheItemType={itemType} code={row.code} />,
		},
	];
	return GRIDCOLUMNS_TOPCODES;
}

export { construct_GRIDCOLUMNS_TOPCODES, ColumnsConstruct_TopCodes };
