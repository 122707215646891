import { Navigate, Route, Routes } from "react-router-dom";
import { AccountRoutes } from "account/AccountRoutes";
import { SignOutPage } from "account/SignOutPage";
import { Overview } from "sections/dashboard/Overview";
import { DiagnosisRoutes } from "sections/diagnosis/DiagnosisRoutes";
import { RevenueRoutes } from "sections/revenue/RevenueRoutes";
import { AdminRoutes } from "sections/settings/AdminRoutes";
import { ReportingRoutes } from "sections/reporting/ReportingRoutes";
import { DeveloperRouter } from "sections/developer/DeveloperRouter";
import { MedicationRoutes } from "sections/medication/MedicationRoutes";
import { FollowedItemsMain } from "sections/followed_items/FollowedItemsMain";
import { ItemDetailsMain } from "sections/item_details/ItemDetailsMain";
import { ProcedureRoutes } from "sections/procedures/ProcedureRoutes";
import { MembersRoutes } from "sections/members_doctors/members/MembersRoutes";
import { DoctorsRoutes } from "sections/members_doctors/doctors/DoctorsRoutes";
import { FacilitiesRoutes } from "sections/facilities/FacilitiesRoutes";

function RouterMain() {
	return (
		<Routes>
			<Route path="/overview/" element={<Overview />} />
			<Route path="/signout/" element={<SignOutPage />} />

			<Route path="/account/*" element={<AccountRoutes />} />

			<Route path="/diagnosis/*" element={<DiagnosisRoutes />} />
			<Route path="/procedures/*" element={<ProcedureRoutes />} />
			<Route path="/prescriptions/*" element={<MedicationRoutes />} />
			<Route path="/spend/*" element={<RevenueRoutes />} />
			<Route path="/members/*" element={<MembersRoutes />} />
			<Route path="/doctors/*" element={<DoctorsRoutes />} />
			<Route path="/facilities/*" element={<FacilitiesRoutes />} />
			<Route path="/reporting/*" element={<ReportingRoutes />} />
			<Route path="/followedItems/*" element={<FollowedItemsMain />} />
			<Route path="/settings/*" element={<AdminRoutes />} />

			<Route path="/itemDetails/*" element={<ItemDetailsMain /*forceCacheItemDetails={true}*/ />} />
			<Route path="/developer/*" element={<DeveloperRouter />} />

			<Route path="*" element={<Navigate to="overview" />} />
		</Routes>
	);
}

export { RouterMain };
