import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { DropDownSelect } from "../DropDownSelect";
import { MonthRangeUtility } from "shared/utilities/MonthRangeUtility";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { getDatesForDateRangeFilterType } from "DataProvider/hooks/useRunFilterQuery";

type MonthRangeSelectionProps = {
	startDate?: Date | null;
	set_startDate: Dispatch<SetStateAction<Date | null>>;
	endDate?: Date | null;
	set_endDate: Dispatch<SetStateAction<Date | null>>;
	inheritGlobalFilter?: boolean;
	layout?: "vertical" | "horizontal";
	styleType?: "simple" | "default";
	initialStartDate?: Date | null;
	initialEndDate?: Date | null;
	start_limit_date?: Date;
	end_limit_date?: Date;
};

// type NumberState = {
// 	value: number | null;
// 	set_value: Dispatch<SetStateAction<number>> | null;
// };

// const helper: { start: { year: NumberState; month: NumberState }; end: { year: NumberState; month: NumberState } } = {
// 	start: {
// 		year: { value: null, set_value: null },
// 		month: { value: null, set_value: null },
// 	},
// 	end: {
// 		year: { value: null, set_value: null },
// 		month: { value: null, set_value: null },
// 	},
// };

const MonthRangeSelection = (props: MonthRangeSelectionProps) => {
	const { values: pf } = usePrimaryFilters();

	const datesFromGlobalFilters = useMemo(() => {
		return getDatesForDateRangeFilterType(pf.DateRange[0], pf.DateRangeCustomDates);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pf.DateRange, pf.DateRange[0], pf.DateRangeCustomDates, pf.DateRangeCustomDates.start, pf.DateRangeCustomDates.end]);

	const [start_limit, end_limit] = useMemo<[string, string]>(() => {
		return [
			MonthRangeUtility.getTokenForDate(props.start_limit_date ?? pf.DateRangeLimits.end_limit),
			MonthRangeUtility.getTokenForDate(props.end_limit_date ?? pf.DateRangeLimits.end_limit),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.start_limit_date, props.end_limit_date]);

	// Full Range Options Initialization
	const monthsByYear: { [key: number]: number[] } = useMemo(() => {
		return MonthRangeUtility.constructMonthsByYearFromRange(start_limit, end_limit);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start_limit, end_limit]); // start_limit, end_limit

	const years = useMemo(() => {
		return Object.getOwnPropertyNames(monthsByYear);
	}, [monthsByYear]);

	const [activeStartYear, set_activeStartYear] = useState<number>(
		(() => {
			let val = props.startDate ? props.startDate.getFullYear() : Number(years[0]);
			if (parseInt(years[0]) > val) {
				val = parseInt(years[0]);
			}
			return val;
		})()
	);
	useEffect(() => {
		// ensure that activeStartYear is always within 'years[]'
		if (parseInt(years[0]) > activeStartYear) {
			set_activeStartYear(parseInt(years[0]));
		} else if (parseInt(years[years.length - 1]) < activeStartYear) {
			set_activeStartYear(parseInt(years[years.length - 1]));
		}
	}, [activeStartYear, years]);

	const [activeStartMonth, set_activeStartMonth] = useState<number>(
		(() => {
			if (props.initialStartDate && monthsByYear[activeStartYear]) {
				let asm = monthsByYear[activeStartYear][props.initialStartDate.getMonth()];
				if (asm) {
					return asm;
				}
			}
			return monthsByYear[activeStartYear] ? monthsByYear[activeStartYear][0] : monthsByYear[parseInt(years[0])][0];
		})()
	);

	const [activeEndYear, set_activeEndYear] = useState<number>(
		(() => {
			let val = props.endDate ? props.endDate.getFullYear() : Number(years[years.length - 1]);
			if (parseInt(years[years.length - 1]) < val) {
				val = parseInt(years[years.length - 1]);
			}
			return val;
		})()
	);
	useEffect(() => {
		// ensure that activeEndYear is always within 'years[]'
		if (parseInt(years[years.length - 1]) < activeEndYear) {
			set_activeEndYear(parseInt(years[years.length - 1]));
		} else if (parseInt(years[0]) > activeEndYear) {
			set_activeEndYear(parseInt(years[0]));
		}
	}, [activeEndYear, years]);

	const [activeEndMonth, set_activeEndMonth] = useState<number>(
		(() => {
			if (props.initialEndDate && monthsByYear[activeEndYear]) {
				let aem = monthsByYear[activeEndYear][props.initialEndDate.getMonth()];
				if (aem) {
					return aem;
				}
			}
			return monthsByYear[activeEndYear]
				? monthsByYear[activeEndYear][monthsByYear[activeEndYear].length - 1]
				: monthsByYear[parseInt(years[years.length - 1])][0];
		})()
	);

	const [initializedStartDate, set_isd] = useState<Date | null>(props.initialStartDate ?? null);
	const [initializedEndDate, set_ied] = useState<Date | null>(props.initialEndDate ?? null);
	/*







*/
	// YEARS OPTIONS
	const startYearOptions = useMemo(() => {
		let allowedYrs = years.filter((x) => {
			return !activeEndYear || Number(x) <= activeEndYear;
		});
		return allowedYrs.map((x) => {
			return { value: x, label: x };
		});
	}, [years, activeEndYear]);

	const endYearOptions = useMemo(() => {
		let allowedYrs = years.filter((x) => {
			return !activeStartYear || Number(x) >= activeStartYear;
		});

		return allowedYrs.map((x) => {
			return { value: x, label: x };
		});
	}, [years, activeStartYear]);
	/*







*/
	// MONTHS OPTIONS

	//START MONTH
	const startMonthOptions = useMemo(() => {
		let months = monthsByYear[activeStartYear] ? monthsByYear[activeStartYear] : monthsByYear[parseInt(years[0])];
		if (activeEndYear === activeStartYear) {
			months = months.filter((m) => m <= activeEndMonth); // cant use slice because not always starting at Jan/1
		}
		let options = months.map((n) => {
			return { value: String(n), label: MonthRangeUtility.monthDefinitions[n - 1]?.abbr };
		});
		MonthRangeUtility.setWithinLowestAndHighest(months, activeStartMonth, set_activeStartMonth);
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// years,
		activeStartYear,
		activeEndMonth,
		monthsByYear,
		//activeEndYear,// Dont include the opposing year change- its own month will adjust first and then trigger if needed
	]);

	// END MONTH
	const endMonthOptions = useMemo(() => {
		let months = monthsByYear[activeEndYear] ? monthsByYear[activeEndYear] : monthsByYear[parseInt(years[years.length - 1])];
		if (activeEndYear === activeStartYear) {
			months = months.filter((m) => m >= activeStartMonth); // cant use slice because not always starting at Jan/1
		}
		let options = months.map((n) => {
			return { value: String(n), label: MonthRangeUtility.monthDefinitions[n - 1]?.abbr };
		});
		MonthRangeUtility.setWithinLowestAndHighest(months, activeEndMonth, set_activeEndMonth);
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		years,
		activeEndYear,
		activeStartMonth,
		monthsByYear,
		//activeStartYear,// Dont include the opposing year change- its own month should adjust first and then triggers if change
	]);

	/*





	Set Dates from Selected Values - Year/Month
	- Take from initialized[Start/End]Date to prevent overwrite values with edges of range





*/

	/*
		useEffects








	*/

	useEffect(() => {
		if (props.inheritGlobalFilter) {
			// const dates = getDatesForDateRangeFilterType(pf.DateRange[0], pf.DateRangeCustomDates);
			const dates = datesFromGlobalFilters;
			if (dates.length >= 2 && dates[0] && dates[1]) {
				let oneDayMillis = 1000 * 60 * 60 * 24;
				let [s, e] = [new Date(dates[0].valueOf() + oneDayMillis), new Date(dates[1].valueOf() - oneDayMillis)];
				let [sY, sM] = [s.getFullYear(), s.getMonth() + 1];
				let [eY, eM] = [e.getFullYear(), e.getMonth() + 1];
				set_activeStartYear(sY);
				set_activeEndYear(eY);
				set_activeStartMonth(sM);
				set_activeEndMonth(eM);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datesFromGlobalFilters, monthsByYear, years]); //RUN WHEN GLOBAL FILTERS CHANGE

	useEffect(() => {
		let sd = initializedStartDate;
		if (initializedStartDate) {
			set_isd(null);
		} else {
			sd = MonthRangeUtility.getDateEdgeForMonth(activeStartYear, activeStartMonth, "leading");
		}
		props.set_startDate(sd);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStartYear, activeStartMonth, props.set_startDate]);

	useEffect(() => {
		if (initializedEndDate) {
			props.set_endDate(initializedEndDate);
			set_ied(null);
		} else {
			let d = MonthRangeUtility.getDateEdgeForMonth(activeEndYear, activeEndMonth, "trailing");
			props.set_endDate(d);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeEndYear, activeEndMonth, props.set_endDate]);
	/*
	
	
		
	
		Render



	
	
	*/

	// helper.end.year.value = activeEndYear;
	// helper.end.year.set_value = set_activeEndYear;
	// helper.end.month.value = activeEndMonth;
	// helper.end.month.set_value = set_activeEndMonth;
	// helper.start.year.value = activeStartYear;
	// helper.start.year.set_value = set_activeStartYear;
	// helper.start.month.value = activeStartMonth;
	// helper.start.month.set_value = set_activeStartMonth;

	return (
		<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
			<Typography className="date-range-label" component="span" sx={{ fontWeight: "700", pr: 1, display: "none" }}>
				Date Range:
			</Typography>
			<Stack
				className={"date-range-container"}
				direction="row"
				spacing="1"
				sx={{
					height: "2.5rem",
					borderRadius: "2.5rem",
					border: "2px solid #fff",
					backgroundColor: "gray.main",
					px: 1.5,
					color: "gray.dark",
					boxShadow: 4,
					alignItems: "center",
					"& .MuiButtonBase-root": {
						height: "100%",
						fontSize: "1rem",

						"& svg": {
							color: "primary.main",
							fontSize: "1.25rem",
						},
					},
				}}
			>
				<Stack className={"date-range-input"} sx={{ flexDirection: "row", pr: 3 }}>
					<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
						Start Date:
					</Typography>
					<Stack data-name="MonthSelectStart" sx={{ flexDirection: "row" }}>
						<DropDownSelect
							title={String(activeStartYear)}
							type="radio"
							options={startYearOptions}
							ariaLabel="RiskModel"
							selectedValues={[String(activeStartYear)]}
							setValues={(v: Array<string>) => {
								set_activeStartYear(Number(v[0]));
							}}
						/>

						<DropDownSelect
							title={MonthRangeUtility.monthDefinitions[activeStartMonth - 1]?.name ?? String(activeStartMonth)}
							type="radio"
							options={startMonthOptions}
							ariaLabel="RiskModel"
							selectedValues={[String(activeStartMonth)]}
							setValues={(v: Array<string>) => {
								set_activeStartMonth(Number(v[0]));
							}}
						/>
					</Stack>
				</Stack>
				<Stack className={"date-range-input"} sx={{ flexDirection: "row" }}>
					<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
						End Date:
					</Typography>
					<Stack data-name="MonthSelectEnd" sx={{ flexDirection: "row" }}>
						<DropDownSelect
							title={String(activeEndYear)}
							type="radio"
							options={endYearOptions}
							ariaLabel="RiskModel"
							selectedValues={[String(activeEndYear)]}
							setValues={(v: Array<string>) => {
								set_activeEndYear(Number(v[0]));
							}}
						/>

						<DropDownSelect
							title={MonthRangeUtility.monthDefinitions[activeEndMonth - 1]?.name ?? String(activeEndMonth)}
							type="radio"
							options={endMonthOptions}
							ariaLabel="RiskModel"
							selectedValues={[String(activeEndMonth)]}
							setValues={(v: Array<string>) => {
								set_activeEndMonth(Number(v[0]));
							}}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export { MonthRangeSelection };
