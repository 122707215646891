import { Dispatch } from "react";
import { FlowAction, FlowState, useFlow, DispatchRef, createUser } from "./flow";

type ForgotPasswordFlowStep = ForgotPasswordStep | ResetPasswordStep | ForgotPasswordCompleteStep;
type ForgotPasswordAction = FlowAction<ForgotPasswordFlowStep>;
export type ForgotPasswordFlow = FlowState & ForgotPasswordFlowStep;

export function useForgotPasswordFlow(): ForgotPasswordFlow {
	return useFlow<ForgotPasswordFlowStep>((dispatch) => forgotPasswordStep(dispatch));
}

export function useResetPasswordFlow(username: string, enqueueSnackbar?: any): ForgotPasswordFlow {
	return useFlow<ForgotPasswordFlowStep>((dispatch) => resetPasswordStep(dispatch, username, enqueueSnackbar));
}

interface ForgotPasswordStep {
	step: "ForgotPasswordStep";
	onForgotPassword: (username: string) => void;
}

function forgotPasswordStep(dispatchRef: DispatchRef<ForgotPasswordAction>): ForgotPasswordStep {
	return {
		step: "ForgotPasswordStep",
		onForgotPassword(username: string) {
			const user = createUser(username);

			const dispatch = dispatchRef();
			dispatch({ type: "pending" });
			user.forgotPassword({
				onSuccess() {
					dispatch({
						type: "next",
						step: resetPasswordStep(dispatchRef, username),
					});
				},
				onFailure(err) {
					dispatch({ type: "error", err });
				},
			});
		},
	};
}

interface ResetPasswordStep {
	step: "ResetPasswordStep";
	username: string;
	onConfirmPassword: (password: string, code: string) => void;
}

function resetPasswordStep(dispatchRef: DispatchRef<ForgotPasswordAction>, username: string, enqueueSnackbar?: any): ResetPasswordStep {
	return {
		step: "ResetPasswordStep",
		username,
		onConfirmPassword(password: string, code: string) {
			const user = createUser(username);
			console.log("CODE", code);
			const dispatch = dispatchRef();
			dispatch({ type: "pending" });
			user.confirmPassword(code, password, {
				onSuccess() {
					enqueueSnackbar("Password Updated", {
						variant: "success",
					});
					dispatch({
						type: "next",
						step: forgotPasswordCompleteStep(dispatch),
					});
				},
				onFailure(err) {
					enqueueSnackbar("Password update failed", {
						variant: "error",
					});
					dispatch({ type: "error", err });
				},
			});
		},
	};
}

interface ForgotPasswordCompleteStep {
	step: "ForgotPasswordCompleteStep";
}

function forgotPasswordCompleteStep(_: Dispatch<ForgotPasswordAction>): ForgotPasswordCompleteStep {
	return {
		step: "ForgotPasswordCompleteStep",
	};
}
