import { Navigate, Route, Routes } from "react-router-dom";
import Reporting from "./Reporting";

function ReportingRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<Navigate to={`overview`} />} />
			<Route path={`overview/*`} element={<Reporting />} />
		</Routes>
	);
}

export { ReportingRoutes };
