import MyAccount from "./MyAccount";
import { Route, Routes } from "react-router-dom";
import ChangePassword from "./ChangePassword";

function AccountRoutes() {
	return (
		<Routes>
			<Route path="myAccount" element={<MyAccount />} />
			<Route path="changePassword" element={<ChangePassword />} />
		</Routes>
	);
}

export { AccountRoutes };
