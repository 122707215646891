import React from "react";
import AddUser from "./AddUser";
import SettingsPage from "./SettingsPage";
import UserDetails from "./UserDetails";
import UserList from "./UserList";
import ConsumerUserList from "./ConsumerUserList";
import ConsumerUserDetails from "./ConsumerUserDetails";
import { Route, Routes } from "react-router-dom";
import ErrorLog from "./ErrorLog";
import ErrorDetails from "./ErrorDetails";

function AdminRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<SettingsPage />} />
			<Route path={`userList`} element={<UserList />} />
			<Route path={`consumerUserList`} element={<ConsumerUserList />} />
			<Route path={`errorLog`} element={<ErrorLog />} />
			<Route path={`users/:username`} element={<UserDetails />} />
			<Route path={`consumerUsers/:id`} element={<ConsumerUserDetails />} />
			<Route path={`errors/:id`} element={<ErrorDetails />} />
			<Route path={`AddUser`} element={<AddUser />} />
		</Routes>
	);
}

export { AdminRoutes };
