import Axios from "axios";
import { useMemo } from "react";

import useFormStatus from "./useFormStatus";

type FormErrors = FormHelpers &
  (
    | { isError: false }
    | {
        isError: true;
        message: string;
        data?: any;
      }
  );

interface FormHelpers {
  getError(field: string): { error?: boolean; helperText?: string };
}

function useFormErrors(): FormErrors {
  const { isError, error } = useFormStatus();

  return useMemo(() => {
    if (!isError) return NoErrors;

    const unpacked = unpackError(error);

    return {
      isError: true,
      ...unpacked,
      getError(field: string) {
        const state = getFieldError(unpacked.data?.errors, field);
        if (!state) return {};

        if (Array.isArray(state)) {
          return { error: true, helperText: state.join(" ") };
        }

        return { error: true, helperText: String(state) };
      },
    };
  }, [isError, error]);
}

export default useFormErrors;

const NoErrors: FormErrors = {
  isError: false,
  getError() {
    return {};
  },
};

function unpackError(error?: unknown) {
  if (!error) {
    return { message: "An unknown error has occurred." };
  }

  if (Axios.isAxiosError(error)) {
    const data = error.response?.data as any;

    return { message: data?.title ?? error.message, data };
  }

  if (error instanceof Error) {
    return { message: error.message };
  }

  return { message: String(error) };
}

function getFieldError(
  errors: any,
  field: string
): undefined | string | string[] {
  if (!errors) return undefined;

  // case-insensitive comparison until the following model validation issue is resolved
  // https://github.com/dotnet/aspnetcore/issues/39010
  const lower = field.toLowerCase();
  const key = Object.keys(errors).find((k) => lower === k.toLowerCase());
  if (!key) return undefined;

  return errors[key];
}
