import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns, GridSortDirection, GridSortItem } from "@mui/x-data-grid-pro";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";
import { displayConverter } from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";

import { Swiper, SwiperSlide } from "swiper/react";
import { DiagnosisTypeApiRequest } from "../../../item_details/diagnosis/DiagnosisDetailsMain";
import { useX_GetDoctorByDiagnosis } from "api_extension/useQueryX/cacheDoctorData/useX_GetDoctorByDiagnosis";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "react-query";

import { ModelsDiagnosisRangeCacheMonthlyAggregate } from "api";
import { useNavigate } from "react-router-dom";
import { Loader } from "shared/components/Loaders/Loader";
import { SimpleTooltipColumn } from "shared/components/grid/GridColumn/SimpleTooltipColumn";

type DiagnosisDetailsDoctorsProps = {
	apiRequest: DiagnosisTypeApiRequest;
	diagnosisID: string;
	icd10Query: string;
	subRangeQuery: string;
	rangeAggregateItem?: ModelsDiagnosisRangeCacheMonthlyAggregate;
};

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Doctors List"} {...props} enableExport={false} />;
}

const defaultSortField = "filtered_diagnosis_cost";
function DiagnosisDetailsDoctors(props: DiagnosisDetailsDoctorsProps) {
	const columnsX: GridColumns = [
		{
			field: "name",
			headerName: "Doctor Name",
			flex: 1.1,
			minWidth: 260,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (params) => {
				const item = params.row.doctor;
				const followItemType = item?.entityTypeCode === 1 ? "PROVIDER" : "ORGANIZATION";
				const fullName = item.firstName ? item?.firstName + " " + item?.lastName : item.organizationName;
				const displayName = fullName.length > 25 ? fullName.substring(0, 25) + "..." : fullName;
				return (
					<SimpleTooltipColumn
						code={item.npi}
						rating={item.radiusRating}
						followItemType={followItemType}
						tooltipTitle={
							<Box>
								<Typography>{fullName}</Typography>
								<Typography>NPI: {item.npi}</Typography>
							</Box>
						}
						name={displayName}
					/>
				);
			},
		},
		{
			field: "npi",
			headerName: "Specialty",
			flex: 1,
			minWidth: 50,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (params) => {
				return (
					<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
						{params.row.doctor?.specialtyText}
					</Typography>
				);
			},
		},
		{
			field: "filtered_diagnosis_count",
			headerName: "Diagnosis Count",
			flex: 1,
			minWidth: 100,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "filtered_diagnosis_cost",
			headerName: "total spend",
			flex: 1,
			minWidth: 100,
			headerAlign: "left",
			align: "left",
			valueGetter(params) {
				return displayConverter.toUSD(params.value);
			},
		},
		{
			field: "filtered_diagnosis_avgcost",
			headerName: "avg spend per diagosis",
			flex: 1,
			minWidth: 100,
			headerAlign: "left",
			align: "left",
			valueGetter(params) {
				return displayConverter.toUSD(params.value);
			},
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => (
				<Button size="small" onClick={() => navigate("/itemDetails?itemType=PROVIDER&code=" + row.npi)} color="secondary">
					Details
				</Button>
			),
		},
	];
	const navigate = useNavigate();
	const qc = useQueryClient();
	const { apiRequest, rangeAggregateItem } = props;
	const { grid } = useDataGrid("doctors", columnsX);

	const pageSize = 10;
	const [page, set_page] = useState(0);
	const [skip, set_skip] = useState(0);
	const take = 10;
	useEffect(() => {
		set_skip(page * pageSize);
	}, [page, pageSize]);

	const [sm, set_sm] = useState<GridSortItem>({ field: defaultSortField, sort: "desc" });

	const [field, desc] = useMemo(() => {
		let f = defaultSortField;
		let d = true;
		if (sm.field) {
			switch (sm.field) {
				case "filtered_diagnosis_cost":
					f = "filtered_diagnosis_cost";
					break;
				case "filtered_diagnosis_avgcost":
					f = "filtered_diagnosis_avgcost";
					break;
				case "filtered_diagnosis_count":
					f = "filtered_diagnosis_count";
					break;
			}
			d = sm.sort !== "asc"; // if its not asc then set as desc
		}
		return [f, d];
	}, [sm]);

	const x = useX_GetDoctorByDiagnosis(apiRequest, { skip: skip, take: take, field: field, descending: desc });

	return (
		<Box>
			<button
				style={{ display: "none" }}
				onClick={() => {
					qc.invalidateQueries("getDoctorByDiagnosis");
				}}
			>
				Invalidate
			</button>
			<QueryStatusDisplay queryResult={x} loaderSize="huge">
				<>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, md: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						{rangeAggregateItem ? (
							<Swiper
								spaceBetween={16}
								slidesPerView={1}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									"700": {
										slidesPerView: 3,
									},
									"1200": {
										slidesPerView: 4,
									},
								}}
							>
								<SwiperSlide>
									<ValueTile
										value={displayConverter.insertThousandsCommas(x?.data?.response?.doctorCount ?? -1)}
										title="Doctors"
										minHeight="7rem"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ValueTile
										value={displayConverter.insertThousandsCommas(rangeAggregateItem.eobCount)}
										title="Diagnosis Count"
										minHeight="7rem"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ValueTile value={displayConverter.toUSD(Number(rangeAggregateItem.totalAmount))} title="Total Spend" minHeight="7rem" />
								</SwiperSlide>
								<SwiperSlide>
									<ValueTile
										value={displayConverter.toUSD(rangeAggregateItem.totalAmount / rangeAggregateItem.eobCount)}
										title="Average Spend"
										minHeight="7rem"
									/>
								</SwiperSlide>
							</Swiper>
						) : (
							<Loader />
						)}
					</Box>

					<Box sx={{ display: "flex", height: "100%" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{x.data?.response?.aggs ? (
								<DataGridPro
									{...grid}
									columns={columnsX}
									autoHeight
									rowCount={x?.data?.response?.doctorCount ?? -1}
									getRowId={(row) => row.npi}
									rows={x.data.response.aggs}
									components={{ Toolbar: Toolbar }}
									page={page}
									pagination
									onPageChange={(newPage) => {
										set_page(newPage);
									}}
									pageSize={pageSize}
									rowsPerPageOptions={[10]}
									sx={{ pt: 2 }}
									disableColumnFilter
									disableColumnMenu
									sortingMode="server"
									sortModel={[sm]}
									onColumnHeaderClick={(x, y) => {
										let sort: GridSortDirection = "desc";
										if (x.field === sm.field && sm.sort === "desc") {
											sort = "asc";
										}
										set_sm({ field: x.field, sort: sort });
									}}
								/>
							) : null}
						</Box>
					</Box>
				</>
			</QueryStatusDisplay>
		</Box>
	);
}

export { DiagnosisDetailsDoctors };
