import { Card, CardHeader, CardContent, Box, Typography, Stack } from "@mui/material";
import { AdminGetHistoricalDataIcdMoneyResultModel } from "api";

import { useMemo } from "react";
import { CartesianGrid } from "recharts";
import Icon from "shared/Icon";
import { Loader } from "shared/components/Loaders/Loader";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";
import { icons, palette } from "theme";

type IcdMoneySummaryProps = {
	data?: AdminGetHistoricalDataIcdMoneyResultModel[];
	queryChips: JSX.Element;
};

function IcdMoneySummaryCard({ data, queryChips }: Readonly<IcdMoneySummaryProps>) {
	const formatter = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

	const aggTotalCharges = useMemo(() => {
		return data?.map((i) => i.totalCharges ?? 0).reduce((a, b) => a + b, 0) ?? 0;
	}, [data]);
	const aggPayerAmount = useMemo(() => {
		return data?.map((i) => i.primaryPayerAmount ?? 0).reduce((a, b) => a + b, 0) ?? 0;
	}, [data]);
	const aggPaymentAmount = useMemo(() => {
		return data?.map((i) => i.paymentAmount ?? 0).reduce((a, b) => a + b, 0) ?? 0;
	}, [data]);

	return (
		<Card elevation={4} sx={{ mb: 1.5 }}>
			<CardHeader title={<>ICD Spend Summary{queryChips}</>} />
			<CardContent sx={{ pt: 0, display: "flex" }}>
				{data ? (
					<>
						<Box
							sx={{
								display: "flex",
								flex: 1,
								flexDirection: "column",
								padding: 2,
								borderRadius: "1rem",
								m: 1,
								backgroundColor: palette.gray.main,
							}}
						>
							<Stack direction="row" gap={4} justifyContent="center" textAlign="center">
								<Stack direction="column">
									<Typography fontWeight="bold" fontSize={18} color={palette.red.main}>
										Total Charges
									</Typography>
									<Typography color={palette.red.main}>{formatter.format(aggTotalCharges)}</Typography>
									<Icon icon={icons.circle} color={palette.red.main} sx={{ alignSelf: "center" }} />
								</Stack>
								<Stack direction="column">
									<Typography fontWeight="bold" fontSize={18} color={palette.green.main}>
										Primary Payer Amount
									</Typography>
									<Typography color={palette.green.main}>{formatter.format(aggPayerAmount)}</Typography>
									<Icon icon={icons.circle} color={palette.green.main} sx={{ alignSelf: "center" }} />
								</Stack>
								<Stack direction="column">
									<Typography fontWeight="bold" fontSize={18} color={palette.lightBlue.main}>
										Payment Amount
									</Typography>
									<Typography color={palette.lightBlue.main}>{formatter.format(aggPaymentAmount)}</Typography>
									<Icon icon={icons.circle} color={palette.lightBlue.main} sx={{ alignSelf: "center" }} />
								</Stack>
							</Stack>
							<StandardBarChart
								data={data?.map((item) => ({ name: item.code ?? "N/A", ...item }))}
								dataKey="code"
								showGrid={true}
								showLegend={false}
								responsiveHeight={300}
								bar_definitions={[
									{
										dataKey: "totalCharges",
										name: "Total Charges",
										fill: palette.red.main,
									},
									{
										dataKey: "primaryPayerAmount",
										name: "Primary Payer Amount",
										fill: palette.green.main,
									},
									{
										dataKey: "paymentAmount",
										name: "Payment Amount",
										fill: palette.lightBlue.main,
									},
								]}
								tooltipFormatter={(value) => {
									return formatter.format(Number(value));
								}}
								yAxisTickFormatter={(value) => {
									return formatter.format(Number(value));
								}}
								xAxisTickFormatter={(value) => {
									return value.toString();
								}}
								cartesianGrid={<CartesianGrid strokeDasharray="1" vertical={false} />}
								axisLabel="axis label"
							/>
						</Box>
					</>
				) : (
					<Loader />
				)}
			</CardContent>
		</Card>
	);
}

export default IcdMoneySummaryCard;
