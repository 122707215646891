import { Card, CardContent, Grid, Button, Select, MenuItem, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { useGetErrorReports } from "api";
import useDataGrid from "shared/components/grid/useDataGrid";
import React, { Dispatch, SetStateAction, useState } from "react";
import FullWidthDialog from "shared/Dialog/FullWidthDialog";
import ErrorDetails from "./ErrorDetails";
import { palette } from "theme";
import { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface Filters {
	searchQuery?: string;
}

function ErrorLog() {
	const columns: GridColumns = [
		{
			field: "id",
			headerName: "Id",
			flex: 0.6,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "type",
			headerName: "Type",
			flex: 0.6,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "code",
			headerName: "Code",
			flex: 1.3,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "message",
			headerName: "Message",
			flex: 1.5,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "createdAt",
			headerName: "Created At",
			flex: 1.3,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "userId",
			headerName: "User ID",
			flex: 1.5,
			headerAlign: "left",
			align: "left",
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,

			renderCell: ({ row }) => (
				<Button onClick={handleOpen.bind(null, row)} color="secondary">
					Details
				</Button>
			),
		},
	];
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("errors", columns);
	const [errorCode, setErrorCode] = useState<string | null>("");
	const [errorType, setErrorType] = useState<string | null>("");
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const { data } = useGetErrorReports({
		...query,
		errorCode: errorCode ?? "",
		errorType: errorType ?? "",
		startDate: startDate?.toISOString(),
		endDate: endDate?.toISOString(),
	});
	function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
		return (
			<>
				{data && (
					<DataGridToolbar
						extraData={
							<>
								<DatePicker
									value={startDate}
									onChange={(newValue: any) => setStartDate(newValue)}
									sx={{ width: "10rem", marginRight: ".75rem" }}
								/>
								<DatePicker value={endDate} onChange={(newValue: any) => setEndDate(newValue)} sx={{ width: "10rem", marginRight: ".75rem" }} />
								<Select
									label="Error Code"
									labelId="error-code-label"
									value={errorCode}
									onChange={(event: any) => {
										setErrorCode(event.target.value);
									}}
									sx={{ marginRight: ".75rem", height: "3.5rem" }}
									displayEmpty={true}
									renderValue={(value) =>
										value?.length ? (
											Array.isArray(value) ? (
												value.join(", ")
											) : (
												value
											)
										) : (
											<Typography sx={{ color: palette.gray.dark }}>Error Code</Typography>
										)
									}
								>
									{data?.model?.allErrorCodes?.map((x) => {
										return (
											<MenuItem key={x} value={x}>
												{x}
											</MenuItem>
										);
									})}
								</Select>
								<Select
									label="Error Type"
									placeholder="Error Type"
									value={errorType}
									sx={{ marginRight: ".75rem", height: "3.5rem" }}
									onChange={(event: any) => {
										setErrorType(event.target.value);
									}}
									displayEmpty={true}
									renderValue={(value) =>
										value?.length ? (
											Array.isArray(value) ? (
												value.join(", ")
											) : (
												value
											)
										) : (
											<Typography sx={{ color: palette.gray.dark }}>Error Type</Typography>
										)
									}
								>
									{data?.model?.allErrorTypes?.map((x) => {
										return (
											<MenuItem key={x} value={x}>
												{x}
											</MenuItem>
										);
									})}
								</Select>
							</>
						}
						header={"Error Log"}
						{...props}
					/>
				)}
			</>
		);
	}

	const [activeErrorId, setActiveErrorId]: [null | number, Dispatch<SetStateAction<null | number>>] = React.useState(null as null | number);
	const [open, setOpen] = React.useState(false);
	const handleOpen = (row: any) => {
		setActiveErrorId(row.id);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<Card elevation={4}>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ display: "flex", height: "100%" }}>
							<Box
								sx={{
									flexGrow: 1,
								}}
							>
								{data && (
									<DataGridPro
										{...grid}
										autoHeight
										rows={data?.model?.errorLogs ?? []}
										rowCount={data?.model?.errorCount}
										components={{ Toolbar: Toolbar }}
										componentsProps={{
											toolbar: {
												onSearch: (searchString: string) => {
													onSearchEntered(searchString);
												},
												initialSearch: query.searchQuery ?? "",
											},
										}}
										sx={{ pt: 1 }}
									/>
								)}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Grid>
			<FullWidthDialog
				title={"Error " + activeErrorId?.toString() ?? "Unknown Error"}
				open={open}
				handleClose={handleClose}
				component={<ErrorDetails id={activeErrorId} />}
			/>
		</Box>
	);
}

export default ErrorLog;
