const ObjectMerger = {
	CombineObjectNumericProperties: <Type>(object1: Type, object2: Partial<Type>, updateObject1: boolean = false, ignoreProps: Array<string> | null = null) => {
		let objectX = updateObject1 ? object1 : { ...object1 };
		// let numericProps1 = Object.getOwnPropertyNames(response).filter((prop) => typeof (response as CIM)[prop] === "number");
		let numericProps2 = Object.getOwnPropertyNames(object2).filter((prop) => typeof (object2 as CIM)[prop] === "number");
		numericProps2.forEach((prop) => {
			if (ignoreProps && ignoreProps.indexOf(prop) !== -1) {
				return;
			}
			let vX = (objectX as CIM)[prop];
			let v2 = (object2 as CIM)[prop];
			if (typeof v2 === "number") {
				if (typeof vX === "number") {
					(objectX as CIM)[prop] = vX + v2;
				} else if (vX === undefined) {
					(objectX as CIM)[prop] = v2;
				}
			}
		});
		return objectX;
	},
};

export { ObjectMerger };
