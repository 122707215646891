import { ModelsPaginationAndSortRequest } from "api";

const ReducerUtility = {
	UpdateWithPartialValue: <Type>(state: Type, action: Partial<Type>) => {
		/*
		Add like this in useReducer
		(x: FormValues, action: Partial<FormValues>) => {
			return formUtilities.formValuesReducer<FormValues>(x, action);
		}
		*/
		return Object.assign({}, state, action);
	},

	UpdatePaginationRequestWithPartialValue: (
		state: ModelsPaginationAndSortRequest,
		action: Partial<ModelsPaginationAndSortRequest>
	): ModelsPaginationAndSortRequest => {
		/*
		Add like this in useReducer
		(x: FormValues, action: Partial<FormValues>) => {
			return formUtilities.formValuesReducer<FormValues>(x, action);
		}

		// Previously was splitting the field on '.' into [field].[subfield] but has changed due to complexity with modularization and the SortBarSelection component
		// this logic can now take place in the useX_ function
		*/

		return Object.assign({}, state, action);
	},
};

export { ReducerUtility };
