import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { GetProductServiceDropdownListParams, getProductServiceDropdownList } from "api";
import { useQuery } from "react-query";

export const useX_getProductServiceDropdown = (req: GetProductServiceDropdownListParams) => {
	let fqrX = useFilterQuery();

	let request: GetProductServiceDropdownListParams = {
		jobIds: fqrX.memberTypes.toString(),
		searchQuery: req.searchQuery,
	};

	console.debug(request);

	let cacheKeys = ["getProductServiceDropdownList", "getProductServiceDropdownList_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getProductServiceDropdownList.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
