import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useLocationState<T>(key: string, defaultState: T) {
	const navigate = useNavigate();
	const { pathname, state } = useLocation();
	const currentState = state?.[key] ?? defaultState;

	const updateState = useCallback(
		(next: T) => {
			navigate(pathname, { state: { ...state, [key]: next } });
		},
		[navigate, state, key, pathname]
	);

	return [currentState, updateState] as const;
}
