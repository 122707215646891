import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetDoctorsByMedicationQuery, ModelsPaginationAndSortRequest, getDoctorsByMedication } from "api";
import { useQuery } from "react-query";
import { MedicationTypeApiRequest } from "sections/item_details/medication/MedicationDetailsMain";

export const useX_GetDoctorByMedication = (req: MedicationTypeApiRequest, pagination?: ModelsPaginationAndSortRequest) => {
	let fqrX = useFilterQuery();

	let request: AdminGetDoctorsByMedicationQuery = {
		dateRangeRequest: {
			startDate: req.startDate,
			endDate: req.endDate,
		},
		jobIds: fqrX.memberTypes,
		codes: req.codes,
		paginationSortRequest: pagination,
	};

	console.debug(request);

	let cacheKeys = ["getDoctorByMedication", "GetDoctorByMedication_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDoctorsByMedication.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
