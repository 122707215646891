import { ReactNode } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { ModelsHCPCSBase } from "api";
import { useX_getProductServiceDropdown } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceDropdown";
import { useSearchInput, useSearchText } from "shared/utilities/search";

type ProcedureSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setSelectedValue?: (values: string | null) => void;
};
function ProcedureSelect(props: Readonly<ProcedureSelectProps>) {
	const { setValue } = useSearchInput();
	const searchText = useSearchText();
	let dropdownOptions = useX_getProductServiceDropdown({ searchQuery: searchText }).data?.procedures;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			loading
			getOptionLabel={(code: ModelsHCPCSBase) => {
				return code.code + ": " + code?.title;
			}}
			isOptionEqualToValue={(option: ModelsHCPCSBase, value: ModelsHCPCSBase) => {
				return option.code === value.code;
			}}
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setValue(value);
			}}
			onChange={(_event, currentValue: ModelsHCPCSBase | null) => {
				props.setSelectedValue && props.setSelectedValue(currentValue?.code ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"CPT code / name"} />}
		/>
	);
}
export { ProcedureSelect };
