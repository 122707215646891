import { Box, Stack } from "@mui/material";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import { SignInPage } from "./SignInPage";
import { SignOutPage } from "./SignOutPage";
import logo from "radius-logo-white.png";
import { palette } from "theme";
import ResetPasswordLandingPage from "./ResetPasswordLandingPage";
import InvitationLandingPage from "./InvitationLandingPage";
import { Routes, Route, Navigate } from "react-router-dom";

export function PublicRoutes() {
	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				minHeight: "100vh",
				"&:before": {
					content: "''",
					position: "absolute",
					top: "0",
					left: "0",
					width: "100%",
					height: "100%",
					backgroundColor: `${palette.gray.light}`,
					opacity: ".8",
					zIndex: "0",
				},

				"& > .MuiCard-root": {
					zIndex: "1",
				},
			}}
		>
			<Box
				sx={{
					position: "relative",
					zIndex: "1",
					maxWidth: "400px",
					width: "100%",
					pt: 3,
					pb: 8,

					"&:before": {
						content: "''",
						position: "absolute",
						top: "0",
						left: "50px",
						width: "300px",
						height: "100%",
						backgroundColor: `${palette.primary.main}`,
						opacity: ".9",
						zIndex: "-1",
						borderRadius: "2rem",
					},
				}}
			>
				<Box sx={{ width: "100%", textAlign: "center", pb: 2 }}>
					<img src={logo} alt="logo" />
				</Box>
				<Routes>
					<Route path="/signin" element={<SignInPage />} />
					<Route path="/signout" element={<SignOutPage />} />
					<Route path="/forgot-password" element={<ForgotPasswordPage />} />
					<Route path="/reset-password" element={<ResetPasswordPage />} />
					<Route path="/reset-password-landing-page" element={<ResetPasswordLandingPage />} />
					<Route path="/invitation-landing-page/:code" element={<InvitationLandingPage />} />
					<Route path="*" element={<Navigate replace to="/signin" />} />
				</Routes>
			</Box>
		</Stack>
	);
}
