import { Box } from "@mui/material";
import ValueTile from "../../../../shared/components/ValueTile";
import { displayConverter } from "../../../../shared/utilities/displayConverter";

import { Swiper, SwiperSlide } from "swiper/react";
import { ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse } from "api";
import { Loader } from "shared/components/Loaders/Loader";

// import SwiperCore, { Pagination } from "swiper"; // ignore this is commented for reference
// SwiperCore.use([Pagination]); // works without this - if its needed once per app then do it in an outer module

function DoctorOverviewHeaderTiles({ overview_data }: { overview_data: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse[] | null }) {
	return (
		<Box
			sx={{
				"& .swiper-container": {
					pb: { xs: 4, xl: 0 },
				},
				"& .swiper-pagination-bullets": {
					bottom: "0",
				},
			}}
		>
			<Swiper
				spaceBetween={16}
				slidesPerView={1}
				onSlideChange={() => console.log("slide change")}
				onSwiper={(swiper) => console.log(swiper)}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"700": {
						slidesPerView: 3,
					},
					"1200": {
						slidesPerView: 4,
					},
					"1500": {
						slidesPerView: 5,
					},
					"1900": {
						slidesPerView: 5,
					},
				}}
			>
				{overview_data ? (
					<>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "PROVIDER")?.distinctCodeCount)}
								title="Total Doctors"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "PATIENT")?.eobCount)}
								title="Total Records"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "MEDICATION")?.eobCount)}
								title="Total Prescriptions"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "PRODUCTSERVICE")?.eobCount)}
								title="Total Procedures"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "DIAGNOSIS")?.eobCount)}
								title="Total Diagnoses"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.toUSD(Number(overview_data?.find((x) => x.itemType === "PROVIDER")?.totalAmount))}
								title="Total Billing"
								minHeight="7rem"
							/>
						</SwiperSlide>
					</>
				) : (
					<Loader />
				)}
			</Swiper>
		</Box>
	);
}

export { DoctorOverviewHeaderTiles };
