/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ErrorsGetErrorReportsResult,
  GetErrorReportsParams,
  ErrorLog,
  GetErrorByIdParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getErrorReports = (
    params?: GetErrorReportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ErrorsGetErrorReportsResult>(
      {url: `/api/admin/Error/ErrorReports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetErrorReportsQueryKey = (params?: GetErrorReportsParams,) => [`/api/admin/Error/ErrorReports`, ...(params ? [params]: [])];

    
export const useGetErrorReports = <TData = AsyncReturnType<typeof getErrorReports>, TError = unknown>(
 params?: GetErrorReportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getErrorReports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetErrorReportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getErrorReports>> = () => getErrorReports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getErrorReports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getErrorById = (
    params?: GetErrorByIdParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ErrorLog>(
      {url: `/api/admin/Error/ErrorById`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetErrorByIdQueryKey = (params?: GetErrorByIdParams,) => [`/api/admin/Error/ErrorById`, ...(params ? [params]: [])];

    
export const useGetErrorById = <TData = AsyncReturnType<typeof getErrorById>, TError = unknown>(
 params?: GetErrorByIdParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getErrorById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetErrorByIdQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getErrorById>> = () => getErrorById(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getErrorById>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

