import { Card, CardContent, SxProps, Typography } from "@mui/material";
import { useMemo } from "react";

interface ValueTileProps {
	value?: string | number | null;
	title: string;
	minHeight?: string;
	active?: boolean;
	onClick?: () => any;
	primaryColor?: string;
	secondaryColor?: string;
	textColor?: string;
}

function ValueTile(props: ValueTileProps) {
	const primaryColor = props.primaryColor ?? "purple.main";
	const secondaryColor = props.secondaryColor ?? "gray.main";
	const textColor = props.textColor ?? "gray.dark";

	const sx = useMemo(() => {
		let sx_: SxProps = {
			backgroundColor: secondaryColor,
			width: "100%",
			minHeight: props.minHeight,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderColor: secondaryColor,
			borderWidth: ".2rem",
			borderStyle: "solid",
			boxSizing: "border-box",
		};

		if (props.onClick) {
			sx_ = Object.assign(sx_ as CIM, {
				cursor: "pointer",
				"&:hover": {
					opacity: 0.6,
				},
			});
		}

		if (props.active) {
			sx_ = Object.assign(sx_ as CIM, {
				cursor: "pointer",
				"&:hover": {
					opacity: 1,
				},
				borderColor: primaryColor,
			});
		}

		return sx_;
	}, [props.onClick, props.active, props.minHeight, secondaryColor, primaryColor]);

	return (
		<Card className="value-tile" elevation={0} sx={sx} onClick={props.onClick ? props.onClick : undefined}>
			<CardContent sx={{ textAlign: "center" }}>
				<Typography variant="h2" sx={{ color: primaryColor, fontWeight: 400 }}>
					{props.value}
				</Typography>
				<Typography
					component="span"
					sx={{
						textTransform: "uppercase",
						fontWeight: 700,
						color: textColor,
					}}
				>
					{props.title}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default ValueTile;
