import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { Stack, Button, TextField, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { createRef, useState } from "react";
import { palette } from "theme";
import { useDebounce } from "react-use";

export interface DataGridToolbarProps {
	readonly header: React.ReactNode;
	readonly subheader?: React.ReactNode;
	readonly addButtonLabel?: string;
	readonly addButtonLink?: string;
	readonly initialSearch?: string;
	readonly enableExport?: boolean;
	readonly enableFilter?: boolean;
	readonly extraData?: React.ReactNode;
	onAdd?(): void;
	onSearch?(text: string): void;
}

function DataGridToolbar(gridToolBarProps: DataGridToolbarProps) {
	let { header, subheader, addButtonLabel, addButtonLink, initialSearch, extraData, onAdd, onSearch, enableExport, enableFilter } = gridToolBarProps;
	const [state, setState] = useState(initialSearch);
	const searchFieldRef = createRef<HTMLInputElement>();

	useDebounce(
		() => {
			onSearch?.(state || "");
		},
		500,
		[state]
	);

	return (
		<GridToolbarContainer>
			<Stack
				className="data-grid-toolbar"
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				width="100%"
				flexWrap="wrap"
				sx={{
					"& > div": {
						width: { xs: "100%", md: "auto" },
					},
				}}
			>
				<Stack>
					<Typography
						variant="subtitle1"
						sx={{
							textTransform: "uppercase",
							fontSize: "1.25rem",
							fontWeight: 700,
						}}
					>
						{header}
					</Typography>
					{!!subheader && <Typography variant="subtitle2">{subheader}</Typography>}
				</Stack>
				<Stack direction="row" flexWrap="wrap">
					{extraData}
					{!!onSearch && (
						<TextField
							autoFocus
							placeholder="Search..."
							inputRef={searchFieldRef}
							value={state}
							sx={{
								width: { xs: "100%", sm: "auto" },
								mb: { xs: 1, md: 0 },
							}}
							onChange={(e) => {
								setState(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<IconButton
										onClick={() => {
											searchFieldRef.current?.focus();
											setState("");
										}}
									>
										<ClearIcon />
									</IconButton>
								),
							}}
						></TextField>
					)}
					{enableExport === false && enableFilter === false ? null : (
						<Stack direction="row" flexWrap="wrap" sx={{ width: { xs: "100%", sm: "auto" } }}>
							{enableExport !== false ? (
								<GridToolbarExport
									sx={{
										backgroundColor: palette.gray.dark,
										width: { xs: "50%", sm: "auto" },
										mb: { xs: 1, md: 0 },
										ml: 1,
									}}
									printOptions={{ disableToolbarButton: true }}
								/>
							) : null}
							{enableFilter !== false
								? // <GridToolbarFilterButton
								  // 	{...gridToolBarProps}
								  // 	sx={{
								  // 		backgroundColor: palette.gray.dark,
								  // 		width: { xs: "50%", sm: "auto" },
								  // 		mb: { xs: 1, md: 0 },
								  // 		ml: 1,
								  // 	}}
								  // />
								  null
								: //   null
								  null}
						</Stack>
					)}
					{!!addButtonLabel && (
						<Button
							sx={{
								minWidth: "auto",
								whiteSpace: "nowrap",
								backgroundColor: palette.gray.dark,
								ml: 1,
							}}
							onClick={() => onAdd?.()}
							href={addButtonLink}
						>
							{addButtonLabel}
						</Button>
					)}
				</Stack>
			</Stack>
		</GridToolbarContainer>
	);
}

export { DataGridToolbar };

function CreateDataGridToolbar(header: string) {
	return (props: DataGridToolbarProps) => {
		return <DataGridToolbar {...props} header={header} enableExport={false} enableFilter={false} />;
	};
}

export { CreateDataGridToolbar };
