import { createContext, useEffect, useState } from "react";
import { DateConverter } from "shared/utilities/DateConverter";

/*
██████  ██████  ██ ███    ███  █████  ██████  ██    ██     ███████ ██ ██      ████████ ███████ ██████  ███████ 
██   ██ ██   ██ ██ ████  ████ ██   ██ ██   ██  ██  ██      ██      ██ ██         ██    ██      ██   ██ ██      
██████  ██████  ██ ██ ████ ██ ███████ ██████    ████       █████   ██ ██         ██    █████   ██████  ███████ 
██      ██   ██ ██ ██  ██  ██ ██   ██ ██   ██    ██        ██      ██ ██         ██    ██      ██   ██      ██ 
██      ██   ██ ██ ██      ██ ██   ██ ██   ██    ██        ██      ██ ███████    ██    ███████ ██   ██ ███████ 
*/

type BasicDateRangeDates = { start: null | Date; end: null | Date };
type BasicDateRangeStrings = { start: null | string; end: null | string };
type PrimaryFilters = {
	Members: Array<string>;
	MembersInitialized: boolean;
	DateRange: Array<string>;
	DateRangeCustomDates: BasicDateRangeDates;
	RiskModel: string;
	Distance: Array<string>;
	InsuranceTypeEobDateRanges: { [key: string]: BasicDateRangeDates };
	InsuranceTypeMembersCount: { [key: string]: number };
	DateRangeLimits: { start_limit: Date; end_limit: Date; start_limit_member: string; end_limit_member: string };
};

type PrimaryFiltersUpdate = Partial<PrimaryFilters>;

let endOfMostRecentMonth = DateConverter.getEndOfMostRecentMonth();
let start = DateConverter.getLeadingEdgeDateForMonthsAgo(12, endOfMostRecentMonth);

let ls_pf = localStorage.getItem("RadiusAdminPrimaryFilters");
let localStoragePrimaryFilters: PrimaryFiltersUpdate = {};
if (ls_pf) {
	let parse: Partial<PrimaryFilters> | null = null;
	try {
		parse = JSON.parse(ls_pf);
	} catch {}
	if (parse) {
		delete parse.InsuranceTypeEobDateRanges;
		if (parse.DateRangeCustomDates) {
			delete parse.DateRangeCustomDates; // Currently deleting the date range filters they will be reset on reload
			// These will be stored as strings after JSON conversion - need to be Dates or will cause errors
			// if (parse.DateRangeCustomDates.start) {
			// 	parse.DateRangeCustomDates.start = new Date(parse.DateRangeCustomDates.start);
			// }
			// if (parse.DateRangeCustomDates.end) {
			// 	parse.DateRangeCustomDates.end = new Date(parse.DateRangeCustomDates.end);
			// }
		}
		delete parse.DateRange;
		delete parse.MembersInitialized;
		delete parse.DateRangeLimits;

		if (parse.Members) {
			localStoragePrimaryFilters.Members = parse.Members;
		}
	}
}

let initial_primary_filters: PrimaryFilters = {
	Members: localStoragePrimaryFilters?.Members ?? [],
	MembersInitialized: false,

	DateRange: ["Custom"], // ["All"]
	DateRangeCustomDates: { start: start, end: endOfMostRecentMonth },
	RiskModel: "RadiusCare",
	Distance: [],
	InsuranceTypeEobDateRanges: {},
	InsuranceTypeMembersCount: {},
	DateRangeLimits: { start_limit: new Date("2016-01-01"), end_limit: new Date(Date.now()), start_limit_member: "default", end_limit_member: "default" },
};

const PrimaryFiltersContext = createContext({
	values: initial_primary_filters,
	set_PrimaryFiltersValues: (u: PrimaryFiltersUpdate) => {},
});

/*






*/
function Context_PrimaryFilters_Provider(props: { children: React.ReactNode }) {
	const [primaryFilters, setPrimaryFilters] = useState(initial_primary_filters);

	const wrappedSetPrimaryFilters = (update: PrimaryFiltersUpdate) => {
		let pf = Object.assign({}, primaryFilters, update);
		localStorage.setItem("RadiusAdminPrimaryFilters", JSON.stringify(pf));
		setPrimaryFilters(pf);
	};

	// SET the start and end ranges limit for the currently selected MemberTypes/JobIds
	useEffect(() => {
		let start_limit: Date | null = null;
		let end_limit: Date | null = null;
		let start_limit_member: string = "none";
		let end_limit_member: string = "none";
		if (primaryFilters.Members && primaryFilters.InsuranceTypeEobDateRanges) {
			let x = primaryFilters.InsuranceTypeEobDateRanges;
			primaryFilters.Members.forEach((member) => {
				let memberDateRange = x[member];
				if (memberDateRange) {
					if (memberDateRange.start) {
						if (!start_limit || start_limit > memberDateRange.start) {
							start_limit = memberDateRange.start;
							start_limit_member = member;
						}
					}
					if (memberDateRange.end) {
						if (!end_limit || end_limit < memberDateRange.end) {
							end_limit = memberDateRange.end;
							end_limit_member = member;
						}
					}
				}
			});
		}
		if (!start_limit) {
			start_limit = new Date(Date.parse("2016-01-01")); // default to 2016 min start if no members selected
		}
		if (!end_limit) {
			end_limit = new Date(Date.now());
		}

		let start = primaryFilters.DateRangeCustomDates.start;
		let end = primaryFilters.DateRangeCustomDates.end;
		if (!end || end > end_limit) {
			end = DateConverter.getEndOfMonth(end_limit);
		}
		if (!start || start < start_limit) {
			start = DateConverter.getStartOfMonth(start_limit);
		}
		let months6BeforeEnd = DateConverter.getStartOfMonth(DateConverter.subtractMonths(new Date(end.getTime() + 600000), 6)); // dont want to rely on 1 millisecond resolution and it is inconsistent so push it 10mins into the next month
		if (start_limit < months6BeforeEnd) {
			start = months6BeforeEnd;
		}
		wrappedSetPrimaryFilters({
			DateRangeLimits: { start_limit, end_limit, start_limit_member, end_limit_member },
			DateRangeCustomDates: { start, end },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primaryFilters.Members, primaryFilters.Members.length, primaryFilters.InsuranceTypeEobDateRanges]);

	window.RadiusCare.developer.test["PrimaryFilters"] = primaryFilters;
	return (
		<PrimaryFiltersContext.Provider
			value={{
				values: primaryFilters,
				set_PrimaryFiltersValues: wrappedSetPrimaryFilters,
			}}
		>
			{props.children}
		</PrimaryFiltersContext.Provider>
	);
}

export { PrimaryFiltersContext, Context_PrimaryFilters_Provider as ContextPrimaryFiltersProvider };

export type { PrimaryFilters, BasicDateRangeDates, BasicDateRangeStrings };
