import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, InputLabel, MenuItem, OutlinedInput, Rating, Select, Stack, Typography } from "@mui/material";
import {
	AdminGetHistoricalDataResult,
	AdminGetSubLevelRangesResult,
	AdminGetTopLevelRangesResult,
	ModelsICD10Short,
	ProvidersHistoricalLookupRecord,
	getDropdownData,
	getHistoricalData,
	getIcdCodesFromRanges,
	getSubLevelRanges,
	getTopLevelRanges,
	useGetStateCountyList,
} from "api";

import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { CartesianGrid } from "recharts";
import { palette } from "theme";
import { Loader } from "shared/components/Loaders/Loader";
import { Controller, useForm } from "react-hook-form";
import * as ChartGeo from "chartjs-chart-geo";
import FullWidthDialog from "shared/Dialog/FullWidthDialog";
import HistoricalProviderDetail from "./HistoricalProviderDetail";
import { Chart, Tooltip } from "chart.js";
import ReportingSelect from "sections/reporting/ReportingSelect";
import IcdMoneySummaryCard from "./IcdMoneySummaryCard";
import TopRelatedConditionsPieChart from "./TopRelatedConditionsPieChart";
import NoDataAvailable from "shared/components/NoDataAvailable";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";

export interface ReportingProps {
	Icd10Code: string;
	Icd10Range: string;
	Icd10SubRange: string;
	Gender: string;
	States: string;
	Race: string;
	Year: string;
	AgeRange: string;
	County: string;
}

export default function Reporting() {
	Chart.register([Tooltip]);
	const gender_colors = [palette.green.main, palette.red.main, palette.purple.main];
	const [data, setGraphData] = useState<AdminGetHistoricalDataResult>();
	const [topLevelRanges, setTopLevelRanges] = useState<AdminGetTopLevelRangesResult | undefined>(undefined);
	const [genders, setGenders] = useState<ProvidersHistoricalLookupRecord[] | undefined>(undefined);
	const [states, setStates] = useState<ProvidersHistoricalLookupRecord[] | undefined>(undefined);
	const [races, setRaces] = useState<ProvidersHistoricalLookupRecord[] | undefined>(undefined);
	const [ageRanges, setAgeRanges] = useState<ProvidersHistoricalLookupRecord[] | undefined>(undefined);
	const [filtersLoader, setFiltersLoader] = useState<boolean>(false);
	const [icd10SubRange, setIcd10SubRange] = useState<AdminGetSubLevelRangesResult>();
	const [icd10Codes, setIcd10Codes] = useState<ModelsICD10Short[]>();
	const [error, setError] = useState<boolean | null>(null);
	const [activeDoctorID, setActiveDoctorID]: [null | number, Dispatch<SetStateAction<null | number>>] = useState(null as null | number);
	const [open, setOpen] = useState(false);
	const { control, getValues, setValue, watch } = useForm<ReportingProps>({
		defaultValues: {
			Icd10Code: "null",
			Icd10Range: "null",
			Icd10SubRange: "null",
			States: "null",
			Gender: "null",
			Race: "null",
			Year: "null",
			AgeRange: "null",
			County: "null",
		},
	});
	const watchIcd10Range = watch("Icd10Range");
	const watchIcd10SubRange = watch("Icd10SubRange");
	const watchIcd10Code = watch("Icd10Code");
	const watchState = watch("States");

	const {
		data: countyList,
		refetch: refetchCounty,
		isRefetching: countyFetching,
	} = useGetStateCountyList({
		//have to pad single digit state codes with 0
		stateCode: getValues("States").toString().padStart(2, "0"),
	});

	const onSubmit = async () => {
		setGraphData(undefined);
		setError(false);
		setFetching(true);
		let subRange = getValues("Icd10Code") === "null" ? getValues("Icd10SubRange") : null;
		let topRange = getValues("Icd10SubRange") === "null" ? getValues("Icd10Range") : null;
		const result = await getHistoricalData({
			icdCode: getValues("Icd10Code"),
			topRange: topRange,
			subRange: subRange,
			state: getValues("States").toString(),
			race: getValues("Race").toString(),
			gender: getValues("Gender").toString(),
			ageRange: getValues("AgeRange").toString(),
			county: getValues("County").toString() !== "null" ? getValues("County").toString().padStart(5, "0") : "null",
		});
		setFetching(false);
		// If there are results, set the display value
		if (result?.genderModels?.length && result?.genderModels?.length > 0) {
			setGraphData(result);
		} else {
			setError(true);
		}
	};

	const handleOpen = (npi: string) => {
		setActiveDoctorID(Number(npi));
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	useEffect(() => {
		setValue("Icd10SubRange", "null");
		setValue("Icd10Code", "null");
		setIcd10SubRange(undefined);
		setIcd10Codes(undefined);
		if (watchIcd10Range === "" || watchIcd10Range === "null") {
			return;
		}
		getRanges();
		async function getRanges() {
			setFiltersLoader(true);
			const res = await getSubLevelRanges({ icd10Range: watchIcd10Range });
			setIcd10SubRange(res);
			setFiltersLoader(false);
		}
	}, [watchIcd10Range, setIcd10SubRange, setValue]);
	useEffect(() => {
		getRanges();
		async function getRanges() {
			setFiltersLoader(true);
			const topLevel = await getTopLevelRanges();
			const dropdownData = await getDropdownData();
			setGenders(dropdownData.genders);
			setRaces(dropdownData.races);
			setStates(dropdownData.states);
			setAgeRanges(dropdownData.ageRanges);
			setTopLevelRanges(topLevel);
			setFiltersLoader(false);
		}
	}, []);

	useEffect(() => {
		const selectedSubRange = icd10SubRange?.items?.find((x) => x.noDecimalCode === watchIcd10SubRange);
		if (!selectedSubRange?.subRanges) {
			setIcd10Codes(undefined);
			return;
		}
		const rangeCategoryIds: string[] = selectedSubRange?.subRanges?.map((x) => {
			if (x.range !== undefined) {
				return x.range;
			}
			return "";
		});
		getCodes();
		async function getCodes() {
			setFiltersLoader(true);
			setValue("Icd10Code", "null");
			const res = await getIcdCodesFromRanges(rangeCategoryIds);
			setIcd10Codes(res.items);
			setFiltersLoader(false);
		}
	}, [icd10SubRange?.items, setValue, watchIcd10SubRange]);

	//refetch county list when selected state changes
	useEffect(() => {
		if (!countyFetching) {
			refetchCounty();
			setValue("County", "null");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchState]);

	const [fetching, setFetching] = useState(false);

	// Map function
	const map = useRef<HTMLCanvasElement>(null);
	useEffect(() => {
		if (data) {
			fetch("/states.json")
				.then((r) => r.json())
				.then((us) => {
					const nation = (ChartGeo.topojson.feature(us, us.objects.nation) as any).features[0];
					const states = (ChartGeo.topojson.feature(us, us.objects.states) as any).features;
					const x = map.current as any;
					if (!x) {
						return;
					}
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const chart = new ChartGeo.ChoroplethChart(x.getContext("2d"), {
						data: {
							labels: states.map((d: { properties: { name: any } }) => d.properties.name),
							datasets: [
								{
									label: "States",
									outline: nation,
									data: states.map((d: any) => {
										const value = data.top10States?.find((st) => st.name === d.properties?.name)?.percentage;
										return { feature: d, value: value ?? 0 };
									}),
								},
							],
						},
						options: {
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									callbacks: {
										label: function (context) {
											let label = (context.element as any).feature.properties.name || "";
											if (label) {
												label += ": ";
											}
											if (context.parsed.r !== null) {
												label += Math.round(context.parsed.r * 100) / 100 + "%";
											}
											return label;
										},
									},
								},
							},
							scales: {
								projection: {
									axis: "x",
									projection: "albersUsa",
								},
								color: {
									axis: "x",
									quantize: 5,
									legend: {
										position: "bottom-right",
										align: "bottom",
									},
									interpolate: "reds",
								},
							},
						},
					});
				});
		}
	}, [map, data]);

	const queryChips = useMemo(() => {
		let icd10QueriedCodes = <></>;
		if (watchIcd10Code !== "null") {
			const code = icd10Codes?.find((x) => x.code === watchIcd10Code);
			const displayCode = code?.description; // && code?.code?.length > 3 ? code?.code?.slice(0, 3) + "." + code?.code?.slice(3) : code?.code;

			icd10QueriedCodes = <Chip sx={{ ml: 1 }} label={displayCode} />;
		} else if (watchIcd10SubRange !== "null") {
			const selectedSubRange = icd10SubRange?.items?.find((x) => x.noDecimalCode === watchIcd10SubRange);
			icd10QueriedCodes = <Chip sx={{ ml: 1 }} label={selectedSubRange?.name} />;
		} else {
			const selectedTopRange = topLevelRanges?.items?.find((x) => x.noDecimalCode === watchIcd10Range);
			icd10QueriedCodes = <Chip sx={{ ml: 1 }} label={selectedTopRange?.name} />;
		}

		return (
			<>
				{icd10QueriedCodes}
				{getValues("Gender") !== "null" && <Chip sx={{ ml: 1 }} label={genders?.find((x) => (x.code as any) === getValues("Gender"))?.description} />}
				{getValues("AgeRange") !== "null" && (
					<Chip sx={{ ml: 1 }} label={ageRanges?.find((x) => (x.code as any) === getValues("AgeRange"))?.description} />
				)}
				{getValues("Race") !== "null" && <Chip sx={{ ml: 1 }} label={races?.find((x) => (x.code as any) === getValues("Race"))?.description} />}
				{getValues("States") !== "null" && <Chip sx={{ ml: 1 }} label={states?.find((x) => (x.code as any) === getValues("States"))?.description} />}
				{getValues("County") !== "null" && (
					<Chip sx={{ ml: 1 }} label={countyList?.counties?.find((x) => (x.code as any) === getValues("County"))?.description} />
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Card elevation={4} sx={{ mb: 2 }}>
							{filtersLoader ? (
								<Loader />
							) : (
								<>
									<CardHeader title="Filters" />
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={12} md={3}>
												{topLevelRanges && (
													<Controller
														control={control}
														name="Icd10Range"
														render={({ field: { onChange, value } }) => (
															<Box sx={{ flexDirection: "column" }}>
																<InputLabel id="ranges-input-label">Icd10 Range</InputLabel>
																<Select
																	labelId="ranges-input-label"
																	id="ranges-select"
																	value={value}
																	onChange={onChange}
																	input={<OutlinedInput label="Range" />}
																	sx={{ display: "flex" }}
																>
																	<MenuItem key={"all-range"} value={"null"}>
																		All Ranges
																	</MenuItem>
																	{topLevelRanges.items?.map((range) => (
																		<MenuItem key={range.id} value={range.noDecimalCode ?? ""}>
																			{range.id + ": " + range.name?.split("(")[0]}
																		</MenuItem>
																	))}
																</Select>
															</Box>
														)}
													/>
												)}
											</Grid>
											<Grid item xs={12} md={3}>
												{icd10SubRange ? (
													<Controller
														control={control}
														name="Icd10SubRange"
														render={({ field: { onChange, value } }) => (
															<Box sx={{ flexDirection: "column" }}>
																<InputLabel id="ranges-input-label">Icd10 Sub-Range</InputLabel>
																<Select
																	labelId="ranges-input-label"
																	id="ranges-select"
																	value={value}
																	onChange={onChange}
																	input={<OutlinedInput label="Range" />}
																	sx={{ display: "flex", pt: 0.5 }}
																>
																	<MenuItem key={"all-sub"} value={"null"}>
																		All Sub-Ranges
																	</MenuItem>
																	{icd10SubRange.items?.map((range) => (
																		<MenuItem key={range.id} value={range.noDecimalCode ?? ""}>
																			{range.id + ": " + range.name?.split("(")[0]}
																		</MenuItem>
																	))}
																</Select>
															</Box>
														)}
													/>
												) : null}
											</Grid>
											<Grid item xs={12} md={3}>
												{icd10Codes ? (
													<Controller
														control={control}
														name="Icd10Code"
														render={({ field: { onChange, value } }) => (
															<Box sx={{ flexDirection: "column" }}>
																<InputLabel id="ranges-input-label">Icd10 Codes</InputLabel>
																<Select
																	labelId="ranges-input-label"
																	id="ranges-select"
																	value={value}
																	onChange={onChange}
																	input={<OutlinedInput label="Range" />}
																	sx={{ display: "flex", pt: 0.5 }}
																>
																	<MenuItem key={"all-codes"} value={"null"}>
																		All Icd10 Codes
																	</MenuItem>
																	{icd10Codes.map((code) => {
																		const displayCode =
																			code.code.length > 3 ? code.code.slice(0, 3) + "." + code.code.slice(3) : code.code;
																		return (
																			<MenuItem key={code.code} value={code.code ?? ""}>
																				{displayCode + ": " + code.description}
																			</MenuItem>
																		);
																	})}
																</Select>
															</Box>
														)}
													/>
												) : null}
											</Grid>
											<Grid item xs={12} md={3}></Grid>
											<Grid item xs={12} md={3}>
												<ReportingSelect name="States" label="States" data={states} control={control} />
											</Grid>
											<Grid item xs={12} md={3}>
												<ReportingSelect name="Gender" label="Genders" data={genders} control={control} />
											</Grid>
											<Grid item xs={12} md={3}>
												<ReportingSelect name="Race" label="Races" data={races} control={control} />
											</Grid>
											<Grid item xs={12} md={3}>
												<ReportingSelect name="AgeRange" label="Age Ranges" data={ageRanges} control={control} />
											</Grid>
											<Grid item xs={12} md={3}>
												{watchState !== "null" ? (
													<ReportingSelect name="County" label="Counties" data={countyList?.counties ?? []} control={control} />
												) : null}
											</Grid>
										</Grid>

										<Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
											<Button type="submit" onClick={onSubmit}>
												Generate Report
											</Button>
										</Box>
									</CardContent>
								</>
							)}
						</Card>

						{data ? (
							<>
								<Card elevation={4} sx={{ mb: 1.5 }}>
									<CardHeader title={<>Patient Demographics{queryChips}</>} />
									<CardContent sx={{ pt: 0, display: "flex" }}>
										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												padding: 2,
												borderRadius: "1rem",
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold" }}>GENDER</Typography>
											{data?.genderModels && (
												<StandardPieChart
													data={data?.genderModels as any}
													colors={gender_colors}
													showLegend
													displayPercentage
													responsiveHeight={300}
													innerRadius={32}
													tooltipFormatter={(value, name) => {
														return value + "%";
													}}
												/>
											)}
										</Box>

										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												padding: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold", display: "flex", flexDirection: "column" }}>
												AGE
											</Typography>
											{data?.ageGraphModels && (
												<StandardBarChart
													data={data?.ageGraphModels as any}
													dataKey="name"
													responsiveHeight={300}
													showGrid
													bar_definitions={[
														{
															dataKey: "value",
															name: "Percentile",
															fill: palette.orange.main,
															legendType: "circle",
														},
													]}
													showLegend={false}
													showYAxis
													tooltipFormatter={(value, name) => {
														return value + "%";
													}}
													yAxisTickFormatter={(value, name) => {
														return value + "%";
													}}
													xAxisTickFormatter={(value, name) => {
														if (value.length < 7) {
															return value;
														}
														if (value === null || value === undefined) {
															return "N/A";
														}
														return `${value.substring(0, 4)}...`;
													}}
													cartesianGrid={<CartesianGrid strokeDasharray="1" vertical={false} />}
												/>
											)}
										</Box>
										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												p: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold" }}>RACE</Typography>
											{data?.raceGraphModels && (
												<StandardBarChart
													data={data?.raceGraphModels as any}
													dataKey="name"
													responsiveHeight={300}
													bar_definitions={[
														{
															dataKey: "value",
															name: "Percentile",
															fill: palette.green.main,
															legendType: "circle",
														},
													]}
													showLegend={false}
													showYAxis
													tooltipFormatter={(value, name) => {
														return value + "%";
													}}
													yAxisTickFormatter={(value, name) => {
														return value + "%";
													}}
													xAxisTickFormatter={(value, name) => {
														if (value.length < 7) {
															return value;
														}
														if (value === null || value === undefined) {
															return "N/A";
														}
														return `${value.substring(0, 4)}...`;
													}}
													showGrid
													cartesianGrid={<CartesianGrid strokeDasharray="1" vertical={false} />}
												/>
											)}
										</Box>
									</CardContent>
								</Card>
								{data.icdMoneySummary && data.icdMoneySummary.length > 0 && (
									<IcdMoneySummaryCard data={data.icdMoneySummary} queryChips={queryChips} />
								)}
								<Card elevation={4} sx={{ mb: 1.5 }}>
									<CardHeader title={<>States{queryChips}</>} />
									<CardContent sx={{ pt: 0, display: "flex" }}>
										<Box sx={{ flex: 2 }}>
											<canvas ref={map}></canvas>
										</Box>

										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												p: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold", pb: 1 }}>TOP 10 STATES</Typography>
											<Box>
												{data.top10States?.slice(0, 10).map((state) => {
													return (
														<Box
															display={"flex"}
															flexDirection={"row"}
															justifyContent={"space-between"}
															borderBottom={"1px solid grey"}
															padding={1}
															key={state.name}
														>
															<Typography color={palette.gray.darker} fontWeight={"bold"}>
																{state.rank + ". " + state.name}{" "}
															</Typography>
															<Typography color={palette.red.main} fontWeight={"bold"}>
																{state.percentage + "%"}
															</Typography>
														</Box>
													);
												})}
											</Box>
										</Box>
									</CardContent>
								</Card>
								{/* Only show the top 10 related conditions when there is data to display */}
								{data.top10Icds && data.top10Icds.length > 0 ? (
									<TopRelatedConditionsPieChart title="Top Related Conditions" icds={data.top10Icds} queryChips={queryChips} />
								) : null}
								<Card elevation={4}>
									<CardHeader title={<>Providers{queryChips}</>} />
									<CardContent sx={{ pt: 0, display: "flex" }}>
										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												p: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold", pb: 1 }}>TOP 10 DOCTORS</Typography>
											<Box>
												{data.top10Doctors?.map((doctor) => {
													return (
														<Box
															display={"flex"}
															flexDirection={"row"}
															justifyContent={"space-between"}
															borderBottom={"1px solid grey"}
															padding={1}
															key={(doctor.name ?? "N/A") + (doctor.percentage ?? 0)}
															onClick={(event) => {
																handleOpen(doctor.value ?? "");
															}}
															sx={{ cursor: "pointer" }}
														>
															<Typography color={palette.gray.darker} fontWeight={"bold"}>
																{doctor.rank + ". " + doctor.name} <br />
																{doctor.rating && <Rating size="small" name="read-only" value={doctor.rating} readOnly />}
															</Typography>
															<Typography color={palette.green.main} fontWeight={"bold"}>
																{doctor.percentage?.toFixed(4) + "%"}
															</Typography>
														</Box>
													);
												})}
											</Box>
										</Box>
										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												p: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold", pb: 1 }}>TOP 10 FACILITIES</Typography>
											{data.top10Facilities?.map((facility) => {
												return (
													<Box
														display={"flex"}
														flexDirection={"row"}
														justifyContent={"space-between"}
														borderBottom={"1px solid grey"}
														padding={1}
														key={(facility.name ?? "N/A") + (facility.percentage ?? 0)}
													>
														<Typography color={palette.gray.darker} fontWeight={"bold"}>
															{facility.rank + ". " + facility.name}{" "}
														</Typography>
														<Typography ml={1} color={palette.green.main} fontWeight={"bold"}>
															{facility.percentage?.toFixed(4) + "%"}
														</Typography>
													</Box>
												);
											})}
										</Box>
										<Box
											sx={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												borderRadius: "1rem",
												p: 2,
												m: 1,
												backgroundColor: palette.gray.main,
											}}
										>
											<Typography sx={{ color: palette.gray.dark, fontWeight: "bold", pb: 1 }}>TOP 10 SPECIALTIES</Typography>
											{data.top10Taxonomies?.map((taxonomy) => {
												return (
													<Box
														display={"flex"}
														flexDirection={"row"}
														justifyContent={"space-between"}
														borderBottom={"1px solid grey"}
														padding={1}
														key={taxonomy.name}
													>
														<Typography color={palette.gray.darker} fontWeight={"bold"}>
															{taxonomy.rank + ". " + taxonomy.name + " (" + taxonomy.value + ")"}{" "}
														</Typography>
														<Typography ml={1} color={palette.green.main} fontWeight={"bold"}>
															{taxonomy.percentage?.toFixed(4) + "%"}
														</Typography>
													</Box>
												);
											})}
										</Box>
									</CardContent>
								</Card>
							</>
						) : fetching ? (
							<>
								<Card elevation={4}>
									<CardContent>
										<Stack justifyContent={"center"} alignItems={"center"}>
											<Loader />
											<Typography color={palette.primary.main} pb={4} variant={"h3"}>
												Calculating results, may take up to 20 seconds
											</Typography>
										</Stack>
									</CardContent>
								</Card>
							</>
						) : null}
						{error && (
							<Card>
								<CardContent>
									<NoDataAvailable />
								</CardContent>
							</Card>
						)}
					</Grid>
				</Grid>
			</Box>
			<FullWidthDialog title={""} open={open} handleClose={handleClose} component={<HistoricalProviderDetail doctorID={activeDoctorID} />} />
		</>
	);
}
