import { Box } from "@mui/material";
import { ProcessedResponseErrorType } from "shared/utilities/apiResponseReader";
import { CSSProperties, ReactNode } from "react";
import { palette } from "theme";

type ErrorDisplayProps = {
	errorType?: ProcessedResponseErrorType | null;
	errorMessage?: null | ReactNode | string;
	errorContent?: ReactNode;
	styling?: CSSProperties;
};
const ErrorDisplay = ({ errorType, errorMessage, errorContent, styling }: ErrorDisplayProps) => {
	let errorTypeStyle: any;
	switch (errorType) {
		case "POSITIVE":
			errorTypeStyle = { color: palette.green.main, borderColor: palette.green.main, backgroundColor: palette.gray.light };
			break;
		case "NEUTRAL":
			errorTypeStyle = { color: palette.info.main, borderColor: palette.info.main, backgroundColor: palette.gray.light };
			break;
		default:
			errorTypeStyle = {};
	}

	return (
		<Box
			data-name="ErrorDisplay"
			sx={Object.assign(
				{
					flexGrow: 1,
					padding: "1.5rem",
					borderRadius: "1rem",
					fontWeight: 600,
					fontSize: "1.25rem",
					textAlign: "center",
					borderStyle: "solid",
					borderWidth: "2px",
					backgroundColor: palette.gray.light,
					borderColor: palette.error.main,
					color: palette.error.main,
				},
				errorTypeStyle,
				styling
			)}
		>
			{errorMessage}
			{errorContent ? <Box style={{ marginTop: "16px" }}>{errorContent}</Box> : null}
		</Box>
	);
};

export { ErrorDisplay };
