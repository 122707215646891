import { TextField, Autocomplete, SxProps, Theme } from "@mui/material";
import { Condition, SubRange } from "../../../shared/schemas/dataStructures";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { ReactNode } from "react";
import { DiagnosisSummary } from "../hooks/useDiagnosisSummaryList";
import { ModelsConditionRecord } from "api";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";

type SubRangeSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	condition?: Condition;
	diagnosisSummary?: DiagnosisSummary;
	styles?: SxProps<Theme>;
	subRanges?: ModelsConditionRecord[];
	setIcd10Value?: () => void;
};
function SubRangeSelect(props: SubRangeSelectProps) {
	const [{ Conditions }, diagnosisFilters] = [useContextCodeDefinitions(), useDiagnosisFilters()];
	let condition: Condition | undefined;
	if (!props.condition) {
		if (props.diagnosisSummary && Conditions?.data) {
			condition = Conditions.data.find((a) => a.id === props.diagnosisSummary?.id);
		}
	} else {
		condition = props.condition;
	}
	if (!condition) {
		return null;
	} else {
		const value = diagnosisFilters.values.SubRangeByCondition[condition.id] ?? "NO_SELECTION";
		return props.subRanges && props.subRanges.length > 0 ? (
			<Autocomplete
				sx={{
					minWidth: "30rem",
					width: "100%",
					...props.styles,
				}}
				autoComplete
				disableClearable
				value={value}
				options={props.subRanges ?? []}
				getOptionLabel={(option) => {
					if (option.name && option.simpleRange !== "") {
						return option.simpleRange + ": " + option.name;
					}
					return "All Sub-Ranges";
				}}
				isOptionEqualToValue={(option: any, value: any) => {
					if (option.name === "All Sub-Ranges" && value === "NO_SELECTION") {
						return true;
					}
					return option.range === value.range;
				}}
				onChange={(_event, currentValue) => {
					let subRangeUpdate: { [key: string]: SubRange } = {};
					subRangeUpdate[condition?.id as string] = currentValue as SubRange;

					// Reset the Icd10 Select
					let conditionUpdate: { [key: string]: SubRange } = {};
					const icd10Codes: SubRange = {
						range: "",
						code: "",
						description: "All Codes",
					};
					conditionUpdate[condition?.id as string] = icd10Codes;
					diagnosisFilters.setValues({ SubRangeByCondition: subRangeUpdate, ICD10CodesByCondition: conditionUpdate });
				}}
				renderInput={(params) => (
					<TextField {...params} label="" placeholder={props.placeholder ? props.placeholder : condition?.name ? "Filter by Sub Range" : ""} />
				)}
			/>
		) : null;
	}
}
export { SubRangeSelect };
