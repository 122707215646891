import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey("ed1f8e535e8bf5d12691f464dd7fa576T1JERVI6MzY0MzYsRVhQSVJZPTE2NzQ0MTU0ODEwMDAsS0VZVkVSU0lPTj0x");

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
