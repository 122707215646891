import { MedicationGetConditionsByMedicationQuery } from "api/backend.schemas";
import { getMedicationImpact } from "api/cache-medication-data/cache-medication-data";
import { useQuery } from "react-query";

function getMedicationDetailsImpactX(request: Partial<MedicationGetConditionsByMedicationQuery>, specialRequest: "RETRIEVE_FULL" | null) {
	console.count("SENDREQUEST getImpactByMedication");
	return getMedicationImpact(Object.assign(request, { specialRequest: specialRequest }));
}

const useX_getMedicationDetailsImpact = (request: Partial<MedicationGetConditionsByMedicationQuery>, specialRequest: "RETRIEVE_FULL" | null) => {
	let cacheKeys = ["getMedicationImpact", "getMedicationImpact" + JSON.stringify(request) + specialRequest];
	return useQuery(cacheKeys, getMedicationDetailsImpactX.bind(null, request, specialRequest), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getMedicationDetailsImpact };
