import { Box, Tab } from "@mui/material";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface TabControlProps {
	label: string;
	value: number;
}

const TabsUtilities = {
	proto_TabControl: (base_id: string, props: TabControlProps) => {
		const { label, value, ...muiInternalStuff } = props;
		return (
			<Tab role="tab" label={label} id={`${base_id}-tab-${value}`} aria-controls={`${base_id}-tabpanel-${value}`} value={value} {...muiInternalStuff} />
		);
	},
	proto_TabPanel: (base_id: string, props: TabPanelProps) => {
		const selected = props.value === props.index;
		return (
			<Box
				role="tabpanel"
				hidden={!selected}
				id={`${base_id}-tabpanel-${props.index}`}
				aria-labelledby={`${base_id}-tab-${props.index}`}
				sx={{ height: "100%" }}
			>
				{selected ? <Box sx={{ height: "100%" }}>{props.children}</Box> : null}
			</Box>
		);
	},
	create_TabRenderFunctions(base_id: string) {
		return {
			TabControl: this.proto_TabControl.bind(this, base_id),
			TabPanel: this.proto_TabPanel.bind(this, base_id),
		};
	},
};

export { TabsUtilities };

export type { TabPanelProps };
