import { apiRequest } from "../../../../api_extension/apiRequest";
import { useQuery, UseQueryResult } from "react-query";
import { DiagnosisDetails_QueryParams } from "../useDiagnosisDetails";
import { randomizeDemoData } from "../../../../shared/utilities/demoDataRandomizer";

type MemberNotification = {
	notificationID: number;
	recipients: number;
	success_rate: number;
	title: string;
	date: string;
};
async function retrieveDiagnosisDetails_MemberNotifications(params: DiagnosisDetails_QueryParams): Promise<Array<MemberNotification>> {
	let diagnosis_data = await apiRequest({
		url: `${process.env.PUBLIC_URL}/test_data/diagnosis_detail/member_notifications.json`,
		method: "GET",
	}).then((data) => {
		if (Array.isArray(data)) {
			randomizeDemoData(data);
		}
		return data;
	});
	return diagnosis_data;
}

function useDiagnosisDetails_MemberNotifications(params: DiagnosisDetails_QueryParams): UseQueryResult<Array<MemberNotification>> {
	return useQuery(
		["DiagnosisDetails_MemberNotifications", `DiagnosisDetails_MemberNotifications_${params.diagnosisID}`],
		retrieveDiagnosisDetails_MemberNotifications.bind(null, params),
		{ cacheTime: Infinity, staleTime: Infinity }
	);
}
export { useDiagnosisDetails_MemberNotifications };

export type { MemberNotification };
