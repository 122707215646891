import { AccountManagerContextProvider } from "./contexts/AccountManager";
import { ContextAppSettingsProvider } from "./contexts/Context_AppSettings";
import { ContextCodeDefinitionsProvider } from "./contexts/Context_CodeDefinitions_Provider";
import { ContextDiagnosisFiltersProvider } from "./contexts/Context_DiagnosisFilters_Provider";
import { ContextPrimaryFiltersProvider } from "./contexts/Context_PrimaryFilters_Provider";
import { ContextUserMetricsProvider } from "./contexts/Context_UserMetrics_Provider";

function DataProvider(props: { children: React.ReactNode }) {
	return (
		<ContextAppSettingsProvider>
			<ContextCodeDefinitionsProvider>
				<ContextDiagnosisFiltersProvider>
					<AccountManagerContextProvider>
						<ContextPrimaryFiltersProvider>
							<ContextUserMetricsProvider>
								{/* */}
								{props.children}
								{/* */}
							</ContextUserMetricsProvider>
						</ContextPrimaryFiltersProvider>
					</AccountManagerContextProvider>
				</ContextDiagnosisFiltersProvider>
			</ContextCodeDefinitionsProvider>
		</ContextAppSettingsProvider>
	);
}

export { DataProvider };
