import { FilterQueryRequest, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetDiagnosisRangeAggregationsQuery, getDiagnosisRangeAggregations } from "api";
import { useQuery } from "react-query";

function getDiagnosisRangeAggregationsX(request: AdminGetDiagnosisRangeAggregationsQuery) {
	return getDiagnosisRangeAggregations(request);
}

const useX_getDiagnosisRangeAggregations = (
	codes: Array<string> | null,
	ranges: Array<string> | null,
	fqr?: Partial<FilterQueryRequest>,
	includeRangeLookup: boolean = false,
	sortOrderRequest: SortOrderRequest | null = null
) => {
	let fqrX = { ...useFilterQuery(), ...fqr };

	let request: AdminGetDiagnosisRangeAggregationsQuery = {
		startDate: fqrX.startDate,
		endDate: fqrX.endDate,
		jobIds: fqrX.memberTypes,
		ranges: ranges,
		codes: codes,
		includeRangeLookup: includeRangeLookup,
	};

	if (sortOrderRequest) {
		request.sortBy = sortOrderRequest.sortBy;
		request.orderBy = sortOrderRequest.orderBy;
	}

	let cacheKeys = ["getDiagnosisRangeAggregations", "getDiagnosisRangeAggregations_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDiagnosisRangeAggregationsX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getDiagnosisRangeAggregations };

// import { AdminGetDiagnosisRangeAggregationsQuery, getDiagnosisRangeAggregations } from "api";
// import { useQuery } from "react-query";

// function getDiagnosisRangeAggregationsX(request: Partial<AdminGetDiagnosisRangeAggregationsQuery>) {
// 	return getDiagnosisRangeAggregations(request);
// }

// const useX_getDiagnosisRangeAggregations = (request: Partial<AdminGetDiagnosisRangeAggregationsQuery>) => {
// 	let cacheKeys = ["getDiagnosisRangeAggregations", "getDiagnosisRangeAggregations_" + JSON.stringify(request)];
// 	return useQuery(cacheKeys, getDiagnosisRangeAggregationsX.bind(null, request), {
// 		cacheTime: Infinity,
// 		staleTime: Infinity,
// 	});
// };

// export { useX_getDiagnosisRangeAggregations };
