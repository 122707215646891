import { useQuery, UseQueryResult } from "react-query";
import { usePrimaryFilters } from "./useContextPrimaryFilters";

import { ModelsDateRangeRequest } from "api";
import { DateConverter } from "shared/utilities/DateConverter";
import { BasicDateRangeDates, BasicDateRangeStrings, PrimaryFilters } from "DataProvider/contexts/Context_PrimaryFilters_Provider";

function getModelsDateRangeRequestFromFilters(pf: PrimaryFilters): ModelsDateRangeRequest {
	let x = getFilterDates(pf);
	return { startDate: x.start, endDate: x.end };
}
function getFilterDates(pf: PrimaryFilters): BasicDateRangeStrings {
	var dates = pf.DateRange;
	var customDates = pf.DateRangeCustomDates;

	let x = getDatesForDateRangeFilterType(dates[0], customDates);
	let xx = {
		start: x[0] ? DateConverter.stringDateForAPI(x[0], { includeTime: true }) : null,
		end: x[1] ? DateConverter.stringDateForAPI(x[1], { includeTime: true }) : null,
	};

	return xx;
}

function getDatesForDateRangeFilterType(dateRangeType: string | null | undefined, customDates: BasicDateRangeDates) {
	let today = new Date();

	var startDate: Date | null = null;
	var endDate: Date | null = null;

	if (dateRangeType) {
		switch (dateRangeType) {
			case "Monthly":
				startDate = new Date(today.getFullYear(), today.getMonth(), 1);
				endDate = new Date(startDate);
				endDate.setMonth(startDate.getMonth() + 1);

				break;
			case "Quarterly":
				let quarter = Math.floor((today.getMonth() + 3) / 3);
				startDate = new Date(today.getFullYear(), (quarter - 1) * 3, 1);
				endDate = new Date(startDate);
				endDate.setMonth(startDate.getMonth() + 2);

				break;
			case "Yearly":
				startDate = new Date(today.getFullYear(), 0, 1);
				endDate = new Date(startDate);
				endDate.setMonth(today.getMonth());
				endDate.setDate(today.getDate());

				break;
			case "Custom":
				if (customDates.start) {
					startDate = customDates.start;
				}
				if (customDates.end) {
					endDate = customDates.end;
				}
				// startDate = new Date(today.getFullYear(), 0, 1);
				// endDate = new Date(today.getFullYear() + 1, 0, 1);

				break;
			case "All":
				break;
			default:
				throw new Error("Unknown DateRange Type");
		}
	}

	return [startDate, endDate];
}

type FilterQueryRequest = {
	query?: string;
	startDate: string | null;
	endDate: string | null;
	memberTypes: Array<string>;
};

export type { FilterQueryRequest };

function useFilterQuery() {
	let primaryFilters = usePrimaryFilters();

	let request: FilterQueryRequest = {
		memberTypes: primaryFilters.values.Members,
		startDate: null,
		endDate: null,
	};

	var dates = primaryFilters.values.DateRange;
	if (dates.length) {
		let x = getFilterDates(primaryFilters.values);
		request.startDate = x.start;
		request.endDate = x.end;
	}
	return request;
}

function useRunFilterQuery<TResult>(query: any, request: any = {}): UseQueryResult<TResult> {
	let req: FilterQueryRequest = useFilterQuery();

	//Object.assign(request, req); // I think it would be better to allow overriding properties of the standard useFilterQuery
	request = Object.assign({}, req, request);

	request.query = query.name; //prevent caching across different request types from useQuery
	// console.trace("trace");
	//assign filter options
	// let primaryFilters = usePrimaryFilters();

	// request.memberTypes = primaryFilters.values.Members;
	// var dates = primaryFilters.values.DateRange;
	// // var customDates = primaryFilters.values.DateRangeCustomDates;
	// if (dates.length) {
	// 	// let [startDate, endDate] = getDatesForDateRangeFilterType(dates[0], customDates);
	// 	// console.debug([startDate, endDate]);
	// 	// //request.StartDate = startDate?.toISOString() ?? null;
	// 	// request.StartDate = startDate ? DateConverter.stringDateForAPI(startDate, { includeTime: true }) : null;
	// 	// //request.EndDate = endDate?.toISOString() ?? null;
	// 	// request.EndDate = endDate ? DateConverter.stringDateForAPI(endDate, { includeTime: true }) : null;

	// 	let x = getFilterDates(primaryFilters.values);

	// 	request.StartDate = x.start;
	// 	request.EndDate = x.end;

	// 	// console.debug([request.StartDate, request.EndDate]);
	// }

	return useQuery([request.query, JSON.stringify(request)], query.bind(null, request), {
		staleTime: Infinity,
		cacheTime: Infinity,
	});
}

export { useRunFilterQuery, getDatesForDateRangeFilterType, getFilterDates, getModelsDateRangeRequestFromFilters, useFilterQuery };
