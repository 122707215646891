import { Box, Chip } from "@mui/material";
import { ReactNode } from "react";

const Pill = (props: { children: ReactNode; onX?: () => any }) => {
	return <Chip label={props.children} onDelete={props.onX} style={{ marginRight: ".25rem", marginBottom: ".25rem" }} />;
};

const PillBox = (props: { children: ReactNode }) => {
	return (
		<Box style={{ padding: "1rem 0rem", display: "flex", flexDirection: "row", justifyContent: "flex-start", maxWidth: "100%", flexWrap: "wrap" }}>
			{props.children}
		</Box>
	);
};

export { Pill, PillBox };
