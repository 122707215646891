import { Typography } from "@mui/material";
import { palette } from "theme";

export default function NoDataAvailable() {
	return (
		<Typography sx={{ textAlign: "center" }} color={palette.error.main} fontSize={"1.5rem"} fontWeight={600}>
			No Data Available
		</Typography>
	);
}
