import { Box, Stack, Typography } from "@mui/material";
import { DropDownSelect, SelectOptions } from "../DropDownSelect";
import {
	ModelsHCPCSBase,
	ModelsICDPCS,
	ModelsLookUpDictionaries,
	ModelsLookUpDictionaryDoctorStubItemDictionary,
	ModelsLookUpDictionaryHCPCSBaseItemDictionary,
	ModelsLookUpDictionaryICD10CMLightItemDictionary,
	ModelsLookUpDictionaryICDPCSItemDictionary,
	ModelsLookUpDictionaryTerminalDrugStructureDataItemDictionary,
	RequestModelsCacheMonthlyQueryRequestNestedProperties,
} from "api";
import { ReducerUtility } from "shared/utilities/ReducerUtility";
import { ReactNode, useMemo, useReducer } from "react";
import { PillBox } from "../Pill";

type NestedCodeFilterSelectedDisplay = {
	medication: ReactNode | null;
	diagnosis: ReactNode | null;
	provider: ReactNode | null;
	productservice: ReactNode | null;
	patient: ReactNode | null;
};

const NestedCodeFilterSelection = ({
	set_nestedCodes,
	lookupDictionaries,
	hideItems,
}: {
	set_nestedCodes: React.Dispatch<React.SetStateAction<RequestModelsCacheMonthlyQueryRequestNestedProperties>>;
	lookupDictionaries?: ModelsLookUpDictionaries;
	hideItems?: { diagnosis?: boolean; medication?: boolean; productservice?: boolean; provider?: boolean };
}) => {
	/* 


 */
	const [nestedCodes, dispatch_nestedCodes] = useReducer(
		(x: RequestModelsCacheMonthlyQueryRequestNestedProperties, action: Partial<RequestModelsCacheMonthlyQueryRequestNestedProperties>) => {
			let z = ReducerUtility.UpdateWithPartialValue<RequestModelsCacheMonthlyQueryRequestNestedProperties>(x, action);
			setTimeout(() => {
				set_nestedCodes(z);
			}, 500);
			return z;
		},
		{
			medicationCodes: [],
			diagnosisCodes: [],
			providerCodes: [],
			productserviceCodes: [],
			patientCodes: [],
		}
	);

	const [selectedCodes, dispatch_selectedCodes] = useReducer(
		(x: NestedCodeFilterSelectedDisplay, action: Partial<NestedCodeFilterSelectedDisplay>) => {
			let z = ReducerUtility.UpdateWithPartialValue<NestedCodeFilterSelectedDisplay>(x, action);
			return z;
		},
		{
			medication: null,
			diagnosis: null,
			provider: null,
			productservice: null,
			patient: null,
		}
	);

	const options = useMemo(() => {
		const o: {
			medicationCodes?: SelectOptions[];
			diagnosisCodes?: SelectOptions[];
			providerCodes?: SelectOptions[];
			productserviceCodes?: SelectOptions[];
			patientCodes?: SelectOptions[];
		} = {};
		let ld: any;
		let ld2: any;
		if (lookupDictionaries) {
			// build the options from lookupDictionaries -- can add other logic here to build or maintain in alternative way
			if (lookupDictionaries.diagnosis) {
				if (lookupDictionaries.diagnosis.codes) {
					ld = lookupDictionaries.diagnosis.itemDictionary;
					o.diagnosisCodes = lookupDictionaries.diagnosis.codes.map((c) => {
						return { label: (ld as ModelsLookUpDictionaryICD10CMLightItemDictionary)?.[c]?.title ? `${ld[c].title} (${c})` : c, value: c };
					});
					o.diagnosisCodes.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
				}
			}
			if (lookupDictionaries.medication) {
				if (lookupDictionaries.medication.codes) {
					ld = lookupDictionaries.medication.itemDictionary;
					o.medicationCodes = lookupDictionaries.medication.codes.map((c) => {
						let m = { label: (ld as ModelsLookUpDictionaryTerminalDrugStructureDataItemDictionary)?.[c]?.name ?? c, value: c };
						if (m.label === "Unidentified Medication") {
							m.label = `Unidentified(${c})`;
						}
						return m;
					});
					o.medicationCodes.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
				}
			}
			if (lookupDictionaries.provider) {
				if (lookupDictionaries.provider.codes) {
					ld = lookupDictionaries.provider.itemDictionary;
					o.providerCodes = lookupDictionaries.provider.codes.map((c) => {
						let p = (ld as ModelsLookUpDictionaryDoctorStubItemDictionary)?.[c];
						return { label: p ? `${p.firstName} ${p.lastName}` : c, value: c };
					});
					o.providerCodes.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
				}
			}
			if (lookupDictionaries.hcpcs || lookupDictionaries.icdpcs) {
				if (lookupDictionaries.hcpcs?.codes || lookupDictionaries.icdpcs?.codes) {
					let prodservCodes: string[] = [];
					if (lookupDictionaries.hcpcs?.codes) {
						prodservCodes = [...prodservCodes, ...lookupDictionaries.hcpcs.codes];
					}
					if (lookupDictionaries.icdpcs?.codes) {
						prodservCodes = [...prodservCodes, ...lookupDictionaries.icdpcs.codes];
					}
					prodservCodes = Array.from(new Set(prodservCodes));
					if (prodservCodes.length) {
						ld = lookupDictionaries.hcpcs?.itemDictionary;
						ld2 = lookupDictionaries.icdpcs?.itemDictionary;
						o.productserviceCodes = prodservCodes.map((c) => {
							let m = { label: c, value: c };
							let x: ModelsHCPCSBase | ModelsICDPCS | undefined = (ld as ModelsLookUpDictionaryHCPCSBaseItemDictionary)?.[c];
							if (x) {
								m.label = x.description;
							} else {
								x = (ld2 as ModelsLookUpDictionaryICDPCSItemDictionary)?.[c];
								if (x) {
									m.label = x.description;
								}
							}
							return m;
						});
						o.productserviceCodes.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
					}
				}
			}
		}
		return o;
	}, [lookupDictionaries]);

	return (
		<Box>
			<Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
				<Stack
					className={"date-range-container"}
					direction="row"
					spacing="1"
					sx={{
						height: "2.5rem",
						borderRadius: "2.5rem",
						border: "2px solid #fff",
						backgroundColor: "gray.main",
						px: 1.5,
						color: "gray.dark",
						boxShadow: 4,
						alignItems: "center",
						"& .MuiButtonBase-root": {
							height: "100%",
							fontSize: "1rem",

							"& svg": {
								color: "primary.main",
								fontSize: "1.25rem",
							},
						},
					}}
				>
					<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
						Filter By:
					</Typography>
					{hideItems?.diagnosis ? null : (
						<DropDownSelect
							title="Diagnosis"
							type="checkbox"
							selectedValues={nestedCodes.diagnosisCodes ?? undefined}
							setValues={(v: Array<string>) => {
								dispatch_nestedCodes({ diagnosisCodes: v });
							}}
							options={options.diagnosisCodes}
							set_selectedDisplayList={(display: ReactNode) => {
								dispatch_selectedCodes({ diagnosis: display });
							}}
						/>
					)}
					{hideItems?.medication ? null : (
						<DropDownSelect
							title="Medication"
							type="checkbox"
							selectedValues={nestedCodes.medicationCodes ?? undefined}
							setValues={(v: Array<string>) => {
								dispatch_nestedCodes({ medicationCodes: v });
							}}
							options={options.medicationCodes}
							set_selectedDisplayList={(display: ReactNode) => {
								dispatch_selectedCodes({ medication: display });
							}}
						/>
					)}
					{hideItems?.productservice ? null : (
						<DropDownSelect
							title="Procedure"
							type="checkbox"
							selectedValues={nestedCodes.productserviceCodes ?? undefined}
							setValues={(v: Array<string>) => {
								dispatch_nestedCodes({ productserviceCodes: v });
							}}
							options={options.productserviceCodes}
							set_selectedDisplayList={(display: ReactNode) => {
								dispatch_selectedCodes({ productservice: display });
							}}
						/>
					)}
					{hideItems?.provider ? null : (
						<DropDownSelect
							title="Doctor"
							type="checkbox"
							selectedValues={nestedCodes.providerCodes ?? undefined}
							setValues={(v: Array<string>) => {
								dispatch_nestedCodes({ providerCodes: v });
							}}
							options={options.providerCodes}
							set_selectedDisplayList={(display: ReactNode) => {
								dispatch_selectedCodes({ provider: display });
							}}
						/>
					)}
				</Stack>
			</Stack>
			<PillBox>
				{selectedCodes.diagnosis}
				{selectedCodes.medication}
				{selectedCodes.productservice}
				{selectedCodes.provider}
			</PillBox>
		</Box>
	);
};

export { NestedCodeFilterSelection };
