/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  OverviewGetRevenueSummaryChartsResult,
  ProvidersSummaryFilterCriteria
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getRevenueSummaryCharts = (
    providersSummaryFilterCriteria: ProvidersSummaryFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<OverviewGetRevenueSummaryChartsResult>(
      {url: `/api/admin/RevenueOverview/GetRevenueSummaryCharts`, method: 'post',
      data: providersSummaryFilterCriteria
    },
      options);
    }
  


    export const useGetRevenueSummaryCharts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getRevenueSummaryCharts>, TError,{data: ProvidersSummaryFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getRevenueSummaryCharts>, {data: ProvidersSummaryFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  getRevenueSummaryCharts(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getRevenueSummaryCharts>, TError, {data: ProvidersSummaryFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    