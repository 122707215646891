import { Box, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { displayConverter } from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Loader } from "shared/components/Loaders/Loader";
import { useMemo } from "react";
import { ProductServiceTypeApiRequest } from "../ProductServiceDetailsMain";
import { ProductServiceCacheProductServiceMonthlyAggregate, RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyDetail } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetail";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";
import { SimpleTooltipColumn } from "shared/components/grid/GridColumn/SimpleTooltipColumn";

SwiperCore.use([Pagination]);

function Toolbar(props: Readonly<Pick<DataGridToolbarProps, "onAdd">>) {
	return <DataGridToolbar header={"Facility List"} enableExport={false} {...props} />;
}

interface Filters {
	tableQuery?: string;
}

type ProductServiceDetailsFacilitiesProps = {
	readonly apiRequest: ProductServiceTypeApiRequest;
	readonly rangeAggregateItem?: ProductServiceCacheProductServiceMonthlyAggregate;
};

function ProductServiceDetailsFacilities(props: ProductServiceDetailsFacilitiesProps) {
	const columns: GridColumns = [
		{
			field: "description",
			headerName: "Facility Name",
			headerAlign: "left",
			align: "left",
			flex: 1,
			renderCell: (params) => {
				const npi: string = params.row.code;
				const facility = lookupDictionaries?.facility?.itemDictionary?.[npi];
				const fullName = facility?.organizationName ?? "";
				const displayName = fullName.length > 25 ? fullName.substring(0, 25) + "..." : fullName;
				return (
					<SimpleTooltipColumn
						followItemType={"ORGANIZATION"}
						rating={facility?.radiusRating ?? undefined}
						code={params.row.code}
						tooltipTitle={
							<Box>
								<Typography>{fullName}</Typography>
								<Typography>NPI: {npi}</Typography>
							</Box>
						}
						name={displayName}
					/>
				);
			},
		},
		{
			field: "count",
			headerName: "Total Facilities",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.insertThousandsCommas(params.row.count),
		},
		{
			field: "cost",
			headerName: "Total Spend",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.toUSD(Number(params.row.cost)),
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => {
				return <ViewEntityDetailButton itemType="ORGANIZATION" code={row.code} />;
			},
		},
	];
	const { grid } = useDataGrid<Filters>("services", columns);
	const { apiRequest } = props;
	const { values: pfValues } = usePrimaryFilters();
	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		return {
			jobIds: [...pfValues.Members],
			codes: apiRequest.codes,
			itemType: "PRODUCTSERVICE",
			includeLookup: true,
			paginationRequest: { skip: 0, take: 100, maxNestedSize: 100 },
			codeMonthlyAggregation: false,
		};
	}, [pfValues.Members, apiRequest.codes]);

	const apiResult = useX_getCacheMonthlyDetail(queryRequest);
	const lookupDictionaries = apiResult?.data?.response?.lookupDictionaries;
	const data = useMemo(() => {
		if (!apiResult.data?.response?.codeAggregations) return null;
		return apiResult.data?.response?.codeAggregations[apiRequest.codes[0]];
	}, [apiRequest.codes, apiResult.data?.response?.codeAggregations]);
	const averageCost = useMemo(() => {
		if (data?.facility?.totalCost && data?.facility?.totalCount) {
			return data.facility.totalCost / data.facility.totalCount;
		}
		return null;
	}, [data]);
	return (
		<Box>
			{data ? (
				<>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, md: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								"700": {
									slidesPerView: 4,
								},
								"1200": {
									slidesPerView: 4,
								},
							}}
						>
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data.facility?.uniqueCount)}
									title="Total Facilities"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data?.facility?.totalCount)}
									title="Total Procedures"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(data.facility?.totalCost)} title="Total Spend" minHeight="7rem" />
							</SwiperSlide>

							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(averageCost)} title="Average Spend" minHeight="7rem" />
							</SwiperSlide>
						</Swiper>
					</Box>
					<Box sx={{ display: "flex", height: "100%" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{data ? (
								<DataGridPro
									{...grid}
									columns={columns}
									autoHeight
									getRowId={(row) => row.code}
									rowCount={data.facility?.codeCounts?.length ?? 0}
									rows={data.facility?.codeCounts ?? []}
									components={{ Toolbar: Toolbar }}
									paginationMode="client"
									initialState={{
										sorting: {
											sortModel: [{ field: "cost", sort: "desc" }],
										},
									}}
									sx={{ pt: 2 }}
								/>
							) : null}
						</Box>
					</Box>
				</>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export { ProductServiceDetailsFacilities };
