import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { AccountManagerContext } from "DataProvider/contexts/AccountManager";
import { FormErrorAlert } from "shared/forms";
import { useNavigate } from "react-router-dom";
import { createUser } from "./auth/flow";

function ChangePassword() {
	const { register, handleSubmit } = useForm<{
		password: string;
		confirmPassword: string;
		verificationCode: string;
	}>();

	const { enqueueSnackbar } = useSnackbar();
	const data = useContext(AccountManagerContext).accountManager.userDetails;
	const navigate = useNavigate();

	const onSubmit = handleSubmit((form) => {
		const user = createUser(data?.user?.username ?? "N?A");

		user.confirmPassword(form.verificationCode, form.password, {
			onSuccess() {
				enqueueSnackbar("Password Updated", {
					variant: "success",
				});

				navigate("/account/myAccount");
			},
			onFailure(err) {
				enqueueSnackbar("Password update failed", {
					variant: "error",
				});
			},
		});
	});

	return (
		<Card
			component="form"
			onSubmit={onSubmit}
			sx={{
				width: "100%",
				paddingLeft: "24px",
				paddingRight: "24px",
				paddingTop: "36px",
				paddingBottom: "24px",
				boxSizing: "border-box",
			}}
			elevation={4}
		>
			<CardContent>
				<Stack spacing={2}>
					<Typography>
						Please check your email for a 6 digit verification code. Once you've received the code, please enter that code below.
					</Typography>
					<TextField label="Verification Code (Check Email)" {...register("verificationCode")} variant="standard" />
					<TextField label="New Password" type="password" {...register("password", { required: true })} variant="standard" />
					<TextField
						label="Confirm New Password"
						type="password"
						{...register("confirmPassword", {
							required: true,
							validate: {
								matchesPassword: () => {
									console.log("matchesPassword");
									return true;
								},
								// fail: () => {
								//   console.log("fail");

								//   return "fails fail";
								// },
							},
						})}
						variant="standard"
					/>
				</Stack>
			</CardContent>
			<FormErrorAlert />
			<CardActions sx={{ justifyContent: "flex" }}>
				<Button type="submit" sx={{ backgroundColor: "core.darkblue" }}>
					Change Password
				</Button>
			</CardActions>
		</Card>
	);
}

export default ChangePassword;
