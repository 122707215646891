import React from "react";
import { useForgotPasswordFlow } from "./auth/useForgotPasswordFlow";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import { Navigate } from "react-router-dom";

function ForgotPasswordPage() {
	const flow = useForgotPasswordFlow();

	switch (flow.step) {
		case "ForgotPasswordStep":
			return <ForgotPasswordForm {...flow} />;

		case "ResetPasswordStep":
			return <Navigate to={`/reset-password?username=${flow.username}`} />;

		case "ForgotPasswordCompleteStep":
			return <Navigate to="/" />;
	}
}

export default ForgotPasswordPage;
