import { Box, Grid } from "@mui/material";
import { MemberRiskRow, riskCategoryVariants } from "./MemberRiskRow";
import { palette } from "theme";
import { useMemo } from "react";
import { usePrimaryFiltersProvidersRiskModelFilterCriteria } from "DataProvider/hooks/useContextPrimaryFilters";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { apiResponseReader } from "shared/utilities/apiResponseReader";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";
import { Loader } from "shared/components/Loaders/Loader";
import { displayConverter } from "shared/utilities/displayConverter";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { useX_GetCachePatientRiskModelScoreOverview } from "api_extension/useQueryX/riskModel/useX_GetCachePatientRiskModelScoreOverview";

function MembersRiskModel() {
	const riskModelFilters = usePrimaryFiltersProvidersRiskModelFilterCriteria();
	const riskModelScoresResponse = useX_GetCachePatientRiskModelScoreOverview(riskModelFilters);
	let colors = [palette.success.main, palette.warning.main, palette.error.main, palette.info.main];

	const [riskChartValues, memberRiskRows] = useMemo(() => {
		let categories = riskModelScoresResponse?.data?.result?.data?.categories;
		console.log({ categories });
		if (!categories) {
			return [null, null];
		}
		return [
			categories.map((x) => {
				return {
					name: x.riskCategory,
					value: x.patientCount,
					fill: x.riskCategory ? riskCategoryVariants[x.riskCategory]?.textColor : null,
				};
			}),
			categories.map((row) => {
				return (
					<MemberRiskRow
						key={row.riskCategory}
						variant={row.riskCategory ?? ""}
						memberPercent={typeof row.patientPercent === "number" ? row.patientPercent : -1}
						memberCount={typeof row.patientCount === "number" ? row.patientCount : -1}
						retentionRate={0}
					/>
				);
			}),
		];
	}, [riskModelScoresResponse]);

	const isError = useMemo(() => {
		let d = riskModelScoresResponse?.data as any;
		if (d?.data && apiResponseReader.typeCheck_ModelsOperationStatus(d?.data)) {
			if (d.data.errorMessage) {
				return true;
			}
		}
		return false;
	}, [riskModelScoresResponse]);

	return (
		<>
			<ResendQueryButton queryCacheName="retrieveCachePatientRiskModelScoreOverview" />
			<Grid container spacing={2} sx={isError ? { pl: "16px" } : null}>
				<QueryStatusDisplay showAsLoading={riskModelScoresResponse === null} queryResult={riskModelScoresResponse ?? undefined}>
					<Grid
						item
						xs={12}
						md={7}
						sx={{
							borderRight: {
								xs: "none",
								md: `2px solid ${palette.gray.main}`,
							},
							pr: {
								xs: 0,
								md: 2,
							},
						}}
					>
						<Box sx={{ width: "100%" }}>{memberRiskRows}</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{riskChartValues ? (
							<StandardPieChart
								responsiveHeight={400}
								innerRadius={32}
								displayPercentage
								dollarAboveLegend
								data={riskChartValues.map((x) => {
									return { name: x.name ?? "N/A", value: x.value ? Number(x.value) : 0 };
								})}
								colors={colors}
								tooltipFormatter={(value, name) => displayConverter.insertThousandsCommas(value) + " Members"}
							/>
						) : (
							<Loader />
						)}
					</Grid>
				</QueryStatusDisplay>
			</Grid>
		</>
	);
}

export { MembersRiskModel };
