import {
	faFileInvoiceDollar,
	faCog,
	faBell,
	faUser,
	faAngleDown,
	faAngleRight,
	faTimes,
	faCheckCircle,
	faTimesCircle,
	faSave,
	faArrowCircleRight,
	faUndo,
	faNotesMedical,
	faUsers,
	faEye,
	faEyeSlash,
	faHeartbeat,
	faMapMarkedAlt,
	faCalendarAlt,
	faSearch,
	faChartLine,
	faHandshake,
	faStethoscope,
	faPrescriptionBottle,
	faShoppingBasket,
	faInfo,
	faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faHeadSideBrain, faUsdCircle, faChartNetwork } from "@fortawesome/pro-solid-svg-icons";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

export const icons = {
	market: faShoppingBasket,
	prescription: faPrescriptionBottle,
	procedure: faStethoscope,
	handshake: faHandshake,
	lineChart: faChartLine,
	moneyCircle: faUsdCircle,
	network: faChartNetwork,
	overview: faEye,
	eye: faEye,
	eyeSlash: faEyeSlash,
	revenue: faFileInvoiceDollar,
	diagnosis: faHeadSideBrain,
	settings: faCog,
	alerts: faBell,
	user: faUser,
	users: faUsers,
	angleDown: faAngleDown,
	angleRight: faAngleRight,
	reporting: faNotesMedical,
	close: faTimes,
	next: faArrowCircleRight,
	save: faSave,
	success: faCheckCircle,
	error: faTimesCircle,
	restore: faUndo,
	riskScore: faHeartbeat,
	distance: faMapMarkedAlt,
	calendar: faCalendarAlt,
	search: faSearch,
	info: faInfo,
	circle: faCircle,
};

export const palette = {
	primary: {
		light: "#424279",
		main: "#02024C",
		dark: "#010126",
		contrastText: "#ffffff",
	},
	secondary: {
		light: "#499F68",
		main: "#499F68",
		dark: "#499F68",
		contrastText: "#ffffff",
	},
	success: {
		light: "#499F68",
		main: "#499F68",
		dark: "#499F68",
		contrastText: "#ffffff",
	},
	error: {
		light: "#E83E3E",
		main: "#E83E3E",
		dark: "#E83E3E",
		contrastText: "#ffffff",
	},
	warning: {
		light: "#F1863F",
		main: "#F1863F",
		dark: "#F1863F",
		contrastText: "#ffffff",
	},
	info: {
		light: "#0E6BA8",
		main: "#0E6BA8",
		dark: "#0E6BA8",
		contrastText: "#ffffff",
	},
	green: {
		light: "#499F68",
		main: "#499F68",
		dark: "#285739",
		contrastText: "#ffffff",
	},
	red: {
		light: "#E83E3E",
		main: "#E83E3E",
		dark: "#E83E3E",
		contrastText: "#ffffff",
	},
	orange: {
		light: "#F1863F",
		main: "#F1863F",
		dark: "#F1863F",
		contrastText: "#ffffff",
	},
	lightBlue: {
		light: "#149df7",
		main: "#0E6BA8",
		dark: "#0E6BA8",
		contrastText: "#ffffff",
	},
	purple: {
		light: "#a432fa",
		main: "#601F91",
		dark: "#601F91",
		contrastText: "#ffffff",
	},
	gray: {
		light: "#F9F9F9",
		main: "#EBEBEB",
		dark: "#848484",
		darker: "#3A3A3A",
		contrastText: "#3A3A3A",
	},
	grid: {
		altRow: "#EBEBEB",
		rowHover: "#DAECE1",
	},
};

const defaults = createTheme();

export const theme = createTheme({
	palette,

	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 1200,
			lg: 1500,
			xl: 1900,
		},
	},

	components: {
		/*! dialog */
		MuiDialog: {
			styleOverrides: {
				root: {
					"& .MuiBackdrop-root": {
						backgroundColor: "rgba(255, 255, 255, 0.85)",
					},
				},
			},
		},

		/*! typography */
		MuiTypography: {
			styleOverrides: {
				h1: {
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.5rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.75rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "2rem",
					},
				},
				h2: {
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.5rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.75rem",
					},
				},
				h3: {
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.5rem",
					},
				},
				h4: {
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.25rem",
					},
				},
				h5: {
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.25rem",
					},
				},
			},
		},

		/*! avatar */
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: ".75rem",
					fontWeight: 700,
					backgroundColor: palette.gray.main,
					color: palette.gray.dark,
					border: "2px solid",
					borderColor: palette.gray.dark,

					"&.green": {
						color: palette.success.main,
						borderColor: palette.success.main,
					},

					"&.red": {
						color: palette.error.main,
						borderColor: palette.error.main,
					},

					"&.orange": {
						color: palette.warning.main,
						borderColor: palette.warning.main,
					},

					"&.blue": {
						color: palette.primary.main,
						borderColor: palette.primary.main,
					},

					"&.light-blue": {
						color: palette.info.main,
						borderColor: palette.info.main,
					},

					"&.purple": {
						color: palette.purple.main,
						borderColor: palette.purple.main,
					},
				},
			},
		},

		/*! buttons */
		MuiButton: {
			defaultProps: {
				variant: "contained",
			},
			styleOverrides: {
				contained: {
					minHeight: "2rem",
					borderRadius: "2rem",
					boxShadow: defaults.shadows[0],
					lineHeight: "normal",
					padding: ".5rem 1.5rem",
				},

				containedPrimary: {
					borderColor: palette.secondary.main,
					color: "#ffffff",
					backgroundColor: palette.secondary.main,
					padding: "1rem 1.5rem",
					fontSize: "1rem",
				},

				containedSecondary: {
					borderColor: palette.gray.dark,
					color: "#ffffff",
					backgroundColor: palette.gray.dark,

					"&:hover": {
						borderColor: palette.primary.main,
						color: "#ffffff",
						backgroundColor: palette.primary.main,
					},
				},
			},
		},

		/*! links */
		MuiLink: {
			styleOverrides: {
				root: {
					color: palette.secondary.main,
					textDecoration: "none",

					"&:hover": {
						color: palette.primary.main,
						textDecoration: "none",
					},
				},
			},
		},

		/*! inputs */
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 700,
					color: palette.gray.dark,

					"&.Mui-focused": {
						color: palette.gray.dark,
					},
				},
			},
		},

		MuiInputLabel: {
			defaultProps: {
				shrink: true,
			},
			styleOverrides: {
				shrink: {
					position: "relative",
					transform: "none !important",
					marginBottom: defaults.spacing(0.5),
				},
			},
		},

		MuiTextField: {
			defaultProps: {
				fullWidth: true,
			},
			styleOverrides: {
				root: {
					"&.inline-field": {
						display: "flex",
						flexDirection: "row",

						"& label": {
							display: "flex",
							alignItems: "center",
							width: "15rem",
							whiteSpace: "normal",
							marginBottom: "0",
							paddingRight: ".5rem",
						},
					},
					"&.textfield-readonly .MuiOutlinedInput-root": {
						backgroundColor: "#fff",
						boxShadow: "none",
					},
					"&.textfield-readonly .MuiOutlinedInput-root input": {
						paddingLeft: "0",
					},
					"&.textfield-readonly fieldset": {
						border: "none",
					},
					"&.textfield-readonly .MuiOutlinedInput-root:hover fieldset": {
						border: "none",
					},
					"&.textfield-readonly .Mui-focused fieldset": {
						border: "none",
					},

					"&.calculated .MuiOutlinedInput-notchedOutline::after": {
						content: '""',
						position: "absolute",
						left: 0,
						top: 0,
						bottom: 0,
						width: 5,
						backgroundColor: palette.primary.main,
					},

					"&.calculated.overridden .MuiOutlinedInput-notchedOutline::after": {
						backgroundColor: palette.secondary.main,
					},
				},
			},
		},

		MuiList: {
			styleOverrides: {
				root: {
					"& .Mui-selected": {
						backgroundColor: "#ffffff !important",
					},
					"& .Mui-selected:hover": {
						backgroundColor: `${palette.gray.light} !important`,
					},
				},
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: palette.gray.main,
					borderRadius: "4rem",
					boxShadow: defaults.shadows[2],
					borderColor: palette.gray.light,

					"& fieldset": {
						top: "0",
					},

					"& fieldset legend": {
						display: "none",
					},

					"&:hover fieldset": {
						borderColor: `${palette.secondary.main} !important`,
					},

					"&.Mui-focused fieldset": {
						borderColor: `${palette.secondary.main} !important`,
					},
				},
				notchedOutline: {
					borderColor: palette.gray.light,
					borderWidth: "2px !important",
					transition: "border-color .5s ease",
				},
			},
		},

		MuiRadio: {
			styleOverrides: {
				root: {
					padding: ".175rem",
				},
			},
		},

		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: ".175rem",
				},
			},
		},

		/*! paper */
		MuiPaper: {
			styleOverrides: {
				root: {
					"& .MuiGridPanelWrapper-root .MuiDataGridPanelHeader-root": {
						padding: defaults.spacing(2),
					},
					"& .MuiGridPanelWrapper-root .MuiGridPanelContent-root": {
						padding: defaults.spacing(1),
					},
					"& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root": {
						paddingTop: defaults.spacing(2),
						paddingBottom: defaults.spacing(2),
						paddingRight: defaults.spacing(1),
						paddingLeft: defaults.spacing(1),
					},

					"& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root .MuiButton-root": {
						flex: 1,
						marginLeft: defaults.spacing(1),
						marginRight: defaults.spacing(1),
					},

					"& .MuiDataGrid-panelWrapper .MuiDataGrid-panelFooter .MuiButton-root": {
						backgroundColor: palette.gray.dark,
						height: "2rem",
						fontSize: "0.875rem",
						margin: defaults.spacing(1),
						padding: defaults.spacing(2),
					},
				},
				rounded: {
					borderRadius: "1.5rem",
				},
			},
		},

		/*! card */
		MuiCardHeader: {
			styleOverrides: {
				root: {
					"& .MuiCardHeader-title": {
						[defaults.breakpoints.down("md")]: {
							fontSize: "1rem",
						},
						[defaults.breakpoints.up("md")]: {
							fontSize: "1.25rem",
						},
						fontWeight: 700,
						textTransform: "Uppercase",
					},
				},

				action: {
					margin: defaults.spacing(0),
				},
			},
		},

		MuiCardContent: {
			styleOverrides: {
				root: {
					"&:last-child": { paddingBottom: "1rem" },
				},
			},
		},

		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: defaults.spacing(2),
				},
			},
		},

		/*! menu popover */
		MuiPopover: {
			styleOverrides: {
				paper: {
					background: palette.gray.main,
					border: "2px solid #ffffff",
					borderRadius: "1rem",
					boxShadow: defaults.shadows[4],
				},
			},
		},

		/*! data grid */
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "none",

					"& .MuiDataGrid-row:hover": {
						backgroundColor: `${palette.grid.rowHover} !important`,
					},

					"& .MuiDataGrid-cell": {
						borderBottom: `none`,
					},

					"& .MuiDataGrid-columnHeaders": {
						top: "-2px",
						borderBottom: `2px solid ${palette.gray.dark}`,
					},

					"& .MuiDataGrid-columnHeaderTitleContainer": {
						padding: 0,
					},

					"& .MuiDataGrid-columnHeaderTitle": {
						fontWeight: 700,
						textTransform: "uppercase",
					},

					"& .MuiDataGrid-columnSeparator": {
						display: "none",
					},

					"& .MuiDataGrid-columnsContainer": {
						borderBottom: `1px solid ${palette.gray.dark}`,
						boxSizing: "border-box",
					},

					"& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
						outline: `1px solid ${palette.secondary.main}`,
					},

					"& .MuiDataGrid-row:nth-of-type(odd)": {
						backgroundColor: palette.grid.altRow,
					},

					"& .MuiDataGrid-toolbarContainer": {
						padding: 0,
					},
				},
			},
		},

		MuiGrid: {
			styleOverrides: {
				root: {},
			},
		},

		/*! tabs */
		MuiTabs: {
			styleOverrides: {
				root: {
					boxShadow: defaults.shadows[3],
					borderRadius: "3rem",
					minHeight: "3rem",

					"& .MuiTab-root": {
						color: palette.gray.dark,
						borderRadius: "3rem",
						zIndex: "1",
						transition: "color .5s ease",
					},

					"& .MuiTab-root.Mui-selected": {
						color: "#fff",
					},
					"& .MuiTabs-indicator": {
						zIndex: "0",
						borderRadius: "3rem",
						backgroundColor: palette.primary.main,
						height: "100%",
					},
				},
			},
		},
	},
});
