import { RequestModelsCacheMonthlyQueryRequest } from "api/backend.schemas";
import { getMedicationGenderAndAge } from "api/cache-medication-data/cache-medication-data";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { useQuery } from "react-query";

function getMedicationGenderAndAgeX(request: RequestModelsCacheMonthlyQueryRequest) {
	return getMedicationGenderAndAge(request);
}

const useX_getMedicationGenderAndAge = (codes: string[] | null) => {
	let r = useFilterQuery();

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes,
		itemType: "MEDICATION",
	};

	let cacheKeys = ["getMedicationGenderAndAge", "getMedicationGenderAndAge_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getMedicationGenderAndAgeX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getMedicationGenderAndAge };
