import { Box, ButtonBase } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

type subMenu = {
	name: string;
	target: string;
};

const linkStyle = {
	textDecoration: "none",
	color: "black",
};

function NavSubMenu() {
	let location = useLocation();
	var partialPathname = location.pathname.split("/")[1];
	let subMenuDefinitions: Array<subMenu> = [];

	switch (partialPathname) {
		case "diagnosisList": {
			subMenuDefinitions = [{ name: "Diagnosis", target: "/diagnosisList" }];
			break;
		}
		case "spend": {
			break;
		}
		case "membersAndDoctors": {
			subMenuDefinitions = [
				{ name: "Members", target: "/membersAndDoctors/members" },
				{
					name: "Doctors",
					target: "/membersAndDoctors/doctors",
				},
			];
			break;
		}
		case "reporting": {
			break;
		}
		case "settings": {
			subMenuDefinitions = [{ name: "Settings", target: "/settings" }];
			break;
		}
		case "dataTesting": {
			subMenuDefinitions = [
				{ name: "Preprocessed", target: "/dataTesting/Preprocessed" },
				{ name: "Live", target: "/dataTesting/Live" },
				{
					name: "ICD10-Category",
					target: "/dataTesting/ICD10CodeCategorization",
				},
				{ name: "API-Test", target: "/dataTesting/APITesting" },
			];
			break;
		}
		default: {
			break;
		}
	}
	return (
		<Box sx={{ flexFlow: "nowrap", height: "100%" }}>
			{subMenuDefinitions.map((subMenu: any, x) => {
				return (
					<ButtonBase
						key={x}
						data-id={"navSubMenu"}
						sx={{
							position: "relative",
							p: 2,
							height: "100%",
							fontSize: { xs: ".75rem", md: "1rem", xl: "1.25rem" },
							color: "primary.main",
							textTransform: "uppercase",
							fontWeight: 700,
							...(location.pathname.indexOf(subMenu.target) === 0 && {
								"&:before": {
									content: `''`,
									position: "absolute",
									top: "0",
									left: "0",
									height: ".5rem",
									width: "100%",
									backgroundColor: "primary.main",
									borderBottomLeftRadius: ".5rem",
									borderBottomRightRadius: ".5rem",
								},
							}),
						}}
					>
						<Link style={linkStyle} to={subMenu.target}>
							{subMenu.name}
						</Link>
					</ButtonBase>
				);
			})}
		</Box>
	);
}

export default NavSubMenu;
