import { Stack, Typography } from "@mui/material";
import { DropDownSelect, SelectOptions } from "./DropDownSelect";
import { useMemo } from "react";

const orderOptions = [
	{ label: "Descending", value: "DESC" },
	{ label: "Ascending", value: "ASC" },
];

const SortBarSelect = ({
	descending,
	set_descending,
	sortOptions,
	sort,
	set_sort,
}: {
	descending: boolean;
	set_descending: (desc: boolean) => CIM;
	sortOptions: Array<SelectOptions>;
	sort: string;
	set_sort: (field: string) => CIM;
}) => {
	const sortLabel = useMemo(() => {
		let x = sort;
		sortOptions.some((so) => {
			if (so.value === sort) {
				x = so.label;
				return true;
			}
			return false;
		});
		return x;
	}, [sort, sortOptions]);

	return (
		<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
			<Stack className={"date-range-input"} sx={{ flexDirection: "row", pr: 3 }}>
				<Stack
					className={"date-range-container"}
					direction="row"
					spacing="1"
					sx={{
						height: "2.5rem",
						borderRadius: "2.5rem",
						border: "2px solid #fff",
						backgroundColor: "gray.main",
						px: 1.5,
						color: "gray.dark",
						boxShadow: 4,
						alignItems: "center",
						"& .MuiButtonBase-root": {
							height: "100%",
							fontSize: "1rem",

							"& svg": {
								color: "primary.main",
								fontSize: "1.25rem",
							},
						},
					}}
				>
					<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
						Sort By:
					</Typography>
					<Stack sx={{ flexDirection: "row" }}>
						<DropDownSelect
							title={sortLabel}
							type="radio"
							options={sortOptions}
							selectedValues={[sort]}
							ariaLabel="Sort By"
							setValues={(v: Array<string>) => {
								set_sort(v[0]);
							}}
						/>
					</Stack>
					<Typography component={"span"} sx={{ color: "primary.main", pr: 1, fontWeight: 700 }}>
						Order By:
					</Typography>
					<Stack sx={{ flexDirection: "row" }}>
						<DropDownSelect
							title={descending ? "Descending" : "Ascending"}
							type="radio"
							options={orderOptions}
							selectedValues={[descending ? "DESC" : "ASC"]}
							ariaLabel="Sort Order"
							setValues={(v: Array<string>) => {
								console.log(v);

								set_descending(v[0] === "DESC");
							}}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export { SortBarSelect };
