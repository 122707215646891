import React, { useContext, useMemo } from "react";
import { Box, Grid, Card, CardContent, CardHeader, Tabs } from "@mui/material";

import { useParams } from "react-router-dom";
import { DiagnosisDetailsOverview } from "../../diagnosis/details/panels/DiagnosisDetailsOverview";

import { useFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { useX_getConditionDetails } from "api_extension/useQueryX/cacheDiagnosisData/useX_getConditionDetails";
import { useX_getDiagnosisRangeAggregations } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosisRangeAggregations";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";
import { DiagnosisDetailsMembers } from "../../diagnosis/details/panels/DiagnosisDetailsMembers";
import { DiagnosisDetailsDoctors } from "../../diagnosis/details/panels/DiagnosisDetailsDoctors";
import { DiagnosisNotificationList } from "notifications/DiagnosisNotificationList";
import { DiagnosisDetailsProductServices } from "sections/diagnosis/details/panels/DiagnosisDetailsProcedures";
import { DiagnosisDetailsMedications } from "sections/diagnosis/details/panels/DiagnosisDetailsMedications";
import { DiagnosisDetailsFacilities } from "sections/diagnosis/details/panels/DiagnosisDetailsFacilities";
import { DiagnosisDetailsImpact } from "sections/diagnosis/details/panels/DiagnosisDetailsImpact";

interface DiagnosisTypeApiRequest extends DateRangeStrings {
	searchQuery: string | undefined;
	icd10Query: string;
	subRangeQuery: string;
}

interface DiagnosisRangeApiRequest extends DateRangeStrings {
	codes: Array<string> | null;
	ranges: Array<string>;
	jobIds: Array<string>;
}

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("diagnosis-details");

function DiagnosisDetailsMainControllable({ code, range }: { code?: string | null; range?: string | null }) {
	// allow passing the code/range directly instead of using the context
	const tabs = ["Overview", "Members", "Doctors", "Facilities", "Prescriptions", "Procedures", "Radius Impact"];

	// LEAVE FOR REFERENCE
	// const [startDate, set_startDate] = useState<Date | null>(null);
	// const [endDate, set_endDate] = useState<Date | null>(null);
	// const MonthRangeSelectInstance = useMemo(() => {
	// 	return <MonthRangeSelection startDate={startDate} set_startDate={set_startDate} endDate={endDate} set_endDate={set_endDate} inheritGlobalFilter />;
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	let params = useParams() as { diagnosisId: string };
	const diagnosisID: string | number = params.hasOwnProperty("diagnosisId") ? params["diagnosisId"] : 0;

	const filters = useFilterQuery();

	const diagnosisRangeRequest: DiagnosisRangeApiRequest = useMemo(() => {
		let x: DiagnosisRangeApiRequest = {
			startDate: filters.startDate,
			endDate: filters.endDate,
			ranges: range ? [range] : [],
			codes: code ? [code] : [],
			jobIds: primaryFilterValues.Members,
		};
		return Object.assign({}, filters, x);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, diagnosisID, primaryFilterValues.Members, code, range]);

	const rangeAggregationResponse = useX_getDiagnosisRangeAggregations(diagnosisRangeRequest.codes, diagnosisRangeRequest.ranges, diagnosisRangeRequest);

	const rangeAggregateItem = rangeAggregationResponse?.data?.response?.aggregateItems?.[0];

	const totalMembers = useMemo_totalMembersInFilter();

	const [activeTab, set_activeTab] = React.useState<number>(0);

	const apiRequest: DiagnosisTypeApiRequest = useMemo(() => {
		return Object.assign({}, filters, {
			searchQuery: undefined,
			icd10Query: code ?? "",
			subRangeQuery: range ?? "",
		});
	}, [code, range, filters]);

	const { data } = useX_getConditionDetails(apiRequest);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={12} xl={9}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={activeTab}
									onChange={(_event: React.SyntheticEvent, newValue: number) => {
										set_activeTab(newValue);
									}}
									aria-label="Diagnosis Details Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
										},
									}}
								>
									{tabs.map((label, value) => {
										return <TabControl key={label + value} label={label} value={value} />;
									})}
								</Tabs>
							}
						></CardHeader>
						{code ?? range ? (
							<CardContent
								sx={{
									pt: 0,
								}}
							>
								<TabPanel value={activeTab} index={0}>
									<DiagnosisDetailsOverview
										api_data={data}
										diagnosisID={code ?? range ?? "------"}
										rangeAggregateItem={rangeAggregateItem}
										totalMembers={totalMembers}
										diagnosisRangeApiRequest={diagnosisRangeRequest}
										code={code}
										range={range}
									/>
								</TabPanel>
								<TabPanel value={activeTab} index={1}>
									<DiagnosisDetailsMembers
										apiRequest={apiRequest}
										api_data={data}
										rangeAggregateItem={rangeAggregateItem}
										totalMembers={totalMembers}
									/>
								</TabPanel>
								<TabPanel value={activeTab} index={2}>
									<DiagnosisDetailsDoctors
										apiRequest={apiRequest}
										diagnosisID={code ?? range ?? "------"}
										icd10Query={code ?? ""}
										subRangeQuery={range ?? ""}
										rangeAggregateItem={rangeAggregateItem}
									/>
								</TabPanel>
								<TabPanel value={activeTab} index={3}>
									<DiagnosisDetailsFacilities
										apiRequest={apiRequest}
										diagnosisID={code ?? range ?? "------"}
										icd10Query={code ?? ""}
										subRangeQuery={range ?? ""}
										rangeAggregateItem={rangeAggregateItem}
									/>
								</TabPanel>
								<TabPanel value={activeTab} index={4}>
									<DiagnosisDetailsMedications apiRequest={apiRequest} />
								</TabPanel>
								<TabPanel value={activeTab} index={5}>
									<DiagnosisDetailsProductServices apiRequest={apiRequest} />
								</TabPanel>
								<TabPanel value={activeTab} index={6}>
									<DiagnosisDetailsImpact apiRequest={apiRequest} rangeAggregateItem={rangeAggregateItem} />
								</TabPanel>
							</CardContent>
						) : null}
					</Card>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Card elevation={4}>
						<CardHeader title="Member Notification"></CardHeader>
						<CardContent>
							<DiagnosisNotificationList diagnosisID={diagnosisID} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
export { DiagnosisDetailsMainControllable };
export type { DiagnosisTypeApiRequest, DiagnosisRangeApiRequest };
