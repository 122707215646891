import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useContext, useState } from "react";
import { Loader } from "shared/components/Loaders/Loader";
import { CachePatientMonthlyUpdater } from "./CachePatientMonthlyUpdater";

const PatientCacheTools = () => {
	const pFilters = useContext(PrimaryFiltersContext);

	const [activeJobId, set_activeJobId] = useState<string | null>(null);

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
                                                  
*/
	if (!pFilters?.values || !pFilters?.values?.Members) {
		return <Loader title="Select a Member Type (JobId)" />;
	}
	return (
		<div>
			<h1>Patient Cache Tools</h1>
			<div>
				{pFilters?.values?.Members.map((x) => {
					return (
						<button
							style={{ backgroundColor: activeJobId === x ? "lightblue" : "white", borderRadius: "4px", padding: "4px", margin: "2px" }}
							key={x}
							onClick={set_activeJobId.bind(null, x)}
						>
							{x}
						</button>
					);
				})}
			</div>
			<p>
				<b>ActiveJobId</b>: {activeJobId}
			</p>
			<CachePatientMonthlyUpdater activeJobId={activeJobId} />
		</div>
	);
};

export { PatientCacheTools };
