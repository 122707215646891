/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminGetRevenueServicesProceduresResult,
  AdminGetRevenueServicesProceduresQuery,
  AdminGetRevenueServicesAppointmentsResult,
  AdminGetRevenueServicesAppointmentsQuery,
  AdminGetRevenueServicesPrescriptionsResult,
  AdminGetRevenueServicesPrescriptionsQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getRevenueServicesProcedures = (
    adminGetRevenueServicesProceduresQuery: AdminGetRevenueServicesProceduresQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetRevenueServicesProceduresResult>(
      {url: `/api/admin/RevenueServices/GetServicesProcedures`, method: 'post',
      data: adminGetRevenueServicesProceduresQuery
    },
      options);
    }
  


    export const useGetRevenueServicesProcedures = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getRevenueServicesProcedures>, TError,{data: AdminGetRevenueServicesProceduresQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getRevenueServicesProcedures>, {data: AdminGetRevenueServicesProceduresQuery}> = (props) => {
          const {data} = props || {};

          return  getRevenueServicesProcedures(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getRevenueServicesProcedures>, TError, {data: AdminGetRevenueServicesProceduresQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getRevenueServicesAppointments = (
    adminGetRevenueServicesAppointmentsQuery: AdminGetRevenueServicesAppointmentsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetRevenueServicesAppointmentsResult>(
      {url: `/api/admin/RevenueServices/GetServicesAppointments`, method: 'post',
      data: adminGetRevenueServicesAppointmentsQuery
    },
      options);
    }
  


    export const useGetRevenueServicesAppointments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getRevenueServicesAppointments>, TError,{data: AdminGetRevenueServicesAppointmentsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getRevenueServicesAppointments>, {data: AdminGetRevenueServicesAppointmentsQuery}> = (props) => {
          const {data} = props || {};

          return  getRevenueServicesAppointments(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getRevenueServicesAppointments>, TError, {data: AdminGetRevenueServicesAppointmentsQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getRevenueServicesPrescriptions = (
    adminGetRevenueServicesPrescriptionsQuery: AdminGetRevenueServicesPrescriptionsQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetRevenueServicesPrescriptionsResult>(
      {url: `/api/admin/RevenueServices/GetServicesPrescriptions`, method: 'post',
      data: adminGetRevenueServicesPrescriptionsQuery
    },
      options);
    }
  


    export const useGetRevenueServicesPrescriptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getRevenueServicesPrescriptions>, TError,{data: AdminGetRevenueServicesPrescriptionsQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getRevenueServicesPrescriptions>, {data: AdminGetRevenueServicesPrescriptionsQuery}> = (props) => {
          const {data} = props || {};

          return  getRevenueServicesPrescriptions(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getRevenueServicesPrescriptions>, TError, {data: AdminGetRevenueServicesPrescriptionsQuery}, TContext>(mutationFn, mutationOptions)
    }
    