import { Typography } from "@mui/material";
import { ModelsDoctorStub, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts } from "api";
import { UserFollowedItemTitle } from "shared/components/UserFollowedItemTitle";

const isProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts = (x: CIM): x is ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts => {
	return x?.lookUpItem !== undefined;
};

const MainTitleDisplayForEntityType = ({ item }: { item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts }) => {
	if (!item) {
		return <p>[missing]</p>;
	}

	if (!item.lookUpItem) {
		return <p>[{item.code}]</p>;
	}

	let render;
	switch (item.lookUpItem.itemType) {
		case "PATIENT":
			render = RenderMainTitleDisplay.PATIENT(item);
			break;
		case "DIAGNOSIS":
			render = RenderMainTitleDisplay.DIAGNOSIS(item);
			break;
		case "DIAGNOSIS_RANGE":
			render = RenderMainTitleDisplay.DIAGNOSIS_RANGE(item);
			break;
		case "PRODUCTSERVICE":
			render = RenderMainTitleDisplay.PRODUCTSERVICE(item);
			break;
		case "MEDICATION":
			render = RenderMainTitleDisplay.MEDICATION(item);
			break;
		case "PROVIDER":
			render = RenderMainTitleDisplay.PROVIDER(item);
			break;
		case "ORGANIZATION":
			render = RenderMainTitleDisplay.ORGANIZATION(item);
			break;
		case "FACILITY":
			render = RenderMainTitleDisplay.ORGANIZATION(item);
			break;
		default:
			render = (
				<p>
					{item.code}
					{item.lookUpItem?.itemType}
				</p>
			);
			break;
	}
	return render;
};

const RenderMainTitleDisplay = {
	PATIENT: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.patientRiskCategory;
		return x ? (
			<UserFollowedItemTitle code={`${x.patientId}||${x.jobId}`} name={x.patientId} followedItemType={"PATIENT"} />
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	DIAGNOSIS: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.diagnosis;
		return x ? (
			<UserFollowedItemTitle
				tooltip={<Typography>{x.icd10Code}</Typography>}
				code={x.icd10Code}
				name={x.title ?? "Unknown Condition"}
				followedItemType="DIAGNOSIS"
			/>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	DIAGNOSIS_RANGE: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.diagnosisRange;
		return x ? (
			<UserFollowedItemTitle
				tooltip={<Typography>{x.range}</Typography>}
				code={x.range}
				name={x.description ?? x.title ?? "Unknown Condition Range"}
				followedItemType="DIAGNOSIS_RANGE"
			/>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	PRODUCTSERVICE: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.hcpcs;
		return x ? (
			<UserFollowedItemTitle
				tooltip={<Typography>{x.code}</Typography>}
				code={x.code}
				name={x.description ?? "Unknown Procedure"}
				followedItemType="PRODUCTSERVICE"
			/>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	MEDICATION: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {
		let x = item?.lookUpItem?.medication;
		return x ? (
			<UserFollowedItemTitle
				tooltip={<Typography>{x.ndc}</Typography>}
				code={x.ndc}
				name={x.fullName ?? x.description ?? "Unknown Medication"}
				followedItemType="MEDICATION"
			/>
		) : (
			<p>{item.code} : Not Found</p>
		);
	},
	PROVIDER: (item?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts | ModelsDoctorStub) => {
		if (!item) {
			return <p>Not Found</p>;
		}
		let doc: ModelsDoctorStub;
		if (isProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts(item)) {
			if (item.lookUpItem?.provider) {
				doc = item.lookUpItem.provider;
			} else {
				return <p>{item?.code} : Not Found</p>;
			}
		} else {
			doc = item;
		}
		const name = doc.firstName ? `${doc.namePrefix ?? ""} ${doc.firstName ?? ""} ${doc.lastName ?? ""}` : doc.organizationName ?? "N/A";
		return doc.npi ? (
			<UserFollowedItemTitle tooltip={<Typography>NPI: {doc.npi}</Typography>} code={doc.npi} name={name} followedItemType="PROVIDER" />
		) : (
			<p>Provider Not Found</p>
		);
	},
	ORGANIZATION: (item?: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts | ModelsDoctorStub) => {
		if (!item) {
			return <p>Not Found</p>;
		}
		let doc: ModelsDoctorStub;
		if (isProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts(item)) {
			if (item.lookUpItem?.organization) {
				doc = item.lookUpItem.organization;
			} else {
				return <p>{item?.code} : Not Found</p>;
			}
		} else {
			doc = item;
		}
		return doc.npi ? (
			<UserFollowedItemTitle
				tooltip={<Typography>NPI: {doc.npi}</Typography>}
				code={doc.npi}
				name={doc.organizationName ?? "[Missing Organization Name]"}
				followedItemType="ORGANIZATION"
			/>
		) : (
			<p>Organization Not Found</p>
		);
	},
};

export { MainTitleDisplayForEntityType, RenderMainTitleDisplay };
