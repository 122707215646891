import React from "react";

export interface FormStatus {
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  error?: unknown;
}

export const FormStatusContext = React.createContext<FormStatus>({});

type Props = React.PropsWithChildren<{ status: FormStatus }>;

function FormStatusProvider({ status, children }: Props) {
  return (
    <FormStatusContext.Provider value={status}>
      {children}
    </FormStatusContext.Provider>
  );
}

export default FormStatusProvider;
