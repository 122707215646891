import { MembersOverview } from "./overview/MembersOverview";
import { Route, Routes, Navigate } from "react-router-dom";

function MembersRoutes() {
	return (
		<Routes>
			<Route path={`overview`} element={<MembersOverview />} />
			<Route path={"*"} element={<Navigate to={`overview`} />} />
		</Routes>
	);
}

export { MembersRoutes };
