// import { getModelsDateRangeRequestFromFilters } from "DataProvider/hooks/useRunFilterQuery";
import { AdminRetrieveCachePatientDataRequest, ModelsPaginationAndSortRequest, ProvidersRiskModelFilterCriteria, retrieveCachePatientData } from "api";
import { useQuery } from "react-query";

const useX_retrieveCachePatientData = (
	filter: ProvidersRiskModelFilterCriteria,
	riskCategory: RiskCategoryNames | null,
	pageSort: ModelsPaginationAndSortRequest,
	patientIds: string[]
) => {
	let request: AdminRetrieveCachePatientDataRequest = {
		memberTypes: filter?.memberTypes ?? undefined,
		dateRangeRequest: { startDate: filter.startDate, endDate: filter.endDate },
		riskModelRequest: {
			riskCategoryName: riskCategory,
			riskModelName: filter.riskModel ?? "NONE_SELECTED",
		},
		paginationRequest: pageSort,
		patientIds,
	};

	let cacheKeys = ["getPaginatedPatientCacheData", "getPaginatedPatientCacheData_" + JSON.stringify(request)];
	return useQuery(cacheKeys, retrieveCachePatientData.bind(null, request), {
		cacheTime: 60 * 60 * 2 * 1000,
		staleTime: 60 * 60 * 2 * 1000,
	});
};

export { useX_retrieveCachePatientData };
