import { Grid, Card, CardHeader, CardContent, Box } from "@mui/material";
import ValueTile from "../../../../shared/components/ValueTile";
import { palette } from "../../../../theme";
import { displayConverter } from "../../../../shared/utilities/displayConverter";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Loader } from "shared/components/Loaders/Loader";
import { useMemo } from "react";
import { ChartColorHelper } from "shared/utilities/ChartColorHelper";
import { ProductServiceTypeApiRequest } from "../ProductServiceDetailsMain";
import { ProductServiceCacheProductServiceMonthlyAggregate } from "api";
import { useX_getProductServiceGenderAndAge } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceGenderAndAge";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";

SwiperCore.use([Pagination]);

type ProductServiceDetailsOverviewProps = {
	rangeAggregateItem?: ProductServiceCacheProductServiceMonthlyAggregate;
	apiRequest: ProductServiceTypeApiRequest;
	totalMembers: number;
};

function ProductServiceDetailsOverview(props: Readonly<ProductServiceDetailsOverviewProps>) {
	const { rangeAggregateItem, apiRequest, totalMembers } = props;

	const genderAndAgeResponse = useX_getProductServiceGenderAndAge(apiRequest.codes);

	const pieChart = useMemo(() => {
		if (genderAndAgeResponse?.data?.response) {
			let pieData: Array<{ name: string; value: number }> = genderAndAgeResponse.data.response.genderModels.map((x) => {
				return { name: x.name ?? "N/A", value: x.count ?? 0 };
			});
			return (
				<StandardPieChart
					data={pieData}
					colors={ChartColorHelper.colorArrayForNameValuesData(pieData)}
					showLegend
					responsiveHeight={300}
					innerRadius={32}
					displayPercentage
					tooltipFormatter={(value: number) => displayConverter.insertThousandsCommas(value)}
				/>
			);
		}
		return null;
	}, [genderAndAgeResponse.data?.response]);

	const barChart = useMemo(() => {
		if (genderAndAgeResponse.data?.response) {
			let ageGendersModels = genderAndAgeResponse.data?.response.ageGenderModels;
			let barData = ageGendersModels.map((agm: { ageKey: any; genderCounts: any[] }) => {
				let a: { [key: string]: number | string; name: string } = { name: agm.ageKey };
				agm.genderCounts.forEach((gc) => {
					a[gc.name.toLowerCase()] = gc.value;
				});
				return a;
			});

			barData.sort((a: any, b: any) => {
				const compareValues = a.name > b.name ? 1 : 0;
				return a.name < b.name ? -1 : compareValues;
			});

			return (
				<StandardBarChart
					data={barData}
					dataKey="name"
					showYAxis
					responsiveHeight={300}
					textAnchor="middle"
					legendStyle={{ paddingBottom: "1rem" }}
					bar_definitions={[
						{
							dataKey: "male",
							name: "Male",
							fill: palette.green.main,
							legendType: "circle",
						},
						{
							dataKey: "female",
							name: "Female",
							fill: palette.purple.main,
							legendType: "circle",
						},
					]}
				/>
			);
		}
		return null;
	}, [genderAndAgeResponse.data?.response]);

	if (!rangeAggregateItem) {
		return <Loader />;
	}
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 3,
							},
							"1200": {
								slidesPerView: 4,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile
								value={
									rangeAggregateItem.uniquePatientCount / totalMembers > 0.01
										? displayConverter.toPercent(rangeAggregateItem.uniquePatientCount / totalMembers)
										: "<1%"
								}
								title="PCT of Total Members"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.uniquePatientCount)} title="Members" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.eobCount)} title="Procedure Count" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(Number(rangeAggregateItem.totalAmount))} title="Total Spend" minHeight="7rem" />
						</SwiperSlide>
					</Swiper>
				</Box>
			</Grid>
			<Grid item xs={12} md={12}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Gender"></CardHeader>
					<CardContent>{pieChart ?? <Loader />}</CardContent>
				</Card>

				<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
					<CardHeader title="Age"></CardHeader>
					<CardContent>{barChart ?? <Loader />}</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export { ProductServiceDetailsOverview };
