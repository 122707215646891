import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { RequestModelsCacheMonthlyQueryRequest, getSpendSummaryCharts } from "api";
import { useQuery } from "react-query";

function getSpendSummaryChartsX(request: RequestModelsCacheMonthlyQueryRequest) {
	return getSpendSummaryCharts(request);
}

const useX_getSpendSummaryCharts = (request_: RequestModelsCacheMonthlyQueryRequest | null) => {
	let r = { ...request_, ...useFilterQuery() };

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		itemType: "MEDICATION",
	};

	let cacheKeys = ["getSpendSummaryCharts", "getSpendSummaryCharts_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getSpendSummaryChartsX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getSpendSummaryCharts };
