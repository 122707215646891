import { AdminGetDiagnosisRangeGenderAndAgeQuery, getDiagnosisRangeGenderAndAge } from "api";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { useQuery } from "react-query";

function getDiagnosis_GenderAndAgeX(request: Partial<AdminGetDiagnosisRangeGenderAndAgeQuery>) {
	return getDiagnosisRangeGenderAndAge(request);
}

const useX_getDiagnosis_GenderAndAge = (codes?: string[] | null, ranges?: string[] | null) => {
	let r = useFilterQuery();

	let request: AdminGetDiagnosisRangeGenderAndAgeQuery = {
		request: {
			dateRangeRequest: {
				startDate: r.startDate,
				endDate: r.endDate,
			},
			jobIds: r.memberTypes,
			codes,
			itemType: "DIAGNOSIS",
		},
		ranges: ranges ?? [],
	};

	let cacheKeys = ["getDiagnosisRangeGenderAndAge", "getDiagnosisRangeGenderAndAge_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDiagnosis_GenderAndAgeX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getDiagnosis_GenderAndAge };
