import { Route, Routes } from "react-router-dom";
import { MedicationSummaryTileList } from "./ListSummaries/MedicationSummaryTileList";
import { MedicationDetailsMain } from "../item_details/medication/MedicationDetailsMain";

function MedicationRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<MedicationSummaryTileList />} />
			<Route path={`details/:prescriptionId`} element={<MedicationDetailsMain />} />
		</Routes>
	);
}

export { MedicationRoutes };
