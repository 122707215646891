import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useGetErrorById } from "api";

interface ErrorDetailsProps {
	id?: number | null;
}

function ErrorDetails({ id }: ErrorDetailsProps) {
	const params = useParams();
	const { data } = useGetErrorById({ id: id ?? Number(params.id) });
	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Id:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.id}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Code:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.code}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Type:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.type}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>User Id:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.userId}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Created At:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.createdAt}</Typography>
				</Box>{" "}
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Message:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.message}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Path:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.path}</Typography>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Typography sx={{ fontSize: "x-large", pl: 1, fontWeight: 600 }}>Trace:</Typography>
					<Typography sx={{ fontSize: "x-large", pl: 1 }}>{data?.trace}</Typography>
				</Box>
			</Box>
		</>
	);
}

export default ErrorDetails;
