import { useEffect, useState } from "react";
import { Box, Stack, Card, CardContent, Typography, Avatar, CircularProgress } from "@mui/material";
import { icons } from "../../theme";
import Icon from "../Icon";
import * as CSS from "csstype";
import { IconDefinition } from "@fortawesome/fontawesome-common-types/index";
import { StandardLineChart } from "./charts/line/StandardLineChart";

interface SummaryTileProps {
	title: string;
	color: CSS.Property.Color | undefined;
	backgroundColor?: CSS.Property.Color | undefined;
	data?: Array<{ name: string; [attr: string]: string | number }>;
	primaryValue?: string;
	dataKey?: string;
	icon?: IconDefinition;
	graphPointLabel?: string;
	tooltipFormat?: Function;
}

function SummaryTile(props: SummaryTileProps) {
	const [dataTimeStamp, setDataTimeStamp] = useState(Date.now());
	useEffect(() => {
		// force the LineChart to rerender and animate
		setDataTimeStamp(Date.now());
	}, [props.data, props.title]);

	return (
		<Card elevation={0} sx={{ backgroundColor: props.backgroundColor ?? "gray.main" }}>
			<CardContent sx={{ textAlign: "center" }}>
				<Stack direction="row">
					<Avatar
						sx={{
							fontSize: "1rem",
							color: props.color,
							borderColor: props.color,
						}}
					>
						<Icon icon={props.icon ? props.icon : icons.users}></Icon>
					</Avatar>
					<Box sx={{ textAlign: "left", pl: 1 }}>
						<Typography variant="h4" sx={{ color: props.color }}>
							{props.primaryValue}
						</Typography>
						<Typography
							component="span"
							sx={{
								textTransform: "uppercase",
								fontWeight: 700,
								color: "gray.dark",
							}}
						>
							{props.title}
						</Typography>
					</Box>
				</Stack>
				<Box sx={{ pt: 2 }}>
					{props.data ? (
						<StandardLineChart
							key={dataTimeStamp}
							data={props.data}
							responsiveHeight={100}
							XAxisAnchor={"middle"}
							tooltipFormat={props.tooltipFormat}
							yKeys={[
								{
									dataKey: props.dataKey ? props.dataKey : "value",
									color: props.color,
									name: props.graphPointLabel,
								},
							]}
						/>
					) : (
						<CircularProgress style={{ color: props.color }} color={"inherit"} />
					)}
				</Box>
			</CardContent>
		</Card>
	);
}

export default SummaryTile;
