/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetSummaryGraphsResult,
  ProvidersSummaryFilterCriteria,
  OverviewGetTopFacilitiesGraphResult,
  OverviewGetOverallServicesChartResult,
  AdminGetAllDistinctJobIdsResult,
  GetAllDistinctJobIdsParams,
  AdminGetIcdSummaryResult,
  GetIcdSummaryParams,
  AdminGetHistoricalLookupsResult,
  GetHistoricalLookupsParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getSummaryGraphs = (
    providersSummaryFilterCriteria: ProvidersSummaryFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetSummaryGraphsResult>(
      {url: `/api/admin/Overview/GetSummaryGraphs`, method: 'post',
      data: providersSummaryFilterCriteria
    },
      options);
    }
  


    export const useGetSummaryGraphs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getSummaryGraphs>, TError,{data: ProvidersSummaryFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getSummaryGraphs>, {data: ProvidersSummaryFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  getSummaryGraphs(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getSummaryGraphs>, TError, {data: ProvidersSummaryFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const getTopFacilities = (
    providersSummaryFilterCriteria: ProvidersSummaryFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<OverviewGetTopFacilitiesGraphResult>(
      {url: `/api/admin/Overview/GetTopFacilities`, method: 'post',
      data: providersSummaryFilterCriteria
    },
      options);
    }
  


    export const useGetTopFacilities = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getTopFacilities>, TError,{data: ProvidersSummaryFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getTopFacilities>, {data: ProvidersSummaryFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  getTopFacilities(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getTopFacilities>, TError, {data: ProvidersSummaryFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const getOverallServices = (
    providersSummaryFilterCriteria: ProvidersSummaryFilterCriteria,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<OverviewGetOverallServicesChartResult>(
      {url: `/api/admin/Overview/GetOverallServices`, method: 'post',
      data: providersSummaryFilterCriteria
    },
      options);
    }
  


    export const useGetOverallServices = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getOverallServices>, TError,{data: ProvidersSummaryFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getOverallServices>, {data: ProvidersSummaryFilterCriteria}> = (props) => {
          const {data} = props || {};

          return  getOverallServices(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getOverallServices>, TError, {data: ProvidersSummaryFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const getAllDistinctJobIds = (
    params?: GetAllDistinctJobIdsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetAllDistinctJobIdsResult>(
      {url: `/api/admin/Overview/GetAllDistinctJobIds`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllDistinctJobIdsQueryKey = (params?: GetAllDistinctJobIdsParams,) => [`/api/admin/Overview/GetAllDistinctJobIds`, ...(params ? [params]: [])];

    
export const useGetAllDistinctJobIds = <TData = AsyncReturnType<typeof getAllDistinctJobIds>, TError = unknown>(
 params?: GetAllDistinctJobIdsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllDistinctJobIds>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllDistinctJobIdsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllDistinctJobIds>> = () => getAllDistinctJobIds(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllDistinctJobIds>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getIcdSummary = (
    params?: GetIcdSummaryParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetIcdSummaryResult>(
      {url: `/api/admin/Overview/GetIcdSummary`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetIcdSummaryQueryKey = (params?: GetIcdSummaryParams,) => [`/api/admin/Overview/GetIcdSummary`, ...(params ? [params]: [])];

    
export const useGetIcdSummary = <TData = AsyncReturnType<typeof getIcdSummary>, TError = unknown>(
 params?: GetIcdSummaryParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getIcdSummary>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetIcdSummaryQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getIcdSummary>> = () => getIcdSummary(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getIcdSummary>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getHistoricalLookups = (
    params?: GetHistoricalLookupsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetHistoricalLookupsResult>(
      {url: `/api/admin/Overview/GetHistoricalLookups`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetHistoricalLookupsQueryKey = (params?: GetHistoricalLookupsParams,) => [`/api/admin/Overview/GetHistoricalLookups`, ...(params ? [params]: [])];

    
export const useGetHistoricalLookups = <TData = AsyncReturnType<typeof getHistoricalLookups>, TError = unknown>(
 params?: GetHistoricalLookupsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getHistoricalLookups>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHistoricalLookupsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getHistoricalLookups>> = () => getHistoricalLookups(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getHistoricalLookups>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

