import { getProductServiceImpact } from "api/-cacheproductservicedata/-cacheproductservicedata";
import { AdminGetProductServiceImpactQuery } from "api/backend.schemas";
import { useQuery } from "react-query";

function getProductServiceDetailsImpactX(request: Partial<AdminGetProductServiceImpactQuery>, specialRequest: "RETRIEVE_FULL" | null) {
	console.count("SENDREQUEST getImpactByProductService");
	return getProductServiceImpact(Object.assign(request, { specialRequest: specialRequest }));
}

const useX_getProductServiceDetailsImpact = (request: Partial<AdminGetProductServiceImpactQuery>, specialRequest: "RETRIEVE_FULL" | null) => {
	let cacheKeys = ["getProductServiceImpact", "getProductServiceImpact" + JSON.stringify(request) + specialRequest];
	return useQuery(cacheKeys, getProductServiceDetailsImpactX.bind(null, request, specialRequest), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getProductServiceDetailsImpact };
