import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";
import { displayConverter } from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";

import { Swiper, SwiperSlide } from "swiper/react";
import { DiagnosisTypeApiRequest } from "../../../item_details/diagnosis/DiagnosisDetailsMain";
import { useMemo } from "react";

import { RequestModelsCacheMonthlyDetailsQueryRequest } from "api";
import { useX_getCacheMonthlyDetail } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetail";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { Loader } from "shared/components/Loaders/Loader";
import { Link } from "react-router-dom";
import { SimpleTooltipColumn } from "shared/components/grid/GridColumn/SimpleTooltipColumn";

type DiagnosisDetailsProductServicesProps = {
	apiRequest: DiagnosisTypeApiRequest;
};

interface Filters {
	tableQuery?: string;
}

function Toolbar(props: Readonly<Pick<DataGridToolbarProps, "onAdd" | "onSearch">>) {
	return <DataGridToolbar header={"Procedures List"} {...props} enableExport={false} />;
}

function DiagnosisDetailsProductServices(props: Readonly<DiagnosisDetailsProductServicesProps>) {
	const columns: GridColumns = [
		{
			field: "description",
			headerName: "Procedure Name",
			headerAlign: "left",
			align: "left",
			flex: 3,
			renderCell: (params) => {
				console.log(params);
				return (
					<SimpleTooltipColumn
						followItemType={"PRODUCTSERVICE"}
						code={params.row.code}
						tooltipTitle={<Typography>{params.row.code}</Typography>}
						name={params.row.description}
					/>
				);
			},
		},
		{
			field: "count",
			headerName: "Total Procedures",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.insertThousandsCommas(params.row.count),
		},
		{
			field: "cost",
			headerName: "Total Spend",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.toUSD(Number(params.row.cost)),
		},

		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => {
				return (
					<Link to={"/procedures/details/" + row.code}>
						<Button size="small" color="secondary">
							Details
						</Button>
					</Link>
				);
			},
		},
	];
	const { grid, query } = useDataGrid<Filters>("services", columns);
	const { apiRequest } = props;

	const { values: pfValues } = usePrimaryFilters();
	const codeQuery = useMemo(() => {
		if (apiRequest.icd10Query !== "") {
			return apiRequest.icd10Query;
		} else if (apiRequest.subRangeQuery !== "") {
			return apiRequest.subRangeQuery;
		}
		return apiRequest.searchQuery ?? "";
	}, [apiRequest.icd10Query, apiRequest.searchQuery, apiRequest.subRangeQuery]);

	const queryRequest: RequestModelsCacheMonthlyDetailsQueryRequest | null = useMemo(() => {
		return {
			jobIds: [...pfValues.Members],
			codes: [codeQuery],
			itemType: apiRequest.icd10Query !== "" ? "DIAGNOSIS" : "DIAGNOSIS_RANGE",
			includeLookup: true,
			paginationRequest: { skip: 0, take: 1000, maxNestedSize: 100 },
			codeMonthAggregations: false,
		};
	}, [pfValues.Members, codeQuery, apiRequest.icd10Query]);

	const apiResult = useX_getCacheMonthlyDetail(queryRequest);
	const data = useMemo(() => {
		if (!apiResult.data?.response?.codeAggregations || codeQuery === "") return null;
		return apiResult.data?.response?.codeAggregations[codeQuery];
	}, [apiResult.data?.response?.codeAggregations, codeQuery]);
	const averageCost = useMemo(() => {
		if (data?.productservice?.totalCost && data?.productservice?.totalCount) {
			return data?.productservice.totalCost / data?.productservice.totalCount;
		}
		return 0;
	}, [data]);
	return (
		<>
			<Box
				sx={{
					"& .swiper-container": {
						pb: { xs: 4, md: 0 },
					},
					"& .swiper-pagination-bullets": {
						bottom: "0",
					},
				}}
			>
				<Swiper
					spaceBetween={16}
					slidesPerView={1}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						"700": {
							slidesPerView: 3,
						},
						"1200": {
							slidesPerView: 4,
						},
					}}
				>
					<SwiperSlide>
						<ValueTile
							value={displayConverter.insertThousandsCommas(data?.productservice?.uniqueCount)}
							title="Total Procedures"
							minHeight="7rem"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ValueTile value={displayConverter.insertThousandsCommas(data?.productservice?.totalCount)} title="Total Diagnoses" minHeight="7rem" />
					</SwiperSlide>

					<SwiperSlide>
						<ValueTile value={displayConverter.toUSD(data?.productservice?.totalCost)} title="Total Spend" minHeight="7rem" />
					</SwiperSlide>

					<SwiperSlide>
						<ValueTile value={displayConverter.toUSD(averageCost)} title="Average Spend" minHeight="7rem" />
					</SwiperSlide>
				</Swiper>
			</Box>

			<Box sx={{ display: "flex", height: "100%" }}>
				<Box
					sx={{
						flexGrow: 1,
					}}
				>
					{data ? (
						<DataGridPro
							{...grid}
							initialState={{
								sorting: {
									sortModel: [{ field: "cost", sort: "desc" }],
								},
							}}
							columns={columns}
							autoHeight
							getRowId={(row) => row.code}
							rowCount={data.productservice?.codeCounts?.length ?? 0}
							rowsPerPageOptions={[10, 20, 50, 100]}
							rows={data.productservice?.codeCounts ?? []}
							components={{ Toolbar: Toolbar }}
							paginationMode="client"
							componentsProps={{
								toolbar: {
									initialSearch: query.tableQuery ?? "",
								},
							}}
							sx={{ pt: 2 }}
						/>
					) : (
						<Loader />
					)}
				</Box>
			</Box>
		</>
	);
}

export { DiagnosisDetailsProductServices };
