import { Box, Stack, Typography, Avatar, SxProps, Theme } from "@mui/material";

interface LegendRowProps {
	item: { name: string | number; value: string | number; [key: string]: any };
	color: any;
	legendRowSx?: SxProps<Theme>;
	legendValue?: string | null;
}

function LegendRow(props: LegendRowProps) {
	return (
		<Stack
			direction="row"
			width="100%"
			data-name="LegendRowMainStack"
			sx={{
				borderBottom: "2px solid",
				borderColor: "gray.main",
				pb: 2,
				pt: 2,
				...props.legendRowSx,
			}}
		>
			<Box>
				<Avatar
					sx={{
						color: props.color,
						borderColor: props.color,
						backgroundColor: "white",
					}}
				>
					{typeof props.item.value === "number" ? Math.round(props.item.value) : props.item.value}
				</Avatar>
			</Box>
			<Stack
				direction="column"
				width="100%"
				data-name="substack"
				alignItems="flex-start"
				justifyContent="center"
				sx={{
					paddingLeft: 1,
					margin: 0,
					color: props.color,
				}}
			>
				<Box>
					{props.legendValue && <Typography variant="subtitle1">{props.legendValue}</Typography>}
					<Typography
						variant="subtitle1"
						sx={{
							// textTransform: "uppercase",
							fontWeight: 700,
							color: "gray.dark",
						}}
					>
						{props.item.name}
					</Typography>
				</Box>
				{props.item.description ? (
					<Typography component="p" sx={{ color: "gray.dark" }}>
						{props.item.description}
					</Typography>
				) : null}
			</Stack>
		</Stack>
	);
}

export { LegendRow };
