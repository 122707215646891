import { populateNDCCodeData } from "api";

const TreatmentCacheTools = () => {
	return (
		<div>
			<h1>Treatment Cache Tools</h1>

			<button onClick={() => populateNDCCodeData()}>Trigger PopulateNDCCodes</button>
		</div>
	);
};

export { TreatmentCacheTools };
