import { Box, Grid, Card, CardHeader, CardContent } from "@mui/material";
import { FacilitiesOverviewList } from "./FacilitiesOverviewList";

function FacilitiesOverview() {
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader /*Only included for consistent spacing - not how I would do it ideally but keeps consistency with existing other sections*/
						/>
						<CardContent>
							<FacilitiesOverviewList />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export { FacilitiesOverview };
