import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { getModelsDateRangeRequestFromFilters, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetDiagnosisRangeAggregationsQuery, getDiagnosisCodeAggregations, getDiagnosisRangeAggregations, getSummaryGraphs } from "api";

import { useMemo, useState } from "react";
import { MonthRangeSelection } from "shared/components/DateRangeFilters/MonthRangeSelection";

const DeveloperTestArea = () => {
	const [startDate, set_startDate] = useState<Date | null>(null);
	const [endDate, set_endDate] = useState<Date | null>(null);
	const MonthRangeSelectInstance = useMemo(() => {
		return <MonthRangeSelection startDate={startDate} set_startDate={set_startDate} endDate={endDate} set_endDate={set_endDate} inheritGlobalFilter />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const createCacheResponse = useX_createCachePatientData({
	// 	dateRangeRequest: {
	// 		startDate: startDate ? DateConverter.stringDateForAPI(startDate) : undefined,
	// 		endDate: endDate ? DateConverter.stringDateForAPI(endDate) : undefined,
	// 	},
	// 	memberTypes: ["BlueButton#Dev"],
	// });

	// console.debug(createCacheResponse);
	let fqrX = useFilterQuery();
	let pf = usePrimaryFilters();

	return (
		<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "1rem" }}>
			<div style={{ textAlign: "center" }}>Developer Test Area</div>
			{MonthRangeSelectInstance}
			{String(startDate)}-{String(endDate)}
			<button
				onClick={async () => {
					// let x = await createCachePatientDataX({
					// 	dateRangeRequest: {
					// 		startDate: startDate ? DateConverter.stringDateForAPI(startDate) : undefined,
					// 		endDate: endDate ? DateConverter.stringDateForAPI(endDate) : undefined,
					// 	},
					// 	memberTypes: ["RadiusInsurance"],
					// });

					// console.debug(x);
					let filters = Object.assign({}, fqrX, { specialRequest: "QueryInNetworkDoctorsWithScript" });

					let r = await getSummaryGraphs(filters);
					console.debug(r);
				}}
				style={{ width: "200px", marginTop: "200px" }}
			>
				Request SummaryGraphs with ScriptQuery
			</button>
			<button
				onClick={async () => {
					let globalDateRange = getModelsDateRangeRequestFromFilters(pf.values);

					let filters: AdminGetDiagnosisRangeAggregationsQuery = Object.assign({}, globalDateRange, { startDate: startDate, endDate: endDate });
					// let q = { startDate: startDate ?? filters.StartDate, endDate: endDate ?? filters.EndDate };
					// filters.ranges = ["M00.00-M99.9"];
					// filters.ranges = ["C76.0-C80.2", "M00.00-M99.9"];
					console.debug(filters);
					let r = await getDiagnosisRangeAggregations(filters);
					console.debug(r);
				}}
				style={{ width: "200px", marginTop: "200px" }}
			>
				getDiagnosisRangeAggregations
			</button>
			<button
				onClick={async () => {
					let globalDateRange = getModelsDateRangeRequestFromFilters(pf.values);

					let filters = Object.assign({}, globalDateRange, { startDate: startDate, endDate: endDate });
					// let q = { startDate: startDate ?? filters.StartDate, endDate: endDate ?? filters.EndDate };
					console.debug(filters);
					let r = await getDiagnosisCodeAggregations(filters);
					console.debug(r);
				}}
				style={{ width: "200px", marginTop: "200px" }}
			>
				getDiagnosisCodeAggregations
			</button>
		</div>
	);
};

export { DeveloperTestArea };
