import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetProductServicesByDiagnosisQuery, getDiagnosisImpact } from "api";
import { useQuery } from "react-query";
import { DiagnosisTypeApiRequest } from "sections/item_details/diagnosis/DiagnosisDetailsMain";

export const useX_getDiagnosisImpact = (req: DiagnosisTypeApiRequest) => {
	let fqrX = useFilterQuery();

	let request: AdminGetProductServicesByDiagnosisQuery = {
		dateRangeRequest: {
			startDate: req.startDate,
			endDate: req.endDate,
		},
		jobIds: fqrX.memberTypes,
		ranges: req.subRangeQuery ? req.subRangeQuery.split(",") : req.searchQuery?.split(","),
		codes: req.icd10Query?.split(","),
	};

	console.debug(request);

	let cacheKeys = ["getDiagnosisImpact", "getDiagnosisImpact_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDiagnosisImpact.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
