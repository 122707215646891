// import { SystemDateOnly, SystemDayOfWeek } from "api";
// import { StandardOption } from "common/types/generic";

const DateConverter = {
	getFullMonthSpan(d1: Date, d2: Date): number {
		let [y1, y2] = [d1.getFullYear(), d2.getFullYear()];
		let [m1, m2] = [d1.getMonth(), d2.getMonth()]; // January is 0 index
		let yearDiff = y2 - y1;

		let monthDiff = m2 - m1;

		// console.debug({ yearDiff, monthDiff, d1, d2 });
		// console.debug(yearDiff * 12 + monthDiff);
		return yearDiff * 12 + monthDiff;
	},
	getLeadingEdgeDateForMonthsAgo(monthsAgo: number, from?: Date): Date {
		const d = from ? new Date(from.getTime() + 1000 * 60 * 60 * 48) : new Date(); // !!! dont change the provide from Date - add offset because it's acting inconsistently - we are expecting end of month values
		let m = d.getMonth(); // 0 index
		d.setMonth(m - monthsAgo); // this will account for -n date values by adjusting the year
		return DateConverter.getStartOfMonth(d);
	},
	getEndOfMostRecentMonth(from?: Date) {
		let d = DateConverter.getStartOfMonth(from);
		d.setDate(1);
		return new Date(d.getTime() - 1);
	},
	getEndOfMonth(from?: Date) {
		const d = from ? new Date(from.getTime()) : new Date(); // !!! dont change the provide from Date
		let year = d.getFullYear();
		let month = d.getMonth();

		// Create a new date object for the next month
		let nextMonthDate = new Date(year, month + 1, 1);
		return new Date(nextMonthDate.getTime() - 1000);

		// Subtract one day from the next month's date to get the end of the current month
		// var endOfMonth = new Date(nextMonthDate - 1);
		// 	d.setHours(0);
		// 	d.setMinutes(0);
		// 	d.setSeconds(0);
		// 	d.setMilliseconds(0);
		// 	d.setDate(1);
		// 	return d; //new Date(d.getTime() - 1);
	},
	getStartOfMonth(from?: Date) {
		const d = from ? new Date(from.getTime()) : new Date(); // !!! dont change the provide from Date
		d.setHours(0);
		d.setMinutes(0);
		d.setSeconds(0);
		d.setMilliseconds(0);
		d.setDate(1);
		return d; //new Date(d.getTime() - 1);
	},
	reactiveSetValueFromDate(d: Date, options: Array<StandardOption<string, number | string>>, intervalType: DateRangeSelect_IntervalType): number | "" {
		const nowDate = new Date();
		let target_value: number;
		if (intervalType === "month") {
			target_value = DateConverter.getFullMonthSpan(d, nowDate);
		}

		let closestMatchOption: StandardOption<string, number> | null = null;
		let surpassed = false;
		options.some((o, i) => {
			if (typeof o.value === "number") {
				if (o.value < target_value) {
					if (i === options.length) {
						closestMatchOption = o as StandardOption<string, number>;
					}
				} else {
					if (!surpassed) {
						closestMatchOption = o as StandardOption<string, number>;
						return true;
					}
				}
			}

			return false;
		});
		// console.debug("reactiveSetValueFromDate >>>> closestMatchOption", closestMatchOption);
		return closestMatchOption ? (closestMatchOption as CIM)?.value : "";
	},

	// systemDateOnlyForAPI(d: Date, options?: { includeTime?: boolean }): SystemDateOnly {
	// 	// will need to implement includeTime if needed
	// 	// return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

	// 	let sysDate = {
	// 		year: d.getFullYear(),
	// 		month: d.getMonth(),
	// 		day: d.getDate(),
	// 		dayOfWeek: d.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
	// 	};

	// 	return sysDate;
	// },
	// export interface SystemDateOnly {
	// 	year?: number;
	// 	month?: number;
	// 	day?: number;
	// 	dayOfWeek?: SystemDayOfWeek;
	// 	readonly dayOfYear?: number;
	// 	readonly dayNumber?: number;
	//   }

	stringDateForAPI_Nullable(d?: Date | null, options?: { includeTime?: boolean }): string | null {
		if (d) {
			return DateConverter.stringDateForAPI(d, options);
		}
		return null;
	},
	stringDateForAPI(d: Date | string, options?: { includeTime?: boolean }): string {
		if (typeof d === "string") {
			d = new Date(d);
		}
		let stringDate = `${String(d.getFullYear()).padStart(4, "0")}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}`;
		if (options?.includeTime) {
			stringDate += `T${String(d.getHours()).padStart(2, "0")}:${String(d.getMinutes()).padStart(2, "0")}:${String(d.getSeconds()).padStart(
				2,
				"0"
			)}.${d.getMilliseconds()}`;
		}
		return stringDate;
	},

	dateFromDateStringYMD(dateString: string): Date | undefined {
		let elements: Array<string> | null = null;
		if (dateString.indexOf("-") !== -1 && dateString.indexOf("-") < 6) {
			elements = dateString.split("-");
		} else if (dateString.indexOf("/") !== -1 && dateString.indexOf("/") < 6) {
			elements = dateString.split("/");
		}
		if (!elements || elements.length < 3) {
			return undefined;
		}
		elements = elements.map((s) => s.trim());
		let d = new Date();
		d.setFullYear(parseInt(elements[0]));
		d.setMonth(parseInt(elements[1]) - 1);
		d.setDate(parseInt(elements[2]));
		return d;
	},

	stringDateForDisplay(d: Date, options?: { includeTime?: boolean; zeroPadDate?: boolean }): string {
		// will need to implement includeTime if needed
		return `${String(d.getMonth() + 1).padStart(options?.zeroPadDate ? 2 : 0, "0")}/${d.getDate()}/${d.getFullYear()}`;
	},
	"YYYY-MM_to_mM/YY"(s: string | unknown, fullYear: boolean = false) {
		if (typeof s === "string") {
			let d = s.split("-");
			return `${d[1].slice(d[1][0] === "0" ? 1 : 0)}/${fullYear ? d[0] : d[0].slice(2)}`;
		}
		return "?";
	},
	"YYYY-MM-DD_to_mM/dD/YY"(s: string | unknown, fullYear: boolean = false) {
		if (typeof s === "string") {
			let d = s.split("T")[0].split("-");
			return `${d[1].slice(d[1][0] === "0" ? 1 : 0)}/${d[2].slice(d[2][0] === "0" ? 1 : 0)}/${fullYear ? d[0] : d[0].slice(2)}`;
		}
		return "??/??";
	},

	ageFromApiStringDate(apiStringdate: string | null) {
		if (!apiStringdate) {
			return null;
		}
		let x = apiStringdate.split("-");
		let d = new Date(Date.now());
		let yearsOld = d.getFullYear() - parseInt(x[0]);
		if (d.getMonth() + 1 < parseInt(x[1])) {
			// then check day if same month
			yearsOld--;
		}
		return yearsOld;
	},
	subtractMonths(date_: Date, months: number) {
		let date = new Date(date_);
		let newMonth = date.getMonth() - months;
		let newYear = date.getFullYear();
		while (newMonth < 0) {
			newMonth += 12;
			newYear--;
		}
		date.setMonth(newMonth);
		date.setFullYear(newYear);
		return date;
	},
};

export { DateConverter };

type DateRangeSelect_IntervalType = "month";
export type { DateRangeSelect_IntervalType };
