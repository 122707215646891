/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminGetHistoricalDataResult,
  AdminGetHistoricalDataQuery,
  ReportingGetDropdownDataResult,
  ReportingGetStateCountyListResult,
  GetStateCountyListParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHistoricalData = (
    adminGetHistoricalDataQuery: AdminGetHistoricalDataQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetHistoricalDataResult>(
      {url: `/api/admin/Reporting/GetIcdSummary`, method: 'post',
      data: adminGetHistoricalDataQuery
    },
      options);
    }
  


    export const useGetHistoricalData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getHistoricalData>, TError,{data: AdminGetHistoricalDataQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getHistoricalData>, {data: AdminGetHistoricalDataQuery}> = (props) => {
          const {data} = props || {};

          return  getHistoricalData(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getHistoricalData>, TError, {data: AdminGetHistoricalDataQuery}, TContext>(mutationFn, mutationOptions)
    }
    export const getDropdownData = (
    
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportingGetDropdownDataResult>(
      {url: `/api/admin/Reporting/GetDropdownData`, method: 'post'
    },
      options);
    }
  


    export const useGetDropdownData = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getDropdownData>, TError,TVariables, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getDropdownData>, TVariables> = () => {
          ;

          return  getDropdownData(requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getDropdownData>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    export const getStateCountyList = (
    params?: GetStateCountyListParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportingGetStateCountyListResult>(
      {url: `/api/admin/Reporting/GetStateCountyList`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetStateCountyListQueryKey = (params?: GetStateCountyListParams,) => [`/api/admin/Reporting/GetStateCountyList`, ...(params ? [params]: [])];

    
export const useGetStateCountyList = <TData = AsyncReturnType<typeof getStateCountyList>, TError = unknown>(
 params?: GetStateCountyListParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getStateCountyList>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStateCountyListQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getStateCountyList>> = () => getStateCountyList(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getStateCountyList>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

