import { enrichMissingProviderSpeciality } from "api";
import { useState } from "react";
import { Loader } from "shared/components/Loaders/Loader";

const outerScope: CIM = {
	loopCount: 0,
	recordsUpdated: 0,
	continue: true,
	set_requestInProgress: (x: boolean) => null,
};

async function enrichMissingProviderSpecialityLoop(): Promise<CIM> {
	outerScope.set_requestInProgress(true);
	let result = await enrichMissingProviderSpeciality({ request: { providerUpdateLimit: 10000 } });

	console.log(result);
	if (result.result?.success) {
		outerScope.loopCount++;
		outerScope.recordsUpdated += result.result?.data?.updatedCount ?? 0;
		outerScope.set_requestInProgress(false);
		if (result.result?.data?.updatedCount && result.result.data.updatedCount > 0) {
			return enrichMissingProviderSpecialityLoop();
		}
	}
}

const EnrichMissingSpeciality = () => {
	const [requestInProgress, set_requestInProgress] = useState<string | null>(null);

	outerScope.set_requestInProgress = set_requestInProgress;

	return (
		<div>
			<h2>Enrich Missing Specialty</h2>

			<div style={{ display: "flex", flexDirection: "column", maxWidth: "200px" }}>
				<button
					disabled={!!requestInProgress}
					onClick={async () => {
						await enrichMissingProviderSpeciality({ request: { providerUpdateLimit: 10000 } });
					}}
				>
					enrichMissingProviderSpeciality
				</button>
				<br />
				<button
					disabled={!!requestInProgress}
					onClick={async () => {
						outerScope.continue = true;
						await enrichMissingProviderSpecialityLoop();
					}}
				>
					enrichMissingProviderSpeciality Loop
				</button>
				<button
					disabled={!!requestInProgress}
					onClick={() => {
						outerScope.continue = false;
					}}
				>
					STOP
				</button>
			</div>
			<p>..{requestInProgress}</p>

			<Loader title={<>Records Updated: {outerScope.recordsUpdated}</>} active={!!requestInProgress} />

			<pre>{JSON.stringify({ outerScope }, null, 4)}</pre>
		</div>
	);
};

export { EnrichMissingSpeciality };
