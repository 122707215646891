import { Box, Stack } from "@mui/material";
import { SiteFilterData } from "../filters/SiteFilterData";
import MyProfileOptions from "account/MyProfileOptions";
import NavSubMenu from "shared/components/NavSubMenu";

function PageHeader() {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				height: {
					xs: "auto",
					sm: "4.5rem",
				},
				width: "100%",
				flexFlow: "wrap",
			}}
		>
			<NavSubMenu />
			<Stack
				direction="row"
				sx={{
					flex: "1",
					justifyContent: { xs: "space-between", sm: "flex-end" },
					alignItems: "center",
					pb: {
						xs: 1,
						sm: 0,
					},
				}}
			>
				<Box paddingRight={1}>
					<SiteFilterData />
				</Box>
				<Box paddingLeft={1}>
					<MyProfileOptions />
				</Box>
			</Stack>
		</Stack>
	);
}

export default PageHeader;
