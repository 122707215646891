import { ReactNode } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { ModelsTerminalDrugStructureDataStub, useGetMedicationDropdownList } from "api";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { useSearchInput, useSearchText } from "shared/utilities/search";

type MedicationSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setSelectedValue?: (values: string | null) => void;
};
function MedicationSelect(props: Readonly<MedicationSelectProps>) {
	const { setValue } = useSearchInput();
	const searchText = useSearchText();
	let jobIds = useFilterQuery().memberTypes.toString();
	let dropdownOptions = useGetMedicationDropdownList({ searchQuery: searchText, jobIds }).data?.medications;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			loading
			getOptionLabel={(code: ModelsTerminalDrugStructureDataStub) => {
				return code.ndc + ": " + code?.name;
			}}
			isOptionEqualToValue={(option: ModelsTerminalDrugStructureDataStub, value: ModelsTerminalDrugStructureDataStub) => {
				return option.ndc === value.ndc;
			}}
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setValue(value);
			}}
			onChange={(_event, currentValue: ModelsTerminalDrugStructureDataStub | null) => {
				props.setSelectedValue && props.setSelectedValue(currentValue?.ndc ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"NDC code / name"} />}
		/>
	);
}
export { MedicationSelect };
