import React from "react";
import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, withFormStatus } from "shared/forms";

interface ResetPasswordFormProps {
	verificationCode?: string;
	onConfirmPassword: (password: string, code: string) => void;
}

function ResetPasswordForm({ verificationCode, onConfirmPassword }: ResetPasswordFormProps) {
	const { register, handleSubmit } = useForm<{
		password: string;
		confirmPassword: string;
		verificationCode: string;
	}>({ defaultValues: { verificationCode } });

	const onSubmit = handleSubmit((form) => onConfirmPassword(form.password, form.verificationCode));

	return (
		<Card
			component="form"
			onSubmit={onSubmit}
			sx={{
				width: "100%",
				paddingLeft: "24px",
				paddingRight: "24px",
				paddingTop: "36px",
				paddingBottom: "24px",
				boxSizing: "border-box",
			}}
			elevation={4}
		>
			<CardContent>
				<Stack spacing={2}>
					<Typography>
						Please check your email for a 6 digit verification code. Once you've received the code, please enter that code below.
					</Typography>
					{verificationCode ? null : <TextField label="Verification Code (Check Email)" {...register("verificationCode")} variant="standard" />}
					<TextField label="New Password" type="password" {...register("password", { required: true })} variant="standard" />
					<TextField
						label="Confirm New Password"
						type="password"
						{...register("confirmPassword", {
							required: true,
							validate: {
								matchesPassword: () => {
									console.log("matchesPassword");
									return true;
								},
								// fail: () => {
								//   console.log("fail");

								//   return "fails fail";
								// },
							},
						})}
						variant="standard"
					/>
				</Stack>
			</CardContent>
			<FormErrorAlert />
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button type="submit" sx={{ backgroundColor: "core.darkblue" }}>
					Change Password
				</Button>
				<Button sx={{ justifyContent: "flex-end", mt: 0, color: "gray.dark" }} component={Link} to="/signin" variant="text">
					Cancel
				</Button>
			</CardActions>
		</Card>
	);
}

export default withFormStatus(ResetPasswordForm);
