import { Box, InputLabel, Select, OutlinedInput, MenuItem, ListItemText } from "@mui/material";
import { ProvidersHistoricalLookupRecord } from "api";
import { Control, Controller } from "react-hook-form";

type ReportingSelectProps = {
	control: Control<any, any>;
    name: string;
	label: string;
	data: ProvidersHistoricalLookupRecord[] | undefined;
    
};
function ReportingSelect(props: ReportingSelectProps) {
	
	return (
		<Controller
			control={props.control}
			name={props.name}
			render={({ field: { onChange, value } }) => (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<InputLabel id={`${props.name}-input-label`}>{props.name}</InputLabel>
					<Select
						labelId={`${props.name}-input-label`}
						id={`${props.name}-select`}
						value={value}
						onChange={onChange}
						input={<OutlinedInput label={props.label} />}
						sx={{ display: "flex" }}
					>
						<MenuItem key={`All ${props.label}`} value={"null"}>
							<ListItemText primary={`All ${props.label}`} />
						</MenuItem>
						{props.data?.map((item) => {
							return (
								<MenuItem key={item.id} value={item.code}>
									<ListItemText primary={item.description} />
								</MenuItem>
							);
						})}
					</Select>
				</Box>
			)}
		/>
	);
}

export default ReportingSelect;