import { useContextAppSettings } from "DataProvider/contexts/Context_AppSettings";
import { useQueryClient } from "react-query";

const ResendQueryButton = ({ queryCacheName }: { queryCacheName: string }) => {
	const appSettings = useContextAppSettings();
	const queryClient = useQueryClient();
	if (appSettings.enable_resend_buttons) {
		return (
			<button
				onClick={() => {
					queryClient.invalidateQueries(queryCacheName);
				}}
			>
				Resend ({queryCacheName})
			</button>
		);
	} else {
		return null;
	}
};

export { ResendQueryButton };
