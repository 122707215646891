import { createContext, useContext, useState } from "react";
import { Condition, SubRange } from "shared/schemas/dataStructures";

/*
██████  ██  █████   ██████  ███    ██  ██████  ███████ ██ ███████     ███████ ██ ██      ████████ ███████ ██████  ███████ 
██   ██ ██ ██   ██ ██       ████   ██ ██    ██ ██      ██ ██          ██      ██ ██         ██    ██      ██   ██ ██      
██   ██ ██ ███████ ██   ███ ██ ██  ██ ██    ██ ███████ ██ ███████     █████   ██ ██         ██    █████   ██████  ███████ 
██   ██ ██ ██   ██ ██    ██ ██  ██ ██ ██    ██      ██ ██      ██     ██      ██ ██         ██    ██      ██   ██      ██ 
██████  ██ ██   ██  ██████  ██   ████  ██████  ███████ ██ ███████     ██      ██ ███████    ██    ███████ ██   ██ ███████
*/
type DiagnosisFilters = {
	Conditions: Array<Condition>;
	ICD10Codes: Array<string>;
	ICD10CodesByCondition: { [key: string]: SubRange };
	SubRangeByCondition: { [key: string]: SubRange };
	DiagnosisType: Array<string>;
};

export type DiagnosisFiltersUpdate = {
	Conditions?: Array<Condition>;
	ICD10Codes?: Array<string>;
	ICD10CodesByCondition?: { [key: string]: SubRange };
	SubRangeByCondition?: { [key: string]: SubRange };
	DiagnosisType?: Array<string>;
};

let initial_diagnosis_filters: DiagnosisFilters = {
	Conditions: [],
	ICD10Codes: [],
	ICD10CodesByCondition: {},
	SubRangeByCondition: {},
	DiagnosisType: [],
};

const DiagnosisFiltersContext = createContext({
	values: initial_diagnosis_filters,
	setValues: (u: DiagnosisFiltersUpdate) => {},
});

function Context_DiagnosisFilters_Provider(props: { children: React.ReactNode }) {
	const [diagnosisFilters, setDiagnosisFilters] = useState(initial_diagnosis_filters);

	const wrappedSetDiagnosisFilters = (update: DiagnosisFiltersUpdate) => {
		let temp_updated: DiagnosisFiltersUpdate = {};
		if (update.hasOwnProperty("ICD10CodesByCondition")) {
			// if ICD10CodesByCondition was provided it needs to change ONLY the ailments specified inside ICD10CodesByCondition and not overwrite the entire property
			temp_updated.ICD10CodesByCondition = {};
			Object.assign(temp_updated.ICD10CodesByCondition, diagnosisFilters.ICD10CodesByCondition, update.ICD10CodesByCondition);
		}
		if (update.hasOwnProperty("SubRangeByCondition")) {
			// if SubRangeByCondition was provided it needs to change ONLY the ailments specified inside SubRangeByCondition and not overwrite the entire property
			temp_updated.SubRangeByCondition = {};
			Object.assign(temp_updated.SubRangeByCondition, diagnosisFilters.SubRangeByCondition, update.SubRangeByCondition);
		}

		setDiagnosisFilters(Object.assign({}, diagnosisFilters, update, temp_updated));
		console.groupEnd();
	};

	return (
		<DiagnosisFiltersContext.Provider
			value={{
				values: diagnosisFilters,
				setValues: wrappedSetDiagnosisFilters,
			}}
		>
			{props.children}
		</DiagnosisFiltersContext.Provider>
	);
}

export { Context_DiagnosisFilters_Provider as ContextDiagnosisFiltersProvider, DiagnosisFiltersContext };

export type { DiagnosisFilters };

// import { DiagnosisFiltersContext } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
// import { useContext } from "react";
// import { DiagnosisFiltersContext } from "../DataProvider";

const useDiagnosisFilters = () => {
	return useContext(DiagnosisFiltersContext);
};

export { useDiagnosisFilters };
