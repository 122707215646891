import { Box, Card, CardContent, CardHeader, SxProps, Theme } from "@mui/material";
import { ModelsPieChartModel } from "api";
import { Loader } from "shared/components/Loaders/Loader";
import { StandardPieChart } from "shared/components/charts/pie/StandardPieChart";
import { palette } from "theme";

type topIcdProps = {
	icds: ModelsPieChartModel[];
	title: string;
	queryChips?: JSX.Element;
	charSx?: SxProps<Theme>;
};
function TopRelatedConditionsPieChart(props: topIcdProps) {
	return (
		<>
			{props.icds ? (
				<Card elevation={4} sx={{ mb: 2 }}>
					<CardHeader
						title={
							<>
								{props.title}
								{props.queryChips}
							</>
						}
					/>
					<CardContent sx={{ pt: 0, display: "flex" }}>
						<Box
							sx={{
								display: "flex",
								flex: 1,
								flexDirection: "column",
								padding: 2,
								borderRadius: "1rem",
								m: 1,
								backgroundColor: palette.gray.main,
							}}
						>
							<StandardPieChart
								data={props.icds as any}
								showLegend
								displayPercentage
								responsiveHeight={525}
								innerRadius={60}
								tooltipFormatter={(value, name) => {
									return Math.round(value * 100) / 100 + "%";
								}}
								legendRowSx={{ pb: 0, pt: 0 }}
							/>
						</Box>
					</CardContent>
				</Card>
			) : (
				<Loader />
			)}
		</>
	);
}

export default TopRelatedConditionsPieChart;
