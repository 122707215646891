import { UserFollowedItemResponse, retrieveFollowedItems } from "api";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const UserFollowedItems_Context = createContext<{
	items: Array<UserFollowedItemResponse>;
	itemsByType: Partial<{ [key in UserFollowedItemTypeNames]: Array<UserFollowedItemResponse> }>;
	trigger_refresh: () => void;
}>({
	items: [],
	itemsByType: {},
	trigger_refresh: () => {},
});

const UserFollowedItemTypes: { [key in UserFollowedItemTypeNames]: number } = {
	DIAGNOSIS: 1,
	DIAGNOSIS_RANGE: 2,
	MEDICATION: 3,
	PRODUCTSERVICE: 4,
	PROVIDER: 5,
	PATIENT: 6,
	ORGANIZATION: 7,
};

function Context_UserFollowedItems_Provider(props: { children: React.ReactNode }) {
	const [updateTrigger, set_updateTrigger] = useState(0);

	const trigger_refresh = useMemo(() => {
		return () => {
			set_updateTrigger(updateTrigger + 1);
		};
	}, [updateTrigger, set_updateTrigger]);

	const [followedItems, set_followedItems] = useState<{
		items: Array<UserFollowedItemResponse>;
		itemsByType: Partial<{ [key in UserFollowedItemTypeNames]: Array<UserFollowedItemResponse> }>;
	}>({ items: [], itemsByType: {} });

	const [responseReceived, set_responseReceived] = useState<boolean>(false); // use to block render until the response has been received

	const [isRetrieving, set_isRetrieving] = useState<boolean>(false);
	useEffect(() => {
		if (!isRetrieving) {
			set_isRetrieving(true);
			retrieveFollowedItems({}).then((r) => {
				if (r.items && r.itemsByType) {
					set_followedItems({ items: r.items, itemsByType: r.itemsByType });
				}
				set_isRetrieving(false);
				set_responseReceived(true);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTrigger, set_followedItems]);

	const value = useMemo(() => {
		return {
			items: followedItems.items,
			itemsByType: followedItems.itemsByType,
			trigger_refresh: trigger_refresh,
		};
	}, [followedItems.items, followedItems.itemsByType, trigger_refresh]);

	return (
		//eslint-disable-next-line react/jsx-pascal-case
		<UserFollowedItems_Context.Provider value={value}>{responseReceived ? props.children : null}</UserFollowedItems_Context.Provider>
	);
}

export { UserFollowedItems_Context as UserFollowedItemsContext, Context_UserFollowedItems_Provider as ContextUserFollowedItemsProvider, UserFollowedItemTypes };

const useContextUserFollowedItems = () => {
	return useContext(UserFollowedItems_Context);
};

export { useContextUserFollowedItems };
