import { Box, Typography, Container, Grid, Card, CardHeader, Stack, Rating } from "@mui/material";
import {
	DoctorGetDoctorDetailsResult,
	getDoctorDetails,
	useGetDoctorTopCondtions,
	useGetDoctorTopIcdSpend,
	useGetTopProvidersAssociated,
	ModelsNPIEnumerationType,
} from "api";
import { useRunFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { useMemo } from "react";
import DoctorAvatar from "sections/members_doctors/doctors/components/DoctorAvatar";
import { displayConverter } from "shared/utilities/displayConverter";
import TopRelatedConditionsPieChart from "./TopRelatedConditionsPieChart";
import { Loader } from "shared/components/Loaders/Loader";
import { icons, palette } from "theme";
import { CartesianGrid } from "recharts";
import Icon from "shared/Icon";
import { StandardBarChart } from "shared/components/charts/bar/StandardBarChart";

function HistoricalProviderDetail(props: { doctorID: number | null }) {
	const { data } = useRunFilterQuery<DoctorGetDoctorDetailsResult>(getDoctorDetails, {
		npi: props.doctorID?.toString(),
	});

	const { data: topConditions } = useGetDoctorTopCondtions({ npi: props.doctorID?.toString() });
	const { data: topConditionsSpend } = useGetDoctorTopIcdSpend({ npi: props.doctorID?.toString() });
	const { data: topDoctorsAssociated } = useGetTopProvidersAssociated({ npi: props.doctorID?.toString(), entityType: ModelsNPIEnumerationType.NUMBER_1 });
	const { data: topFacilitiesAssociated } = useGetTopProvidersAssociated({ npi: props.doctorID?.toString(), entityType: ModelsNPIEnumerationType.NUMBER_2 });
	const formatter = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
	const doctorSpecialty = useMemo(() => {
		if (data?.doctor?.specialty && data?.doctor?.specialty?.length > 0) {
			return data?.doctor?.specialty[0];
		} else {
			return "N/A";
		}
	}, [data]);
	const zipCode = useMemo(() => {
		if (data?.doctor?.postalCode) {
			if (data.doctor.postalCode?.length > 5) {
				return data?.doctor?.postalCode?.slice(0, 5) + "-" + data?.doctor?.postalCode?.slice(5, 9);
			} else {
				return data?.doctor?.postalCode;
			}
		}
		return "";
	}, [data?.doctor?.postalCode]);
	const aggTotalCharges = useMemo(() => {
		return topConditionsSpend?.topIcdSpendTotals?.map((i) => i.totalCharges ?? 0).reduce((a: any, b: any) => a + b, 0) ?? 0;
	}, [topConditionsSpend?.topIcdSpendTotals]);

	var phoneNumber = data?.doctor?.phoneNumber + "";
	if (phoneNumber.length === 10) {
		phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
	}

	return (
		<Container maxWidth="md">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4} sx={{ mb: 1.5 }}>
						<CardHeader title="Doctor Details" />
						<Box
							sx={{
								display: "flex",
								flex: 1,
								flexDirection: "column",
								padding: 2,
								borderRadius: "1rem",
								m: 1,
								backgroundColor: palette.gray.main,
							}}
						>
							<DoctorAvatar
								doctorName={displayConverter.display(data?.doctor?.name ?? "N/A")}
								doctorSpecialty={displayConverter.display(doctorSpecialty)}
								doctorAffiliation={displayConverter.display(data?.doctor?.affiliation ?? "N/A")}
								doctorRating={data?.doctor?.rating ?? 0}
							/>
							<Box sx={{ display: "flex", flexDirection: "row", paddingBottom: "1rem", flex: 2 }}>
								<Box flex={1} sx={{ flexDirection: "column" }}>
									<Typography sx={{ fontWeight: "bold" }}>Address</Typography>
									<Typography>{data?.doctor?.address}</Typography>
									<Typography>{data?.doctor?.city + ", " + data?.doctor?.state + ", " + zipCode}</Typography>
									<Typography>{data?.doctor?.secondLineAddress}</Typography>
									<Typography>{phoneNumber}</Typography>
								</Box>
								<Box flex={1} sx={{ flexDirection: "column", flex: 2 }}>
									<Typography sx={{ fontWeight: "bold" }}>Specialties</Typography>
									{data?.doctor?.specialty?.map((sp) => {
										return <Typography key={sp}>{sp}</Typography>;
									})}
								</Box>
								<Box flex={1} sx={{ flexDirection: "column", flex: 1 }}>
									<Typography sx={{ fontWeight: "bold" }}>Npi Number</Typography>
									{data?.doctor?.npi}
								</Box>
							</Box>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12}>
					{topConditions?.models ? <TopRelatedConditionsPieChart title="Top 10 Conditions" icds={topConditions?.models ?? []} /> : <Loader />}
				</Grid>
				<Grid item xs={12}>
					{topConditionsSpend?.topIcdSpendTotals ? (
						<Card elevation={4} sx={{ mb: 1.5 }}>
							<CardHeader title="Top Condition Spend" />
							<>
								<Box
									sx={{
										display: "flex",
										flex: 1,
										flexDirection: "column",
										padding: 2,
										borderRadius: "1rem",
										m: 1,
										backgroundColor: palette.gray.main,
									}}
								>
									<Stack direction="column" justifyContent="center" textAlign="center">
										<Typography fontWeight="bold" fontSize={18} color={palette.red.main}>
											Total Charges
										</Typography>
										<Typography color={palette.red.main}>{formatter.format(aggTotalCharges)}</Typography>
										<Icon icon={icons.circle} color={palette.red.main} sx={{ alignSelf: "center" }} />
									</Stack>
									<StandardBarChart
										data={topConditionsSpend?.topIcdSpendTotals as any}
										dataKey="code"
										showGrid={true}
										showLegend={false}
										responsiveHeight={300}
										bar_definitions={[
											{
												dataKey: "totalCharges",
												name: "Total Charges",
												fill: palette.red.main,
											},
										]}
										tooltipFormatter={(value, name) => {
											return formatter.format(Number(value));
										}}
										yAxisTickFormatter={(value, name) => {
											return formatter.format(Number(value));
										}}
										xAxisTickFormatter={(value, name) => {
											return value.toString();
										}}
										cartesianGrid={<CartesianGrid strokeDasharray="1" vertical={false} />}
										axisLabel="axis label"
									/>
								</Box>
							</>
						</Card>
					) : (
						<Loader />
					)}
				</Grid>
				<Grid item xs={12}>
					{topDoctorsAssociated?.models ? (
						<Card elevation={4} sx={{ mb: 1.5 }}>
							<CardHeader title="Top 10 Associated Doctors" />
							<>
								{topDoctorsAssociated?.models?.length === 0 ? (
									<Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} padding={1} sx={{ pl: 2.5 }}>
										<Typography fontWeight={"bold"}>None associated</Typography>
									</Box>
								) : (
									<Typography />
								)}
								{topDoctorsAssociated?.models?.map((data) => {
									return (
										<Box
											display={"flex"}
											flexDirection={"row"}
											justifyContent={"space-between"}
											borderBottom={"1px solid grey"}
											padding={1}
										>
											<Typography color={palette.gray.darker} fontWeight={"bold"}>
												{data?.doctor?.namePrefix} {data?.doctor?.firstName} {data?.doctor?.lastName}
												<br />
												{data?.doctor?.radiusRating && (
													<Rating size="small" name="read-only" value={data?.doctor?.radiusRating} readOnly />
												)}
											</Typography>
											<Typography color={palette.green.main} fontWeight={"bold"}>
												{data?.percentOfTotalCount?.toFixed(4) + "%"}
											</Typography>
										</Box>
									);
								})}
							</>
						</Card>
					) : (
						<Loader />
					)}
				</Grid>

				<Grid item xs={12}>
					{topFacilitiesAssociated?.models ? (
						<Card elevation={4} sx={{ mb: 1.5 }}>
							<CardHeader title="Top 10 Associated Facilites" />

							{topFacilitiesAssociated?.models?.length === 0 ? (
								<Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} padding={1} sx={{ pl: 2.5 }}>
									<Typography fontWeight={"bold"}>None associated</Typography>
								</Box>
							) : (
								<Typography />
							)}

							{topFacilitiesAssociated?.models?.map((data) => {
								return (
									<Box
										key={data?.doctor?.npi}
										display={"flex"}
										flexDirection={"row"}
										justifyContent={"space-between"}
										borderBottom={"1px solid grey"}
										padding={1}
									>
										<Typography color={palette.gray.darker} fontWeight={"bold"}>
											{data?.doctor?.organizationName}
											<br />
											{data?.doctor?.radiusRating && <Rating size="small" name="read-only" value={data?.doctor?.radiusRating} readOnly />}
										</Typography>
										<Typography color={palette.green.main} fontWeight={"bold"}>
											{data?.percentOfTotalCount?.toFixed(4) + "%"}
										</Typography>
									</Box>
								);
							})}
						</Card>
					) : (
						<Loader />
					)}
				</Grid>
			</Grid>
		</Container>
	);
}

export default HistoricalProviderDetail;
