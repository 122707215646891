import { ReactNode } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { useX_getPatientDropdown } from "api_extension/useQueryX/cachePatientData/useX_getPatientDropdown";
import { useSearchInput, useSearchText } from "shared/utilities/search";

type PatientSelectProps = {
	icon?: ReactNode;
	placeholder?: string;
	styles?: SxProps<Theme>;
	setSelectedValue?: (values: string | null) => void;
};
function PatientSelect(props: Readonly<PatientSelectProps>) {
	const { setValue } = useSearchInput();
	const searchText = useSearchText();
	let dropdownOptions = useX_getPatientDropdown({ searchQuery: searchText }).data?.patientIds;
	return (
		<Autocomplete
			sx={{
				minWidth: "30rem",
				width: "100%",
				...props.styles,
			}}
			autoComplete
			options={dropdownOptions ?? []}
			onInputChange={(_event, value) => {
				setValue(value);
			}}
			onChange={(_event, currentValue: string | null) => {
				props.setSelectedValue && props.setSelectedValue(currentValue ?? null);
			}}
			renderInput={(params) => <TextField {...params} label="" placeholder={"Member Id"} />}
		/>
	);
}
export { PatientSelect };
