import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { queryPatientBySearchTerm, QueryPatientBySearchTermParams } from "api";
import { useQuery } from "react-query";

export const useX_getPatientDropdown = (req: QueryPatientBySearchTermParams) => {
	let fqrX = useFilterQuery();

	let request: QueryPatientBySearchTermParams = {
		jobIds: fqrX.memberTypes.toString(),
		searchQuery: req.searchQuery,
	};

	let cacheKeys = ["queryPatientBySearchTerm", "queryPatientBySearchTerm_" + JSON.stringify(request)];
	return useQuery(cacheKeys, queryPatientBySearchTerm.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
