import { getProductServiceGenderAndAge } from "api/-cacheproductservicedata/-cacheproductservicedata";
import { RequestModelsCacheMonthlyQueryRequest } from "api/backend.schemas";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { useQuery } from "react-query";

function getProductServiceGenderAndAgeX(request: RequestModelsCacheMonthlyQueryRequest) {
	return getProductServiceGenderAndAge(request);
}

const useX_getProductServiceGenderAndAge = (codes: string[] | null) => {
	let r = useFilterQuery();

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes,
		itemType: "PRODUCTSERVICE",
	};

	let cacheKeys = ["getProductServiceGenderAndAge", "getProductServiceGenderAndAge_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getProductServiceGenderAndAgeX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getProductServiceGenderAndAge };
