import React, { FunctionComponent } from "react";

export const CustomizedDot: FunctionComponent<any> = (props: any) => {
  const { cx, cy } = props;

  return (
    <svg
      x={cx - 4}
      y={cy - 4}
      width={8}
      height={8}
      fill={props.fill}
      viewBox="0 0 100 100"
    >
      <circle cx="48" cy="48" r="48" />
    </svg>
  );
};
