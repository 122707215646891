import { Card, CardContent, CardHeader, Tabs } from "@mui/material";
import React from "react";
// import TopMedicationsPanel from "./TopMedicationsPanel";
// import { TopDiagnosisPanel } from "./TopDiagnosisPanel";
// import TopProductServicePanel from "./TopProductServicePanel";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { TopCodesPanel } from "./TopCodesPanel";

const { TabControl, TabPanel } = TabsUtilities.create_TabRenderFunctions("dasboard-overview");

function TopSpendCard() {
	const [activeTab, set_activeTab] = React.useState(0);

	return (
		<Card
			elevation={4}
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				minHeight: { xs: "700px", xl: "auto" },
			}}
		>
			<CardHeader title="Top Spend" />
			<CardHeader
				sx={{
					"& .MuiCardHeader-action": {
						width: "100%",
					},
				}}
				action={
					<Tabs
						value={activeTab}
						onChange={(eve, value) => {
							set_activeTab(value);
						}}
						aria-label="Dashboard Overview tabs"
						variant="scrollable"
						sx={{
							width: "100%",
							"& .MuiTab-root": {
								flex: { xs: "none", md: "1" },
								whiteSpace: "nowrap",
								maxWidth: "none",
							},
						}}
					>
						<TabControl label="Top Diagnosis" value={0} />
						<TabControl label="Top Prescriptions" value={1} />
						<TabControl label="Top Procedures" value={2} />
					</Tabs>
				}
			/>
			<CardContent sx={{ flex: 1 }}>
				<TabPanel value={activeTab} index={0}>
					{/* <TopDiagnosisPanel /> */}
					<TopCodesPanel itemType="DIAGNOSIS" />
				</TabPanel>
				<TabPanel value={activeTab} index={1}>
					{/* <TopMedicationsPanel /> */}
					<TopCodesPanel itemType="MEDICATION" />
				</TabPanel>
				<TabPanel value={activeTab} index={2}>
					{/* <TopProductServicePanel /> */}
					<TopCodesPanel itemType="PRODUCTSERVICE" />
				</TabPanel>
			</CardContent>
		</Card>
	);
}

export { TopSpendCard };
