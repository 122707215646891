import { ProvidersRiskModelFilterCriteria } from "api/backend.schemas";
import { retrieveCachePatientRiskModelScoreOverview } from "api/cache-patient-data/cache-patient-data";
import { useQuery } from "react-query";

function retrieveCachePatientRiskModelScoreOverviewX(filter: ProvidersRiskModelFilterCriteria | null) {
	if (!filter) {
		return null;
	}
	console.log("useX_GetCachePatientRiskModelScoreOverview");
	return retrieveCachePatientRiskModelScoreOverview(filter);
}

export const useX_GetCachePatientRiskModelScoreOverview = (filter: ProvidersRiskModelFilterCriteria | null) => {
	let cacheKeys = ["retrieveCachePatientRiskModelScoreOverview", JSON.stringify(filter)];
	return useQuery(cacheKeys, retrieveCachePatientRiskModelScoreOverviewX.bind(null, filter), {
		cacheTime: 60 * 60 * 60 * 1000,
		staleTime: 60 * 60 * 60 * 1000,
	});
};
